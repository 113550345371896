$('#fileupload').on(
	'change', function (e) {
		$('#employee_account-files-form').submit();
	}
);
kv.controller('EmployeeAccountViewCtrl', function ($scope, $element, $attrs, $injector, $rootScope, $http, resource, $uibModal, $filter) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector);
	vm.primaryKey = 'employee_account_id';
	vm.entity = 'employee_account';
	vm.rest = resource.init('employee_account');
	// vm.getInfinitScroll({});

	vm.employee_account = JSON.parse(vm.bladeParams.employeeAccount);

	vm.aux_accounts_validation = true;
	vm.groups = {};
	vm.employee_account_status = vm.bladeParams.employeeAccountStatus;
	vm.commentsConfig = {
		entity_type: vm.entity,
		entity_id: vm.employee_account.employee_account_id
	};

	/**
	 * get expenses linked to the current employee account
	 * @type {number}
	 */
	let counter = 0;
	vm.getExpenses = function () {
		counter++;
		let REST = resource.init('expense');
		REST.get({
			url: 'expense/list?employee_account_id=' + vm.employee_account.employee_account_id,
			params: {
				direction: ( counter & 1 ) ? "ASC" : "DESC",
			}
		}).then(function (data) {
			vm.expenses = data.data;
		});
	};

	/**
	 * get total amount for the current employee account
	 */
	vm.getTotalAmount = function () {
		vm.rest.get({
			url: 'employee_account/getTotalAmount/' + vm.employee_account.employee_account_id
		}).then(function (res) {
			$('.totalAmount').html($filter('number')(parseFloat(res.total_amount)));
			$('.totalBaseAmount').html($filter('number')(parseFloat(res.total_base_amount)));
			$('.totalVatAmount').html($filter('number')(parseFloat(res.total_vat_amount)));
		});
	};

	let checkExpenseValidation = function (extra_validation, status_id, callback) {
		// if tenant has extra validation
		// daca este de tip chitanta sau factura fa validarea
		if ([1, 2].indexOf(parseInt(vm.employee_account.type_id)) > -1 && parseInt(extra_validation) == 1) {
			//foreach expenses check if validation fields are completed
			vm.expenses.forEach(function ($expense) {
				if (parseInt($expense.accounts_validation) == 0) {
					vm.aux_accounts_validation = false;
					return;
				}
				callback(status_id);
				return;
			});

		}
	}

	let approveEmployeeAccount = function (status_id) {

		// Comentat 30-mar-2018, nu se stie ce ce se face asta ( nu este ruta/nimic in repo )
		// if(status_id == 4) {
		//     REST.get({
		//         url: "employee_account/allocateExpensesOnValidate/{{ $id }}"
		//     });
		// }
		let postData = angular.copy(vm.employee_account);
		postData.status_id = status_id;
		let rest = resource.init('employee_account');
		rest.update({
			id: vm.employee_account.employee_account_id,
			data: postData,
			params: {
				force_update_entity: true
			}
		}).then(function (response) {
			if (response.status == true) {
				swal(vm.trans("LANG.SUCCESS"), vm.trans("LANG.PAGE_WILL_RELOAD"), "success");
				setTimeout(function (){
					location.reload();
				}, 2500);
			} else {
				vm.alert("LANG.ERROR", vm.trans("ERROR_WHILE_SAVING"));
			}
		});

	}
	// 26 apr -> am adaugat parametrul pentru verificare daca butonul este afisat la deconturile interne
	// extra validation - citit din param_value, employee_account_require_extra_information_on_validation, se aplica doar daca este <> 0
	vm.changeEmployeeAccountStatus = function (status_id, is_internal, extra_validation, expense_account_info) {
		// variable used for display ERROR for blank fields
		vm.aux_accounts_validation = true;
		// if valiation status is approved
		if (status_id == 4 && is_internal == 0 && parseInt(expense_account_info) == 1) {	//status = aprobat
			checkExpenseValidation(extra_validation, status_id, approveEmployeeAccount);
		} else {
			approveEmployeeAccount(status_id);
		}


	};

	/**
	 * calculate if current employee account allows change
	 * @returns {boolean}
	 */
	vm.allowChange = function () {
		if([3, 4, 5, 6].indexOf(parseInt(vm.employee_account.type_id)) > -1)
			return true;

		if ((parseInt(vm.bladeParams.expenseAccountingInfo) === 1) && (!vm.current_accounting_period || !vm.current_accounting_period.id))
			return false;

		if (parseInt(vm.bladeParams.isBillingOrContabilitateOrAdmin) === 1)
			return true;

		if (parseInt(vm.bladeParams.hasAccessToUpdate) === 1)
			return vm.employee_account_status_id === 1;

		if (parseInt(vm.employee_account.is_created_by) === API.user.user_id)
			return vm.employee_account_status_id === 1;

		return false;
	};

	/**
	 * check if current employee account allows delete
	 * @param entity
	 * @returns {boolean}
	 */
	vm.allowDelete = function (entity) {
		if (entity.is_created_by == API.user.user_id && vm.employee_account_status_id == 1) return true;
		else return (vm.bladeParams.hasAccessToDestroy == "1");
	};

	/**
	 * check the current accounting period. if not defined, raise error
	 */
	if(parseInt(vm.bladeParams.expenseAccountingInfo) == 1){
		vm.rest.get({
			url: "filter/accounting_period/current_accounting_period"
		}).then(function (res) {
			vm.current_accounting_period = res;
			if (!vm.current_accounting_period.id) toastr.error("You must setup an account period for current month!");
		});
	}

	/**
	 * edit employee account screen
	 */
	vm.editEmployeeAccount = function () {
		vm.openModal({
			size: 'md',
			templateUrl: 'employee_account-edit',
			controller: 'EmployeeAccountEditCtrl',
			resolve: {
				data: function () {
					return {
						index: 1,
						entity: vm.employee_account,
						type: vm.employee_account.sys_employee_account_type,
						employee_account: vm.employee_account
					};
				}
			}

		}).then(response => {
			if (!response) return;
			// this.infinitScroll.items.unshift(response.entity);
			window.location = "/employee_account/" + response.entity.employee_account_id;
		});
	};

	/**
	 * edit expense screen
	 * @param index
	 * @param entity
	 * @param duplicate
	 */
	vm.addEntity = function (index, entity, duplicate) {
		vm.openModal({
			templateUrl: 'expense-edit',
			controller: 'ExpenseEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					return {
						index: index,
						entity: entity,
						editFromEmployeeAccount: true,
						employee_account: vm.employee_account,
						current_accounting_period: vm.current_accounting_period,
						duplicate: duplicate,
					};
				}
			}
		}).then(response => {
			if (!response || !response.entity) return;
			if (response.index == -1) vm.expenses.unshift(response.entity);
			else angular.extend(vm.expenses[response.index], response.entity);
			vm.getExpenses();
			vm.getTotalAmount();
		});
	};

	/**
	 * add expenses screen
	 *
	 * @param employeeAccountId
	 */
	vm.addExpenseInEmployeeAccount = function (employeeAccountId) {
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'employee_account_add_expense',
			controller: function ($uibModalInstance, data, resource, $filter, cmdate, validator, $scope, search) {
				var vm = this;
				vm.primaryKey = 'expense_id';
				vm.entity = 'expense';
				vm.search = {};
				var expenseIdsList = {};
				var REST = resource.init(vm.entity);
				vm.action = {
					save: save,
					cancel: cancel,
					addEdit: add,
				};

				function add($index) {
					var expenseId = vm.result[$index][vm.primaryKey];

					if (!vm.result[$index].checked) {
						expenseIdsList[expenseId] = vm.result[$index][vm.primaryKey];
						$("#" + expenseId + ' > .pb-10').hide();
						$("#" + expenseId).prepend('<td width="5px" class="check" rowspan="1"><i class="fa fa-check"></i></td>');
						vm.result[$index].checked = true;
					} else {
						delete expenseIdsList[expenseId];
						$("#" + expenseId + '> td.check').remove();
						$("#" + expenseId + ' > .pb-10').show();
						vm.result[$index].checked = false;
					}
				}


				function save($saveAll = null) {
					var REST = resource.init('expense');
					if ($saveAll != null) {
						REST.update({
							url: "expense/addExpenseToEmployeeAccount",
							data: vm.search,
							params: {
								save_all: 1,
								employee_account_id: data.employee_account_id
							}
						}).then(function (response) {
							$uibModalInstance.close({response: response, index: data.index});
							window.location.reload();
						});
					} else {
						REST.update({
							url: "expense/addExpenseToEmployeeAccount",
							params: {
								expense_ids: JSON.stringify(expenseIdsList),
								employee_account_id: data.employee_account_id
							}
						}).then(function (response) {
							$uibModalInstance.close({response: response, index: data.index});
							window.location.reload();
						});
					}

				}

				function cancel() {
					$uibModalInstance.dismiss('cancel');
				}

				vm.search.no_employee_account = true;
				vm.search.customer_ids = data.bladeParams.customerIds;

				$scope.$watch('vm.search', function (newValues, oldValues) {
					values = angular.copy(newValues);
					var currentDate = new Date();

					var firstDayLastYear = new Date(currentDate.getFullYear() - 1, 0, 1);

					var lastYearMonth = firstDayLastYear.getMonth() + 1; // Month is zero-based, so add 1
					var lastYearYear = firstDayLastYear.getFullYear();

					var formattedDate = lastYearYear + '-' + (lastYearMonth < 10 ? '0' : '') + lastYearMonth + '-01';

					if (values.date_from) {
						values.date_from = values.date_from;
					} else {
						vm.search.date_from = formattedDate;
					}
					if (values.date_to) {
						values.date_to = values.date_to;
					}
					search.watch({
						search: vm.search,
						newValues: values,
						oldValues: oldValues,
						callback: function (filters) {
							REST.list({
								params: filters
							}).then(function (data) {
								vm.total = data.data.length;
								vm.result = data.data
							});
						},
						fixPager: function () {
							vm.search.page = 1;
						}
					});
				}, true);

				var readyToLoadMore = false;
				vm.loadMore = function () {
					if (readyToLoadMore && !vm.last_page) {
						if (!vm.search.page) {
							vm.search.page = 1;
						}
						vm.search.page = vm.search.page + 1;
						readyToLoadMore = false;
						ngRepeatFinished = false;
					}
				};
			},
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						employee_account_id: vm.employee_account.employee_account_id,
						bladeParams: vm.bladeParams
					};
				}
			}
		});

		//used to refresh the list
		modalInstance.result.then(function () {
		});
	};

	/**
	 * delete expense
	 *
	 * @param $index
	 */
	vm.delete = function ($index) {
		vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then(response => {
			if (response !== 'ok') return;
			$http.delete("/expense/" + vm.expenses[$index]['expense_id']).then(function () {
				vm.expenses.splice($index, 1);
				vm.getTotalAmount();
			});
		});
	};

	vm.getExpenses();
	vm.getTotalAmount();

	return vm;
});
