kv.controller('meetingRoomListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "meeting_room_id",
		entity: "meeting_room",
		modal: {
			templateUrl: "meeting_room-edit",
			controller: "meetingRoomEditCtrl"
		}
	});
});
