kv.controller('DashboardLitigationsListNewsCtrl', function ($element, $attrs, $scope, $injector, resource) {
	var vm = new DashboardWidgetListCtrl($element, $attrs, $scope, $injector);
	vm.entity = "news";
	vm.rest = resource.init(vm.entity);

	vm.data = {
		"internal": [],
		"zf": [],
		"bizlawyer": [],
		"mof": [],
	};

	vm.isLoading = true;

	vm.rest.list().then(function (data) {
		if (data) {
			// remove pagination details
			data.internal = data.internal.data;

			vm.data = data;
		}
		vm.isLoading = false;
	});

	return vm;
});
