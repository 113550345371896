kv.controller('CustomerActivityPbcaCustomReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);

    let initParams = kvUtils.getAllQueryParams();

    if (initParams) {
        if (initParams.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    return vm;
});
