kv.controller('ActiveCustomersReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var params = kvUtils.getAllQueryParams();
    var data = JSON.parse(vm.bladeParams.data);

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
            "columnDefs": [ {
                "targets": 1,
                "orderable": false
            } ]
        });
        $('.dataTables_filter input[type="search"]').
        attr('placeholder','Search in table...').
        removeClass('input-small').
        css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
        addClass('pull-right');
    });

    vm.isLoading = true;
console.log(data);
    var chart_categories = data[2];
    var series = data[1];


    function chart() {
        $('#chart').hide();
        var config = {
            chart: {
                type: 'column'
            },
            title: {
                text: null
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category',
                categories: chart_categories
            },
            yAxis: {
                title: {
                    text: $filter('trans')('ACTIVITY')
                }

            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:f}'
                    }
                }
            },

            tooltip: {
                enabled: false
            },

            series: [
                {
                    colorByPoint: false,
                    data: series
                }
            ],

        }


        $('#chart').highcharts(config);
        vm.isLoading = false;


        setTimeout(function() {
            $('#chart').show();
            $('#chart').highcharts().reflow();
        }, 1);
    }

    if (series) {
        chart();
    }

    return vm;
});
