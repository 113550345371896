kv.controller('InvoiceViewPaymentEditCtrl', function ($uibModalInstance, data, $scope, $injector, resource, cmdate) {
	var vm = new modalCtrl($uibModalInstance, $injector, 'payment', 'payment_id', $scope, data);

	if (!data.payment_id) {
		vm.isNewRecord = true;
		vm.data = {
			date: moment(),
			type: "PO",
			currency: data.invoice.currency,
			value: data.invoice.due_amount,
			invoice_id: data.invoice.invoice_id
		};
	} else {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		vm.rest.get({
			id: data.payment_id
		}).then(function (res) {
			vm.data = res;
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			$scope.$broadcast("dataLoaded");
		});
	}
	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		//send invoice_id for generate cash doc number
		postData.invoice_id = data.invoice.invoice_id;
		return postData;
	};
	return vm;
});
