kv.controller('replaceUserActivityCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'user', 'user_id', $scope, params);

	vm.user = params.user ? params.user : {};
	vm.saveCallback = saveCallback;

	KApp.block('.modal-body');

	let rest = resource.init('user');
	rest.get({
		url: "user/" + vm.user.user_id +"/getResponsibleEntities",
	}).then(function (response) {
		KApp.unblock('.modal-body');
		vm.data = response;
	});

	vm.save = function () {
		vm.data.user_id = vm.user.user_id;
		let postData = angular.copy(vm.data);
		rest.post({
			url: "user/replaceUser",
			data: postData
		}).then(function (response) {
			if (response.status || response.status == undefined) {
				if (vm.saveCallback) vm.saveCallback(response, vm.modal);
			}
		});
	}

	vm.cancel = function () {
		vm.saveCallback('cancel', vm.modal);
	}

	return vm;
});
