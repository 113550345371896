/**
 *   --==== USAGE - CRUD ====--
 *
 *   // init REST resource
 *   var REST = resource.init(vm.entity);
 *
 *   // GET /contact/list
 *   var list = REST.list();
 *
 *   // POST /contact/
 *   var result = REST.create({
 *       data: {
 *           first_name: "Teeeest",
 *           last_name: "Teeeest",
 *           contact_name: "Teeeest",
 *       },
 *       success: callbackFunction
 *   });
 *
 *   // GET /contact/9999
 *   var result = REST.get({
 *       id: 9999
 *   });
 *
 *   // PUT /contact/9999
 *   var result = REST.update({
 *       id: 9999,
 *       data: {
 *           first_name: "Test2",
 *           last_name: "Test2",
 *           contact_name: "Test2",
 *       },
 *       success: callbackFunction
 *   });
 *
 *   // DELETE /contact/9999
 *   var result = REST.delete({
 *       id: 9999
 *   });
 *
 *   --==== USAGE - CUSTOM ====--
 *
 *   // POST /user/1/contact_user/9999
 *   REST.create({ // update, delete, list, get
 *       url: 'user/:user_id/contact_user/:contact_id',
 *       params: {
 *           user_id: 1,
 *           contact_id: 9999,
 *       },
 *       success: callbackFunction
 *   });
 */
(function () {
	'use strict';

	angular.module('kv')
		.factory('resource', resource);

	function resource($resource, API) {
		var resource = {};
		resource.init = function (entity) {
			if (!entity) {
				entity = "";
			}
			return createResource($resource, entity, API);
		};
		return resource;
	}

	function createResource($resource, entity, API) {
		function toQueryString(query) {
			return new URLSearchParams(query).toString();
		}

		function getUrl(vars, _params) {
			var url = API.URL + entity;
			if (_params) {
				url += _params;
			}
			if (vars && vars.url) {
				if (vars.url.indexOf(API.URL) != -1) {
					url = vars.url;
				} else {
					url = API.URL + vars.url;
				}
			}
			if (vars && vars.query) {
				url = `${url}?${toQueryString(vars.query)}`;
			}
			return url;
		}

		function setVars(vars) {
			var variables = {
				params: {},
				data: {},
				success: function () {
					// console.log("Global success")
				},
				id: 0
			};
			if (vars) {
				if (vars.url) {
					variables.url = vars.url;
				}
				if (vars.params) {
					variables.params = vars.params;
				}
				if (vars.data) {
					variables.data = vars.data;
				}
				if (vars.success) {
					variables.success = vars.success;
				}
				if (vars.id) {
					variables.params = angular.extend(variables.params, {id: vars.id});
				}
			}
			return variables;
		}

		function getResource(vars, _params) {
			_vars = setVars(vars);
			if (_params) {
				var url = getUrl(vars, _params);
			} else {
				var url = getUrl(vars);
			}
			return $resource(url, getParamDefaults(), getActions());
		}

		var _vars = {};
		return {
			list: function (vars) {
				var resource = getResource(vars, "/list");
				return resource.list(_vars.params, _vars.data, _vars.success).$promise;
			},
			export: function (vars) {
				var resource = getResource(vars, "/export");
				return resource.list(_vars.params, _vars.data, _vars.success).$promise;
			},
			all: function (vars) {
				var resource = getResource(vars);
				return resource.get(_vars.params, _vars.data, _vars.success).$promise;
			},
			create: function (vars) {
				var resource = getResource(vars);
				return resource.create(_vars.params, _vars.data, _vars.success).$promise;
			},
			get: function (vars) {
				var resource = getResource(vars, "/:id");
				return resource.get(_vars.params, _vars.data, _vars.success).$promise;
			},
			post: function (vars) {
				var resource = getResource(vars);
				return resource.create(_vars.params, _vars.data, _vars.success).$promise;
			},
			update: function (vars) {
				var resource = getResource(vars, "/:id");
				return resource.update(_vars.params, _vars.data, _vars.success).$promise;
			},
			delete: function (vars) {
				var resource = getResource(vars, "/:id");
				return resource.delete(_vars.params, _vars.data, _vars.success).$promise;
			},
			filter: function (vars) {
				var resource = getResource(vars);
				return resource.post(_vars.params, _vars.data, _vars.success).$promise;
			},
			getArray: function (vars) {
				var resource = getResource(vars);
				return resource.getArray(_vars.params, _vars.data, _vars.success).$promise;
			}
		};
	}

	function getParamDefaults() {
		var paramDefaults = {
			id: '@id'
		};
		return paramDefaults;
	}

	function getActions() {
		var actions = {
			list: {
				method: 'GET'
			},
			export: {
				method: 'GET',
			},
			post: {
				method: 'POST',
			},
			get: {
				cache: false,
				method: 'GET',
				transformResponse: function (data, headersGetter, status) {
					var headers = headersGetter();
					var response = null;
					if (headers["content-type"].indexOf("text/html") !== -1) {
						response = {"data": angular.fromJson(data)};
					} else {
						response = JSON.parse(data);
					}
					// console.log("response", data, response);
					return response;
				}
			},
			getArray: {
				method: 'GET',
				isArray: true
			},
			create: {
				method: 'POST',
			},
			update: {
				method: 'PUT'
			},
			delete: {
				method: 'DELETE',
				transformResponse: function (data, headersGetter, status) {
					var headers = headersGetter();
					var response = null;
					if (headers["content-type"].indexOf("text/html") !== -1) {
						response = {"data": angular.fromJson(data)};
					} else {
						response = JSON.parse(data);
					}
					return response;
				}
			}
		};
		return actions;
	}
}());
