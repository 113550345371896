kv.controller('inventoryItemEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'inventory_item_id';
    vm.entity = 'inventory_item';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');

    vm.inventoryItemCodeExists = false;

    var REST = resource.init(vm.entity);
    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];

    if(page_identifier == 'edit') {
        $http.get("/inventory_item/" + page_entity_id).then(function(response) {
            vm.data = response.data;
        });
    } else {
        vm.data = {};
        vm.data.status ='IN_USE';
        vm.isNewRecord = true;
    }



    vm.newEntity = function newEntity(entity) {
        if(!vm.data) {
            vm.data = {};
        }

        if(!vm.data[entity]) {
            return vm.data[entity] = [{}];
        }

        return vm.data[entity].push({});
    };

    vm.deleteEntity = function deleteEntity($index, entity, id) {
        if(!id) {
            return vm.data[entity].splice($index,1);
        }

        if(page_entity_id) {
            REST.delete({
                url: vm.entity + '/:id/' + entity + '/:entityId',
                params: {
                    id: page_entity_id,
                    entityId: id
                }
            }).then(function(data) {
                vm.data[entity].splice($index,1);
            });
        }
    };

    vm.checkInventoryItemCode = function (codeToCheck) {
        let error = false;
        var REST = resource.init('inventory_item');

        // check if field was filled with data
        if (vm.data.code && vm.data.code.length) {
            error = false;
        } else {
            error = true;
            vm.inventoryItemCodeExists = false;
        }

        // if field is not empty, make request
        if (!error) {
            REST.list({
                params: {
                    inventory_item_code: codeToCheck,
                }
            }).then(function (res) {
                if (res.data.length) {
                    vm.inventoryItemCodeExists = true;
                } else {
                    vm.inventoryItemCodeExists = false;
                }
            });
        }
    };

    vm.checkInventoryItemSeries = function (seriesToCheck) {
        let error = false;
        var REST = resource.init('inventory_item');

        // check if field was filled with data
        if (vm.data.series && vm.data.series.length) {
            error = false;
        } else {
            error = true;
            vm.inventoryItemSeriesExists = false;
        }

        // if field is not empty, make request
        if (!error) {
            REST.list({
                params: {
                    inventory_item_series: seriesToCheck,
                }
            }).then(function (res) {
                if (res.data.length) {
                    vm.inventoryItemSeriesExists = true;
                } else {
                    vm.inventoryItemSeriesExists = false;
                }
            });
        }
    };

    return vm;
});
