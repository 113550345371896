kv.controller('efacturaReceivedListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'id';
    vm.entity = 'efactura_received';
    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getInfinitScroll({
        params: {
            action: 'efactura_received'
        },
        url: "efactura_received",
    });

    vm.edit = function (entity, index) {
        vm.openModal({
            templateUrl: "efactura_received-edit",
            controller: 'EFacturaReceivedEditCtrl',
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                params: {
                    index: index,
                    entity: entity
                }
            }
        }).then(function (response) {
            if (!response) return;
            if (response.index == -1) vm.infinitScroll.items.unshift(response.entity);
            else angular.extend(entity, response.entity);
        });
    };

    vm.syncEfacturaReceived = function () {
        KApp.block('.efactura_received-table');
        toastr.success(vm.trans('SYNC_RECEIVED_INVOICES_FROM_SPV'));
       vm.rest.get({
           url: 'efactura_received/sync_efactura_received'
        }).then(function (response) {
            if(response.status == true){
                window.location.reload();
            }else{
                toastr.error(vm.trans('SYNC_ERROR'));
            }
           KApp.unblock('.efactura_received-table');
        });
    }

    return vm;
});

