kv.controller('efacturaReceivedViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal,cmdate, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'id';
    vm.entity = 'efactura_received';
    vm.entityType = 'efactura_received';
    vm.groups = {};

    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];

    vm.entityId = page_entity_id;
    vm.commentsConfig = {
        entity_type: vm.entity
    };

    vm.goBack = function () {
        $state.go("app.efactura_received");
    };

    vm.getEfacturaReceivedDetails = function getEfacturaReceivedDetails() {
        var REST = resource.init('efactura_received/list');
        REST.get({
            params: {
                efactura_received_id: page_entity_id
            }
        }).then(function (data) {
            vm.efactura_received = data.data[0].details;
        });
    };
    vm.getEfacturaReceivedDetails();

    vm.getEfacturaReceivedExpenses = function getEfacturaReceivedExpenses() {
        var REST = resource.init('expense/list');
        REST.get({
            params: {
                efactura_received_id: page_entity_id
            }
        }).then(function (data) {
            vm.expenses = data.data;
        });
    };

    vm.getEfacturaReceivedExpenses();

    vm.getQueryParam = function (param, param_value) {
        return "&" + param + "=" + param_value;
    };

    vm.addExpenseFromEfacturaReceived = function(tenant_fiscal_entity_id, tenant_fiscal_entity_name, invoice_date, total_amount, currency, invoice_series, customer_fiscal_entity_id, customer_fiscal_entity_name, invoice_id, vat_code, description){
        let url = 'expense/?create_from=efactura_received';
        if(tenant_fiscal_entity_id){
            url += vm.getQueryParam("tenant_fiscal_entity_id", tenant_fiscal_entity_id);
            url += vm.getQueryParam("tenant_fiscal_entity_name", tenant_fiscal_entity_name);
        }
        if(invoice_date){
            let currentDate = new Date();
            let day = currentDate.getDate();
            let month = currentDate.getMonth() + 1;
            let year = currentDate.getFullYear();
            let expense_date = year + '-' + (month < 10 ?  '0' + month : month) + '-' + (day < 10 ? '0' + day : day)+ ' ' + vm.API.START_DAY_AT;
            // url += vm.getQueryParam("date", expense_date);
            // modificat gbarcun 1 jul 2024 - cerinta nndkp andreea ruset
            url += vm.getQueryParam("date", invoice_date);
            url += vm.getQueryParam("payment_date", invoice_date);
        }

        if(total_amount){
            url += vm.getQueryParam("total_amount", total_amount);
        }

        if(currency){
            url += vm.getQueryParam("currency", currency);
        }

        if(vat_code){
            url += vm.getQueryParam("vat_code", vat_code);
        }

        if(invoice_series){
            url += vm.getQueryParam("invoice_received_number", invoice_series);
        }

        if(customer_fiscal_entity_id){
            url += vm.getQueryParam("supplier_id", customer_fiscal_entity_id);
            url += vm.getQueryParam("supplier_name", customer_fiscal_entity_name);
        }

        if(description){
            url += vm.getQueryParam("comments", description);
        }

        url += vm.getQueryParam("efactura_received_id", invoice_id);

        window.location.href = vm.API.URL + url;
    };

    /**
     * mark as confidential
     */
    vm.markSupplierAsConfidential = function (customer_fiscal_entity_id) {
        KApp.block('.changeStatus');

        let rest = vm.getInjection('resource').init('customer_fiscal_entity');
        rest.update({
            url: 'customer_fiscal_entity/' + customer_fiscal_entity_id,
            params: {
                action: 'markAsConfidential'
            }
        })
            .then((response) => {
                toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
                if (!response.status) vm.alert('ALERT', vm.trans('Error setting confidential status'));
                KApp.unblock('.changeStatus');
                window.location.href = "/efactura_received/" + page_entity_id;
            })
            .catch(err => {
                KApp.unblock('.changeStatus');
                console.log('Error setting confidential status');
                console.log(err); // bum related error
                window.location.href = "/efactura_received/" + page_entity_id;
            });
    };

    vm.markAsProcessed = function (efactura_received_id) {
        //page_entity_id
        KApp.block('.changeStatus');

        let rest = vm.getInjection('resource').init('efactura_received');
        rest.post({
            url: 'efactura_received/' + efactura_received_id + '/actions/mark_as_processed',
        })
        .then((response) => {
            toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
            if (!response.status) vm.alert('ALERT', vm.trans('Error setting processed status'));
            KApp.unblock('.changeStatus');
            vm.entity.processed = true;
            // wait 500 ms
            setTimeout(() => {
                window.location.href = "/efactura_received/" + page_entity_id;
            }, 500);
        })
        .catch(err => {
            KApp.unblock('.changeStatus');
            console.log('Error setting processed status');
            console.log(err); // bum related error
            if (response.message) vm.alert('ALERT', response.message);
            // window.location.href = "/efactura_received/" + page_entity_id;
        });
    }

    return vm;
});
