kv.controller('ProjectAddEditBillingRuleCtrl', function ($uibModalInstance, params, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'billing_rule', 'billing_rule_id', $scope, params);
	vm.modal.rendered.then(() => {
		if (!vm.dataHasLoaded) KApp.block('.modal-content');
	});
	vm.data = {};
	vm.data.parent_id = params.data.parent_id;
	vm.dataHasLoaded = false;
	if (params.type == "add") {
		vm.isNewRecord = true;
		vm.data = angular.copy(params.data);
		vm.dataHasLoaded = true;
	} else {
		vm.isNewRecord = false;
		vm.rest.get({id: params.data.billing_rule_id}).then(response => {
			KApp.unblock('.modal-content');
			vm.data = response;
			vm.data.project_currency = params.project_currency;
			vm.dataHasLoaded = true;
		});
	}
	//default value for HOURLY_THRESHOLD
	if (params.data.rule_type == "HOURLY_THRESHOLD") {
		vm.data.threshold_effort = 10;
	}

	vm.addEntity = function (entity) {
		if (!vm.data) {
			vm.data = {};
		}
		if (!vm.data[entity]) {
			vm.data[entity] = [];
		}
		vm.data[entity].push({});
	};

	vm.deleteEntity = function (entity, $index) {
		vm.data[entity].splice($index, 1);
	};

	/**
	 * check if user can save without value on billing rule
	 * in case of HOURLY_BLENDED, HEARING, DISCOUNT, FEE_DISCOUNT, SUCCESS_FEE value is mandatory
	 * @return {[type]} [description]
	 */
	vm.checkHasValue = function () {
        vm.need_value = false;
        let rule_type = vm.data.rule_type;

        if (rule_type == 'HOURLY_BLENDED' || rule_type == 'HEARING' || rule_type == 'DISCOUNT' || rule_type == 'FEE_DISCOUNT' || rule_type == 'SUCCESS_FEE' || rule_type == 'FLAT_FEE' || rule_type == 'SUBSCRIPTION' || rule_type == 'CAP' || rule_type == 'ESTIMATE' || rule_type ==  'FEE_ADVANCE') {
            if(!vm.data.value || vm.data.value == null)
            	vm.need_value = true;
        }

        if(vm.need_value == false){
            vm.save();
        }
    };


	return vm;
});
