class dictionaryEditCtrl extends modalCtrl {
	constructor($uibModalInstance, data, $injector, $scope, config) {
		super($uibModalInstance, $injector, config.entity, config.primaryKey, $scope, data, config.bladeParamsId);
		this.isNewRecord = !data.entity;
		if (this.isNewRecord) {
			this.data = angular.copy(config.newEntity);
		} else this.data = angular.copy(data.entity);
		if (config.prepareDataToSave) this.prepareDataToSave = config.prepareDataToSave.bind(this);
		if (config.onInit) config.onInit.call(this);
	}
}