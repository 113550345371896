var kvUtils = {}; //initializare kvUtils --nu sterge

var createElement = function (tagName, attributes, innerHTML, parent) {
	var element = document.createElement(tagName);
	if (attributes && attributes.constructor === Object) {
		for (let attribute in attributes) {
			if (attributes.hasOwnProperty(attribute)) {
				let attributeName = attribute.reverseCamelCase();
				element.setAttribute(attributeName, attributes[attribute]);
			}
		}
	} else if (attributes) {
		for (let i = 0; i < attributes.length; i++) {
			let attribute = attributes[i];
			if (!attribute.nodeName) continue;
			element.setAttribute(attribute.nodeName, attribute.nodeValue);
		}
	}
	if (angular.isString(innerHTML)) element.innerHTML = innerHTML;
	else if (angular.isObject(innerHTML)) element.appendChild(innerHTML);

	element.addClass = function (className) {
		if (this.hasClass(className)) return;
		this.className += (this.className ? " " : "") + className;
	};
	element.removeClass = function (className) {
		var listExisting = this.className.split(" ");
		var listToRemove = className.split(" ");
		for (let i = 0; i < listToRemove.length; i++) {
			let index = listExisting.indexOf(className);
			if (index === -1) continue;
			listExisting.splice(index, 1);
		}
		this.className = listExisting.join(" ");
	};
	element.hasClass = function (className) {
		var listExisting = this.className.split(" ");
		return listExisting.indexOf(className) > -1;
	};

	element.addCssText = function (cssText) {
		var cssList = cssText.split(";");
		for (let i = 0; i < cssList.length; i++) {
			if (cssList[i].split(":").length !== 2) continue;
			this.addStyle(cssList[i].split(":")[0], cssList[i].split(":")[1]);
		}
	};
	element.addStyle = function (style, value) {
		var cssVals = this.style.cssText.split(";");
		var newStyle = "";
		for (let i = 0; i < cssVals.length; i++) {
			let cssItem = cssVals[i];
			if (cssItem.indexOf(":") === -1) continue;
			let name = cssItem.split(":")[0].trim();
			if (name === style) continue;
			newStyle += cssItem + ";";
		}
		this.style[style] = value;
		newStyle += style + ":" + value + ";";
		this.style.cssText = newStyle;
	};
	element.hasStyle = function (style) {
		if (this.style[style]) return true;
		var cssVals = this.style.cssText.split(";");
		for (let i = 0; i < cssVals.length; i++) {
			let cssItem = cssVals[i];
			if (cssItem.indexOf(":") === -1) continue;
			let name = cssItem.split(":")[0].trim();
			if (name === style) return true;
		}
		return false;
	};
	element.addAttributes = function (attributes, element) {
		if (!attributes) return;
		element = element || this;
		if (attributes && attributes.constructor === Object)
			for (let attribute in attributes) {
				if (attributes.hasOwnProperty(attribute)) {
					element.setAttribute(attribute, attributes[attribute]);
				}
			}
		else for (let i = 0; i < attributes.length; i++) {
			if (!attributes[i].nodeName) ;
			element.setAttribute(attributes[i].nodeName, attributes[i].nodeValue);
		}
	};
	if (angular.isObject(parent)) parent.appendChild(element);
	return element;
};

String.prototype.camelCase = function () {
	var target = this;
	var SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g;
	var MOZ_HACK_REGEXP = /^moz([A-Z])/;
	return target.replace(SPECIAL_CHARS_REGEXP, function (_, separator, letter, offset) {
		return offset ? letter.toUpperCase() : letter;
	}).replace(MOZ_HACK_REGEXP, 'Moz$1');
};
String.prototype.reverseCamelCase = function () {
	var input = this;
	return input
	// insert a space between lower & upper
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		// space before last upper in a sequence followed by lower
		.replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1-$2$3')
		// uppercase the first character
		.toLowerCase();
};
String.prototype.urlAccepted = function () {
	var target = this.toLowerCase().replaceAll(" ", "-").replaceAll("_", "-")// jshint ignore:line
		.replaceAll("/", "-").normalize('NFD').replace(/[\u0300-\u036f]/g, "");// jshint ignore:line
	return target;
};
String.prototype.replaceAll = function (search, replacement, removeLineBrakes) {
	var target = this;
	if (removeLineBrakes) target = target.replace(/(\r\n|\n\r|\n|\r|\t)/g, "");
	let newText = target.replace(new RegExp(search, "g"), replacement);
	// console.log( newText);
	return newText;
};
String.prototype.contains = function (search, invariant) {
	var target = this;
	if (target.toLowerCase().indexOf(search.trim().toLowerCase()) > -1) return true;
	if (invariant && invariant.toLowerCase().indexOf(search.trim().toLowerCase()) > -1) return true;
	return false;
};
String.prototype.startsWith || (String.prototype.startsWith = function (search) {
	return (this.substring(0, search.length) === search);
}); // jshint ignore:line

String.prototype.toProperCase || (String.prototype.toProperCase = function () {
	return (this.substring(0, 1).toUpperCase() + this.substring(1).toLowerCase());
}); // jshint ignore:line

String.prototype.cleaning = function (separator) {
	let self = this.trim().replaceAll("\"", "\'");
	return self;
};

String.prototype.toTitleCase = function (separator) {
	let self = this.cleaning();
	separator = separator || " ";
	let words = self.split(separator);
	words.each(function (word, i) {
		let accents = word.split("'");
		let newAccents = [];
		accents.each(function (accent, j) {
			newAccents[j] = j = 0 ? accent : accent.toProperCase();
		});
		words[i] = newAccents.join("'");
	});
	return words.join(separator);
}; // jshint ignore:line
String.prototype.repeat || (String.prototype.repeat = function (length) {
	var returnString = "";
	for (var i = 0; i < length; i++) {
		returnString += this;
	}
	return returnString;
}); // jshint ignore:line
String.prototype.padLeft = function (length, character) {
	if (character === undefined || character === null) character = " ";
	if (character.length > 1) character = character.substring(0, 1);
	return (character.repeat(length - this.length) + this);
}; // jshint ignore:line

var guid = function () {
	var d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
		d += window.performance.now(); //use high-precision timer if available
	}
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
		function (c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	return uuid;
};
var loader = function (selector, classes) {
	var uid = guid();
	var container = createElement("div", {uid: uid, role: 'loader'});
	var overlay = createElement("div", {
		style: "position: absolute;top:0;left: 0;width: 100%;height: 100%;margin: 0",
		role: "loader-overlay"
	});
	container.appendChild(overlay);
	var spinner = createElement("i", {
		class: classes || "fa fa-circle-o-notch fa-spin",
		style: "position: absolute;color: gray;top: 50%;left: 50%;font-size: 30px;margin-top: -15px;margin-left: -15px; z-index: 10000000000000000;"
	});
	container.appendChild(spinner);
	var recipient;
	if (angular.isObject(selector)) recipient = angular.element(selector);
	else recipient = angular.element(selector || 'body');
	if (recipient.length === 0) angular.element(this.defaultLoaderSelector);
	recipient.append(container);
	if (!recipient[0].style.position) recipient[0].style.position = "relative";
	return {
		uid: uid,
		recipient: recipient,
		remove: function (response) {
			if (response) console.error(response);
			// console.log("remove", this.uid, recipient.find("[uid='" + this.uid + "']"));
			if (recipient[0] && recipient[0].isConnected) recipient.find("[uid='" + this.uid + "']").remove();
			else angular.element("div[role=loader][uid='" + this.uid + "']").remove();
		}
	};

};
kvUtils.transformEffortsInHours = function (data ) {
	// data.input_effort = use_industrial_minutes?  $filter("timesheetHoursFormat")(data.input_effort, true):  data.input_effort / 60;
	data.input_effort = data.input_effort / 60;
	data.total_effort = data.total_effort / 60;
	data.blb_effort = data.blb_effort / 60;
	data.nb_effort = data.nb_effort / 60;
	data.nc_effort = data.nc_effort / 60;
	data.dnb_effort = data.dnb_effort / 60;
	return data;
};
kvUtils.humanTotalEffortToMinutes = function (hrs, round) {
	var out = 0;
	if (hrs === undefined || hrs === null) return 0;
	if (typeof hrs !== 'string') hrs = hrs.toString();
	if (hrs.charAt(2) !== ':') {
		out = hrs * 60;
	} else {
		var hours = parseInt(hrs.substring(0, 2), 10);
		var minutes = parseInt(hrs.substring(3, 5), 10);
		out = hours * 60 + minutes;
	}
	if (round === true) out = (Math.round((out / 60) * 10) / 10) * 60;
	if (round === 0) out = Math.round(out);
	if (round === 1) out = Math.round(out * 10) / 10;
	if (round === 2) out = Math.round(out * 100) / 100;
	return out;
};
kvUtils.ifNaNReturnZero = function (number) {
	return isNaN(number) ? number : 0;
};
kvUtils.checkHoursFormat = function (timesheetItem, $filter, inputAsMinutes) {

	if (!$filter && this.getInjection) $filter = this.getInjection('$filter');
	if (!$filter) console.error("You must pass $filter as fifth parameter to checkHoursFormat");
	if (!$filter) throw "You must pass $filter as fifth parameter to checkHoursFormat";

	kvUtils.checkHoursFormatForEffort(timesheetItem, 'total_effort', $filter, inputAsMinutes);
	kvUtils.checkHoursFormatForEffort(timesheetItem, 'input_effort', $filter, inputAsMinutes);
	kvUtils.checkHoursFormatForEffort(timesheetItem, 'dnb_effort', $filter, inputAsMinutes);
	kvUtils.checkHoursFormatForEffort(timesheetItem, 'nb_effort', $filter, inputAsMinutes);
	kvUtils.checkHoursFormatForEffort(timesheetItem, 'nc_effort', $filter, inputAsMinutes);
	kvUtils.checkHoursFormatForEffort(timesheetItem, 'blb_effort', $filter, inputAsMinutes);
	kvUtils.checkHoursFormatForEffort(timesheetItem, 'billable', $filter, inputAsMinutes);
	kvUtils.checkHoursFormatForEffort(timesheetItem, 'non_billable', $filter, inputAsMinutes);
	kvUtils.checkHoursFormatForEffort(timesheetItem, 'inefficient', $filter, inputAsMinutes);
	kvUtils.checkHoursFormatForEffort(timesheetItem, 'eligible_effort', $filter, inputAsMinutes);
	return true;
};
kvUtils.checkHoursFormatForEffort = function (timesheetItem, fieldName, $filter, inputAsMinutes) {
	if (timesheetItem[fieldName] === undefined) return;
	let value = timesheetItem[fieldName];
	timesheetItem[fieldName] = $filter("timesheetHoursFormat")(value, API.USE_INDUSTRIAL_MINUTES, inputAsMinutes);
}
kvUtils.newRelatedModelEntityFor = function (entity_type, data) {
	if (!data) return console.error('Data must be initialized before use this method!');
	//if not exist data['entity_type'] intialize with empty array
	if (!data[entity_type]) data[entity_type] = [];
	let newEntity = {};
	switch (entity_type) {
		case 'address':
			newEntity = {
				address_type_id: 1,
				type: {
					address_type_name: 'MAIN' //main address
				}
			};
			break;
		case 'phone':
			newEntity = {
				phone_type_id: 2, //mobile phone
				type: {
					phone_type_name: 'MOBILE'
				}
			};
			break;
		case 'email':
			newEntity = {
				email_type_id: 2, //business email
				type: {
					email_type_name: 'BUSINESS'
				}
			};
			break;

	}
	if (['phone', 'address', 'email', 'customer_contact', 'contact_user']) {
		//check if exist an entity with is_primary = true;
		let primaryEntity = data[entity_type] ? data[entity_type].find(entity => entity.is_primary) : false;
		newEntity.is_primary = !primaryEntity;
	}
	if(['subtask']){

	}
	data[entity_type].push(newEntity);
	return data;
};

kvUtils.extractQueryParam = function (paramName) {
	let queryString = window.location.search.substr(1);
	let queryParams = queryString.split("&");
	let paramValue = null;
	queryParams.some(queryParam => {
		if (queryParam.split("=")[0] === paramName) {
			if (queryParam.split("=").length > 0) paramValue = queryParam.split("=")[1];
			return true;
		}
		return false;
	});
	return paramValue;
};

kvUtils.getAllQueryParams = function () {
	// let queryString = window.location.search.substr(1);
	let queryString = decodeURIComponent(window.location.search.replace("?", "").replace(/\+/g, '%20'));
	if (queryString === "") return null;
	let queryParams = queryString.split("&");
	let params = {};
	queryParams.forEach(queryParam => {
		if (queryParam.split("=").length > 0) {
			let paramName = queryParam.split("=")[0];
			let paramValue = queryParam.split("=")[1];
			if (paramValue === "" || paramValue === "undefined" || paramValue === "null") return;
			if (paramName.endsWith('[]')) {
				paramName = paramName.substr(0, paramName.length - 2);
				if (!params[paramName]) params[paramName] = [];
				params[paramName].push(paramValue);
			} else params[paramName] = paramValue;
		}
	});
	return params;
};
let queryParamToJson = function () {
	let str = decodeURIComponent(window.location.search.replace("?", "").replace(/\+/g, '%20'));
	let params = {};
	let itemsList = str.split("&");
	itemsList.forEach(item => {
		let itemParts = item.split("=");
		params[itemParts[0]] = itemParts[1];
	});
	return params;
};

kvUtils.setActiveItem = function (item) {
	// reset current active item
	KUtil.addClass(item, 'k-menu__item--active');

	let parents = $(item).parents('.k-menu__item--submenu');
	for (let i = 0, len = parents.length; i < len; i++) {
		KUtil.addClass(parents[i], 'k-menu__item--open');
	}
};
kvUtils.findCurrentMenuItem = function (url) {
	let item = $('#k_aside_menu').find("a[href='" + url + "']").parent('.k-menu__item');
	if (item.length) kvUtils.setActiveItem(item[0]);
	else {
		let urls = url.split("/");
		urls.splice(urls.length - 1, 1);
		let parentUrl = urls.join('/');
		if (parentUrl === window.location.origin) return;
		kvUtils.findCurrentMenuItem(parentUrl);
	}
};
kvUtils.activateCurrentTabFromRequestUrl = function (tabsSelector, $element, callback) {
	let url = document.location.toString();
	if (url.match('#')) {
		let tabId = url.split('#')[1];
		let tabSelector = tabsSelector + '> li > a[href="#' + tabId + '"]';
		if ($element) $element.find(tabSelector).tab('show');
		else angular.element(tabSelector).tab('show');
		if (callback) callback(tabId);
	}
};
kvUtils.validateEmail = function (email) {
	let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

// activator tooltip pe atribut data-toggle='tooltip'
// var tooltipsActivator = function () {
//     var activateTooltips = function () {
// 		$('[data-toggle="tooltip"]').tooltip();
//     }
//
//     return {
//         // Public functions
//         init: function() {
//             activateTooltips();
//         }
//     };
// }();
//minimize aside-menu
kvUtils.minimizeAsideMenu = function () {
	KUtil.addClass(angular.element('body')[0], 'k-brand--minimize k-aside--minimize');
};

if ($(window).width() <= 1399) {
	kvUtils.minimizeAsideMenu();
}

$(document).ready(function () {
	$('[data-toggle="tooltip"]').tooltip();
	$('[data-toggle="popover"]').popover();
	kMenu = KLayout.getAsideMenu();
// console.log('klayout', kMenu.setDefaults());
	try {
		$.datepicker.setDefaults(
			{
				changeMonth: true,
				changeYear: true,
				firstDay: 1,
				dateFormat: 'yy-mm-dd',
			});
	} catch (err) {
	}

	// set current menu active item and open parents submenus
	kvUtils.findCurrentMenuItem(window.location.href);


	// la click pe vezi mai multe, la deschidere sidebar pt. filtre suplimentare, adaug style pt. pozitionare corecta datetimepicker la bottom-right orientation
	// styling-ul se inlatura la click pe #k_quick_panel_close_btn, la metoda scrisa in mainCtrl as mainCtrl
	KeenTracking.utils.listener('#k_quick_panel_toggler_btn').on('click', (e) => {
		if (document.querySelector("#side-bar-style")) {
			$('head').find('#side-bar-style').remove();
		} else {
			let style = `.datetimepicker-dropdown-bottom-right{
				  right:20px !important;
				  left:auto !important;
			 }`;
			let styleEl = createElement('style', {id: "side-bar-style", type: "text/css"}, style);
			let head = document.head || document.getElementsByTagName('head')[0];
			head.appendChild(styleEl);
		}
	});

});

/**
 * @@@@@ !!!!! Nu trebuie pus in document ready ca nu mai merg culorile !!!!! @@@@@
 */
Highcharts.theme = {colors: ['#769EFE', '#fb8a9e', '#ffc57c', '#bedd8d', '#a9a0d7', "#76d3fe", '#f38acf', '#f47272', '#78d2d2', '#b684cb', '#a2a7c2']};
// HighCharts default settings
Highcharts.setOptions({
	colors: ['#769EFE', '#fb8a9e', '#ffc57c', '#bedd8d', '#a9a0d7', "#76d3fe", '#f38acf', '#f47272', '#78d2d2', '#b684cb', '#a2a7c2'],
	legend: {
		itemStyle: {
			fontWeight: 'normal'
		},
		floating: false,
		layout: 'horizontal',
		borderRadius: 4,
		borderWidth: 1
	},
	subtitle: {
		style: {
			color: '#6D869F',
			fontSize: '12px'
		},
		y: 30
	},
	credits: {
		enabled: false
	},
	exporting: {
		enabled: true
	}
});

// set highcharts color array in kvUtils global variable
kvUtils.highchartsColors = ['#769EFE', '#fb8a9e', '#ffc57c', '#bedd8d', '#a9a0d7', "#76d3fe", '#f38acf', '#f47272', '#78d2d2', '#b684cb', '#a2a7c2'];

// dropdown actions
$('button.rnf-dropdown-toggle').click(function (e) {
	e.preventDefault();
	$(this).siblings('.rnf-dropdown-menu').toggle();
});

if (window.matchMedia('(min-width: 1280px)').matches) {

	$('li.rnf-dropdown-toggle').mouseenter(function () {
		$(this).children('.rnf-dropdown-menu').show();
	});

	$('li.rnf-dropdown-toggle').mouseleave(function () {
		$(this).children('.rnf-dropdown-menu').hide();
	});

} else {

	$('li.rnf-dropdown-toggle a').click(function (e) {
		e.preventDefault();
		$(this).siblings('.rnf-dropdown-menu').toggle();
	});

}

$('body').click(function (e) {

	if ($(e.target).is('.rnf-dropdown') || $(e.target).parents('.rnf-dropdown').length !== 0) {
		return;
	}

	$('.rnf-dropdown-menu').hide();

});
