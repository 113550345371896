kv.controller('timesheetTemplateListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "timesheet_template_id",
		entity: "timesheet_template",
		modal: {
			templateUrl: "timesheet_template-edit",
			controller: "timesheetTemplateEditCtrl"
		}
	});
});
