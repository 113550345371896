(function () {
	angular.module('kv').controller("appSearchCtrl", appSearchCtrl);
	appSearchCtrl.$inject = ["$scope", "$http", "$timeout", "$element", "$document"];

	function appSearchCtrl($scope, $http, $timeout, $element, $document) {
		this.search = "";
		let vm = this;
		this.searching = false;
		this.runingTask = null;
		this.searchResult = null;
		this.showPopup = false;
		this.cancelSearch = false;
		let clearIcon = $element.find(".icon-clear");
		let input = $element.find("input");
		let onChange = function () {
			let search = this.search;
			input.addClass('not-empty');
			if (search.length < 3) return vm.searchResult = null;
			if (search.length) clearIcon.show();
			else return clearSearch(null, true);

			this.cancelSearch = false;
			if (this.runingTask) {
				$timeout.cancel(this.runingTask);
				// console.log("cancel search");
			}

			let runTask = function () {
				let callback = function (response) {
					// console.log("Response", response);
					 this.searching = false;
					if (!response || !response.data.status || this.cancelSearch) {
						this.searchResult = null;
						return;
					}
					this.searchResult = response.data.data.items;
					if (!this.showPopup) {
						this.showPopup = true;
						// $element.find(".autocomplete-popup").show();
						// $document.on("click", documentClickHandler.bind(this));
					}
				};
				this.searching = true;
				// console.log("start search for ", search);
				$http.get("/search", {params: {q: search}}).then(callback.bind(this));
			};
			this.runingTask = $timeout(runTask.bind(this), 300);
		};
		// $element.find(".autocomplete-popup").css({display: "none", visibility: ""});
		this.onChange = onChange.bind(this);
		let clearSearch = function (event, noScopeApply) {
			console.debug("clear search");
			vm.search = "";
			vm.showPopup = false;
			vm.cancelSearch = true;
			vm.searchResult = null;
			if (!noScopeApply) input.focus();
			input.removeClass('not-empty');
			clearIcon.hide();
			if (!noScopeApply) $scope.$apply();
		};
		clearIcon[0].addEventListener("click", clearSearch);
	}
})();
