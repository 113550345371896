angular
	.module('kv')
	.directive('datetimepicker', datetimepicker);

function datetimepicker(cmdate, API) {
	return {
		templateUrl: "/assets/custom/js/directives/datetimepicker/datetimepicker.html",
		scope: {
			type: '@?',
			ngModel: '=',
			placeholder: '@?',
			name: '@?',
			ngDisabled: '=?',
			ngChange: "&",
			daysDisabled: '@?' // set if you want to disable selection of certain days
		},
		require: 'ngModel',
		transclude: true,
		link: function ($scope, element, attrs, ngModelController, $timeout) {
			if (!$scope.ngDisabled) {
				$scope.ngDisabled = false;
			}
			element.addClass("input-group date");
			// element[0].style.cssText += "width: 100% !important; padding: 0;";

			let defaultInputFormat = "YYYY-MM-DD HH-mm-ss";
			if (attrs.displayFormat) {
				$scope.displayFormat = attrs.displayFormat;
				$scope.minView = "month";
				defaultInputFormat = "YYYY-MM-DD";
			} else if ($scope.type === 'noTime') {
				$scope.displayFormat = API.DATE_FORMAT;
				$scope.minView = "month";
			} else {
				$scope.displayFormat = "YYYY-MM-DD HH:ii";
			}
			$scope.inputFormat = attrs.inputFormat ? attrs.inputFormat : defaultInputFormat;

			let config = {
				minuteStep: 15,
				weekStart: 1,
				minView: $scope.minView,
				format: $scope.displayFormat.toLowerCase(),
				autoclose: true,
				todayBtn: true,
				todayHighlight: true,
				daysOfWeekDisabled: ($scope.daysDisabled ? $scope.daysDisabled : null)
			};
			if (attrs.class) config.class = attrs.class;
			if (attrs.orientation) config.orientation = attrs.orientation;
			else config.orientation = "bottom left";
			let datetimePicker = element.find('input').datetimepicker(config);
			datetimePicker.on('show', () => {
				// console.log('show', event);
			});

			ngModelController.$parsers.push(function (viewValue) {
				// undo the timezone adjustment we did during the formatting
				// viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
				// we just want a local date in ISO format
				let result = moment(viewValue).format($scope.displayFormat);
				console.log("viewValue", result, viewValue);
				return result;
			});

			ngModelController.$formatters.push(function (modelValue) {
				// console.log("modelValue", modelValue);
				if (!modelValue) {
					$scope.dt = null;
					$scope.converted = true;
					return undefined;
				}
				if (!$scope.converted) {
					let momentObj = moment(modelValue, $scope.inputFormat);
					// OLD CODE: let ngModel = momentObj.format($scope.displayFormat);
					// modificat 26.09.2019 MBarbu - in view nu parsa corect valoarea minutelor
					// (ex. 2019-09-26 10:30 era afisat 2019-09-26 10:ii)
					// fallback, in caz ca displayFormat e cu HH:ii, formateaza cu HH:mm
					let ngModel = momentObj;
					if ($scope.displayFormat === 'YYYY-MM-DD HH:ii') {
						ngModel = momentObj.format('YYYY-MM-DD HH:mm');
					} else {
						ngModel = momentObj.format($scope.displayFormat);
					}
					$scope.converted = true;
					if ($scope.ngModel !== ngModel) {
						$scope.ngModel = ngModel;
						$scope.dt = momentObj.toDate();
						element.find("input").attr("value", $scope.ngModel);
						$scope.refreshValue();
						return $scope.dt;
					}
				}
				// date constructor will apply timezone deviations from UTC (i.e. if locale is behind UTC 'dt' will be one day behind)
				$scope.dt = moment(modelValue, $scope.displayFormat).toDate();
				// $scope.dt.setMinutes($scope.dt.getMinutes() + $scope.dt.getTimezoneOffset());
				$scope.refreshValue();

				return $scope.dt;
			});
			$scope.refreshValue = function () {
				datetimePicker.val($scope.ngModel);
				datetimePicker.attr('value', $scope.ngModel);
				// element.attr('data-date', $scope.ngModel);
			};
			$scope.$on('dataLoaded', () => {
				$scope.$watch($scope.ngModel, function (newValue) {
					// console.log("watch modelValue", newValue, $scope.ngModel);
					$scope.dt = moment($scope.ngModel, $scope.displayFormat).toDate();
					if ($scope.ngModel === null) $scope.dt = null;
					$scope.refreshValue();
				});
			});

		}
	};
}
