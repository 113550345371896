kv.controller('submissionProjectEditCtrl', function ($uibModalInstance, params, $scope, $injector) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'submission_project', 'submission_project_id', $scope, params);

    vm.created_from_project = false;
    if(params.created_from_project){
        vm.data ={};
        vm.data.currency = 'EUR';
        vm.created_from_project = true;
    }
    if (params.submission_project_id == null && !params.entity) {
        vm.isNewRecord = true;
        if(params.created_from_project){
            vm.data ={};
            vm.data.currency = 'EUR';
            vm.data.customer_id = params.project.customer_id;
            vm.data.customer = {
                customer_name: params.project.customer_name
            };
            vm.data.project_id = params.project.project_id;
            vm.data.project = {
                project_name: params.project.project_name
            };
            vm.data.customer_fiscal_entity_id = params.project.customer_fiscal_entity_id;
            vm.data.customer_fiscal_entity = {
                name: params.project.customer_fiscal_entity_name
            };
        }else{
            vm.data = {};
            vm.data.currency = 'EUR';
        }
    } else {
        vm.modal.rendered.then(() => {
            if (!vm.dataHasLoaded) KApp.block('.modal-content');
        });
        vm.rest.get({id: params.entity.submission_project_id}).then((response) => {
            vm.dataHasLoaded = true;
            KApp.unblock('.modal-content');
            if (response.$resolved)
            {
                vm.data = response;
                if (vm.data.attendee_partner) {
                    angular.forEach(vm.data.attendee_partner, function (v, i) {
                        vm.data.attendee_partner[i].id = v.people_id;
                        vm.data.attendee_partner[i].name = v.people.people_name;
                    });
                }
                if (vm.data.attendee_lawyer) {
                    angular.forEach(vm.data.attendee_lawyer, function (v, i) {
                        vm.data.attendee_lawyer[i].id = v.people_id;
                        vm.data.attendee_lawyer[i].name = v.people.people_name;
                    });
                }
            }
            else {
                console.error(response);
            }
        });
    }

    $scope.$watch("lookup.project.all_project_with_customer", function (value) {
        if (value) {
            vm.data.customer_id = value.customer_id;
            vm.data.customer = {
                customer_name: value.customer_name
            };
            vm.data.customer_fiscal_entity_id = value.customer_fiscal_entity_id;
            vm.data.customer_fiscal_entity = {
                name: value.customer_fiscal_entity_name
            };
        }
    });

    vm.validateSomeInputs = function () {
        vm.custom_errors = null;
        vm.has_error = false;

        if(!vm.data.project_id){
            $('[ng-model="vm.data.project_id"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.PROJECT_IS_REQUIRED'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.data.project_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }

        if(!vm.data.customer_id){
            $('[ng-model="vm.data.customer_id"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.CUSTOMER_IS_REQUIRED'));
            vm.has_error = true;
        }else{
            $('[ng-model="vm.data.customer_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }

        if(!vm.data.customer_fiscal_entity_id){
            $('[ng-model="vm.data.customer_fiscal_entity_id"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.CUSTOMER_FISCAL_ENTITY_IS_REQUIRED'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.data.customer_fiscal_entity_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }

        if(!vm.data.start_date){
            $('[ng-model="vm.data.start_date"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.START_DATE_IS_REQUIRED'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.data.start_date"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }

        if(vm.has_error){
            $('.custom_error_messages').css('display', 'block');
        } else {
            $('.custom_error_messages').css('display', 'none');
            vm.save();
        }

    };

    vm.prepareDataToSave = function () {
        if(params.created_from_project){
            let dataToSend = angular.copy(vm.data);
            dataToSend.project_id = params.project.project_id;
            return dataToSend;
        }else{
            let dataToSend = angular.copy(vm.data);
            dataToSend.submission_id = params.submission_id;
            return dataToSend;
        }

    };

    return vm;
});
