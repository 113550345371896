kv.controller('TopCustomersReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    if (vm.bladeParams && vm.bladeParams.data) {
        var data = JSON.parse(vm.bladeParams.data);
    } else {
        var data = [];
    }
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }
    
    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": false,
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            addClass('no-print').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');
    });

    vm.isLoading = true;

    var chartData = data;
    function chart() {
        $('#topCustomersChart').hide();
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    load: function () {
                        this.reflow();
                    }
                },
                height: 550,
            },
            title: {
                text: 'Top ' + $filter('trans')('CUSTOMERS')
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}: <b>{point.y} % ({point.percentage:.1f} % din selectie) </b>'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    },
                    showInLegend: false,
                    innerSize: '60%'
                }
            },
            series: [{
                data: chartData
            }],
            afterPrint: function(a,b,c) {
                console.log(a,b,c)
            }
        }

        // hide dataLabels when resolution is xxs (phone)
        if( $(window).width() < 480 )
        {
          config.plotOptions.pie.dataLabels.enabled = false
        }
        $('#topCustomersChart').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#topCustomersChart').show();
            $('#topCustomersChart').highcharts().reflow();
        }, 1);
    }

    if (chartData) {
        chart();
    }

	return vm;
});
