kv.controller('addTaskCtrl', function ($uibModalInstance, params, resource, $scope, $injector) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'task', 'task_id', $scope, params);
    vm.modal.rendered.then(() => {
        if (!vm.dataHasLoaded) KApp.block('.modal-content');
    });
    vm.data = {};
    vm.dataHasLoaded = false;
    vm.isNewRecord = true;
    vm.data.invoice_id = angular.copy(params.invoice_id);
    vm.dataHasLoaded = true;
    vm.data.all_day = true;
    vm.data.users = [];
    vm.data.users.push({
        id: API.user.user_id,
        name: API.user.full_name
    });


    let currentDate = new Date();
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();

    if (!params.start_date) vm.data.start_date = year + '-' + (month < 10 ?  '0' + month : month) + '-' + (day < 10 ? '0' + day : day)+ ' ' + vm.API.START_DAY_AT;
    if (!params.end_date) vm.data.end_date =  year + '-' + (month < 10 ?  '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + ' ' + vm.API.END_DAY_AT;
  //  vm.data.start_date = new dateClass(vm.data.start_date);
  //  vm.data.end_date = new dateClass(vm.data.end_date);

    vm.entity = 'collection_logs';
    var REST = resource.init(vm.entity);

    vm.saveTask = function () {
        let postData = angular.copy(vm.data);
        REST.post({
            url: 'invoice/' + vm.data.invoice_id + '/collection/actions/new_task',
            data: postData
        }).then(function (response) {
            if(response.status == 'success'){
                toastr.success(response.message);
            }else{
                toastr.error('Error creating task');
            }
            vm.close();
        });
    };

    return vm;
});
