(function () {
	class sideFiltersCtrl {
		constructor(rootScope, $scope) {
			if (!rootScope.sideFiltersCtrl) rootScope.sideFiltersCtrl = {};
			$scope.vm = rootScope.sideFiltersCtrl;
		}
	}

	sideFiltersCtrl.$inject = ["$rootScope", "$scope"];
	angular.module('kv').controller('sideFiltersCtrl', sideFiltersCtrl);
}());