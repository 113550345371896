kv.controller('sysRoutesEditCtrl', function ($uibModalInstance, resource, params, saveCallback, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'sys_routes', 'route_id', $scope, params);

	vm.data = params.data;
	var REST = resource.init("");
	vm.security_groups = [];

	if (params.route_id) {
		vm.route_id = params.route_id;
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		REST.get({
			url: 'sys_routes/' + vm.route_id
		}).then(function (data) {
			angular.forEach(data.tenant_access_rule, function (entity, index) {
				vm.security_groups.push({
					id: entity.security_group.security_group_id,
					name: entity.security_group.name,
				});
			});
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
		});
	} else vm.dataHasLoaded = true;

	vm.save = function save() {
		var postData = [];
		angular.forEach(vm.security_groups, function (entity) {
			postData.push({
				security_group_id: entity.id,
				route_id: vm.route_id,
			});
		});
		REST.update({
			url: 'sys_routes/' + vm.route_id,
			data: {
				tenant_access_rule: vm.security_groups
			}
		}).then(function (data) {
			$uibModalInstance.close(vm.route_id);
		});

	};


	vm.saveCallback = saveCallback;
	return vm;
});
