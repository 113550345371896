kv.controller('userVacationEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'vacation_id';
	vm.entity = 'user_vacation';
	vm.rest = vm.getInjection('resource').init(vm.entity);
	vm.validator = vm.getInjection('validator');

	// let cmdate = vm.getInjection('cmdate');

	var REST = resource.init(vm.entity);
	let url_segments = window.location.pathname.split( '/' );
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];

	if(page_identifier == 'edit') {
		$http.get("/user_vacation/" + page_entity_id).then(function(response) {
			vm.data = response.data;
		});
	} else {
		vm.data = {};
		vm.isNewRecord = true;
		vm.data.user= [];
		vm.data.user_id = API.user.user_id;
		vm.data.user.full_name = API.user.full_name;

		if(!vm.data.request_date){
			let currentDate = new Date();
			let day = currentDate.getDate();
			let month = currentDate.getMonth() + 1;
			let year = currentDate.getFullYear();
			vm.data.request_date = year + '-' + (month < 10 ?  '0' + month : month) + '-' + (day < 10 ? '0' + day : day)+ ' ' + vm.API.START_DAY_AT;
		}

	}

	vm.newEntity = function newEntity(entity) {
		if(!vm.data) {
			vm.data = {};
		}

		if(!vm.data[entity]) {
			return vm.data[entity] = [{}];
		}

		return vm.data[entity].push({});
	};


	vm.deleteEntity = function deleteEntity($index, entity, id) {
		if(!id) {
			return vm.data[entity].splice($index,1);
		}

		if(page_entity_id) {
			REST.delete({
				url: vm.entity + '/:id/' + entity + '/:entityId',
				params: {
					id: page_entity_id,
					entityId: id
				}
			}).then(function(data) {
				vm.data[entity].splice($index,1);
			});
		}
	};

	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		// used for validations: if contract is normal created, do validations, else not (case project, where required data is sent from backend)
		return postData;
	};

	return vm;
});
