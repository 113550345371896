kv.controller('exchangeRateListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "exchange_rate_id",
		entity: "exchange_rate",
		modal: {
			templateUrl: "exchange_rate-edit",
			controller: "exchangeRateEditCtrl"
		}
	});
});
