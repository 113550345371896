kv.controller('UserWorkloadReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var data = JSON.parse(vm.bladeParams.data);
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function() {
        var t = $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
            "columnDefs": [
                {
                    "searchable": false,
                    "orderable": false,
                    "targets": 0
                },
                {
                    "searchable": false,
                    "orderable": false,
                    "targets": 7
                }
            ],
            "order": [[ 1, 'asc' ]]
        });

        t.on( 'order.dt search.dt', function () {
            t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                cell.innerHTML = i+1;
            } );
        } ).draw();

        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right');

        function setOrderBy() {
            var orderBy = $('[aria-sort="ascending"], [aria-sort="descending"]').attr("data-order-by");
            var direction = $('[aria-sort="ascending"], [aria-sort="descending"]').attr('aria-sort');

            $("#print").attr("href", $("#print").attr("href") + "&orderBy=" + orderBy + "&direction=" + direction);
        }
    });

    vm.isLoading = true;

    var chartData = data[1];
    function chart() {
        $('#userWorkloadChart').hide();
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'bar',
                events: {
                    load: function () {
                        this.reflow();
                    }
                },
            },
            title: {
                text: $filter('trans')('USER_WORKLOAD_REPORT.NAME')
            },
            subtitle: {
                text: chartData.subtitle
            },
            xAxis: {
                categories: chartData.names
            },
            yAxis: {
                min: 0,
                max: 200,
                title: {
                    text: null
                },
            },
            tooltip: {
                shared: true,
                valueSuffix: ' %'
            },
            credits: {
                enabled: false
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                bar: {
                    borderWidth: 0,
                    shadow: false,
                    groupPadding: 0,
                },
            },
            series: [
                {
                    name: $filter('trans')('USER_WORKLOAD_VACATION_AND_HOLIDAY'),
                    color: '#769EFE',
                    data: chartData.vacations
                },
                {
                    name: $filter('trans')('INTERNAL'),
                    color: '#a1a8c3',
                    data: chartData.internal
                },
                {
                    name: $filter('trans')('USER_WORKLOAD_NOT_CERTAIN'),
                    color: '#ffc57c',
                    data: chartData.not_certain
                },
                {
                    name: $filter('trans')('USER_WORKLOAD_CERTAIN'),
                    color: '#bedd8d',
                    data: chartData.certain
                },
            ]
        };

        // hide dataLabels when resolution is xxs (phone)
        if( $(window).width() < 480 )
        {
          config.plotOptions.pie.dataLabels.enabled = false;
        }
        $('#userWorkloadChart').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#userWorkloadChart').show();
            $('#userWorkloadChart').highcharts().reflow();
        }, 1);
    }

    if (chartData && chartData.names) {
        chart();
    }

	return vm;
});
