kv.controller('invoiceEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'invoice_id';
	vm.entity = 'invoice';
	vm.rest = vm.getInjection('resource').init(vm.entity);
	vm.validator = vm.getInjection('validator');
	vm.dataHasLoaded = false;

	var REST = resource.init(vm.entity);
	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];
	vm.invoice_id = page_entity_id;

	if (page_identifier == 'edit') {
		KApp.block($element.find('.k-content__head'));
		$http.get("/invoice/" + page_entity_id).then(function (response) {
			vm.data = response.data;
			vm.dataHasLoaded = true;
			KApp.unblock($element.find('.k-content__head'));
		});
	} else {
		vm.data = {};
	}

	vm.quickAddCustomerFiscalEntity = function () {
		vm.openModal({
			animation: true,
			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'customer_fiscal_entity';
				}
			}
		}).then(function (result) {
			vm.data.customer_fiscal_entity_id = result.customer_fiscal_entity_id;
			vm.data.customer_fiscal_entity = {
				name: result.name
			};
		});
	};


	vm.newEntity = function newEntity(entity) {
		if (!vm.data) {
			vm.data = {};
		}

		if (!vm.data[entity]) {
			return vm.data[entity] = [{}];
		}

		return vm.data[entity].push({});
	};

	vm.deleteEntity = function deleteEntity($index, entity, id) {
		if (!id) {
			return vm.data[entity].splice($index, 1);
		}

		if (page_entity_id) {
			REST.delete({
				url: vm.entity + '/:id/' + entity + '/:entityId',
				params: {
					id: page_entity_id,
					entityId: id
				}
			}).then(function (data) {
				vm.data[entity].splice($index, 1);
			});
		}
	};

	vm.parseTranslate = function ($index) {
		vm.data.invoice_detail[$index].item_description = $filter("parseTranslate")(vm.data.invoice_detail[$index].item_description);
		vm.data.invoice_detail[$index].item_description = $filter("br2nl")(vm.data.invoice_detail[$index].item_description);
	}

	function setAllToSecondary($index, entity) {
		angular.forEach(vm.data[entity], function (value, key) {
			if ($index != key) {
				value.is_primary = 0;
			}
		});
	}

	vm.addDiscount = function ($index) {
		vm.openModal({
			templateUrl: "add_discount",
			controller: 'addDiscountCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: function () {
					return {
						index: $index,
						result: ($index != null) ? vm.data.invoice_detail[$index] : null,
						vat_percent: vm.data.vat_percent,
					};
				},
				saveCallback: function () {
				},
			}
		}).then(response => {
			if (!response) return;
			vm.addInvoiceDetail(response.discount_type, response.discount_value, response.discount_vat, response.master_project, response.source_project);
		});
	}

	vm.addInvoiceDetail = function (type, value, vat, master_project, source_project) {
		let new_index = vm.data.invoice_detail.length;

		var item_id = null;
		var item_name = null;
		switch (type) {
			case "SERVICES":
				item_id = 1;
				item_name = 'ITEM_SERVICE';
				break;
			case "EXPENSES":
				item_id = 2;
				item_name = 'ITEM_EXPENSE';
				break;
			case "DISCOUNT_VALUE":
			case "DISCOUNT_PERCENT":
				item_id = 3;
				item_name = 'ITEM_DISCOUNT';
				break;
			case "FEE_ADVANCE":
				item_id = 4;
				item_name = 'ITEM_FEE_ADVANCE';
				break;
			case "EXPENSE_ADVANCE":
				item_id = 5;
				item_name = 'ITEM_EXPENSE_ADVANCE';
				break;
			case "DISCOUNT_VALUE":
				item_id = 6;
				item_name = 'ITEM_EXPENSE_DISCOUNT';
				break;
		}
		var lastLineNo = 0;
		if (vm.data.invoice_detail[(vm.data.invoice_detail.length - 1)]) {
			lastLineNo = parseInt(vm.data.invoice_detail[(vm.data.invoice_detail.length - 1)].line_no);
		}
		vm.data.invoice_detail.push({
			item_id: item_id,
			sys_invoice_item: {
				item_id: item_id,
				name: item_name
			},
			line_no: (lastLineNo + 1),
			item_description: $filter('trans')(type),
			base_amount: (value) ? value : null,
			vat_amount: (vat) ? vat : null,
			vat_percent: vm.data.vat_percent,
			master_project_id: (master_project) ? master_project.project_id : null,
			master_project: (master_project) ? master_project : null,
			source_project_id: (source_project) ? source_project.project_id : null,
			source_project: (source_project) ? source_project : null
		});

		// la adaugarea unei linii noi se deschide automat linia de proiect si referinta
		$('#makeCollapse').on('click', function () {
			$("[data-target='#collapse_" + new_index + "']").click();
		});
	}

	/*$scope.$watch("vm.data.invoice_ext.invoice_series_id", function (value) {
		if (value){
			$http.get("/invoice_series").then(function(response) {
				if(response !== undefined && response.data !== undefined && response.data.data !== undefined ) {
					var result = response.data.data;

					angular.forEach(result, function (v, i) {
						if (result[i].prefix != null){
							if(result[i].series_type_id === 1 && result[i].is_active === 1 && (result[i].document_series_id == value || result[i].prefix.concat(result[i].current_number) == value || result[i].current_number == value || result[i].prefix.concat(result[i].current_number, result[i].suffix) == value)){
								result.prefix = v.prefix;
								result.current_number = v.current_number;
								result.end_number = v.end_number;
								result.suffix = v.suffix;
							}
						}else if (result[i].current_number == value){
							result.number = v.next_invoice_number;
							result.serial_number = v.next_invoice_number.concat(v.next_invoice_number);
						}
					});
					vm.data.series = result.prefix;
					vm.data.serial_number = result.current_number;
					vm.data.end_number = result.end_number;
					vm.current_number = result.current_number;
					vm.data.suffix = result.suffix;
					vm.data.prefix = result.suffix;
					if(vm.data.suffix != null){
						vm.data.contract_series_type = result.prefix.concat(result.current_number, result.suffix);
					}else if(vm.data.prefix != null){
						vm.data.contract_series_type = result.prefix.concat(result.current_number);
					}else{
						vm.data.contract_series_type = result.current_number;
					}


				}
			});
		}
	}, true);*/

	vm.delete = function ($index) {
		var modalInstance = $uibModal.open({
			component: 'modalConfirm',
			resolve: {
				content: () => {
					return $filter("trans")("ARE_YOU_SURE_DELETE");
				},
				title: () => {
					return $filter("trans")("DELETE");
				}
			}
		});

		modalInstance.result.then(function () {
			$http.delete("/invoice_detail/" + vm.data.invoice_detail[$index]['invoice_detail_id']).then(function () {
				vm.data.invoice_detail.splice($index, 1);
			});
		});
	};

	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);

		// used for validations: if action is change_status, don't validate, just change status, else make validations
		// postData.action = 'add_edit';

		switch (arguments[0]) {
			case 'proforma':
				postData.invoice_type_id = 1;
				break;
			case 'fiscal':
				postData.invoice_type_id = 2;
				break;
			default:
		}

		return postData;
	};

	vm.calculateLineTotal = function (index) {
		let qtty = parseFloat(vm.data.invoice_detail[index].qtty).toFixed(2);
		let price = parseFloat(vm.data.invoice_detail[index].price).toFixed(4);
		if (qtty && price) {
			vm.data.invoice_detail[index].base_amount = Math.round(qtty * price * 100) / 100;
			vm.data.invoice_detail[index].vat_amount = vm.data.invoice_detail[index].vat_percent / 100 * vm.data.invoice_detail[index].base_amount;
			vm.data.invoice_detail[index].total_amount = vm.data.invoice_detail[index].base_amount + vm.data.invoice_detail[index].vat_amount;
		}
	};

	// calculate vat amount, depending on base_amount, on input change
	vm.calculateVat = function(index) {
		if(vm.data.invoice_detail[index].item_id == 3 || vm.data.invoice_detail[index].item_id == "6"){
			let base_amount = Math.abs(vm.data.invoice_detail[index].base_amount);
			vm.data.invoice_detail[index].vat_amount = '-' + (vm.data.invoice_detail[index].vat_percent / 100 * base_amount);
		} else {
			vm.data.invoice_detail[index].vat_amount = vm.data.invoice_detail[index].vat_percent / 100 * vm.data.invoice_detail[index].base_amount;
		}
	};


	return vm;
});
