kv.controller('tenantBankAccountEditCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'bank_account', 'bank_account_id', $scope, params);

	if (!params.bank_account_id) {
		vm.data = params;
		vm.data.is_default = 0;
		vm.isNewRecord = true;
	} else {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		vm.rest.get({id: params.bank_account_id}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) vm.data = response;
			else console.error(response);
		});
	}
	vm.saveCallback = saveCallback;

	return vm;
});
