kv.controller('ExpenseListReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    var params = kvUtils.getAllQueryParams();
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function (){
        var table = $('#more-details').DataTable({
            'autoWidth': false,
            'responsive': true,
            "paging":   false,
            'order': [[1, 'asc']],
            "columns": [
                {
                    'orderable':      false,
                    'data':           null,
                    'defaultContent': '' 
                },
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                {
                    'orderable': false,
                    'width': '21%'
                },
                null,
                null,
                null
            ]
        });
        
        $('#more-details').on('click', 'td.details-control', function () {
          var tr = $(this).closest('tr');
          var row = table.row(tr);
            if (row.child.isShown()) {
                // sectiune de extra detalii este deschisa, aplica clasa 'shown' - afiseaza butonul cu '-' rosu
                tr.addClass('shown');
            } else {
                // sectiunea de extra detalii este inchisa, nu se mai aplica clasa 'shown' - afiseaza butonul cu '+' verde 
                tr.removeClass('shown');
            }
        });
    });

    return vm;
});
