kv.controller('RecoveryRateHeatmapReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var data = JSON.parse(vm.bladeParams.data);
    let initParams = kvUtils.getAllQueryParams();

    if (initParams) {
        if (initParams.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    vm.drillDown = function(group_by, agg_id) {
        let params = angular.copy(initParams);
        params[params.group_by + "_id"] = agg_id;
        params.group_by = group_by;

        // if initial grouping was made by YEAR_MONTH, format date from and date to by interval from result
        // E.g.:
        //  201812 ....... => at drillDown, report will be recalled with date_from = 2018-12-01 and date_to = 2018-12-31
        //  201901 ....... => at drillDown, report will be recalled with date_from = 2019-01-01 and date_to = 2019-01-31
        //  201902 ....... => at drillDown, report will be recalled with date_from = 2019-02-01 and date_to = 2019-02-28
        if (initParams.group_by == 'year_month') {
            // get year and month and format date_from
            let year = agg_id.toString().substring(0,4);
            let month = agg_id.toString().substring(4);
            let dateFrom = year + '-' + month + '-01';
            // Check selected interval -> date_from. If initial date_from is, for example, 2019-03-15, and you want drillDown for this month, keep this original date_from, not 2019-03-01
            // Recall will be made with date_from=2019-03-15 and date_to=2019-03-31
            if ((initParams.date_from && new Date(initParams.date_from) <= (new Date(dateFrom))) || !initParams.date_from) {
                params.date_from = dateFrom;
            }

            // get last day of selected month and format date_to
            let currMonth = new Date(params.date_from);
            let lastDayOfMonth = new Date(currMonth.getFullYear(), currMonth.getMonth()+1, 0);
            let dateTo = lastDayOfMonth.getFullYear() + '-' + ((lastDayOfMonth.getMonth()+1) < 10 ? '0' : '') + (lastDayOfMonth.getMonth()+1) + '-' + lastDayOfMonth.getDate();
            // Check selected interval -> date_to. If initial date_to is, for example, 2019-03-15, and you want drillDown for this month, keep this original date_to, not 2019-03-31
            // Recall will be made with date_from=2019-03-01 and date_to=2019-03-15
            if ((initParams.date_to && new Date(initParams.date_to) >= (new Date(dateTo))) || !initParams.date_to) {
                params.date_to = dateTo;
            }
        // if initial grouping was made by YEAR, format date from and date to by interval from result
        // E.g.:
        //  2018 ....... => at drillDown, report will be recalled with date_from = 2018-01-01 and date_to = 2018-12-31
        //  2019 ....... => at drillDown, report will be recalled with date_from = 2019-01-01 and date_to = 2019-12-31
        } else if (initParams.group_by == 'year') {
            // get year and format date_from
            let year = agg_id;
            let dateFrom = year + '-01-01';
            // Check selected interval -> date_from. If initial date_from is, for example, 2019-03-15, and you want drillDown for this month, keep this original date_from, not 2019-01-01
            // Recall will be made with date_from=2019-03-15 and date_to=2019-12-31
            if ((initParams.date_from && new Date(initParams.date_from) <= (new Date(dateFrom))) || !initParams.date_from) {
                params.date_from = dateFrom;
            }

            // format date_to
            let dateTo = year + '-12-31';
            // Check selected interval -> date_to. If initial date_to is, for example, 2019-03-15, and you want drillDown for this month, keep this original date_to, not 2019-12-31
            // Recall will be made with date_from=2019-01-01 and date_to=2019-03-15
            if ((initParams.date_to && new Date(initParams.date_to) >= (new Date(dateTo))) || !initParams.date_to) {
                params.date_to = dateTo;
            }
        }

        let url = window.location.origin + window.location.pathname + "?" + $filter("objToParams")(params);
        window.open(url);
    };
    
    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
            // "columnDefs": [ {
            //     "targets": [1, 6],
            //     "orderable": false
            // } ]
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');
    });

    vm.isLoading = true;

    var chartData = data[1];

    function chart() {
        $('#chart').hide();

        var config = {
            series: [{
                type: 'treemap',
                layoutAlgorithm: 'squarified',
                data: chartData
            }],
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            tooltip: {
                formatter: function() {
                        return '<b>' + this.point.name + '</b>'
                            +'<br/><b>Efort</b>:            ' + Highcharts.numberFormat(this.point.value) + ' hrs '
                            +'<br/><b>Recovery rate</b>:  ' + this.point.recovery_percent + '% '
                            ;
                }
            },
            title: '',
            credits: {
                enabled: false
            }
        }
        // hide dataLabels when resolution is xxs (phone)
        if( $(window).width() < 480 )
        {
          config.plotOptions.pie.dataLabels.enabled = false
        }
        $('#chart').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#chart').show();
            $('#chart').highcharts().reflow();
        }, 1);
    }

    if (initParams && chartData.length) {
        chart();
    }

	return vm;
});
