kv.controller('projectTaskEditCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'task', 'task_id', $scope, params);


	vm.isNewRecord = true;
	vm.data = {};

	vm.data.user_id = API.user.user_id;
	vm.data.user = {
		full_name: API.user.full_name
	};
console.log(params)
	vm.data.project_id = params.entity.project_id;
	vm.data.task_date = moment();
	vm.data.start_date = vm.data.task_date.set({
		'hour': moment().get('hour') + 1,
		'minute': 0,
		'second': 0
	});
	vm.data.end_date = angular.copy(vm.data.start_date).add(1, 'hours');
	vm.data.all_day = false;

	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		postData.force_update_entity = true;
		return postData;
	};

	vm.saveCallback = saveCallback;
	return vm;
});
