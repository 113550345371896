kv.controller('duplicateProjectCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'project', 'project_id', $scope, params);

    vm.dataHasLoaded = false;
    vm.modal.rendered.then(() => {
        if (!vm.dataHasLoaded) KApp.block('.modal-content');
    });

    vm.rest.get({
        url: "project/" + params.entity.project_id,
        id: params.entity.project_id,
        params: {
            duplicate: true,
        }
    }).then(function (data) {
        KApp.unblock('.modal-content');
        vm.data = data;
    });

    vm.isNewRecord = true;
    vm.prepareDataToSave = function () {
        let postData = angular.copy(vm.data);
        postData.project_type = "project";
        postData.entity_type = "standard";

        postData.old_project_id = postData.project_id;
        postData.project_name = '(Copy of) ' + postData.project_name;
        postData.duplicate = true;

        // some fields are reset
        postData.contract = null;
        delete postData.project_id;
        delete postData.project_number;
        delete postData.created_by;
        delete postData.created_at;

        if (postData.project_ext) {
            delete postData.project_ext.project_ext_id;
            delete postData.project_ext.project_id;
        }

        // delete project_manager ids and project ids
        if (postData.project_manager) {
            //delete project_manager_id
            for ($i = 0; $i < postData.project_manager.length; $i++) {
                delete postData.project_manager[$i].project_manager_id;
                delete postData.project_manager[$i].project_id;
            }
        }

        // delete commodity_activity ids and project ids
        if (postData.commodity_activity) {
            for ($i = 0; $i < postData.commodity_activity.length; $i++) {
                delete postData.commodity_activity[$i].commodity_activity_id;
                delete postData.commodity_activity[$i].project_id;
            }
        }

        // delete project_budget ids and project ids, alerts, values
        if (postData.project_budget) {
            delete postData.project_budget.project_budget_id;
            delete postData.project_budget.project_id;
            delete postData.project_budget.actual_value;
            delete postData.project_budget.alert_created_at;
            delete postData.project_budget.alert_treshold_percent;
            delete postData.project_budget.alert_treshold_value;
            delete postData.project_budget.budget_type;
            delete postData.project_budget.budget_value;
            delete postData.project_budget.enable_budget_alert;
        }

        // delete project_team ids and project ids
        if (postData.project_team) {
            for (let $i = 0; $i < postData.project_team.length; $i++) {
                delete postData.project_team[$i].project_team_id;
                delete postData.project_team[$i].project_id;
            }
        }

        // delete project_billing_rules ids and project ids and invoice ids
        if (postData.project_billing_rules) {
            postData.billing_rule = postData.project_billing_rules;
            //delete project_billing_rules_id
            for (let $i = 0; $i < postData.billing_rule.length; $i++) {
                delete postData.billing_rule[$i].billing_rule_id;
                delete postData.billing_rule[$i].project_id;
                delete postData.billing_rule[$i].invoice_id;
            }
            delete postData.project_billing_rules;
        }

        // copy projects(references) into another variable in order to work with related models in service AND delete projects (references)
        if (postData.projects) {
            postData.reference = postData.projects;
            delete postData.projects;
            //delete parent_id
            for (let $i = 0; $i < postData.reference.length; $i++) {
                if (postData.reference[$i].project_billing_rules) {
                    postData.reference[$i].billing_rule = postData.reference[$i].project_billing_rules;
                    //delete project_billing_rules_id
                    for (let $j = 0; $j < postData.reference[$i].billing_rule.length; $j++) {
                        delete postData.reference[$i].billing_rule[$j].billing_rule_id;
                        delete postData.reference[$i].billing_rule[$j].project_id;
                        delete postData.reference[$i].billing_rule[$j].invoice_id;
                    }
                }
                delete postData.reference[$i].project_billing_rules;
                delete postData.reference[$i].project_id;
                delete postData.reference[$i].parent_id;
            }
        }

        // delete references ids and parent ids
        if (postData.reference) {
            //delete parent_id
            for (let $i = 0; $i < postData.reference.length; $i++) {
                delete postData.reference[$i].project_id;
                delete postData.reference[$i].parent_id;
            }
        }

        return postData;
    };

    vm.saveCallback = saveCallback;

    return vm;
});