/**
 * Usage
 * {{ user.birthday | birthday }}
 * @return {human readable text} e.g.: in 4 days
 */
(function ()
{
    'use strict';

    angular
        .module('kv')
        .filter('birthday', birthday);

    function birthday($filter, cmdate) {
        return function(input) {
            if(input) {
                var now = moment();
                var date = cmdate.fromServer(input).set({'year': now.format("YYYY")});
                if(date > now) {
                    var diff = moment.duration(date.diff(now));
                    if(diff.asDays() < 30) {
                        return diff.humanize(true);
                    }
                }
            }
            return "";
        };
    };

})();
