kv.controller('submissionListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'submission_id';
    vm.entity = 'submission';

    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getInfinitScroll({
        params: {
            action: 'submission'
        },
        url: "submission",
    });

    return vm;
});

