kv.controller('contractEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'contract_id';
    vm.entity = 'contract';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');

    // let cmdate = vm.getInjection('cmdate');

    var REST = resource.init(vm.entity);
    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];  

    if(page_identifier == 'edit') {
        $http.get("/contract/" + page_entity_id).then(function(response) {
            vm.data = response.data;
            if (vm.data.fee_types) {
                angular.forEach(vm.data.fee_types, function (v, i) {
                    vm.data.fee_types[i].id = v.fee_type_id;
                    vm.data.fee_types[i].name = v.fee_type_name;
                });
            }
            if(vm.data.parent_id){
                vm.data.contract_number = vm.data.parent.number;
            }
        });

    } else {
        vm.data = {};
        vm.isNewRecord = true;

        $http.get("/document_series").then(function(response) {
            if(response !== undefined && response.data !== undefined && response.data.data !== undefined ) {
                var result = response.data.data;
                angular.forEach(result, function (v, i) {
                    if( result[i].series_type_id === 1 && result[i].is_active === 1){
                        result.prefix = v.prefix;
                        result.current_number = v.current_number;
                        result.end_number = v.end_number;
                        result.suffix = v.suffix;
                    }
                });
                vm.data.series = result.prefix;
                vm.data.suffix = result.suffix;
                vm.data.end_number = result.end_number;
                vm.current_number = result.current_number;
                vm.data.serial_number = result.current_number;

                if(vm.data.suffix != null){
                    vm.data.contract_series_type = result.prefix.concat(result.current_number, result.suffix);
                }else if(vm.data.prefix != null){
                    vm.data.contract_series_type = result.prefix.concat(result.current_number);
                }else{
                    vm.data.contract_series_type = result.current_number;
                }


            }
        });


        $scope.$watch("vm.data.contract_series_type", function (value) {
            if (value){
                $http.get("/document_series").then(function(response) {
                    if(response !== undefined && response.data !== undefined && response.data.data !== undefined ) {
                        var result = response.data.data;

                        angular.forEach(result, function (v, i) {
                            if (result[i].prefix != null){
                                if(result[i].series_type_id === 1 && result[i].is_active === 1 && (result[i].document_series_id == value || result[i].prefix.concat(result[i].current_number) == value || result[i].current_number == value || result[i].prefix.concat(result[i].current_number, result[i].suffix) == value)){
                                    result.prefix = v.prefix;
                                    result.current_number = v.current_number;
                                    result.end_number = v.end_number;
                                    result.suffix = v.suffix;
                                }
                            }else if (result[i].current_number == value){
                                result.prefix = v.prefix;
                                result.current_number = v.current_number;
                                result.end_number = v.end_number;
                                result.suffix = v.suffix;
                            }
                        });
                        vm.data.series = result.prefix;
                        vm.data.serial_number = result.current_number;
                        vm.data.end_number = result.end_number;
                        vm.current_number = result.current_number;
                        vm.data.suffix = result.suffix;
                        vm.data.prefix = result.prefix;
                        if(vm.data.suffix != null){
                            vm.data.contract_series_type = result.prefix.concat(result.current_number, result.suffix);
                        }else if(vm.data.prefix != null){
                            vm.data.contract_series_type = result.prefix.concat(result.current_number);
                        }else{
                            vm.data.contract_series_type = result.current_number;
                        }


                    }
                });
            }
        }, true);


        $scope.$watch('vm.data.series', function (value) {
            if (value && vm.data.serial_number && vm.data.serial_number !== null && vm.data.suffix) {
                vm.data.number = vm.data.series.concat(vm.data.serial_number, vm.data.suffix);
            }else if(value && vm.data.serial_number && vm.data.serial_number !== null){
                vm.data.number = vm.data.series.concat(vm.data.serial_number);
            }
        });

        $scope.$watch('vm.data.serial_number', function (value) {
            if (value && vm.data.series && vm.data.series !== null && vm.data.suffix) {
                vm.data.number = vm.data.series.concat(vm.data.serial_number, vm.data.suffix);
            }else if(value && vm.data.series && vm.data.series !== null){
                vm.data.number = vm.data.series.concat(vm.data.serial_number);
            }else{
                vm.data.number = vm.data.serial_number;
            }
        });

        let defaultObjects = JSON.parse($element.find("#defaultObjects").attr("defaultObjects"));
        vm.data = defaultObjects;
        vm.data.series = vm.bladeParams.series ? vm.bladeParams.series : vm.data.series;
    }

    vm.checkContractNumber = function () {
        REST.get({
            url: "contract/list",
            params: {
                contract_number: vm.data.number
            }
        }).then(function (data) {
            if (data && data.length >= 1) {
                vm.data.has_duplicated_contract_number = true;
            }else{
                vm.data.has_duplicated_contract_number = false;
            }

        });
    }

    vm.newEntity = function newEntity(entity) {
        if(!vm.data) {
            vm.data = {};
        }

        if(!vm.data[entity]) {
            return vm.data[entity] = [{}];
        }

        return vm.data[entity].push({});
    };



    vm.deleteEntity = function deleteEntity($index, entity, id) {
        if(!id) {
            return vm.data[entity].splice($index,1);
        }
        
        if(page_entity_id) {
            REST.delete({
                url: vm.entity + '/:id/' + entity + '/:entityId',
                params: {
                    id: page_entity_id,
                    entityId: id
                }
            }).then(function(data) {
                vm.data[entity].splice($index,1);
            });
        }
    };

    vm.quickAddCustomer = function quickAddCustomer() {
        vm.openModal({
            animation: true,
            component: 'quickAddEntity',
            resolve: {
                entity: function () {
                    return $rootScope.quickAddEntityTemplate = 'customer';
                }
            }
        }).then(function (data) {
            vm.data.customer_id = data.customer_id;
            vm.data.customer = {
                customer_name: data.customer_name
            };
        });
    };

    vm.validateSomeInputs = function () {
        vm.checkContractNumber();

        vm.custom_errors = null;
        vm.has_error = false;


        if(vm.data.contract_series_type && vm.data.serial_number > vm.data.end_number){
            $('[ng-model="vm.data.serial_number"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.NO_AVAILABLE_CONTRACT_SERIES'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.data.serial_number"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }

        if(vm.data.has_duplicated_contract_number){
            $('[ng-model="vm.data.number"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.DUPLICATED_CONTRACT_NUMBER'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.data.number"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }

        if(vm.has_error){
            $('.custom_error_messages').css('display', 'block');
        } else {
            $('.custom_error_messages').css('display', 'none');
            vm.save();
        }

    };

    vm.prepareDataToSave = function () {
        let postData = angular.copy(vm.data);
        // used for validations: if contract is normal created, do validations, else not (case project, where required data is sent from backend)
        postData.basic_create = true;
        return postData;
    };

	return vm;
});
