kv.controller('internalCategoryEditCtrl', function ($uibModalInstance, data, $injector, $scope) {
	return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
		entity: "internal_category",
		primaryKey: "internal_category_id",
		newEntity:{
		},
		prepareDataToSave: function () {
			let dataToSend = angular.copy(this.data);
			return dataToSend;
		}
	});
});