kv.controller('departmentListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "department_id",
		entity: "department",
		modal: {
			templateUrl: "department-edit",
			controller: "departmentEditCtrl"
		}
	});
});
