kv.controller('WipReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);

	let initParams = kvUtils.getAllQueryParams();

	if (initParams) {
		if (initParams.generate) {
			$('[href="#results-tab"]').tab("show");
		}
	}

	vm.drillDown = function (aggregate_by, agg_id, agg_name) {
		let params = angular.copy(initParams);
		params[params.aggregate_by + "_id"] = agg_id;
		params[params.aggregate_by] = agg_name;
		params.aggregate_by = aggregate_by;
		let url = window.location.origin + window.location.pathname + "?" + $filter("objToParams")(params);
		window.open(url);
	};

	return vm;
});
