kv.controller('ProjectAllocationShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, cmdate) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    var params = kvUtils.getAllQueryParams();
    var REST = resource.init('');
    vm.action = {
        confirmAllocation: confirmAllocation,
        simulateAllocation: simulateAllocation,
        confirmAllocationAll: confirmAllocationAll,
        addProjectStatus: addProjectStatus,
    };

    toastr.options = {
        "closeButton": true,
        "debug": false,
        "positionClass": "toast-top-center",
        "onclick": null,
        "showDuration": "500",
        "hideDuration": "500",
        "timeOut": "0",
        "extendedTimeOut": "0",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
   
    $(document).ready(function() {
        function format(value) {
            return '<div>' + value + '</div>';
        }
        var table = $('#table').DataTable({
            "paging":   true,
            "ordering": true,
            "info":     true,
            "fnInfoCallback": function( oSettings, iStart, iEnd, iMax, iTotal, sPre ) {
                return iStart +"-"+ iEnd + ' din ' + iTotal +' proiecte';
            },
            "bLengthChange": false,
              "columnDefs": [
                { "orderable": false, "targets": [0,9] }
              ],
            "order": [[ 1, 'asc' ]],
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');

        $('#table').on('click', 'td.details-control', function () {
              var tr = $(this).closest('tr');
              var row = table.row(tr);

              if (row.child.isShown()) {
                  // This row is already open - close it
                  row.child.hide();
                  tr.removeClass('shown');
              } else {
                  // Open this row
                  row.child(format(tr.data('child-value'))).show();
                  tr.addClass('shown');
              }
          });
    });
    if(vm.bladeParams.customerId) {
        vm.filter.customer_id = params.customer_id;
        vm.filter.customer_name = vm.bladeParams.customerName;
    }
    if(vm.bladeParams.tenantFiscalEntityId) {
        vm.filter.tenant_fiscal_entity_id = params.tenant_fiscal_entity_id;
        vm.filter.tenant_fiscal_entity_name = vm.bladeParams.tenantFiscalEntityName;
    }

    if(vm.bladeParams.allocationMethod) {
        vm.allocation_method = params.allocation_method;
        if(vm.allocation_method == 1) {
            vm.allocation_method_name = $filter('trans')('STANDARD');
        } else if (vm.allocation_method == 2) {
            vm.allocation_method_name = $filter('trans')('BY_STATUS');
        } else if (vm.allocation_method == 3) {
            vm.allocation_method_name = $filter('trans')('ALL');
        }
    }

    if (vm.bladeParams.year) {
    	vm.filter.year = params.year;
    } else {
    	vm.filter.year = (new Date()).getFullYear();
    }

    function confirmAllocation(project_id, date) {
        // date = cmdate.fromServer(date);
        allocate(project_id, date);
    }

    function simulateAllocation(project_id, date) {
        // date = cmdate.fromServer(date);
        allocate(project_id, date, true);
    }

    function confirmAllocationAll(date) {
        var projects = JSON.parse(vm.bladeParams.projects);
        // date = cmdate.fromServer(date);

        allocateMultiple(projects, 0, date);
    }

    function allocateMultiple(projects, index, date) {
        // instantiaza vm.start, doar la primul apel al functiei de alocare
        if (index == 0) {
            vm.start = new Date().getTime();
            vm.totalProjectCount = projects.length;
            vm.processedProjectCount = 1;
        }

        // apel asincron pentru fiecare proiect in parte
        // count curent = index + 1
        // count total = projects.length
        if(projects[index]) {
            // get next 100
            PACKET_SIZE = 50;
            project_list = '';
            current_index = index;
            max_index = Math.min(projects.length, current_index + PACKET_SIZE);
            for (i = current_index; i <= max_index; i++) {
                if (projects[i]) {
                    project_list += projects[i] + ',';
                }
            }

            REST.getArray({
                url: vm.bladeParams.url,
                params: {
                    allocate: true,
                    project_id: project_list,
                    date: date
                }
            }).then(function(res) {
                // calculate elapsed time
                vm.processedProjectCount = max_index;
                vm.multipleAllocationStatus = vm.processedProjectCount + " of " + vm.totalProjectCount + " projects";
                vm.multipleAllocationStatusPercent = ((vm.processedProjectCount / vm.totalProjectCount)*100).toFixed(0);
                vm.percentageCompleted = parseInt(vm.processedProjectCount / vm.totalProjectCount)*100;

                vm.now = new Date().getTime();
                vm.elapsedTime = (vm.now-vm.start)/1000;
                vm.remainingTime = vm.elapsedTime / vm.processedProjectCount * ( vm.totalProjectCount - vm.processedProjectCount );

                // recursive call to process the next project
                allocateMultiple(projects, max_index, date);

                if (res.length > 0){
                    vm.projectAllocationErrors = '';
                    angular.forEach(res, function (v, i) {
                        vm.projectAllocationErrors += v + ". </br> </br>";
                    });
                    toastr.error(vm.trans('ALLOCATION_ERROR') + ". </br> " + vm.projectAllocationErrors);
                }
            })
            // format minutes and seconds
            vm.secondsRemaining = parseInt(vm.remainingTime);
            vm.minutesRemaining = parseInt(vm.remainingTime/60);
        }
    }

    function allocate(project_id, date, simulate) {
        REST.getArray({
            url: vm.bladeParams.url,
            params: {
                allocate: true,
                project_id: project_id,
                date: date,
                simulate: (simulate) ? 1 : 0,
                debug: (simulate) ? 1 : 0
            }
        }).then(function(res) {
            if(res.length > 1) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'debug-result',
                    controller: function($uibModalInstance, data) {
                        var vm = this;

                        setTimeout(function(){
                             $('#table01').DataTable({
                                "paging":   false,
                                "ordering": true,
                                "info":     false,
                                "bLengthChange": false
                            });
                             $('#table02').DataTable({
                                // "paging":   false,
                                "pageLength": 20,
                                "ordering": true,
                                "info":     false,
                                "bLengthChange": false
                            });
                             $('#table03').DataTable({
                                "pageLength": 20,
                                "ordering": true,
                                "info":     false,
                                "bLengthChange": false
                            });
                             $('#table04').DataTable({
                                // "paging":   false,
                                "pageLength": 20,
                                "ordering": true,
                                "info":     false,
                                "bLengthChange": false,
                                "footerCallback": function ( row, data, start, end, display ) {
                                    var api = this.api(), data;
                                    // Remove the formatting to get integer data for summation
                                    var intVal = function ( i ) {
                                        return typeof i == 'string' ?
                                            i.replace(/[\$,]/g, '')*1 :
                                            typeof i == 'number' ?
                                                i : 0;
                                    };
                                    // Total on filtered value
                                    total4 = api
                                        .column( 4 , {"filter":"applied"})
                                        .data()
                                        .reduce( function (a, b) {
                                            return intVal(a) + intVal(b);
                                        }, 0 );
                                    total5 = api
                                        .column( 5 , {"filter":"applied"})
                                        .data()
                                        .reduce( function (a, b) {
                                            return intVal(a) + intVal(b);
                                        }, 0 );
                                    total6 = api
                                        .column( 6 , {"filter":"applied"})
                                        .data()
                                        .reduce( function (a, b) {
                                            return intVal(a) + intVal(b);
                                        }, 0 );

                                    // Update footer
                                    $( api.column( 4 ).footer() ).html( $filter('number')(Math.round(total4, 2)) );
                                    $( api.column( 5 ).footer() ).html( $filter('number')(Math.round(total5, 2)) );
                                    $( api.column( 6 ).footer() ).html( $filter('number')(Math.round(total6, 2)) );
                                }
                            });
                             $('#table05').DataTable({
                                // "paging":   false,
                                "pageLength": 20,
                                "ordering": true,
                                "info":     false,
                                "bLengthChange": false,
                                "footerCallback": function ( row, data, start, end, display ) {
                                    var api = this.api(), data;
                                    // Remove the formatting to get integer data for summation
                                    var intVal = function ( i ) {
                                        return typeof i == 'string' ?
                                            i.replace(/[\$,]/g, '')*1 :
                                            typeof i == 'number' ?
                                                i : 0;
                                    };
                                    // Total on filtered value
                                    total6 = api
                                        .column( 6 , {"filter":"applied"})
                                        .data()
                                        .reduce( function (a, b) {
                                            return intVal(a) + intVal(b);
                                        }, 0 );
                                    total7 = api
                                        .column( 7 , {"filter":"applied"})
                                        .data()
                                        .reduce( function (a, b) {
                                            return intVal(a) + intVal(b);
                                        }, 0 );
                                    total8 = api
                                        .column( 8 , {"filter":"applied"})
                                        .data()
                                        .reduce( function (a, b) {
                                            return intVal(a) + intVal(b);
                                        }, 0 );
                                    total9 = api
                                        .column( 9 , {"filter":"applied"})
                                        .data()
                                        .reduce( function (a, b) {
                                            return intVal(a) + intVal(b);
                                        }, 0 );
                                    total10 = api
                                        .column( 10 , {"filter":"applied"})
                                        .data()
                                        .reduce( function (a, b) {
                                            return intVal(a) + intVal(b);
                                        }, 0 );

                                    // Update footer
                                    $( api.column( 6 ).footer() ).html( $filter('number')(Math.round(total6, 2)) );
                                    $( api.column( 7 ).footer() ).html( $filter('number')(Math.round(total7, 2)) );
                                    $( api.column( 8 ).footer() ).html( $filter('number')(Math.round(total8, 2)) );
                                    $( api.column( 9 ).footer() ).html( $filter('number')(Math.round(total9, 2)) );
                                    $( api.column( 10 ).footer() ).html( $filter('number')(Math.round(total10, 2)) );
                                }
                            });
                             $('#table06').DataTable({
                                // "paging":   false,
                                "pageLength": 20,
                                "ordering": true,
                                "info":     false,
                                "bLengthChange": false
                            });
                             $('#table07').DataTable({
                                // "paging":   false,
                                "pageLength": 20,
                                "ordering": true,
                                "info":     false,
                                "bLengthChange": false,

                                "footerCallback": function ( row, data, start, end, display ) {
                                    var api = this.api(), data;
                                    // Remove the formatting to get integer data for summation
                                    var intVal = function ( i ) {
                                        return typeof i == 'string' ?
                                            i.replace(/[\$,]/g, '')*1 :
                                            typeof i == 'number' ?
                                                i : 0;
                                    };
                                    // Total on filtered value
                                    total7 = api
                                        .column( 6 , {"filter":"applied"})
                                        .data()
                                        .reduce( function (a, b) {
                                            return intVal(a) + intVal(b);
                                        }, 0 );

                                    // Update footer
                                    $( api.column( 6 ).footer() ).html( $filter('number')(Math.round(total7, 2)) );
                                }
                            });

                            $('.dataTables_filter').
                                addClass('pull-right');
                        }, 200);

                        vm.result = data;

                        vm.cancel = function() {
                            $uibModalInstance.dismiss('cancel');
                        };
                    },
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        data: function() {
                            return res
                        }
                    }
                });
            } else if(res.length == 1) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'modalAlert',
                    resolve: {
                        title: function () {
                            return $filter('trans')('SUCCESS_ALLOCATION');
                        }
                    }
                });
            }
        })
    }

    function addProjectStatus(project_id) {
        var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'project-status-edit',
            controller: function($uibModalInstance, project_id, resource) {
                var vm = this;

                var REST = resource.init('project_status');

                vm.newResult = {
                    project_id: project_id
                }

                vm.action = {
                    save: save,
                    cancel: cancel,
                };

                function save() {
                    // vm.newResult.date = cmdate.toServer(vm.newResult.date);

                    REST.create({
                        data: vm.newResult
                    }).then(function(response) {
                        $uibModalInstance.close({response: response, index: -1});
                    });
                }

                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                };
            },
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                project_id: function() {
                    return project_id
                }
            }
        });
    }

    return vm;
});
