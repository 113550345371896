angular
    .module('kv')
    .filter('prettyJson', prettyJson);

function prettyJson($filter) {
	var maxLength = 100;
    return function(text) {
    	try {
	        var obj = JSON.parse(text.replace(/\\n/g, " "));
	        // if ((obj.comments != undefined) && (obj.comments.length >= maxLength)) {
	        // 	obj.comments = obj.comments.substr(0, maxLength) + '..';
	        // }
	        return $filter('json')(obj);
	    } catch(e) {
	    	return text;
	    }
    }
};

