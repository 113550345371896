kv.controller('expenseVatTypeListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "vat_type_id",
		entity: "expense_vat_type",
		modal: {
			templateUrl: "expense_vat_type-edit",
			controller: "expenseVatTypeEditCtrl"
		}
	});
});
