kv.controller('customFieldEditCtrl', function ($uibModalInstance, data, $injector, $scope) {
	return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
		entity: "custom_field",
		primaryKey: "custom_field_id",
		newEntity:{
			entity_type: 'contract'
		},
		prepareDataToSave: function () {
			let dataToSend = angular.copy(this.data);
			return dataToSend;
		},
	});
});
// kv.controller('customFieldEditCtrl', function ($uibModalInstance, $scope, $http, $injector, resource) {
// 	let vm = new modalCtrl($uibModalInstance, $injector, 'custom_field', 'custom_field_id', $scope);
// 	vm.rest = vm.getInjection('resource').init(vm.entity);
// 	vm.validator = vm.getInjection('validator');
// 	vm.isNewRecord = true;
// 	console.log(vm);
// 	var REST = resource.init(vm.entity);
//
// 	// if(vm.bladeParams.year) {
// 	// 	$http.get(vm.bladeParams.budgetItemWithChildrenListUrl)
// 	// 		.then(function(budget_items) {
// 	// 			vm.budget_items = budget_items.data.data;
// 	// 			$scope.$broadcast('dataLoaded');
// 	// 		});
// 	// 	$http.get(vm.bladeParams.budgetItemValueShowUrl)
// 	// 		.then(function(response) {
// 	// 			vm.data = response.data;
// 	// 			$scope.$broadcast('dataHasLoaded');
// 	// 		});
// 	// } else {
// 	// 	$http.get(vm.bladeParams.budgetItemListUrl)
// 	// 		.then(function(budget_items) {
// 	// 			vm.budget_items = budget_items.data;
// 	// 			$scope.$broadcast('dataLoaded');
// 	// 		});
// 	// 	vm.data = {};
// 	// }
//
// 	// overwrite function from baseCtrl, for adding supplimentary item, target
// 	// target is used in model, for verifying where the request is done(modal or edit), to make some fields required
// 	vm.prepareDataToSave = function () {
// 		let dataToSend = angular.copy(vm.data);
// 		return dataToSend;
// 	};
//
// 	// overwrite function from baseCtrl, for redirect to list, not to view
// 	vm.saveCallback = function () {
// 		window.location = "/" + vm.entity;
// 	};
//
// 	return vm;
// });