kv.controller('courseListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'course_id';
	vm.entity = 'course';
	vm.math = window.Math;

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'course'
		},
		url: "course",
		search: {
			is_archived: 0,
		}
	});

	return vm;
});

