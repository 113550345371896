kv.controller('tenantBankAccountsViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $timeout, search) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'bank_account_id';
	vm.entity = 'bank_account';
	vm.entityType = 'bank_account';
	var fiscalEntities = [];
	var REST = resource.init(vm.entity);

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];

	/**
	 * List all fiscal entities and their bank accounts
	 */
	vm.listEntity = function() {
		REST.list().then(function (data) {
			// extract tenant fiscal entities from data and replace vm.data with the new structure fiscal entity / bank accounts
			let rawData = data.data;
			let fiscalEntitiesMap = {}; // To temporarily store fiscal entities by their ID
			let fiscalEntitiesArray = []; // To store the final list of fiscal entities

			rawData.forEach(function(bank_account) {
				let fiscalEntityId = bank_account.tenant_fiscal_entity.tenant_fiscal_entity_id;

				if (!fiscalEntitiesMap[fiscalEntityId]) {
					fiscalEntitiesMap[fiscalEntityId] = {
						fiscal_entity_id: fiscalEntityId,
						fiscal_entity_name: bank_account.tenant_fiscal_entity.tenant_fiscal_entity_name,
						bank_accounts: []
					};
					fiscalEntitiesArray.push(fiscalEntitiesMap[fiscalEntityId]);
				}

				fiscalEntitiesMap[fiscalEntityId].bank_accounts.push(bank_account);
			});

			vm.data = fiscalEntitiesArray;
		});
	};

	/**
	 * Add or edit bank account
	 * @param entity
	 */
	vm.addEditBankAccount = function (entity) {
		let isNewRecord = !entity;
		vm.openModal({
			templateUrl: "bank_account-edit",
			controller: 'tenantBankAccountEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					if (!entity) {
						entity = {
							bank_account_id: page_entity_id
						};
					}
					return entity;
				},
				saveCallback: function () {
					return function(response){
						vm.listEntity();
						response.modal.close();
					}
				},
			}
		});
	};

	/**
	 * delete bank account
	 *
	 * @param bankAccount
	 */
	vm.deleteBankAccount = function (bankAccount) {
		vm.confirm('Confirm', $filter('trans')('LANG.ARE_YOU_SURE_DELETE')).then(response => {
			if (response === 'cancel') return;

			let rest = this.getInjection('resource').init("bank_account");
			let ctrl = this;
			rest.delete({
				params: {
					id: bankAccount.bank_account_id
				},
				success: function() {
					// refresh list
					vm.listEntity();
				},
				error: function(response) {
					if (response.status === 500) {
						console.error('Internal Server Error:', response);
						vm.alert('Server error occurred while deleting bank account. Please contact support.');
					} else {
						console.error('Error:', response);
						vm.alert('Error deleting bank account. Please try again.');
					}
				}
			});
		});
	};

	// Initialize the view
	vm.listEntity();

	return vm;
});

