kv.controller('contactListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'contact_id';
	vm.entity = 'contact';

	vm.count = {
		total: 0,
		showing: 0
	};
	// options for showOnly filters
	vm.showOnly = [
		{
			name: $filter('trans')('ONLY_MY_CONTACTS'),
			id: "my_contacts"
		},
		{
			name: $filter('trans')('CURRENT_MONTH_ANIVERSARY'),
			id: "this_month_aniversary"
		}
	];

	vm.getInfinitScroll({
		params: {
			action: 'contact'
		},
		url: "contact",
	});

	vm.addToMyContacts = function addToMyContacts($index) {
		REST.create({
			url: vm.entity + "/" + vm.result[$index].contact_id + "/addToMyContacts",
		}).then(function (data) {
			vm.result[$index].isContactCurrentUser = true
			toastr.success($filter('trans')('CONTACT_ADDED_TO_USER_CONTACTS'), 'Succes!');
		});
	};

	vm.addToGroup = function addToGroup($index) {
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'group-list',
			controller: function ($uibModalInstance, data, resource, $filter) {
				var vm = this;

				var REST = resource.init("group");
				REST.getArray({
					"url": "filter/group/all_group_without_this_contact?contact_id=" + data.contact_id
				}).then(function (res) {
					vm.result = res;
				})

				vm.action = {
					save: save,
					add: add,
					cancel: cancel,
				};

				function save(response) {
					$uibModalInstance.close({response: response, index: -1});
				}

				function add($index) {
					REST.create({
						url: 'group/' + vm.result[$index].id + "/people_group",
						data: [data.contact_id]
					}).then(function (res) {
						vm.result.splice($index, 1);
					});
				}

				function cancel() {
					$uibModalInstance.dismiss('cancel');
				}
			},
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				data: function () {
					return {
						contact_id: vm.result[$index].contact_id,
					};
				}
			}
		});

		modalInstance.result.then(function (data) {
			if (data.index != null) {
				vm.result[data.index] = data.response;
			} else {
				vm.result.push(data.response);
			}
		});
	};

	return vm;
});
