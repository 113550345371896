kv.controller('projectViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $timeout) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'project_id';
	vm.entity = 'project';
	vm.entityType = 'project';
	vm.entityId = vm.bladeParams.projectId;
	vm.project = JSON.parse(vm.bladeParams.entity);
	vm.validator = vm.getInjection('validator');
	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	vm.thecurdate = moment().format('YYYY-MM-DD');
	let copyData = {};
	let copyDataCommunicationsLog = {};
	let REST = resource.init('project');
	$scope.CurrentDate = new Date();

	vm.commentsConfig = {
		entity_type: vm.entity
	};

	vm.getInfinitScroll({
		params: {
			action: 'project'
		},
		url: "project/list?parent_id=" + page_entity_id + "&project_type=reference&orderBy=project_name&direction=asc",
	});

	$(document).ready(function () {
		$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
			$(e.target.hash).find('.chart').each(function () {
				if ($(this).highcharts()) {
					$(this).highcharts().reflow();
				}
			});
		});
	});

	/**
	 * filter task list by selected status
	 * @param  {[type]} status [description]
	 * @return {[type]}        [description]
	 */
	vm.filterList = function (status) {
		if (status) {
			vm.projectTaskList = [];
			angular.forEach(copyData, function (v, i) {
				if (v.done == false && status == 'ongoing') {
					vm.projectTaskList = vm.projectTaskList.concat(v);
				}
				if (status == 'realized' && v.done == true) {
					vm.projectTaskList = vm.projectTaskList.concat(v);
				}
				if (status == 'all') {
					vm.projectTaskList = copyData;

				}
			})
		}
	}

	vm.getBillingRule = function getBillingRule(project_id, parent_id) {
		KApp.block('#billing_rule_portlet');
		var REST = resource.init('billing_rule/list');
		REST.getArray({
			params: {
				project_id: project_id
			}
		}).then(function (data) {
			vm.billing_rule_loaded = true;
			KApp.unblock('#billing_rule_portlet');
			if (!parent_id) {
				vm.billing_rule = data;
			} else {
				angular.forEach(vm.references, function (v, i) {
					if (v.project_id == project_id) {
						vm.references[i].project_billing_rules = data;
					}
				});
			}
		});

		REST.getArray({
			url: "filter/sys_billing_rule_type/all_sys_billing_rule_type"
		}).then(function (data) {
			vm.sys_billing_rule_type = data;
		});
	};

	vm.getReferences = function getReferences() {
		$scope.isLoading = true;
		var REST = resource.init('project/list');
		REST.get({
			params: {
				parent_id: page_entity_id,
				project_type: "reference",
				orderBy: "project_name",
				direction: "asc"
			}
		}).then(function (data) {
			vm.referencesListCount = null;
			vm.references = data.data;
			angular.forEach(vm.references, function () {
				vm.referencesListCount++;
			});
			vm.project.references_count = vm.referencesListCount;
		});
	};


	vm.changeMasterBillingRuleMethod = function changeMasterBillingRuleMethod($type) {
		REST.update({
			url: 'project/' + page_entity_id,
			data: {
				master_billing_rule_type: $type,
				force_update_status: true
			},
			params: {
				force_update_entity: true
			}
		}).then(function (response) {
			window.location = page_entity_id;
		});
	};

	vm.changeStatusOnProject = function (project_status_id) {

		var REST = resource.init("project");
		REST.update({
			id: page_entity_id,
			data: {
				is_billable: project_status_id,
			},
			params: {
				force_update_entity: true
			}
		}).then(function (data) {
			window.location = "/project/" + data.data.project_id;
		});
	};

	vm.getCommodityActivities = function getCommodityActivities() {
		var REST = resource.init('commodity_activity/list');
		REST.get({
			params: {
				project_id: page_entity_id
			}
		}).then(function (data) {
			vm.commodity_activities = data.data;
			angular.forEach(vm.commodity_activities, function (v, i) {
				if (parseInt(v.is_editable) == 0) {
					vm.commodity_activities[i].is_editable = false;
				} else {
					vm.commodity_activities[i].is_editable = true;
				}
			});
		});
	};

	vm.getProjectExpensesType = function getProjectExpensesType() {
		var REST = resource.init('project_expense_type/list');
		REST.get({
			params: {
				project_id: page_entity_id
			}
		}).then(function (data) {
			vm.project_expense_type = data.data;
			// angular.forEach(vm.project_expense_type, function (v, i) {
			// 	if (parseInt(v.is_editable) == 0) {
			// 		vm.project_expense_type[i].is_editable = false;
			// 	} else {
			// 		vm.project_expense_type[i].is_editable = true;
			// 	}
			// });
		});
	};

	vm.deleteCommodityActivity = function deleteCommodityActivity($index) {
		vm.openModal({
			component: 'modalConfirm',
			resolve: {
				content: () => {
					return $filter("trans")("ARE_YOU_SURE_DELETE");
				},
				title: () => {
					return $filter("trans")("DELETE");
				}
			}
		}).then(function () {
			var REST = resource.init("commodity_activity");

			REST.delete({
				params: {
					id: vm.commodity_activities[$index].commodity_activity_id
				}
			}).then(function () {
				vm.commodity_activities.splice($index, 1);
				vm.getCommodityActivities();
			});
		});
	};

	vm.addEditCommodityActivity = function addEditCommodityActivity(entity, index) {
		let isNewRecord = !entity;
		vm.openModal({
			templateUrl: "commodity_activity-edit",
			controller: 'commodityActivityEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				resolve: function () {
					return {
						data: angular.copy(entity),
					};
				},
				params: function () {
					if (!entity) {
						entity = {
							project_id: page_entity_id
						};
					}
					return {
						entity: entity,
						index: index
					};
				},
				saveCallback: function () {
				},
			}
		}).then(response => {
			if (!response) return;
			vm.getCommodityActivities();
		});
	};

	vm.addEditProjectExpenseType = function addEditProjectExpenseType(entity, index) {
		let isNewRecord = !entity;
		vm.openModal({
			templateUrl: "project_expense_type-edit",
			controller: 'projectExpenseTypeEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				resolve: function () {
					return {
						data: angular.copy(entity),
					};
				},
				params: function () {
					if (!entity) {
						entity = {
							project_id: page_entity_id
						};
					}
					return {
						entity: entity,
						index: index
					};
				},
				saveCallback: function () {
				},
			}
		}).then(response => {
			if (!response) return;
			vm.getProjectExpensesType();
		});
	};

	vm.deleteProjectExpenseType = function deleteProjectExpenseType($index) {
		vm.openModal({
			component: 'modalConfirm',
			resolve: {
				content: () => {
					return $filter("trans")("ARE_YOU_SURE_DELETE");
				},
				title: () => {
					return $filter("trans")("DELETE");
				}
			}
		}).then(function () {
			var REST = resource.init("project_expense_type");

			REST.delete({
				params: {
					id: vm.project_expense_type[$index].project_expense_type_id
				}
			}).then(function () {
				vm.project_expense_type.splice($index, 1);
				vm.getProjectExpensesType();
			});
		});
	};

	vm.getProjectSourceData = function(){
		KApp.block('#project_source');
		vm.dataHasLoaded = false;
		vm.rest = resource.init('project');

		vm.rest.getArray({
			url: 'project/' + page_entity_id,
			params: {
				action: 'projectSource'
			}
		}).then(function (data) {
			vm.project_source = data[0];
			vm.dataHasLoaded = true;
			KApp.unblock('#project_source');
		});
	};

	vm.editSource = function editSource() {
		vm.openModal({
			templateUrl: "project_source-edit",
			controller: 'projectSourceEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					return {
						data: angular.copy(vm.project_source),
						project_id: page_entity_id
					};
				},
				saveCallback: function () {
				},
			}
		}).then(response => {
			if (!response) return;
			vm.getProjectSourceData();
		});
	};

	vm.listProjectStatus = function listProjectStatus($projectId) {
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'project-status-list',
			controller: function ($uibModalInstance, data, resource) {
				var vm = this;

				vm.newResult = {};
				var REST = resource.init('project_status');

				vm.getListProjectStatus = function () {
					REST.get({
						url: 'project_status/list',
						params: {
							project_id: $projectId
						}
					}).then(function (response) {
						vm.project_status_list = response.data;
					});
				}

				vm.getListProjectStatus();

				vm.cancel = function cancel() {
					$uibModalInstance.dismiss('cancel');
				};

				vm.addProjectStatus = function ($index, $projectStatusId) {
					var modalInstance = $uibModal.open({
						animation: true,
						ariaLabelledBy: 'modal-title',
						ariaDescribedBy: 'modal-body',
						templateUrl: 'project-status-edit',
						controller: function ($uibModalInstance, data, resource) {
							var vm = this;
							vm.newResult = {};
							var REST = resource.init('project_status');

							if ($projectStatusId) {
								vm.newResult.date = data.project_status[$index].date;
								REST.get({
									url: 'project_status/' + $projectStatusId
								}).then(function (response) {
									vm.newResult.percent_completed = response.percent_completed;
								});
							}


							vm.save = function save() {
								vm.newResult.project_id = $projectId;

								if ($projectStatusId) {
									REST.update({
										url: 'project_status/' + $projectStatusId,
										params: vm.newResult,
									}).then(function (response) {
										$uibModalInstance.close({response: response, index: -1});
									});
								} else {
									REST.create({
										data: vm.newResult
									}).then(function (response) {
										$uibModalInstance.close({response: response, index: -1});
									});
								}
							}

							vm.cancel = function cancel() {
								$uibModalInstance.dismiss('cancel');
							};
						},
						controllerAs: 'vm',
						size: 'md',
						resolve: {
							data: function () {
								return {
									index: $index,
									data: vm.newResult,
									project_status: vm.project_status_list
								};
							}
						}
					});

					modalInstance.result.then(function (data) {
						vm.getListProjectStatus();
					});
				}

				vm.deleteProjectStatus = function deleteProjectStatus($projectStatusId) {
					var REST = resource.init('project_status');

					REST.delete({
						params: {
							id: $projectStatusId
						}
					}).then(function () {
						vm.getListProjectStatus();
					});

				}
			},
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						data: vm.newResult,
						project_status: vm.project_status
					};
				}
			}
		});
	};
	vm.addEditBillingRuleMenuToggle = function (event) {
		angular.element('#add-billing-rule-menu').toggle();
	};
	vm.getReference = function getReference(reference, parentIndex) {
		var REST = resource.init('project');
		REST.get({
			url: "/project/" + reference.project_id,
		}).then(function (data) {
			vm.references[parentIndex] = data;
		});
	};

	vm.addReference = function ($index, entity, event) {
		if (event) event.stopPropagation();
		vm.openModal({
			templateUrl: "project-reference-edit",
			controller: 'projectReferenceEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			backdrop: 'static',
			keyboard: false,
			resolve: {
				params: function () {
					return {
						index: $index,
						entity: entity
					};
				},
				bladeParams: function () {
					return vm.bladeParams;
				}
			}
		}).then(response => {
			if (response.index == -1) {
				vm.infinitScroll.items.unshift(response.entity);
			}
			else {
				response.entity.responsible_id = parseInt(response.entity.responsible_id);
				response.entity.responsible.user_id = parseInt(response.entity.responsible.user_id);
				//angular.extend(entity, response.entity);
			    angular.extend(vm.infinitScroll.items[response.index], response.entity);
			}
			 vm.project.references_count = vm.infinitScroll.items.length;
		});
	};

	/**
	 * add task
	 * @param {[type]} ev     [description]
	 * @param {[type]} $index [description]
	 */

	vm.addEditTask = function (entity) {
		vm.openModal({
			templateUrl: "task-edit",
			size: 'lg',
			controller: "taskEditCtrl",
			controllerAs: "vm",
			resolve: {
				params: function () {
					return {
						entity: entity,
						task_id: entity ? entity.task_id : null,
						project: {
							project_id: vm.project.project_id,
							project_name: vm.project.project_name,
							customer_id: vm.project.customer.customer_id,
							customer_name: vm.project.customer.customer_name
						},
						customer: {
							customer_id: vm.project.customer.customer_id,
							customer_name: vm.project.customer.customer_name
						}
					}
				}
			}
		}).then(response => {
			if (!response) return;
			vm.getTaskList();
		});
	};

	vm.deleteTask = function deleteEntity($index) {
		this.openModal({
			animation: true,
			component: 'modalConfirm',
			resolve: {
				title: function () {
					return 'DELETE';
				},
				content: function () {
					return $filter('trans')('LANG.ARE_YOU_SURE_DELETE');
				}
			}
		}).then(() => {
			let rest = this.getInjection('resource').init("task");
			let ctrl = this;
			rest.delete({
				params: {
					id: vm.projectTaskList[$index].task_id
				},
				success: function () {
					vm.projectTaskList.splice($index, 1);
					vm.getTaskList();
				}
			});
		});
	};

	vm.addEditSubmission = function (entity) {
		vm.openModal({
			templateUrl: "submission-project-edit",
			size: 'lg',
			controller: "submissionProjectEditCtrl",
			controllerAs: "vm",
			resolve: {
				params: function () {
					return {
						created_from_project: true,
						entity: entity,
						submission_id: entity ? entity.submission.submission_id : null,
						submission_project_id: entity ? entity.submission_project_id : null,
						project: {
							project_id: vm.project.project_id,
							project_name: vm.project.project_name,
							customer_id: vm.project.customer.customer_id,
							customer_name: vm.project.customer.customer_name,
							customer_fiscal_entity_id: vm.project.customer_fiscal_entity.customer_fiscal_entity_id,
							customer_fiscal_entity_name: vm.project.customer_fiscal_entity.name
						}
					}
				}
			}
		}).then(response => {
			if (!response) return;
			vm.getSubmissionList();
		});
	};

	vm.deleteSubmission = function deleteEntity($index) {
		this.openModal({
			animation: true,
			component: 'modalConfirm',
			resolve: {
				title: function () {
					return 'DELETE';
				},
				content: function () {
					return $filter('trans')('LANG.ARE_YOU_SURE_DELETE');
				}
			}
		}).then(() => {
			let rest = this.getInjection('resource').init("submission_project");
			let ctrl = this;
			rest.delete({
				params: {
					id: vm.projectSubmissionList[$index].submission_project_id
				},
				success: function () {
					vm.projectSubmissionList.splice($index, 1);
					vm.getSubmissionList();
				}
			});
		});
	};


	/**
	 * enable / disable Reference
	 * @param  {[type]} project_id [description]
	 * @param  {[type]} index      [description]
	 * @param  {[type]} event      [description]
	 * @return {[type]}            [description]
	 */
	
	vm.changeProjectStatus = function () {
		let postData = {};
		// postData.enabled = vm.project.enabled ? 0 : 1;
		KApp.block('.changeProjectStatus');

		let rest = vm.getInjection('resource').init('project');
		rest.update({
			url: 'project/' + page_entity_id,
			params: {
				action: (vm.project.enabled ? 'disableProject' : 'enableProject')
			}
		})
		.then((response) => {
			toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
			if (!response.status) vm.alert('ALERT', vm.trans('Error changing status'));
			window.location.href = "/project/" + page_entity_id;
			KApp.unblock('.changeProjectStatus');
		})
		.catch(err => {
			KApp.unblock('.changeProjectStatus');
		  	console.log('Error changing project status');
		  	console.log(err); // bum related error
		});
	};

	vm.toggleReferenceStatus = function toggleReferenceStatus(reference, index, event) {
		event.stopPropagation();
		let toggleAction = reference.enabled ? 'disableReference' : 'enableReference';
		let confirmationMessage = reference.enabled 
					? 'Reference was closed' 
					: 'Reference was opened';
		
		let rest = vm.getInjection('resource').init('project');
		rest.update({
			url: 'project/' + reference.project_id,
			params: {
				action: toggleAction
			}
		})
		.then((response) => {
			reference.enabled = response.data.enabled;
			toastr.success(confirmationMessage);
			if (!response.status) vm.alert('ALERT', vm.trans('Error changing status'));
		})
		.catch(err => {
		  	console.log('Error changing project status');
		  	console.log(err); // bum related error
		});
	};

	/**
	 * delete reference
	 * @param  {[type]} project_id [description]
	 * @param  {[type]} index      [description]
	 * @param  {[type]} event      [description]
	 * @return {[type]}            [description]
	 */
	vm.deleteReference = function deleteReference(project_id, index, event) {
		event.stopPropagation();
		vm.confirm("DELETE", vm.trans('ARE_YOU_SURE_DELETE')).then(response => {
			if (response !== 'ok') return;
			vm.entity = 'project';
			var REST = resource.init(vm.entity);
			REST.delete({
				params: {
					id: project_id
				}
			}).then(function (response) {
				if(response.error && response.error.error_code){
					toastr.error((response.error.message + ". </br> " + response.error.details));
				} else {
					vm.infinitScroll.items.splice(index, 1);
				}
				vm.project.references_count = vm.infinitScroll.items.length;
			});
		});
	};

	vm.openBillingRulesPopup = function (event, parentIndex, reference) {
		if (event) event.stopPropagation();
		vm.openModal({
			templateUrl: "project-view-billing_rule-list",
			controller: function ($uibModalInstance) {
				this.cancel = () => $uibModalInstance.dismiss('cancel');
				this.close = (rule_type) => {
					$uibModalInstance.close(rule_type);
				};
				if(reference != null){
					this.isReference = reference.parent_id != null ? 1 : 0;
				}
			},
			controllerAs: 'vm',
			size: 'lg',
			resolve: {},
		}).then(response => {
			vm.addEditBillingRule(response, 'add', null, parentIndex);
		});
	};

	// metoda apelata din projectDetails (fara parentIndex) si din ProjectReferences/ reference-view (cu parentIndex)
	vm.addEditBillingRule = function addEditBillingRule(rule_type, type, billing_rule, parentIndex) {
		let data = angular.copy(billing_rule || {});
		let reference = parentIndex !== undefined ? vm.infinitScroll.items[parentIndex] : null;
		data.project_id = reference ? reference.project_id : vm.project.project_id;
		data.parent_id = parentIndex ? parentIndex : null;
		data.rule_type=rule_type;
		if (!data.date) {
			var today = new Date();
			var dd = today.getDate();
			var mm = today.getMonth() + 1;
			var yyyy = today.getFullYear();

			if (dd < 10) {
				dd = '0' + dd;
			}

			if (mm < 10) {
				mm = '0' + mm;
			}

			data.date = yyyy + '-' + mm + '-' + dd;
		}

		let haveHourlyRule = false;
		if (haveHourlyRule) return vm.alert('ALERT', vm.trans(!reference ? "PROJECT_ALREADY_HAVE_HOURLY_RULE" : "REFERENCE_ALREADY_HAVE_HOURLY_RULE"));
		vm.openModal({
			templateUrl: rule_type.toLowerCase(),
			controller: 'ProjectAddEditBillingRuleCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: function () {
					return {
						data: data,
						entity: reference ? reference : vm.project,
						type: type,
						project_currency: vm.project.currency
					};
				}
			}
		}).then(response => {
			if (type == 'add') {
				if (reference !== null) {
					reference.project_billing_rules.unshift(response.entity);
				} else {
					vm.billing_rule.unshift(response.entity);
				}
			} else angular.extend(billing_rule, response.entity);
		});
	};

	vm.deleteBillingRule = function (billing_rule) {
		// if not reference - delete billing rule from Project Detail tab
		if(vm.billing_rule.invoice_id !== null) {
			vm.confirm("DELETE", vm.trans("ARE_YOU_SURE_DELETE")).then(response => {
				if (response !== 'ok') return;
				let REST = resource.init("billing_rule");
				REST.delete({
					params: {
						id: billing_rule.billing_rule_id
					}
				}).then(function () {
					let index = vm.billing_rule.indexOf(billing_rule);
					vm.billing_rule.splice(index, 1);
				});
			});
		}
	};

	vm.deleteProjectBillingRule = function (project, billing_rule) {
		// if not reference - delete billing rule from Project Detail tab
		vm.confirm("DELETE", vm.trans("ARE_YOU_SURE_DELETE")).then(response => {
			if (response !== 'ok') return;
			let REST = resource.init("billing_rule");
			REST.delete({
				params: {
					id: billing_rule.billing_rule_id
				}
			}).then(function () {
				let project_index = vm.infinitScroll.items.indexOf(project);
				let billing_rule_index = vm.infinitScroll.items[project_index].project_billing_rules.indexOf(billing_rule);
				vm.infinitScroll.items[project_index].project_billing_rules.splice(billing_rule_index, 1);
			});
		});
	};

	/**
	 * show or hide date depending on rule type
	 * @param rule_type
	 * @param date
	 */
	vm.showDateDetails = function (rule_type, date) {
		switch (rule_type) {
			case "FEE_ADVANCE":
			case "FLAT_FEE":
			case "SUCCESS_FEE":
				return date;
			default:
				return '';
		}
	}

	/**
	 * format rule value based on type
	 * @param rule_type
	 * @param currency
	 * @returns {string|string|*}
	 */
	vm.valueFormatByType = function (rule_type, currency) {
		switch (rule_type) {
			case "FEE_ADVANCE":
			case "FLAT_FEE":
			case "SUCCESS_FEE":
			case "CAP":
			case "HEARING":
			case "ESTIMATE":
				return (currency ? currency : '-');
			case "DISCOUNT":
			case "FEE_DISCOUNT":
			case "MARKUP":
				return " %";
			case "SUBSCRIPTION":
				return (currency ? currency + "/" + vm.trans("MONTH").toLowerCase() : '-');
			case "HOURLY_BLENDED":
				return (currency ? currency + "/h" : '-');

			case "PRACTICE_ALLOC":
			case "USER_ALLOC":
			case "PERCENT_ALLOC":
			case "HOURLY_SENIORITY":
			case "HOURLY_USER":
			case "HOURLY_ACTIVITY":
			case "HOURLY_THRESHOLD":

				break;
		}
	}

	vm.buildList = function (data) {
		vm.projectTaskList = data;
		copyData = data;
		if (data) {
			$scope.count = {
				ongoing: 0,
				realized: 0
			};

			angular.forEach(vm.projectTaskList, function (v, i) {
				if (v.done !== true) {
					$scope.count.ongoing = parseInt($scope.count.ongoing) + 1;
				} else {
					$scope.count.realized = parseInt($scope.count.realized) + 1;
				}
			});
		}
	};

	vm.getTaskList = function () {
		if(page_entity_id) {
			let taskListRest = resource.init('project/' + page_entity_id);
			taskListRest.getArray({
				params: {
					action: 'taskList'
				}
			}).then(function (response) {
				vm.projectTaskList = response;
				vm.projectTaskListCount = null;
				$.each(response, function (index, value) {
					if (value.done !== true) {
						vm.projectTaskListCount++;
					}
					vm.projectTaskList[index].responsible_count = value.users.length;
				});
				vm.project.tasks_count = vm.projectTaskListCount;
				vm.buildList(vm.projectTaskList);
			});
		}
	};

	vm.getSubmissionList = function () {
		REST.getArray({
			url: 'project/' + page_entity_id,
			params: {
				action: 'submissionList'
			}
		}).then(function (response) {
			vm.projectSubmissionList = response;
			vm.projectSubmissionCount = null;
			$.each(response, function (index, value) {
				vm.projectSubmissionCount++;
			});
			vm.project.submissions_count = vm.projectSubmissionCount;
		});
	};

	/**
	 * change task status
	 * @param  {[type]} $activity_id [description]
	 * @return {[type]}              [description]
	 */
	vm.changeStatus = function (entity) {
		var REST_task = resource.init("task");
		entity.done = !entity.done;
		REST_task.update({
			id: entity.task_id,
			params: {
				action: (entity.done ? 'markCompleted' : 'markToDo')
			}
		}).then(function () {
			vm.getTaskList();
		});
	}

	/**
	 * change status on project
	 * @param  {[type]} postData [description]
	 * @return {[type]}          [description]
	 */
	vm.changeProjectStatus = function () {
		let postData = {};
		// postData.enabled = vm.project.enabled ? 0 : 1;
		KApp.block('.changeProjectStatus');

		let rest = vm.getInjection('resource').init('project');
		rest.update({
			url: 'project/' + page_entity_id,
			params: {
				action: (vm.project.enabled ? 'disableProject' : 'enableProject')
			}
		})
		.then((response) => {
			toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
			if (!response.status) vm.alert('ALERT', vm.trans('Error changing status'));
			window.location.href = "/project/" + page_entity_id;
			KApp.unblock('.changeProjectStatus');
		})
		.catch(err => {
			KApp.unblock('.changeProjectStatus');
		  	console.log('Error changing project status');
		  	console.log(err); // bum related error
		});
	};

	/**
	 * change status on project
	 * @param  {[type]} postData [description]
	 * @return {[type]}          [description]
	 */
	// vm.changeStatusForProject = function(postData) {
	// 	let rest = vm.getInjection('resource').init('project');
	// 		rest.update({
	// 			url: 'project/' + page_entity_id,
	// 			// data: postData,
	// 			params: {
	// 				action: (postData.enabled ? 'enableProject': 'disableProject')
	// 			}
	// 		}).then((response) => {
	// 			toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
	// 			if (!response.status) vm.alert('ALERT', vm.trans('LANG.ACTIVE_STATUS_NOT_CHANGE'));
	// 			window.location.href = "/project/" + page_entity_id;
	// 			KApp.unblock('.changeProjectStatus');

	// 		});
	// };

	vm.checkProjectForDisable = function() {
		let rest = vm.getInjection('resource').init('project');
		if(vm.project.enabled){
			rest.get({
				url: 'project/' + vm.entityId,
				params: {
					check_for_disable: true
				}
			}).then((response) => {
				if (response.uninvoiced_value.total_value != 0){
					vm.confirm(vm.trans('LANG.DISABLE_PROJECT_TITLE') + ' ' + response.uninvoiced_value.total_value + ' ' + response.uninvoiced_value.invoice_currency, vm.trans("LANG.DISABLE_PROJECT_MESSAGE")).then(response => {
						if (response !== "ok") return;
						vm.changeProjectStatus();
					});
				}else{
					vm.changeProjectStatus();
				}
			});
		}else{
			vm.changeProjectStatus();
		}

	};

	/**
	 * rest call to get updated comm log list
	 * @return {[type]} [description]
	 */
	vm.getCommunicationsLogList = function () {
		REST.getArray({
			url: 'project/' + page_entity_id,
			params: {
				action: 'communicationsLogList'
			}
		}).then(function (response) {
			vm.communicationsLog = response;
			vm.communicationsLogListCount = null;
			$.each(response, function (index, value) {
				vm.communicationsLogListCount++;
			});
			vm.project.communications_log_count = vm.communicationsLogListCount;
			vm.buildCommuncationsLogList(vm.communicationsLog);
		});
	}

	/**
	 * filter communication log list by type
	 * @param  {[type]} type [description]
	 * @return {[type]}        [description]
	 */
	vm.filtersCommunicationsLog = function (type) {
		if (type) {
			vm.communicationsLog = [];
			angular.forEach(copyDataCommunicationsLog, function (v, i) {
				if (type == 'email' && v.type == type) {
					vm.communicationsLog = vm.communicationsLog.concat(v);
				}
				if (type == 'phone' && v.type == type) {
					vm.communicationsLog = vm.communicationsLog.concat(v);
				}
				if (type == 'all') {
					vm.communicationsLog = copyDataCommunicationsLog;
				}
			})
		}
	}

	vm.buildCommuncationsLogList = function (data) {
		vm.communicationsLog = data;
		copyDataCommunicationsLog = data;
		if (data) {
			$scope.communicationsLogCount = {
				email: 0,
				phone: 0,
			};

			angular.forEach(vm.communicationsLog, function (v, i) {
				if (v.type == 'email') {
					$scope.communicationsLogCount.email = parseInt($scope.communicationsLogCount.email) + 1;
				} else if (v.type == 'phone') {
					$scope.communicationsLogCount.phone = parseInt($scope.communicationsLogCount.phone) + 1;
				}
			})
		}
	}

	/**
	 * edit communications log
	 * @param {[type]} ev     [description]
	 * @param {[type]} $index [description]
	 */
	vm.communicationsLogAddEdit = function ($index, $type) {
		vm.openModal({
			templateUrl: 'communications-log-edit',
			controller: 'communicationsLogEditCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: function () {
					return {
						index: $index,
						type: $type,
						project_id: page_entity_id
					};
				},
				saveCallback: function () {
					return function (response) {

						vm.getCommunicationsLogList();
						response.modal.close();
					}
				},
			}
		});
	}

	vm.deleteCommunicationsLog = function ($index) {
		var modalInstance = $uibModal.open({
			component: 'modalConfirm',
			resolve: {
				content: () => {
					return $filter("trans")("ARE_YOU_SURE_DELETE");
				},
				title: () => {
					return $filter("trans")("DELETE");
				}
			}
		});

		modalInstance.result.then(function () {
			var REST = resource.init("communications_log");
			REST.delete({
				params: {
					id: $index
				}
			}).then(function () {
				vm.getCommunicationsLogList();
			});
		});
	}

	vm.getKpiForDashboard = function (filterInterval = null) {
		var REST = resource.init('project');
		KApp.block('#project_budget');
		REST.get({
			url: 'project/' + vm.entityId,
			params: {
				action: 'kpi',
				interval: (filterInterval ? filterInterval : 'this_year')
			}
		}).then(function (response) {
			KApp.unblock('#loader_dashboard');
			KApp.unblock('#project_budget');
			vm.projectKPI = response;
			vm.chart1Error = response.userChartData.length <= 1;
			vm.chart2Error = response.practiceChartData.length <= 1;
			vm.chart3Error = response.seniorityChartData.length <= 1;
			vm.date_from = response.date_from;
			vm.date_to = response.date_to;

			$timeout(function () {
				renderCharts(vm.projectKPI);
			}).catch(response => {
				console.error(response);
			});
		});
	}

	vm.changeInterval = function (interval) {
		vm.filter_project_data = interval;
		KApp.block('#loader_dashboard');
		vm.getKpiForDashboard(interval);
	};

	vm.allocationDebug = function ($id) {
		let modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'allocation-debug',
			controller: function ($uibModalInstance, data, resource) {
				var vm = this;
				vm.newResult = {};
				vm.newResult.calculation_date = "2018-12-31";

				vm.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				};

				vm.generate = function () {
					$simulateAllocationUrl = "/project/allocate-current-project"
						+ "?project_id=" + $id
						+ "&reference_id=" + (vm.newResult.reference_id ? vm.newResult.reference_id : '')
						+ "&invoice_id=" + (vm.newResult.invoice_id ? vm.newResult.invoice_id : '')
						+ "&calculation_date=" + (vm.newResult.calculation_date ? vm.newResult.calculation_date : '')
						+ "&simulate=1";

					window.open($simulateAllocationUrl, '_blank');
					return true;
				};
			},
			controllerAs: 'vm',
			size: 'sm',
			resolve: {
				data: function () {
				}
			}
		});
	};

	vm.allocateCurrentProject = function (project_id) {
		let REST = resource.init('project/allocate-current-project');
		REST.get({
			project_id: project_id
		}).then(function (response) {
			toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
		});

	};

	/***************************************************************
	 * build charts for user, practice and seniority
	 *
	 */

	vm.chartUser_is_rendered = false;

	function chartUser(chart1Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartUser_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart1Data

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart1').highcharts(config);
		} else {
			$('#chart1').highcharts(config);
			$('#chart1').highcharts().reflow();
		}
	};

	vm.chartPractice_is_rendered = false;

	function chartPractice(chart2Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartPractice_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart2Data

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart2').highcharts(config);
		} else {
			$('#chart2').highcharts(config);
			$('#chart2').highcharts().reflow();
		}
	};

	vm.chartSeniority_is_rendered = false;

	function chartSeniority(chart3Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartSeniority_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart3Data

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart3').highcharts(config);
		} else {
			$('#chart3').highcharts(config);
			$('#chart3').highcharts().reflow();
		}
	};

	function renderCharts(chartsData) {

		if (chartsData.userChartData.length > 1) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartUser_is_rendered) {
				chartUser(chartsData.userChartData, true);
			} else {
				chartUser(chartsData.userChartData, false);
				$('#chart1').show();
			}
		}
		if (chartsData.practiceChartData.length > 1) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartPractice_is_rendered) {
				chartPractice(chartsData.practiceChartData, true);
			} else {
				chartPractice(chartsData.practiceChartData, false);
				$('#chart2').show();
			}
		}
		if (chartsData.seniorityChartData.length > 1) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartSeniority_is_rendered) {
				chartSeniority(chartsData.seniorityChartData, true);
			} else {
				chartSeniority(chartsData.seniorityChartData, false);
				$('#chart3').show();
			}
		}
	}
	vm.updateCollectionContacts = function () {
		vm.confirm(vm.trans('LANG.WARNING') + ' ' + vm.trans('LANG.UPDATE_COLLECTION_CONTACTS'), vm.trans('LANG.UPDATE_COLLECTION_CONTACTS_PROJECT')).then(response => {
			if (response !== "ok") return;
			KApp.block('.modal-content');
			let rest = vm.getInjection('resource').init('project');
			rest.update({
				url: 'project/' + page_entity_id,
				params: {
					action: 'updateBillingContactOnCollection'
				}
			}).then(function (data) {
				KApp.unblock('.modal-content');
				if(data.error && data.error.error_code === 1){
					toastr.error(data.error.message);
				}else{
					toastr.success(data.message);
				}
			});
		});
	}

	vm.addEditEfacturaFields = function (project_id, type){
		vm.openModal({
			templateUrl: "efactura-fields-configuration",
			controller: 'efacturaFieldsConfigurationCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: {
					entity_id: project_id,
					type: type
				}
			}
		}).then(response => {
			// window.location = "/project/" + project_id;
		});
	};

	if(vm.project.project_ext.use_billing_config_from_customer_fiscal_entity === true && vm.project.customer_fiscal_entity.customer_fiscal_entity_ext.invoice_currency !== vm.project.project_ext.tenant_bank_account.currency)
	{
	  if (!vm.custom_errors) vm.custom_errors = [];
	  vm.custom_errors.push(vm.trans('LANG.BANK_ACCOUNT_CURRENCY_NOT_CORRESPOND_WITH_INVOICE_CURRENCY'));
	  vm.has_error = true;
	}


	if(vm.project.project_ext.use_billing_config_from_customer_fiscal_entity === 0
	  && vm.project.project_ext.force_invoice_currency && vm.project.project_ext.force_invoice_currency !== vm.project.project_ext.tenant_bank_account.currency){
	  if (!vm.custom_errors) vm.custom_errors = [];
	  vm.custom_errors.push(vm.trans('LANG.BANK_ACCOUNT_CURRENCY_NOT_CORRESPOND_WITH_INVOICE_CURRENCY'));
	  vm.has_error = true;
	}

	/****************************************************************************
	 * get all project related entities
	 ****************************************************************************/
	// references
	// vm.getReferences();
	vm.getBillingRule(page_entity_id, null);
	if (vm.project.is_commodity) {
		vm.getCommodityActivities();
	}
	vm.getProjectSourceData();	
	if (vm.project.tasks_count > 0) {
		vm.getTaskList();
	}
	if (vm.project.submissions_count > 0) {
		vm.getSubmissionList();
	}
	if (vm.project.communications_log_count > 0) {
		vm.getCommunicationsLogList();
	}
	vm.getProjectExpensesType();
	vm.getKpiForDashboard();

	return vm;
});
