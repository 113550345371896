kv.controller('communicationsLogEditCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource, $rootScope) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'communications_log', 'communications_log_id', $scope, params);

	if (params.index) {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		vm.isNewRecord = false;

		vm.index = params.index;
		vm.rest.get({
			id: params.index
		}).then(function (response) {
			vm.data = response;
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
		});
	} else {
		vm.isNewRecord = true;
		vm.data = {};
		vm.data.type = params.type;
		vm.data.project_id = params.project_id;
		vm.data.from = API.user.user_id;
		vm.data.user = {};
		vm.data.user.full_name = API.user.full_name;
	}

	vm.quickAddContact = function () {
		vm.openModal({
			animation: true,
			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'contact';
				}
			}
		}).then(function (result) {
			vm.data.to = result.contact_id;
			vm.data.to = {
				contact_name: result.contact_name
			};
		});
	};

	vm.saveCallback = saveCallback;
	return vm;
});
