kv.controller('bpiFiscalEntityEditCtrl', function ($uibModalInstance, params, $scope, $http, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'bpi_fiscal_entity', 'bpi_fiscal_entity_id', $scope, params);

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];

	if (!page_entity_id && params.entity) {
		page_entity_id = params.entity.bpi_fiscal_entity_id;
	}

	vm.modal.rendered.then(() => {
		if (!vm.dataHasLoaded) KApp.block('.modal-content');
	});

	if (page_entity_id) {
		$http.get("/bpi_fiscal_entity/" + page_entity_id)
			.then(function (response) {
				vm.isNewRecord = false;
				vm.data = response.data;
				if (vm.data.bpi_follower) {
					angular.forEach(vm.data.bpi_follower, function (v, i) {
						vm.data.bpi_follower[i].id = v.user_id;
						vm.data.bpi_follower[i].name = v.user.user_name;
					});
				}
				vm.dataHasLoaded = true;
				KApp.unblock('.modal-content');
			});
	} else {
		vm.isNewRecord = true;
		vm.data = {};
		vm.dataHasLoaded = true;
	}

	return vm;
});
