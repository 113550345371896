kv.controller('groupViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'group_id';
	vm.entity = 'group';
	vm.entityType = 'group';
	vm.entityId = vm.bladeParams.groupId;
	vm.groups = {};


	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];


    vm.commentsConfig = {
      entity_type: vm.entity
    };

	vm.addEditEntity = function (entity) {
		vm.openModal({
			templateUrl: "group-edit",
			controller: 'groupEditCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: {
					entity: entity
				}
			}
		}).then(response =>{
			window.location = "/group/" + page_entity_id;
		});
	};

	vm.goBack = function () {
		$state.go("app.group");
	};

	return vm;

});

