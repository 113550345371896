kv.controller('PaymentsByUserReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    if (vm.bladeParams && vm.bladeParams.data) {
        var data = JSON.parse(vm.bladeParams.data);
    } else {
        var data = [];
    }
    let initParams = kvUtils.getAllQueryParams();

    if (initParams) {
        if (initParams.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    vm.drillDown = function(group_by, agg_id, agg_name = null) {
        let params = angular.copy(initParams);
        params[params.group_by + "_id"] = agg_id;
        params[params.group_by] = agg_name;
        params.group_by = group_by;

        let url = window.location.origin + window.location.pathname + "?" + $filter("objToParams")(params);
        window.open(url);
    };

    vm.isLoading = true;

    var chartData = data;
    function chart() {
        $('#chart').hide();
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    load: function () {
                        this.reflow();
                    }
                },
                height: 550,
            },
            title: {
                text: 'Top ' + $filter('trans')('OF_SELECTION')
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}: <b>{point.y} % ({point.percentage:.1f} % din selectie) </b>'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    },
                    showInLegend: false,
                    innerSize: '60%'
                }
            },
            series: [{
                data: chartData
            }],
            afterPrint: function(a,b,c) {
                console.log(a,b,c)
            }
        }

        // hide dataLabels when resolution is xxs (phone)
        if( $(window).width() < 480 )
        {
            config.plotOptions.pie.dataLabels.enabled = false;
        }
        $('#chart').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#chart').show();
            $('#chart').highcharts().reflow();
        }, 1);
    }

    if (chartData) {
        chart();
    }

    return vm;
});
