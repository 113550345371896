kv.controller('InvoiceAddCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'invoice_id';
	vm.entity = 'invoice';
	vm.rest = vm.getInjection('resource').init(vm.entity);
	vm.timeout = vm.getInjection('$timeout');

	vm.action = {
		delete: deleteEntity,
		filter: filter,
		resetFilters: resetFilters,
		issue: issue,
		issueGroup: issueGroup,
		issueAll: issueAll,
	};
	vm.all_projects_ids = [];

	var lsCacheFilters = lscache.get('invoice-add-filter');
	if (lsCacheFilters !== null) {
		vm.search = {
			customer: lsCacheFilters.customer,
			default: lsCacheFilters.default,
			invoicing_elements_id: lsCacheFilters.invoicing_elements_id,
			date_from: lsCacheFilters.date_from,
			date_to: lsCacheFilters.date_to,
			project_id: lsCacheFilters.project_id,
			exchange_date: lsCacheFilters.exchange_date,
			show_debug_info: '0'
		};
	} else {
		vm.search = {
			invoicing_elements_id: 2,						// service & expense
			date_from: vm.bladeParams.firstDayOfMonth,	    // first day of current month
			date_to: vm.bladeParams.lastDayOfMonth,		    // last day of current month
			exchange_date: moment(),						// calculate with today's exchange rate
			show_debug_info: '0'								// no debug
		};
	}

	vm.newResult = {};

	vm.toggleChild = toggleChild;
	vm.toggleProject = toggleProject;
	vm.selectAllProjects = selectAllProjects;
	vm.showProject = showProject;
	vm.showIssueButton = showIssueButton;
	vm.show_customer = {};
	vm.selected_projects = {};
	vm.selectAllProjectsCheckbox = {};

	function getExpenseTypeFilter() {
		var expenseType = lscache.get('expenseTypeFilter');

		if (expenseType) {
			if (expenseType.length) {
				return expenseType;
			} else {
				lscache.set('expenseTypeFilter', "SERVICES_AND_EXPENSES", vm.expenseFilterCacheTime);
			}
		} else {
			lscache.set('expenseTypeFilter', "SERVICES_AND_EXPENSES", vm.expenseFilterCacheTime);
		}

		return lscache.get('expenseTypeFilter');
	}

	getExpenseTypeFilter();

	function deleteEntity($index) {
		$http.delete("{{ action('InvoiceController@index') }}/" + vm.result[$index][vm.primaryKey]).then(function () {
			vm.result.splice($index, 1);
		});
	}

	function showIssueButton(customer_fiscal_entity_id) {
		if (vm.selected_projects[customer_fiscal_entity_id] == undefined) {
			return 0;
		}
		if (vm.selected_projects[customer_fiscal_entity_id].length > 0) {
			return 1;
		}
		return 0;
	}

	function toggleChild(customer_fiscal_entity_id) {
		if (vm.show_customer[customer_fiscal_entity_id] == undefined) {
			vm.show_customer[customer_fiscal_entity_id] = false;
		}
		vm.show_customer[customer_fiscal_entity_id] = !vm.show_customer[customer_fiscal_entity_id];

		let element = $(".collapse_button_" + customer_fiscal_entity_id);
		if (element.hasClass("fa-caret-right")) {
			element.removeClass('fa-caret-right').addClass('fa-caret-down');
		} else {
			element.removeClass('fa-caret-down').addClass('fa-caret-right');
		}
	}

	function toggleProject(customer_fiscal_entity_id, project_id, value) {
		if (vm.selected_projects[customer_fiscal_entity_id] == undefined) {
			vm.selected_projects[customer_fiscal_entity_id] = [];
		}
		if (value == undefined) { //toggle
			if (vm.selected_projects[customer_fiscal_entity_id].indexOf(project_id) != -1) {
				var pos = vm.selected_projects[customer_fiscal_entity_id].indexOf(project_id);
				vm.selected_projects[customer_fiscal_entity_id].splice(pos, 1);
			} else {
				vm.selected_projects[customer_fiscal_entity_id].push(project_id);
			}
		} else {
			//set to value
			//if value and project is not selected added to selected
			if (value && vm.selected_projects[customer_fiscal_entity_id].indexOf(project_id) == -1) {
				vm.selected_projects[customer_fiscal_entity_id].push(project_id);
			}
			// if value = false && project is selected remove from selected
			if (!value && vm.selected_projects[customer_fiscal_entity_id].indexOf(project_id) != -1) {
				var pos = vm.selected_projects[customer_fiscal_entity_id].indexOf(project_id);
				vm.selected_projects[customer_fiscal_entity_id].splice(pos, 1);
			}
		}
	}

	function selectAllProjects(customer) {
		let search = (vm.g || '').toLowerCase().trim();
		let filteredProjects = vm.q ? customer.projects.filter(project => {
			return project.project.name.toLowerCase().includes(search);
		}) : customer.projects;
		let ids = vm.selected_projects[customer.customer_fiscal_entity_id] = [];
		// mtCheckbox need a digest cycle until value is updated to model
		vm.timeout(() => {
			let value = customer.$selectAllProjects == 1;
			// execute add all if value =true, else not necessary.
			if (value) filteredProjects.forEach(project => {
				toggleProject(customer.customer_fiscal_entity_id, project.project_id, value)
			});

		});
	}

	function showProject(customer_fiscal_entity_id) {
		if (vm.showAllProjects) {
			return true;
		}
		return (vm.show_customer[customer_fiscal_entity_id] == undefined ? false : vm.show_customer[customer_fiscal_entity_id]);
	}

	function getResults(filter) {
		$scope.isLoading = true;
		vm.rest.get({
			url: 'invoice/getInvoiceable',
			params: filter
		}).then(function (response) {
			if(response.status == false) {
				vm.alert(vm.trans('LANG.ERROR') + ' ' + response.error.error_code, response.error.message, response.error.details, response.error.fullError);
				$scope.isLoading = false;
			} else {
				let data = response.data;
				data.projects.forEach(customer => {
					customer.$selectAllProjects = false;
					let total_converted = 0;
					let total = 0;
					angular.forEach(customer.projects, function (project, pindex) {

						if (!customer.projects[pindex].invoice_value) {
							customer.projects[pindex].invoice_value = 0;
						}

						if (!customer.projects[pindex].invoice_value_customer_currency) {
							customer.projects[pindex].invoice_value_customer_currency = 0;
						}
						if (customer.projects.length == 1) {
							total_converted = parseFloat(customer.projects[pindex].invoice_value_customer_currency);
							total = parseFloat(customer.projects[pindex].invoice_value);
						} else if (customer.projects.length > 1) {
							total_converted += parseFloat(customer.projects[pindex].invoice_value_customer_currency);
							total += parseFloat(customer.projects[pindex].invoice_value);
						}
						vm.all_projects_ids.push(project.project_id);
					});
					customer.total_converted = total_converted;
					customer.total = total;
				});
				vm.result = data.projects;
				vm.totals = data.totals;
				$scope.isLoading = false;
			}

		});
	}

	function filter() {
		var filter = angular.copy(vm.search);
		if (filter.exchange_date) {
			filter.exchange_date = filter.exchange_date;
		}
		if (filter.date_from) {
			filter.date_from = filter.date_from;
			angular.element("[ng-model='vm.search.date_from']").removeClass("required");
		} else {
			angular.element("[ng-model='vm.search.date_from']").addClass("required")
			return;
		}
		if (filter.date_to) {
			filter.date_to = filter.date_to;
		}
		if (API.user.tenant.tenant_ext.use_proforma_invoice == 0) {
			filter.invoice_type = "2";
		}
		vm.result = [];
		vm.selected_projects = {};
		vm.show_customer = {};
		lscache.set('invoice-add-filter', filter, 30);
		checkCurrency(filter.exchange_date, API.user.tenant.reporting_currency);
		getResults(filter);
		// delete filter.date_range;
	}

	function resetFilters() {
		vm.search = {};
		vm.selected_projects = {};
		vm.show_customer = {};
	}


	/**
	 *  Issue invoice
	 *
	 * @param projects - list of project ids [123, 124, 125]
	 * @param currency
	 * @param direct_invoice - issue invoice as proforma or fiscal, auto-validated, and window is not redirected to new invoice;
	 * @param index - nu ar trebui sa existe
	 * @returns {boolean}
	 */
	function issue(projects, project_currency, invoice_currency, direct_invoice, index) {
		var postData = angular.copy(vm.search);

		// delete postData.date_range;
		if (postData.exchange_date) {
			postData.exchange_date = postData.exchange_date;
		}
		if (postData.date_from) {
			postData.date_from = postData.date_from;
		}
		if (postData.date_to) {
			postData.date_to = postData.date_to;
		}

		// if tenant has not set use_proforma_invoice option, issue fiscal invoices by default
		if(API.user.tenant.tenant_ext.use_proforma_invoice == 0) {
			postData.invoice_type = "2";
		}

		// if direct invoice -> call this function (issue) for every projects[$index] and then increment index;
		// basically the app will issue one separate invoice for each project in projects[]
		//
		// if not direct invoice -> send all projects to function -> the invoice will have 3 lines, one for each project
		if (!direct_invoice) {
			postData.projects = projects;
		} else {
			if (projects[index]) {
				// get current project from array and issue invoice for this project ONLY
				postData.projects = [parseInt(projects[index])];

				// increment index, so that the next issue call will use this value
				index++;
			} else {
				return true;
			}
		}
		postData.direct_invoice = direct_invoice;

		if (project_currency) {
			KApp.block('#issue_invoice_portlet');
			// check if there is currency rate for currncy on issue date
			checkCurrency(postData.exchange_date, project_currency);
			// if no currency, show error
			if (currencyData.length == 0) {
				vm.currency = currency;
				return;
			}

			postData.force_currency = invoice_currency;

			//add class info && loader for direct_invoice
			let trElement = $('tr[data-project-id="' + postData.projects[0] + '"]');
			let loaderObj = null;
			if (direct_invoice) {
				trElement.addClass("info");
				loaderObj = loader(trElement.find("td.project-name"));
			}
			// issue invoice
			vm.rest.create({
				url: 'invoice/createFromProject',
				data: postData
			}).then(function (response) {
				KApp.unblock('#issue_invoice_portlet');
				if(response.status == false) {
					vm.alert("LANG.ERROR", response.error.message, response.error.details, response.error.fullError);
				} else if (response.status == true) {
					// localStorage.setItem('_debugger', data.data.debug);

					// if this is a simple issue then refresh list after invoice generation
					if (!direct_invoice) {
						// full refresh list
						if (response && response.data.data.invoice_id) filter();

						// if invoice_type is not set -> open invoice in edit mode, so the user can selectd the type (proforma or fiscal)
						// else open invoice in view mode
						//
						// if wizard then open invoice in wizard mode (with annex wizard)
						if (!response.data.data.type_id) {
							if (vm.bladeParams.useInvoiceAnnexWizard) {
								window.open("/invoice/" + response.data.data.invoice_id + "#annex", '_blank');
							} else {
								window.open("/invoice/" + response.data.data.invoice_id + "/edit", '_blank');
							}
						} else {
							if (vm.bladeParams.useInvoiceAnnexWizard) {
								window.open("/invoice/" + response.data.data.invoice_id + "#annex", '_blank');
							} else {
								window.open("/invoice/" + response.data.data.invoice_id, '_blank');
							}
						}

					} else {
						// list is not refreshed, only update class for every issued project line
						// set line to green (parsed)
						loaderObj.remove();
						trElement.removeClass("info").addClass('success');

						// issue invoice for next project
						issue(projects, currency, direct_invoice, index);
					}
				} else {
					vm.alert("LANG.ERROR", vm.trans("LANG.ERROR_SAVING_INVOICE"), "Unknown response from server.");
				}

			});
		}
	}

	function checkCurrency(exchange_date, currency) {
		vm.currency = currency;
		//check if exist currency_rate for selected date
		$scope.currencyCheck = false;
		var exchangeRateRest = resource.init("exchange_rate");
		exchangeRateRest.getArray({
			url: 'filter/exchange_rate/get_daily_currency_rate',
			params: {
				date: exchange_date,
				currency: currency,
			},
		}).then(function (data) {
			currencyData = data;
			if (currencyData[0]) {
				$scope.currencyCheck = false;
			} else {
				$scope.currencyCheck = true;
			}
		})
	}

	function issueGroup(customer_fiscal_entity_id, currency) {
		issue(vm.selected_projects[customer_fiscal_entity_id], currency);
	}

	function issueAll(currency) {
		// vm.showAllProjects = true;
		var selected_projects_ids = [];
		angular.forEach(vm.selected_projects, function (v) {
			angular.forEach(v, function (id) {
				selected_projects_ids.push(id);
			});
		});
		if (selected_projects_ids.length) {
			issue(selected_projects_ids, currency, true, 0);
		}
	}

	vm.quickInvoiceAdd = function () {
		vm.openModal({
			templateUrl: "quick-invoice",
			controller: 'quickInvoiceAddCtrl',
			controllerAs: 'vm',
			size: 'xl',
			resolve: {
				params: function () {
					return {};
				},
				bladeParams: () => {
					return vm.bladeParams;
				}
			}
		});
	};

	return vm;
});

