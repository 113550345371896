kv.controller('addDiscountCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'invoice_detail', 'invoice_detail_id', $scope, params);
    vm.data = {};
    vm.data.discount_type = "DISCOUNT_PERCENT";

    vm.save = function () {
        var discount_value = 0;
        var discount_vat = 0;
        var vat_percent = params.vat_percent;
        var discount_type = "";
        if(vm.data.discount_type == "DISCOUNT_VALUE") {
            discount_value = parseFloat(-vm.data.discount_amount).toFixed(2);
            if(params.result.item_id == 2) { // expsense
                discount_type = "DISCOUNT_VALUE";
            } else {
                discount_type = "DISCOUNT_VALUE";
            }
        } else if(vm.data.discount_type == "DISCOUNT_PERCENT") {
            discount_value = parseFloat(-params.result.base_amount * (vm.data.discount_amount / 100)).toFixed(2);
            if(params.result.item_id == 2) { // expsense
                discount_type = "DISCOUNT_VALUE";
            } else {
                discount_type = "DISCOUNT_PERCENT";
            }
        }
        discount_vat = parseFloat((discount_value * vat_percent) / 100).toFixed(2);

        $uibModalInstance.close({
            discount_type: discount_type,
            discount_value: discount_value,
            discount_vat: discount_vat,
            master_project: params.result.master_project,
            source_project: params.result.source_project
        });
    }

	vm.saveCallback = saveCallback;
	return vm;
});
