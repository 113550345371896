kv.controller('EFacturaReceivedEditCtrl', function ($uibModalInstance, params, $scope, $http, $injector, resource) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'efactura_received', 'id', $scope, params);

    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];

    if (!page_entity_id && params.entity) {
        page_entity_id = params.entity.party_monitor_id;
    }

    // vm.modal.rendered.then(() => {
    //     if (!vm.dataHasLoaded) KApp.block('.modal-content');
    // });

    vm.data = params.entity;

    vm.getQueryParam = function (param, param_value) {
        return "&" + param + "=" + param_value;
    };

    vm.addExpenseFromEfacturaReceived = function(entity){
        let url = 'expense/?create_from=efactura_received';
        if(entity.tenant_fiscal_entity){
            url += vm.getQueryParam("tenant_fiscal_entity_id", entity.tenant_fiscal_entity_id);
            url += vm.getQueryParam("tenant_fiscal_entity_name", entity.tenant_fiscal_entity.tenant_fiscal_entity_name);
        }
        if(entity.invoice_date){
            url += vm.getQueryParam("date", entity.invoice_date);
        }
        if(entity.total_amount){
            url += vm.getQueryParam("total_amount", entity.total_amount);
        }

        if(entity.currency){
            url += vm.getQueryParam("currency", entity.currency);
        }

        if(entity.invoice_series){
            url += vm.getQueryParam("invoice_number", entity.invoice_series);
        }

        if(entity.supplier){
            url += vm.getQueryParam("supplier_id", entity.supplier.customer_fiscal_entity_id);
            url += vm.getQueryParam("supplier_name", entity.supplier.name);
        }

        window.location.href = vm.API.URL + url;
    };

    // if (params.entity.id) {
    //     $http.get("/efactura_received/" + params.entity.id)
    //         .then(function (response) {
    //             vm.isNewRecord = false;
    //             vm.data = response.data;
    //             vm.dataHasLoaded = true;
    //             KApp.unblock('.modal-content');
    //         });
    // } else {
    //     vm.isNewRecord = true;
    //     vm.data = {
    //     };
    //     vm.dataHasLoaded = true;
    // }

    return vm;
});
