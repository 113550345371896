kv.controller('TenantViewTabOnlinePaymentCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'tenant_id';
    vm.entity = 'tenant';
    vm.entityType = 'tenant';
    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];
    vm.bladeParams.entity = JSON.parse(vm.bladeParams.entity);

    KApp.block('#loader');
    vm.page_loaded = false;
    vm.getData = function() {
        var REST = resource.init('tenant');
        REST.get({
            url: "tenant"
        }).then(function (res) {
            vm.data = res;
            // if has logo, create another variable from it, to refresh logo after change
            vm.data.new_logo_path = "";
            if (vm.data.logo_path) {
                vm.data.new_logo_path = vm.data.logo_path + "?" + new Date().getTime();
            }

            if (vm.data.tenant_ext.password_expiration_days) {
                vm.force_changing_password_periodically = true;
            }

            KApp.unblock('#loader');
            vm.page_loaded = true;
        });
    };
    vm.getData();

    vm.editTenant = function (entity, template) {
        vm.openModal({
            templateUrl: template + "-edit",
            controller: 'TenantViewWidgetEditCtrl',
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                params: function () {
                    if (!entity) {
                        entity = {
                            tenant_id: page_entity_id
                        };
                    }
                    return entity;
                },
                bladeParams: vm.bladeParams,
                saveCallback: function () {
                    return function(){
                        vm.getData();
                    };
                }
            }
        });
    };

    return vm;
});

