kv.controller('tenantInvoiceSeriesEditCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'invoice_series', 'invoice_series_id', $scope, params);

    if (!params.invoice_series_id) {
        vm.data = params;
        vm.data.is_active = 1;
        vm.isNewRecord = true;
    } else {
        vm.modal.rendered.then(() => {
            if (!vm.dataHasLoaded) KApp.block('.modal-content');
        });
        vm.rest.get({id: params.invoice_series_id}).then((response) => {
            vm.dataHasLoaded = true;
            KApp.unblock('.modal-content');
            if (response.$resolved) vm.data = response;
            else console.error(response);
        });
    }


    vm.validateSomeInputs = function () {
        vm.custom_errors = null;
        vm.has_error = false;
        if(!vm.data.code){
            $('[ng-model="vm.data.code"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.CODE_ERROR'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.data.code"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }
        

        if(vm.has_error){
            $('.custom_error_messages').css('display', 'block');
        } else {
            $('.custom_error_messages').css('display', 'none');
            vm.save();
        }

    };
    vm.saveCallback = saveCallback;

    return vm;
});
