kv.controller('ebillingPlatformListCtrl', function ($element, $attrs, $scope, $injector) {
    return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
        primaryKey: "ebilling_platform_id",
        entity: "ebilling_platform",
        modal: {
            templateUrl: "ebilling_platform-edit",
            controller: "ebillingPlatformEditCtrl"
        }
    });
});
