kv.controller('groupEditCtrl', function ($uibModalInstance, params, $scope, $http, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'group', 'group_id', $scope, params, 'group-edit-params');
	vm.isNewRecord = !vm.params.entity;
	if (vm.params.entity) {
		vm.entityId = vm.params.entity.group_id;
	}

	if (!vm.isNewRecord) {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		$http.get("/group/" + vm.entityId)
			.then(function (response) {
				vm.data = response.data.main;
				vm.dataHasLoaded = true;
				KApp.unblock('.modal-content');
				$scope.$broadcast('dataLoaded');
			});
	} else
		vm.data = {};
	return vm;
});
