/**
 * Usage
 * <action-menu>
		<action-menu-item>Show</action-menu-item>
	</action-menu>
 */
(function ()
{
	'use strict';

	angular
		.module('kv')
		.directive('actionMenu', actionMenu);

	function actionMenu() {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			templateUrl: "/assets/custom/js/directives/action-menu/templates/action-menu.html",
			link: function(scope, elem, attr) {}
		};
	}
})();
