kv.controller('projectSourceEditCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource, $rootScope) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'project_source', 'project_source_id', $scope, params);
	vm.dataHasLoaded = false;
	vm.modal.rendered.then(() => {
		if (!vm.dataHasLoaded) KApp.block('.modal-content');
		if(params.data){
			vm.data = params.data;
			vm.isNewRecord = false;
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
		} else {
			vm.isNewRecord = true;
			vm.data = {};
			vm.data.project_id = params.project_id;
			vm.data.customer = vm.data.customer_fiscal_entity = vm.data.contact = {};
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
		}
	});

	vm.quickAddCustomer = function quickAddCustomer() {
		vm.openModal({
			animation: true,
			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'customer';
				}
			}
		});
	};

	vm.quickAddContact = function quickAddContact() {
		vm.openModal({
			animation: true,
			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'contact';
				}
			}
		});
	};
	vm.quickAddCustomerFiscalEntity = function quickAddCustomerFiscalEntity() {
		vm.openModal({
			animation: true,
			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'customer_fiscal_entity';
				}
			}
		});
	};

	vm.saveCallback = saveCallback;

	vm.save = function save() {
		let project_source_id = vm.data.project_source_id;
		let postData = angular.copy(vm.data);
		vm.rest = resource.init('project_source');
		vm.custom_errors = null;
		vm.has_error = false;

		if (!vm.custom_errors) vm.custom_errors = [];
		// casa de avocatura, client, contacte personale - Firma/Client sursa/Contact sursa obligatoriu
		if (([1, 2, 7].indexOf(parseInt(vm.data.source_type_id)) !== -1) && !vm.data.entity_id) {
			$('[ng-model="vm.data.entity_id"] .select2-selection--single').css("border", "1px solid red");
			vm.custom_errors.push((parseInt(vm.data.source_type_id) == 1 ? vm.trans('LANG.FIRM') : parseInt(vm.data.source_type_id) == 2 ? vm.trans('LANG.SOURCE_CONTACT') : parseInt(vm.data.source_type_id) == 7 ? vm.trans('LANG.SOURCE_CUSTOMER') : '') + ' ' + vm.trans('IS_REQUIRED'));
			vm.has_error = true;
		} else {
			$('[ng-model="vm.data.entity_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
		}

		// evenimente, ghiduri, publicatii, organizatii si asociatii, altul - Detalii obligatoriu
		if (([4, 5, 6, 9, 11].indexOf(parseInt(vm.data.source_type_id)) !== -1) && !vm.data.comment) {
			$('[ng-model="vm.data.comment"]').css("border", "1px solid red");
			vm.custom_errors.push(vm.trans('LANG.SOURCE_COMMENTS') + ' ' + vm.trans('IS_REQUIRED'));
			vm.has_error = true;
		} else {
			$('[ng-model="vm.data.comment"]').css("border", "1px solid #c2cad8");
		}

		if(vm.has_error) {
			$('.custom_error_messages').css('display', 'block');
		} else {
			$('.custom_error_messages').css('display', 'none');
			if (project_source_id) {
				vm.rest.update({
					url: 'project_source/' + project_source_id,
					data: postData,
				}).then(function (response) {
					$uibModalInstance.close({response: response, index: -1});
				});
			} else {
				vm.rest.create({
					data: postData
				}).then(function (response) {
					$uibModalInstance.close({response: response, index: -1});
				});
			}
		}
	}

	vm.cancel = function cancel(){
		$uibModalInstance.dismiss('cancel');
	}
	
	return vm;
});
