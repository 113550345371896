kv.controller('sendEmailCtrl', function ($uibModalInstance, params, resource, $scope, $uibModal, $rootScope, $injector) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'reminders', 'id', $scope, params);

    vm.data = {};
    vm.dataHasLoaded = false;
    vm.isNewRecord = true;
    vm.invoice_id = angular.copy(params.invoice_id);
    vm.customer_id = angular.copy(params.customer_id);
    vm.has_annex = angular.copy(params.has_annex);
    vm.template = angular.copy(params.template);
    vm.dataHasLoaded = false;

    vm.entity = 'collection_logs';
    var REST = resource.init(vm.entity);
    vm.modal.rendered.then(() => {
        if (!vm.dataHasLoaded) KApp.block('.modal-content');
    });

    /**
     * ck editor config
     */
    vm.ckeditor = {
        height: 600,
        toolbarGroups: [
            {name: 'editing'},
            {name: 'basicstyles', groups: ['basicstyles'] },
            { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
            {name: 'font'},
            // {name: 'styles'},
            {name: 'colors'}
        ],
        removeButtons: 'Subscript,Superscript,RemoveFormat,Strike,Blockquote'
    };


    vm.getEmailContent = function () {
        let postData = {
            invoice_id: vm.invoice_id,
            template: vm.template
        };
        try {
            KApp.block('.modal-content');
            REST.post({
                url: 'invoice/' + vm.invoice_id + '/collection/actions/mock_email',
                data: postData
            }).then(function (response) {
                vm.dataHasLoaded = true;
                if (response.status === 'success') {
                    vm.data = response.data;
                    if (response.data.options && response.data.options.show_attach_invoice === false) {
                        vm.data.attach_invoice = false;
                    }
                    if (response.data.options && response.data.options.show_attach_annex === false) {
                        vm.data.attach_invoice = false;
                    }
                    if (response.data.options && response.data.options.show_include_collection_history === false) {
                        vm.data.include_collection_history = false;
                    }
                    if (vm.data.decoded && vm.data.decoded.to.contacts) {
                        angular.forEach(vm.data.decoded.to.contacts, function (v, i) {
                            vm.data.decoded.to.contacts[i].id = v.id;
                            vm.data.decoded.to.contacts[i].name = v.contact_name + " <" + v.email + ">";
                        });
                    }
                    KApp.unblock('.modal-content');
                    // console.log(vm.data);
                } else {
                    toastr.error('Error creating email');
                }
            });
        } catch (e) {
            console.log(e);
        } finally {

        }
    };
    vm.modal.rendered.then(() => {
        vm.getEmailContent();
    });

    vm.sendEmail = function () {
        let postData = angular.copy(vm.data);
        KApp.block('.send-email-modal');
        console.log(postData);
        REST.post({
            url: 'invoice/' + vm.data.invoice_id + '/collection/actions/send_email',
            data: postData
        }).then(function (response) {
            if(response.status === 'success'){
                toastr.success(response.message);
            }else{
                toastr.error('Error sending email');
            }
            KApp.unblock('.send-email-modal');
            vm.close();
        });
    };

    vm.quickAddContact = function quickAddContact() {
        var modalInstanceContact = $uibModal.open({
            animation: true,
            component: 'quickAddEntity',
            resolve: {
                entity: function () {
                    return $rootScope.quickAddEntityTemplate = 'contact';
                }
            }
        });
    };


    return vm;
});
