kv.controller('AllocationBilledVsCollectedReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function() {
        $('#table').DataTable({
            fixedHeader : {
                header: false,
                footer: true
            },
            "bPaginate": false,
            "paging":   false,
            "ordering": false,
            "info":     false
        });

        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');
    });

	return vm;
});
