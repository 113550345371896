class DashboardWidgetCtrl extends baseCtrl {
	constructor($element, $attrs, $scope, $injector, drawWidget, loaderSelector) {
		super($scope, $element, $attrs, $injector);
		this.resource = this.getInjection('resource');
		this.rest = this.resource.init();
		this.API = this.getInjection('API');
		this.drawWidget = drawWidget.bind(this);
		this.Dashboard = $scope.$parent.Dashboard;
		this.loaderSelector = this.element.find(loaderSelector ? loaderSelector : '.k-portlet__body');
		this.loading = {};
		this.error = {};
		this.getInjection('$timeout')(() => this.addRequests());
	}

	addRequests() {
		if (!this.bladeParams.tabId) console.error("You must have tab-id attribute in blade-params element!!!");
		Object.keys(this.bladeParams).forEach(attr => {
			if (!attr.startsWith('ajaxCall')) return;
			if (this.bladeParams[attr] == '') return;
			let id = attr.replace('ajaxCall', '');
			let params = [this.bladeParams[attr], id];
			this.Dashboard.addRequest(this.bladeParams.tabId, this.getData.bind(this), params);
		});
	}

	getData(ajaxCall, id) {
		let lscache_key = ajaxCall + "&tenant_id=" + this.API.user.tenant_id;
		if (ajaxCall == "") console.error("no ajaxCall parameter found for widget");
		id = id ? parseInt(id) : 1;
		let cache = lscache.get(lscache_key);
		if (cache == null || (cache.error && cache.error.message) || !cache.data) {
			this.error[id] = undefined;
			this.loading[id] = true;
			this.rest.get({
				url: ajaxCall
			}).then((response) => {
				if (response.status && this.API.LS_CACHE_TIMEOUT > 0) {
					lscache.set(lscache_key, response, this.API.LS_CACHE_TIMEOUT);
					// console.log('new cache for ' + lscache_key);
				}
				if (response.status) this.drawWidget(response.data, id);
				else this.error[id] = this.trans("ERROR_LOADING_DATA");
				if (!response.status) this.loading[id] = false;
			});
		} else {
			// console.log("get data form cache", cache.data);
			this.drawWidget(cache.data, id);
		}
	}
}
