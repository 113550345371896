kv.controller('costCenterListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "cost_center_id",
		entity: "cost_center",
		modal: {
			templateUrl: "cost_center-edit",
			controller: "costCenterEditCtrl"
		}
	});
});
