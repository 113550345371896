(function () {
	class commentsCtrl {
		constructor($rootScope, $element, $scope, $sce, resource, $uibModal, $filter, validator) {
			if (!$rootScope.commentsCtrl) $rootScope.commentsCtrl = {};
			$scope.vm = $rootScope.commentsCtrl;
			// la getComments se insereaza in vm.comments = this.data.items

			this.scope = $scope;
			this.sce = $sce;
			this.rest = resource.init('comment');
			this.modal = $uibModal;
			this.trans = $filter('trans');
			this.data = {};
			this.element = $element;
			$scope.$watch("vm.entityId", this.getComments.bind(this));
			this.validator = validator;
			this.ckeditor = {
				height: 300,
				toolbarGroups: [
					{
						name: 'editing',
						// groups: ['find', 'selection', 'spellchecker']
					},
					{name: 'font'},
					{name: 'styles'},
					{name: 'colors'}
				]
			};
		}

		post($index) {
			this.isLoading = true;
			if(this.data.comment_id) {
				this.rest.update({
					data: {
						"comment": this.data.items[$index].comment,
						"entity_type": this.scope.vm.entityType,
						"entity_id": this.scope.vm.entityId,
						"source_type": this.scope.vm.sourceType,
						"source_type_id": this.scope.vm.sourceTypeId
					},
					id: this.data.comment_id
				}).then(() => {
					this.data.comment_id = null;
					this.getComments();
				});
			} else {
				this.rest.create({
					data: {
						"comment": this.data.comment,
						"entity_type": this.scope.vm.entityType,
						"entity_id": this.scope.vm.entityId,
						"source_type": this.scope.vm.sourceType,
						"source_type_id": this.scope.vm.sourceTypeId
					}
				}).then(() => {
					this.data.comment = "";
					this.getComments();
				});
			}
			$('#addComment').show();
		}

		getComments() {
			this.isLoading = true;
			// console.log("this.scope.vm", this.scope.vm);
			if (!this.scope.vm.entityId) return;
			this.rest.list({
				params: {
					entity_type: this.scope.vm.entityType,
					entity_id: this.scope.vm.entityId,
					source_type: this.scope.vm.sourceType,
					source_type_id: this.scope.vm.sourceTypeId
				}
			}).then(response => {
				this.data.items = response.data;

				let loading = 0;
				this.isLoading = this.data.items.length > 0;
				this.data.items.forEach((item, i) => {
					this.data.items[i].comment = this.sce.trustAsHtml(item.comment.toString());
					if (item.source_type === 'group') {
						this.data.items = this.data.items.filter(e => e == item.source_type);
					}
					if (item.scope_type && item.scope_type == 'activity') {
						this.rest.get({
							url: 'activity',
							params: {
								activity_id: item.scope_type_id,
							}
						}).then((data) => {
							item.activity_name = data.activity_name;
							item.activity_id = data.activity_id;
							loading++;
							this.isLoading = loading < this.data.items.length;
						});
					} else loading++;
					//insert comments items in vm.comments
					this.scope.vm.comments = this.data.items;
					this.isLoading = loading < this.data.items.length;
					// console.log(this.loading);
				});
			});
		}


		deleteComment($index) {
			let ctrl = this;
			var modalInstance = this.modal.open({
				animation: true,
				component: 'modalConfirm',
				resolve: {
					content: () => {
						return ctrl.trans("ARE_YOU_SURE_DELETE");
					},
					title: () => {
						return ctrl.trans("DELETE");
					}
				}
			});

			modalInstance.result.then((response) => {
				if (response == 'cancel') return;
				this.rest.delete({
					url: 'comment/' + this.data.items[$index].comment_id,
					success: () => {
						this.data.items.splice($index, 1);
					}
				});
			});
		}

		br2nl(str) {
			return str.replace(/<br\s*\/?>/mg, "\r");
		}

		editComment(item, index) {
			$('#addComment').hide();
			this.data.comment_id = item.comment_id;
			this.data.items[index].edit = true;
		}

		validateSomeInputs() {
			this.custom_errors = null;
			this.has_error = false;

			if(!this.data.comment){
				$('[ng-model="commentsCtrl.data.comment"]').css("border", "1px solid red");
				if (!this.custom_errors) this.custom_errors = [];
				this.custom_errors.push(this.trans('LANG.COMMENT_REQUIRED'));
				this.has_error = true;
			} else {
				$('[ng-model="commentsCtrl.data.comment"] .select2-selection--single').css("border", "1px solid #c2cad8");
			}

			if(this.has_error){
				$('.custom_error_messages').css('display', 'block');
			} else {
				$('.custom_error_messages').css('display', 'none');
				this.post();
			}

			this.data.addComment = false;
		}
	}

	angular.module('kv').controller('commentsCtrl', commentsCtrl);
}());
