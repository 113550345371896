kv.controller('substitutionDelegationEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'substitution_delegation_id';
    vm.entity = 'substitution_delegation';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');


    var REST = resource.init(vm.entity);
    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];

    if(page_identifier == 'edit') {
        $http.get("/substitution_delegation/" + page_entity_id).then(function(response) {
            vm.data = response.data;
        });
    } else {
        let defaultObjects = JSON.parse($element.find("#defaultObjects").attr("defaultObjects"));
        vm.data = defaultObjects;
        vm.data.user= [];
        vm.data.user_id = API.user.user_id;
        vm.data.user.full_name = API.user.full_name;

        let currentDate = new Date();
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        vm.data.date = year + '-' + (month < 10 ?  '0' + month : month) + '-' + (day < 10 ? '0' + day : day)+ ' ' + vm.API.START_DAY_AT;
        vm.isNewRecord = true;
       /* vm.data.user= [];
        vm.data.user_id = API.user.user_id;
        vm.data.user.full_name = API.user.full_name;*/
    }

    vm.newEntity = function newEntity(entity) {
        if(!vm.data) {
            vm.data = {};
        }

        if(!vm.data[entity]) {
            return vm.data[entity] = [{}];
        }

        return vm.data[entity].push({});
    };

    $scope.$watch('vm.data.user_id', function(value) {
        if(value) {
            var REST = resource.init('user');

            REST.get({
                url: "user/",
                params: {
                    delegation_user_id: value
                }
            }).then(function(data) {
                if(data.data){
                    angular.forEach(data.data, function (value, key) {
                        if (value.position) {
                           vm.data.substituted_user_profession = value.position;
                        }
                    });
                }
            });
        }
    });

    $scope.$watch('vm.data.empowered_user_id', function(value) {
        if(value) {
            var REST = resource.init('user');

            REST.get({
                url: "user/",
                params: {
                    delegation_user_id: value
                }
            }).then(function(data) {
                if(data.data){
                    angular.forEach(data.data, function (value, key) {
                        if (value.position) {
                            vm.data.empowered_user_profession = value.position;
                        }
                    });
                }
            });
        }
    });

    $scope.$watch('vm.data.legal_case_id', function(value) {
        if(value) {
            var REST = resource.init('court_phase/list');
            REST.getArray({
                params: {
                    legal_case_id: value
                }
            }).then(function(data) {
                if(data[0]){
                    if (data[0].object) {
                        vm.data.object = data[0].object;
                    }
                }
            });
        }
    });

    vm.deleteEntity = function deleteEntity($index, entity, id) {
        if(!id) {
            return vm.data[entity].splice($index,1);
        }

        if(page_entity_id) {
            REST.delete({
                url: vm.entity + '/:id/' + entity + '/:entityId',
                params: {
                    id: page_entity_id,
                    entityId: id
                }
            }).then(function(data) {
                vm.data[entity].splice($index,1);
            });
        }
    };

    return vm;
});
