kv.controller('securityGroupListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'security_group_id';
	vm.entity = 'security_group';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'security_group'
		},
		url: "security_group",
	});

	vm.delete = function ($index) {
		$http.delete("security_group/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
			vm.infinitScroll.items.splice($index, 1);
		});
	};

	$scope.resetFilters = function () {
        $scope.filters = {};
        for(var x = 0; x< $("lookup").length; x++ ){
            var placeholder = $($("lookup" )[x]).attr('placeholder');
            $($('.select2-selection__rendered')[x]).html('<span class="select2-selection__placeholder">'+ placeholder +'</span>');
        }
        vm.search = {};
    };

	return vm;
});

