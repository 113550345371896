kv.controller('budgetItemValueListCtrl', function ($element, $attrs, $scope, $injector) {
 	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "budget_item_value_id",
		entity: "budget_item_value",
		modal: {
			templateUrl: "budget_item_value-edit",
			controller: "budgetItemValueModalEditCtrl"
		}
	});
});
