kv.controller('calendarSchedulerEditEventCtrl', function ($uibModalInstance, type, calendarEvent, start, end, $scope, activity_id, date, resource, room, deleteEvent, saveCallback, $filter, $injector) {
	var vm = new modalCtrl($uibModalInstance, $injector, "scheduler", "scheduler_id", $scope, {},
		"calendar-scheduler-edit-event");

	// Data
	vm.type = type;
	vm.calendarEvent = calendarEvent;
	vm.start = start;
	vm.end = end;

	vm.quickAddContact = function () {
		vm.getInjection("$uibModal").open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-content',
			windowClass: 'show',
			backdropClass: 'show',

			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return vm.rootScope.quickAddEntityTemplate = 'contact';
				}
			}
		});
	};

	vm.quickAddCustomer = function () {
		vm.getInjection("$uibModal").open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-content',
			windowClass: 'show',
			backdropClass: 'show',

			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return vm.rootScope.quickAddEntityTemplate = 'customer';
				}
			}
		});
	};
	vm.saveEvent = function () {
		KApp.block('.modal-content');

		let dataToSave = angular.copy(this.data);
		dataToSave.start_date = this.data.start_date.PostDatetime;
		dataToSave.end_date = this.data.end_date.PostDatetime;

		if (this.data.is_bd_activity == true) {
			dataToSave.activity_type_id = 2; // bd meeting local
			dataToSave.business_status_id = 10; // status -> planned
			if (dataToSave.meeting_room_id) {
				dataToSave.has_meeting_room = 1;
			}
		} else {
			dataToSave.activity_type_id = 1; // meeting type (activity general)
		}

		if (this.data.all_day) {
			dataToSave.start_date = this.data.start_date.PostDate + " " + vm.API.START_DAY_AT;
			dataToSave.end_date = this.data.start_date.PostDate + " " + vm.API.END_DAY_AT;
		}

		dataToSave.fromScheduler = true;
		if (this.data.activity_id) delete dataToSave.attendee;
		let action = this.data.activity_id ? "update" : "create";
		let params = {data: dataToSave};
		if (action == "update") params.id = dataToSave.activity_id;
		var REST = resource.init('scheduler');
		REST[action](params).then(response => {
			KApp.unblock('.modal-content');
			if (response.status) {
				saveCallback(response.data, type);
				$uibModalInstance.close();
				if (vm.data.has_occurred) vm.sendToTimesheet(vm.data);

			} else {
				vm.errors = response.error;
				vm.validator.markErrors(response.error, vm.element);

			}
		});
	};


	// vm.saveEvent1 = function () {
	// 	if (!vm.data.user_id) {
	// 		vm.user_id_is_required = true;
	// 	} else {
	// 		$uibModalInstance.close(vm.data);
	// 		if (vm.data.has_occurred) {
	// 			vm.sendToTimesheet(vm.data);
	// 		}
	// 	}
	// };

	vm.sendToTimesheet = function (data) {
		vm.getInjection("$uibModal").open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-content',
			windowClass: 'show',
			backdropClass: 'show',
			templateUrl: 'calendar-scheduler-edit-event-send-to-timesheet',
			controller: function ($uibModalInstance, resource, $http) {
				var vm = this;
				vm.entity = 'timesheet';
				var REST = resource.init(vm.entity);
				vm.action = {
					cancelTimesheet: () => $uibModalInstance.dismiss('cancel'),
				};
				vm.data = data;
			},
			controllerAs: 'vm',
			size: 'md',
			resolve: {}
		});
	};

	/**
	 * Close the dialog
	 */
	vm.closeDialog = function () {
		$uibModalInstance.dismiss('cancel');
	};

	vm.dataHasLoaded = false;
	vm.data = {};
	$uibModalInstance.rendered.then(() => {
		if (calendarEvent) {
			$('.modal-header .modal-title').html($filter('trans')('EDIT') + ' ' + $filter('trans')('ACTIVITY').toLowerCase());
			vm.data = calendarEvent;
			if (calendarEvent.activity_id) { //este activity
				vm.data.start_date = new dateClass(calendarEvent.start_date);
				vm.data.end_date = new dateClass(calendarEvent.end_date);
				vm.data.meeting_room = {
					calculated_full_name: calendarEvent.meeting_room.name
				};

			} else {
				vm.data.start_date = new dateClass(calendarEvent.start);
				vm.data.end_date = new dateClass(calendarEvent.end);
				vm.data.meeting_room_id = calendarEvent.resource_id
			}
			vm.dataHasLoaded = true;

			if (!vm.data.all_day) {
				vm.data.all_day = false;
			}

			// meeting room full name (#01 - Name (x pers))
			if (room) {
				vm.data.calculated_full_name = "#" + vm.data.meeting_room.number + " - " + vm.data.meeting_room.name + " (" + vm.data.meeting_room.capacity + " pers)";
			}
		} else {
			$('.modal-header .modal-title').html($filter('trans')('ADD') + ' ' + $filter('trans')('ACTIVITY').toLowerCase());

			vm.data.start_date = start;
			vm.data.end_date = end;
			vm.data.all_day = false;
			vm.data.is_bd_activity = false;

			vm.data.user_id = vm.bladeParams.userId;
			vm.data.user = {
				full_name: vm.bladeParams.userFullName
			};
			if (room) {
				vm.data.meeting_room_id = room.id;
				vm.data.meeting_room = {
					calculated_full_name: room.title
				};
			}
		}
	});
// vm.data.is_bd_activity = true;

	return vm;
})
;

