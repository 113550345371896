var stopwatchDocumentClick = function (event) {
	// console.log("click", event);
	let stopwatch = $(event.target).closest("#stopwatch");
	if (stopwatch.length > 0) return;
	let scope = $("#stopwatch > div").scope();
	scope.$ctrl.popupToggle();
};

class StopwatchIconCtrl {
	constructor(scope, element, http, $document) {
		this.scope = scope;
		this.element = element;
		this.http = http;
		this.document = $document;
		Object.defineProperty(this, 'tasks', {
			get() {
				return scope.tasks;
			}, set(tasks) {
				if (tasks.length === undefined) return;
				scope.tasks = tasks;
				scope.tasks.forEach(task => {
					task.name = function () {
						if (this.is_internal_hours) return this.internal_project.project_name;
						else return this.project.project_name + (this.reference ? "<br/><small>" + this.reference.project_name + "</small>" : "");
					};
					Object.defineProperty(task, "elapsedTime", {
						get() {
							if (this.stopped) return this.time;
							let start = moment(this.start_datetime);
							let thisMoment = moment();
							let now = thisMoment.valueOf();
							let elapsedSecs = (now - start.valueOf()) / 1000;
							let min = Math.floor(elapsedSecs / 60);
							this.elapsed_minutes = min;
							let hours = Math.floor(min / 60);
							let restMin = min - hours * 60;
							// let time = hours.toString().padStart(2, '0') + ":" + restMin.toString().padStart(2, 0);
							let time = hours.toString() + "h " + restMin.toString() + "m"
							this.time = time;

							return time;
						}
					});
				});
			}
		});
		let ctrl = this;
		http.get('/stopwatch/list').then(response => {
			ctrl.tasks = response.status === 200 && response.data ? response.data : [];
		});
	}

	popupToggle() {
		let element = angular.element(this.element).find("#stopwatch-dropdown");
		let visible = element.css("display") !== "none";
		element.toggle();
		if (!visible) document.body.addEventListener("click", stopwatchDocumentClick);
		else document.body.removeEventListener("click", stopwatchDocumentClick);
	}
}

var module = angular.module('kv');
module.component('stopwatchIcon',
	{
		templateUrl: '/assets/custom/js/directives/stopwatch/stopwatch-icon.html',
		controller: StopwatchIconCtrl
	});

StopwatchIconCtrl.$inject = ["$scope", "$element", "$http", "$document"];
