(function () {
	let controller = function () {
		return {
			restrict: 'E',
			replace: false,
			link: function (scope, element, attrs) {
				element.css("display","none");
			}
		};
	};
	angular.module("kv").directive('bladeParams', controller);

}());