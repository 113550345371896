/**
 * Usage
 * <avatar avatar="dbo.people.avatar 3ad7c2ebb96fcba7cda0cf54a2e802f5.jpg" first-name="John" last-name="Doe" ?size="medium/big"></avatar>
 */
(function () {
	'use strict';

	angular
		.module('kv')
		.directive('avatar', avatar);


	function avatar(API) {
		return {
			template: '<div href="javascript:;" class="avatar mr-3 {{color}}  {{ avatarSize }} {{ class }} align-self-start">' +
				'<img ng-src="{{ avatarUrl() }}" alt="image" class="avatar {{ avatarSize }} {{ class }}" ' +
						'ng-if="avatarUrl()" onerror="$(this).remove(); avatar = false">' +
				'<span ng-if="!avatarUrl()">{{ initials }}</span>' +
				'</div>',
			scope: {
				avatar: '=',
				avatarName: '@',
				size: '@',
				class: '@',
				useInitials: '@'
			},
			restrict: "E",
			link: function (scope, element, attrs, controller) {
				// calculate initials based on firstName and lastName
				let colors = ['bg1', 'bg2', 'bg3', 'bg4', 'bg5'];

				// set avatarSize class
				scope.avatarSize = ((scope.size === "large") ? "large" : "small");
				let directAvatarSize = ((scope.size === "large") ? "l" : "s");

				// return avatar url from api
				scope.avatarUrl = function () {
					let avatar = scope.avatar;
					if (avatar) {
						if (avatar.length === 36) {
							scope.color = "";
							// gbarcun modificare 23 jul 2020
							// return API.URL + "avatar/" + avatar + "/" + ((scope.size) ? scope.size : "small");
							return API.URL + "storage/avatars/" + directAvatarSize + "_" + avatar;
						} else {
							return avatar;
						}
					}
				};

				// set default initials
				// if 
				scope.initials = "#";
				scope.$watch('avatarName', (value => {
					if (scope.avatarName && scope.avatarName.trim() !== '') {
						// initial
						scope.initials = scope.avatarName.toUpperCase().charAt(0);

						// random color
						//scope.color = colors[Math.floor(Math.random() * colors.length)];
						scope.color = colors[scope.initials.charCodeAt(0) % 5];
					} else {
						scope.color = "";
					}

				}));
			}
		};
	}
})();
