kv.controller('newsEditCtrl', function ($uibModalInstance, params, $scope, $http, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'news', 'news_id', $scope, params, 'news-edit-params');
	vm.isNewRecord = !vm.params.entity;
	if (vm.params.entity) {
		vm.entityId = vm.params.entity.news_id;
	}

	if (vm.entityId) {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		$http.get("/news/" + vm.entityId)
			.then(function (response) {
				vm.data = response.data;
				vm.dataHasLoaded = true;
				KApp.unblock('.modal-content');
				$scope.$broadcast('dataLoaded');
			});
	} else
		vm.data = {};

	return vm;
});
