class SettingController {
    canBeDisplayed() {
        if (!this.setting || !this.entitySettings) {
            return false;
        }
        return (this.setting.depends_on || [])
            .map((settingPath) => (this.getNestedProperty(settingPath) || {value: false}).value)
            .reduce((prev, current) => prev && current, true);

    }

    getNestedProperty(path) {
        return path.split('.').reduce((acc, part) => acc && acc[part], this.entitySettings);
    }
}

kv.component('kvSetting', {
    templateUrl: '/assets/custom/js/components/settings/setting.component.html',
    controller: SettingController,
    bindings: {
        setting: '=',
        entitySettings: '='
    }
});
