/**
 * factory pt. modal instance of addTask
 * @param $uibModal
 * @returns {Function}
 */


function calendarAddEventCtrl($uibModalInstance, type, calendarEvent, start, end, $scope, activity_id, date, resource, room, deleteEvent, saveCallback, $filter, validator, $injector) {
	var vm = new modalCtrl($uibModalInstance, $injector, "calendar", "calendar_id", $scope, {}, "event-form-dialog-params");
	// Data
	// vm.type = type;
	// vm.calendarEvent = calendarEvent;
	// vm.start = start;
	// vm.end = end;

	/**
	 * Close the dialog
	 */
	vm.closeDialog = function () {
		$uibModalInstance.dismiss('cancel');
	};

	vm.dataHasLoaded = false;
	vm.data = {};
	vm.modal.rendered.then(() => {
		if (calendarEvent && calendarEvent.activity_id) {
			$('.modal-header .modal-title').html($filter('trans')('ACTIVITY_EDIT'));
			vm.data = calendarEvent;
			vm.dataHasLoaded = true;

			if (!vm.data.all_day) {
				vm.data.all_day = false;
			}
		} else {
			$('.modal-header .modal-title').html($filter('trans')('ADD') + ' ' + $filter('trans')('TASK').toLowerCase());

			vm.data.start_date = start;
			vm.data.end_date = end;

			vm.data.all_day = true;
			vm.data.users = [];
			vm.data.users.push({
				id: vm.bladeParams.userId,
				name: vm.bladeParams.userFullName
			});
		}
	});
	vm.saveEvent = function () {
		let data = angular.copy(vm.data);
		data.start_date = vm.data.start_date.PostDate;
		data.end_date = vm.data.end_date.PostDate;
		data.editable = true;
		if (data.all_day) {
			data.start_date = vm.data.start_date.DateFormat + " " + vm.API.START_DAY_AT;
			data.end_date = vm.data.start_date.DateFormat + " " + vm.API.END_DAY_AT;
		} else {
			data.start_date = vm.data.start_date.PostDatetime;
			data.end_date = vm.data.end_date.PostDatetime;
		}
		console.log(data);
		var REST = vm.getInjection('resource').init('task');
		REST.create({
			url: 'task',
			data: data
		}).then(response => {
			if (response.status) {
				saveCallback(response);
				vm.closeDialog();
			} else {
				vm.errors = response.error;
				vm.validator.markErrors(response.error, vm.element);
			}
		});
	};

	return vm;
}


