kv.controller('documentTemplatesViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $timeout) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'document_templates_id';
	vm.entity = 'document_templates';
	vm.entityType = 'document_templates';
	vm.entityId = vm.bladeParams.documentTemplatesId;

	vm.commentsConfig = {
		entity_type: vm.entity
	};

	return vm;
});

