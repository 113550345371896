class dictionaryListCtrl extends baseCtrl {
	constructor($element, $attrs, $scope, $injector, config) {
		super($scope, $element, $attrs, $injector);
		this.primaryKey = config.primaryKey;
		this.entity = config.entity;
		this.getInfinitScroll(config.infinitScroll || {
			url: config.entity,
		});
		this.config = config;
	}

	addEditEntity(event, entity, $index) {
		this.openModal({
			templateUrl: this.config.modal.templateUrl,
			controller: this.config.modal.controller,
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						index: $index,
						entity: entity
					};
				}
			}
		}).then((result) => {
			if (!result) return;
			if (result.index == -1) {
				this.infinitScroll.items.unshift(result.entity);
			} else angular.extend(entity, result.entity);
			this.getInfinitScroll(this.config.infinitScroll || {
				url: this.config.entity,
			});
		});
	}

	deleteEntity(entity, $index) {
		this.openModal({
			animation: true,
			component: 'modalConfirm',
			resolve: {
				title: function () {
					return 'ARE_YOU_SURE_DELETE';
				}
			}
		}).then(() => {
			let rest = this.getInjection('resource').init(this.entity);
			let ctrl = this;
			KApp.block(this.element);
			return rest.delete({
				params: {
					id: entity[this.primaryKey]
				},
				success: (response) => {
					KApp.unblock(this.element);
					if(typeof response.status !== 'undefined') {
						if(response.status == true) {
							ctrl.infinitScroll.items.splice($index, 1);
							return response;
						} else if (response.status == false) {
							this.alert(this.trans('LANG.ERROR') + ' ' + response.error.error_code, response.error.message, response.error.details, response.error.fullError);
						}
					} else {
						ctrl.infinitScroll.items.splice($index, 1);
					}
				},
				error: (response) => {
					KApp.unblock(this.element);
					console.error(response);
					return response;
				}
			});
			// rest.delete({
			// 	params: {
			// 		id: entity[this.primaryKey]
			// 	},
			// 	success: function () {
			// 		ctrl.infinitScroll.items.splice($index, 1);
			// 	}
			// });
		});
	}


}
