class timesheetBaseCtrl extends baseCtrl {
	constructor($scope, $element, $attrs, $injector) {
		super($scope, $element, $attrs, $injector);
	}

	/**
	 * @param timesheetItem
	 * @param use_industrial_minutes
	 * @param duplicate
	 * @param firstCheckHoursFormat
	 */
	checkHoursFormat(timesheetItem, $filter, inputAsMinutes) {
		return kvUtils.checkHoursFormat.call(this, timesheetItem, $filter, inputAsMinutes);
	}

	/**
	 *
	 * @param hrs
	 * @param round
	 * @returns {number}
	 */
	humanTotalEffortToMinutes(hrs, round) {
		return kvUtils.humanTotalEffortToMinutes.call(this, hrs, round);
	}


}
