/**
 * Usage
 * <widget config="{config.object}"></widget>
 * Widget wrapper is a container used to repeat widgets given in the config param
 */

(function ()
{
    'use strict';

    angular
        .module('kv')
        .directive('widget', widget);

    function widget(API, $http, $timeout) {
        return {
            /**
             * container template, used as repeater
             * @type {String}
             */
            templateUrl:"/assets/custom/js/directives/widget/widget-wrapper.html",
            scope: {
                config: '='
            },

            restrict: "E",

            link: function( scope, element, attributes, controller ) {
                var BASE_URL = '/assets/custom/js/directives/widget/widgets/';
                scope.widgets = [];
                if(!scope.config) {
                    return;
                }
                // retrieve json config for every given widget
                $http.get(BASE_URL + scope.config.template + '/config.json').success(function(data) {
                    var data;
                    // json to object
                    data = angular.fromJson(data);

                    // if there's any chart included in the widget, attach below function
                    // used to initialize the graph when everything is loaded
                    if (data.chart) {
                        data.chart.func = function(chart) {
                            $timeout(function() {
                                chart.reflow();
                            }, 0);
                        };
                    }

                    // call api for widget data
                    // create an array of widgets for the repeater
                    $http.post(scope.config.url, scope.config.filter).success(function(response) {
                        var res;
                        if (response.data !== undefined) {
                            res = response.data;
                        } else {
                            res = response;
                        }

                        let currentDate = new Date();

                        for(let index = 0; index < res.length; index++) {
                            let start_date = new Date(res[index].start_date);
                            if(currentDate.getFullYear() === start_date.getFullYear()){
                                res[index].is_current_year = 1;
                            } else {
                                res[index].is_current_year = 0;
                            }
                            if(res[index].start_date === null){
                                res[index].is_current_year = -1; // invalid date
                            }
                        }

                        scope.widgets.push({
                            id: scope.config.template,
                            config: data,
                            data: res,
                            title: scope.config.title
                        });
                    });
                });
            }
        };
    }
})();
