kv.controller('roleTypeEditCtrl', function ($uibModalInstance, data, $injector, $scope) {
	return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
		entity: "role_type",
		primaryKey: "role_type_id",
		newEntity:{
		},
		prepareDataToSave: function () {
			let dataToSend = angular.copy(this.data);
			return dataToSend;
		}
	});
});