kv.controller('budgetItemValueEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'budget_item_value_id';
    vm.entity = 'budget_item_value';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');
    vm.isNewRecord = true;

    var REST = resource.init(vm.entity);

    if(vm.bladeParams.year) {
        $http.get(vm.bladeParams.budgetItemWithChildrenListUrl)
            .then(function(budget_items) {
                vm.budget_items = budget_items.data.data;
                $scope.$broadcast('dataLoaded');
            });
        $http.get(vm.bladeParams.budgetItemValueShowUrl)
        .then(function(response) {
            vm.data = response.data;
            $scope.$broadcast('dataHasLoaded');
        });
    } else {
        $http.get(vm.bladeParams.budgetItemListUrl)
            .then(function(budget_items) {
                vm.budget_items = budget_items.data;
                $scope.$broadcast('dataLoaded');
            });
        vm.data = {};
    }

    // overwrite function from baseCtrl, for adding supplimentary item, target
    // target is used in model, for verifying where the request is done(modal or edit), to make some fields required
    vm.prepareDataToSave = function () {
        let dataToSend = angular.copy(vm.data);
        dataToSend.year = vm.bladeParams.year;
        dataToSend.master_practice_id = vm.bladeParams.masterPracticeId;
        dataToSend.target = 'edit';
        return dataToSend;
    };

    // overwrite function from baseCtrl, for redirect to list, not to view
    vm.saveCallback = function () {
        window.location = "/" + vm.entity;
    };

	return vm;
});
