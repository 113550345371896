kv.controller('calendarSchedulerViewEventCtrl', function ($uibModalInstance, calendarEvent, $calendarSchedulerEditEvent, event, $scope, resource, deleteEvent, saveCallback, cmdate, $timeout) {
		var vm = this;
		$uibModalInstance.rendered.then(() => {
			// KApp.block(".modal-content");
		});
		// Data
		vm.calendarEvent = calendarEvent;

		vm.dataHasLoaded = false;
		vm.data = {};
		if (vm.calendarEvent && vm.calendarEvent.id) {
			var REST = resource.init("scheduler");
			REST.get({
				id: vm.calendarEvent.id
			}).then(function (data) {
				vm.data = data;
				// KApp.unblock(".modal-content");
				// I use cmdate.toServer intentional (fix for cmdate:'HH:mm' filter)
				vm.data.start_date = cmdate.toServer(moment(vm.data.start_date));
				vm.data.end_date = cmdate.toServer(moment(vm.data.end_date));

				 vm.dataHasLoaded = true;

				// map internal attendee
				if (vm.data.attendee_internal) {
					angular.forEach(vm.data.attendee_internal, function (entity, index) {
						vm.data.attendee_internal[index].id = entity.people_id;
						vm.data.attendee_internal[index].name = (entity.people ? entity.people.people_name : "");
					});
				}
				// map external attendee
				if (vm.data.attendee_external) {
					angular.forEach(vm.data.attendee_external, function (entity, index) {
						vm.data.attendee_external[index].id = entity.people_id;
						vm.data.attendee_external[index].name = (entity.people ? entity.people.people_name : "");
					});
				}
				// map invited customers
				if (vm.data.bdoffer_customer) {
					angular.forEach(vm.data.bdoffer_customer, function (entity, index) {
						vm.data.bdoffer_customer[index].id = entity.customer_id;
						vm.data.bdoffer_customer[index].name = entity.customer.customer_name;
					});
				}

				// meeting room full name (#01 - Name (x pers))
				if (data.meeting_room) {
					vm.data.meeting_room.calculated_full_name = "#" + data.meeting_room.number + " - " + data.meeting_room.name + " (" + data.meeting_room.capacity + " pers)";
					vm.data.meeting_room.number_name = data.meeting_room.name;
				}

			});
		}

		vm.closeDialog = function () {
			$uibModalInstance.dismiss('cancel');
		};

		vm.editEvent = function (activity) {
			$uibModalInstance.dismiss('cancel');
			$timeout(() => $calendarSchedulerEditEvent('edit', activity, false, false, event, null, deleteEvent, saveCallback));
		};

		vm.markAsPassed = markAsPassed;

		function markAsPassed() {
			if (vm.data.activity_id) {
				var postData = {};
				postData.has_occurred = 1;
				vm.data.has_occurred = 1;

				postData.activity_type_id = vm.data.activity_type_id;
				postData.user_id = vm.data.user_id;

				if ((typeof vm.data.meeting_room_id != 'undefined') && (vm.data.meeting_room_id != '')) {
					postData.has_meeting_room = 1;
					postData.meeting_room_id = vm.data.meeting_room_id;
				}

				if (vm.data.activity_type_id == 2 || vm.data.activity_type_id == 3) {
					postData.business_status_id = 12;
				} else {
					postData.business_status_id = 2;
				}

				var REST = resource.init('scheduler');
				delete vm.data.attendee;
				REST.update({
					id: vm.data.activity_id,
					data: postData,
					params: {
						force_update_entity: true
					}
				});
			}
		}

	vm.deleteEvent = function (calendarEvent) {
		var REST = resource.init("scheduler");
		KApp.block('.modal-content');
		REST.delete({
			id: calendarEvent.activity_id
		}).then((response => {
			deleteEvent(calendarEvent);
			KApp.unblock('.modal-content');
			$uibModalInstance.dismiss('cancel');
		}));
	};
	}
);

