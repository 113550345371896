kv.controller('legalCaseEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'legal_case_id';
	vm.entity = 'legal_case';
	vm.rest = vm.getInjection('resource').init(vm.entity);
	vm.validator = vm.getInjection('validator');
	let standardAddon = JSON.parse(vm.bladeParams.hasAccessToAddonStandard);

	// variable for display error alert, in case of legal case number is existent
	vm.legal_case_number_exists = false;

	// store case_date from api call to be used later when "Search in portal" button is pushed
	vm.buffer_data = [];

	// let cmdate = vm.getInjection('cmdate');

	var REST = resource.init(vm.entity);
	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];

	if (page_identifier == 'edit') {
		vm.isNewRecord = false;
		$http.get("/legal_case/" + page_entity_id).then(function (response) {
			vm.data = response.data;
			if (vm.data.legal_case_follower) {
				angular.forEach(vm.data.legal_case_follower, function (v, i) {
					vm.data.legal_case_follower[i].id = v.user_id;
					vm.data.legal_case_follower[i].name = v.user.user_name;
				});
			}

			if (vm.data.legal_case_responsible) {
				angular.forEach(vm.data.legal_case_responsible, function (v, i) {
					vm.data.legal_case_responsible[i].id = v.user_id;
					vm.data.legal_case_responsible[i].name = v.user.user_name;
				});
			}
			vm.data.court_phase.case_number = vm.data.number;
			$scope.$broadcast('dataLoaded');
		});
	} else {
		vm.isNewRecord = true;
		vm.data = {};
		let defaultObjects = JSON.parse(vm.bladeParams.defaultObjects);
		vm.data = defaultObjects;

		vm.data.legal_case_responsible = [];
		vm.data.legal_case_responsible.push({
			id: API.user.user_id,
			name: API.user.full_name
		});

		// pre-fill case informations based on url data
		if (!vm.data.court_phase) {
			vm.data.court_phase = {};
		}
		vm.data.court_phase.case_number = vm.bladeParams.caseNumber ? vm.bladeParams.caseNumber : vm.data.court_phase.case_number;

		if (!vm.data.sys_legal_case_type) {
			vm.data.sys_legal_case_type = {};
		}
		vm.data.legal_case_type_id = vm.bladeParams.legalCaseTypeId ? vm.bladeParams.legalCaseTypeId : vm.data.legal_case_type_id;
		vm.data.sys_legal_case_type.legal_case_type_name = vm.bladeParams.legalCaseTypeName ? vm.bladeParams.legalCaseTypeName : vm.data.sys_legal_case_type.legal_case_type_name;

		if (!vm.data.court_phase) {
			vm.data.court_phase = {};
		}
		if (!vm.data.court_phase.case_stage) {
			vm.data.court_phase.case_stage = {};
		}
		vm.data.court_phase.case_stage_id = vm.bladeParams.caseStageId ? vm.bladeParams.caseStageId : vm.data.court_phase.case_stage_id;
		vm.data.court_phase.case_stage.legal_case_stage_name = vm.bladeParams.legalCaseStageName ? vm.bladeParams.legalCaseStageName : vm.data.court_phase.case_stage.legal_case_stage_name;

		if (!vm.data.case_status) {
			vm.data.case_status = {};
		}
		vm.data.status_id = vm.bladeParams.statusId ? vm.bladeParams.statusId : vm.data.status_id;
		vm.data.case_status.legal_case_status_name = vm.bladeParams.legalCaseStatusName ? vm.bladeParams.legalCaseStatusName : vm.data.case_status.legal_case_status_name;

		if (!vm.data.court_phase.region) {
			vm.data.court_phase.region = {};
		}
		vm.data.court_phase.region_id = vm.bladeParams.regionId ? vm.bladeParams.regionId : vm.data.court_phase.region_id;
		vm.data.court_phase.region.region_name = vm.bladeParams.regionName ? vm.bladeParams.regionName : vm.data.court_phase.region.region_name;

		if (!vm.data.court_phase.court) {
			vm.data.court_phase.court = {};
		}
		vm.data.court_phase.court_id = vm.bladeParams.courtId ? vm.bladeParams.courtId : vm.data.court_phase.court_id;
		vm.data.court_phase.court.name = vm.bladeParams.courtName ? vm.bladeParams.courtName : vm.data.court_phase.court.name;

	}

/*	vm.prepareToSave = function prepareToSave() {
			vm.save();
	};*/

	vm.newEntity = function newEntity(entity_type) {
		if (!vm.data) {
			vm.data = {};
		}

		if (!vm.data[entity_type]) {
			return vm.data[entity_type] = [{}];
		}

		return vm.data[entity_type].push({});
	};

	vm.deleteEntity = function deleteEntity($index, entity_type, id) {
		if (!id) {
			return vm.data[entity_type].splice($index, 1);
		}

		if (page_entity_id) {
			REST.delete({
				url: vm.entity + '/:id/' + entity_type + '/:entityId',
				params: {
					id: page_entity_id,
					entityId: id
				}
			}).then(function (data) {
				vm.data[entity_type].splice($index, 1);
			});
		}
	};

	// $scope.$watch('vm.data.court_phase.case_number', function (value) {
	// 	if (value) {
	// 		vm.data.court_phase.court_id = vm.bladeParams.courtId ? vm.bladeParams.courtId : null;
	// 		vm.data.court_phase.region_id = vm.bladeParams.regionId ? vm.bladeParams.regionId : null;
	//
	// 		vm.getCaseData();
	// 	}
	// });

	vm.quickAddCustomer = function () {
		vm.openModal({
			component: 'quickAddEntity',
			resolve: {
				params: function(){
					vm.data.fiscal_entity_type_id = 2
				},
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'customer';
				}
			}
		}).then(function (result) {
			vm.data.customer_id = result.customer_id;
			vm.data.customer = {
				customer_name: result.customer_name
			};
		});
	};

	vm.getCaseData = function() {
		KApp.block(".region_and_court");
		vm.data.court_phase.calling_just_buffer = true;
		vm.undefined_legal_case = false;
		vm.rest.post({
			url: "court_phase/callJustBuffer",
			data: vm.data.court_phase
		}).then(function (data) {
			if (data.case_number) {
				vm.buffer_data = data;

				if (data.court_id) {
					vm.data.court_phase.court_id = data.court_id;
					vm.data.court_phase.court = {
						name: data.court.name
					};
					vm.data.court_phase.region_id = data.region_id;
					vm.data.court_phase.region = {
						region_name: data.region.region_name
					};
					vm.data.court_phase.case_stage_id = data.stage_id;
					vm.data.court_phase.case_stage = {
						legal_case_stage_name: data.case_stage.legal_case_stage_name
					};
				}

				vm.data.court_phase.case_stage_id = data.case_stage_id;
				vm.data.court_phase.case_stage.legal_case_stage_name = data.case_stage.legal_case_stage_name;
			}
			KApp.unblock(".region_and_court");
			vm.data.court_phase.calling_just_buffer = false;
		}, function () {
			vm.data.court_phase.calling_just_buffer = false;
			vm.data.court_phase.calling_just_buffer_error = true;

		});
	};

	// get value from parties field, and split by <enter>, to construct a select with all parties
	$scope.$watch('vm.data.court_phase.parties', function(value) {
		if (value) {
			// remove blank lines from input
			let procValue = value.replace(/^\s*[\r\n]/gm, '');
			let parties = procValue.split("\n");
			vm.parties = [];
			// remove duplicates from parties
			parties.forEach(function(item) {
				if(vm.parties.indexOf(item) < 0) {
					vm.parties.push(item);
				}
			});
		}
	});

	// when change option from select, put the value from option into other_party field
	vm.setOtherParty = function (party) {
		vm.data.other_party = party;
	};

	vm.findCaseInPortal = function () {
		KApp.block("#container");
		vm.data.court_phase.calling_just_buffer = true;
		vm.rest.post({
			url: "court_phase/callJustBuffer",
			data: vm.data.court_phase
		}).then(function (data) {
			if (data.case_number) {
				vm.buffer_data = data;

				if (data.court_id) {
					vm.data.court_phase.court_id = data.court_id;
					vm.data.court_phase.court = {
						name: data.court.name
					};
					vm.data.court_phase.region_id = data.region_id;
					vm.data.court_phase.region = {
						region_name: data.region.region_name
					};
				}
				vm.data.court_phase = vm.buffer_data;
				if(data.portal_court_id === -1){
					vm.data.portal_sync_link = data.link;
				}else{
					vm.data.portal_sync_link = "http://portal.just.ro/" + vm.buffer_data.portal_court_id + "/SitePages/Rezultate_dosare.aspx?k=" + vm.buffer_data.case_number + "&a=%20MJmpIdInstitutie=" + vm.buffer_data.portal_court_id;
				}

				if (vm.buffer_data.sync_link) {
					vm.data.court_phase.sync_link = vm.buffer_data.sync_link;
				}
				vm.data.name = vm.buffer_data.object;
				vm.data.court_phase.calling_just_buffer = false;
				vm.data.court_phase.calling_just_buffer_error = false;
				vm.checkLegalCaseNumber(data.case_number);
				KApp.unblock("#container");
			} else {
				KApp.unblock("#container");
				vm.data.court_phase.calling_just_buffer = true;
				vm.data.court_phase.calling_just_buffer_error = true;
				if(data.hasError == true){
					vm.data.error_message_legal_case = data.message;
				}
			}
		}, function () {
			KApp.unblock("#container");
			vm.data.court_phase.calling_just_buffer = false;
			vm.data.court_phase.calling_just_buffer_error = true;
		});
	};

	vm.checkLegalCaseNumber = function (numberToCheck) {
		if (page_identifier != 'edit'){
			var REST = resource.init('legal_case');

			// check if field was filled with data
			if (vm.data.court_phase.case_number && vm.data.court_phase.case_number.length) {
				error = false;
			} else {
				error = true;
				vm.legal_case_number_exists = false;
				vm.number_is_valid = false;
				vm.undefined_legal_case = false;
			}

			// if field is not empty, make request
			if (!error && !vm.undefined_legal_case) {
				// variable for display success alert
				vm.number_is_valid = false;
				vm.undefined_legal_case = false;
				REST.list({
					params: {
						legal_case_number: numberToCheck,
					}
				}).then(function (res) {
					if (res.data.length) {
						vm.legal_case_number_exists = true;
						vm.number_is_valid = false;
						vm.undefined_legal_case = false;
					} else {
						vm.legal_case_number_exists = false;
						vm.number_is_valid = true;
						vm.undefined_legal_case = false;
					}
					if(vm.buffer_data.length === 0){
						vm.legal_case_number_exists = false;
						vm.number_is_valid = false;
						vm.undefined_legal_case = true;
					}
				});
			}
		}
	};

	vm.quickAddReference = function ($index, legal_case_project) {
		vm.openModal({
			templateUrl: "legal-case-project-reference-quick-add",
			controller: 'legalCaseProjectReferenceQuickAddCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					return {
						index: -1,
						legal_case_project: legal_case_project,
						legal_case: vm.data
					};
				},
				bladeParams: function () {
					return vm.bladeParams;
				}
			}
		}).then(function(response) {
			//autocomplete with new reference
			legal_case_project.reference = {};
			legal_case_project.reference_id = legal_case_project.reference.project_id = response.entity.project_id;
			legal_case_project.reference.project_name = response.entity.project_name;
		});
	};

	vm.validateSomeInputs = function () {
		vm.custom_errors = null;
		vm.has_error = false;

		if (vm.data.customer_id === null || typeof vm.data.customer_id == 'undefined') {
			$('[ng-model="vm.data.customer_id"] .select2-selection--single').css("border", "1px solid red");
			if (!vm.custom_errors) vm.custom_errors = [];
			vm.custom_errors.push(vm.trans('LANG.CUSTOMER_IS_REQUIRED'));
			vm.has_error = true;
		} else {
			$('[ng-model="vm.data.customer_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
		}

		if(vm.has_error){
			$('.custom_error_messages').css('display', 'block');
		} else {
			$('.custom_error_messages').css('display', 'none');
			vm.save();
		}
	}

	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		if (postData.court_phase) {
			postData.number = postData.court_phase.case_number;
			postData.court_phase.is_current = true;
		}

		if (!postData.status_id) {
			postData.status_id = 2;
			postData.case_status = {legal_case_status_name: 'IN_PROGRESS'};
		}
		// used for validations: if action is change_status, don't validate, just change status, else make validations
		// postData.action = 'add_edit';
		if (postData.court_phase) {
			postData.court_phase.sync_link = (postData.court_phase.sync_link ? postData.court_phase.sync_link : postData.portal_sync_link);
		}
		return postData;
	};

	vm.syncCourtHearing = function (legal_case_id) {
		var REST = resource.init('court_hearing/sync');
		REST.get({
			params: {
				legal_case_id: legal_case_id,
				fresh: new Date()
			}
		});
	};

	vm.save = function () {
		this.errors = undefined;
		let params = {data: this.prepareDataToSave.apply(this, arguments)};
		if (!this.isNewRecord) params.id = params.data['legal_case_id'];
		let action = this.isNewRecord ? "create" : "update";

		KApp.block(this.element);
		this.rest[action](params).then(response => {
			KApp.unblock(this.element);
			if (response.status || response.status == undefined) {
				if (this.saveCallback) this.saveCallback(response);
				else {
					let legal_case_id = (params.data['legal_case_id'] ? params.data['legal_case_id'] : response.data['legal_case_id']);
					if(action == "create") vm.syncCourtHearing(legal_case_id);
					window.location = "/legal_case/" + legal_case_id;
				}
			} else this.showErrorMessages(response);
		});
	};
	return vm;
});
