kv.controller('userVacationViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'vacation_id';
    vm.entity = 'user_vacation';
    vm.entityType = 'user_vacation';
    vm.groups = {};

    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];

    vm.entityId = page_entity_id;
    vm.commentsConfig = {
        entity_type: vm.entity
    };

    vm.goBack = function () {
        $state.go("app.user_vacation");
    };


    vm.edit = function () {
        $state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
    };


    /**
     * change status on vacation
     */
    vm.changeStatus = function (newStatus) {
        KApp.block('.actions-section');

        let rest = vm.getInjection('resource').init('user_vacation');
        rest.update({
            url: 'user_vacation/' + page_entity_id,
            params: {
                action: newStatus
            }
        })
            .then((response) => {
                toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
                if (!response.status) vm.alert('ALERT', vm.trans('Error changing status'));
                window.location.href = "/user_vacation/" + page_entity_id;
                KApp.unblock('.actions-section');
            })
            .catch(err => {
                KApp.unblock('.actions-section');
                console.log('Error changing status');
                console.log(err); // bum related error
            });
    };

    return vm;
});
