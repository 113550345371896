kv.controller('ConflictCheckReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var exact_match = vm.bladeParams.exactmatch;
    var keyword = vm.bladeParams.keyword;
    
    vm.addComment = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'comment',
            controller: function($uibModalInstance, resource) {
                var vm = this;

                var REST = resource.init('conflict_check_log');
                vm.data = {
                    is_conflict: true,
                    exact_match: exact_match,
                    keyword: keyword
                }
                vm.action = {
                    save: save,
                    cancel: cancel,
                };


                function save() {
                    var postData = angular.copy(vm.data);
                    if(postData.is_conflict == true) {
                        if (postData.comment && postData.comment.length) {
                            $('[ng-model="vm.data.comment"]').css("border", "1px solid #c2cad8");
                        } else {
                            $('[ng-model="vm.data.comment"]').css("border", "1px solid red");
                        }
                        
                        if ((postData.comment && postData.comment.length)) {
                            vm.error = false;
                        } else {
                            vm.error = true;
                            return false;
                        }                            
                    }

                    vm.data.log_type = 'USER';

                    REST.create({
                        data: vm.data
                    }).then(function (response) {
                        $uibModalInstance.close({response: response, index: -1});
                        toastr.success($filter('trans')('DATA_HAS_BEEN_SAVED_WITH_SUCCESS'));
                    });
                }

                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                };
            },
            controllerAs: 'vm',
            size: 'lg'
        });
    }

    $(document).ready(function() {
        $('#table_conflicts').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
            addClass('pull-right');
    });

    $(document).ready(function() {
        $('#table_timesheet').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
            addClass('pull-right');
    });

    // $(document).ready(function() {
    //     $('#table_invoice').DataTable({
    //         "paging":   false,
    //         "ordering": true,
    //         "info":     false,
    //         "bLengthChange": false,
    //         searchHighlight: true
    //     });
    //     $('.dataTables_filter input[type="search"]').
    //         attr('placeholder','Search in table...').
    //         removeClass('input-small').
    //         css({'width':'350px','display':'inline-block'});

    //     $('.dataTables_filter').
    //         addClass('pull-right');
    // });

    $(document).ready(function() {
        $('#table_project').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
            addClass('pull-right');
    });

    $(document).ready(function() {
        $('#table_reference').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
            addClass('pull-right');
    });

    $(document).ready(function() {
       $('#table_contact').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
            addClass('pull-right');
    });

    $(document).ready(function() {
        $('#table_customer').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
            addClass('pull-right');
    });

    // $(document).ready(function() {
    //     $('#table_employee_account').DataTable({
    //         "paging":   false,
    //         "ordering": true,
    //         "info":     false,
    //         "bLengthChange": false,
    //         searchHighlight: true
    //     });
    //     $('.dataTables_filter input[type="search"]').
    //         attr('placeholder','Search in table...').
    //         removeClass('input-small').
    //         css({'width':'350px','display':'inline-block'});

    //     $('.dataTables_filter').
    //         addClass('pull-right');
    // });

    $(document).ready(function() {
        $('#table_comment').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
            addClass('pull-right');
    });
    
    return vm;
});
