kv.controller('ExpenseApprovalListCtrl', function ($scope, $element, $attrs, $injector, $http, resource, $uibModal, search, Session, validator, cmdate, $filter, $rootScope, $timeout, API) {
	let vm = new baseCtrl($scope, $element, $attrs, $injector);
	vm.listCtrl = $scope.$parent.vm;
	vm.primaryKey = 'expense_id';
	vm.entity = 'expense-approval';
	vm.countLines = 0;
	vm.getInfinitScroll({
		search: {
			date_from: moment().startOf('year').format(vm.API.DATE_FORMAT),
		},
		callback: function (response) {
			vm.countLines = 0;
			if (!response.status) return;
			vm.countLines = (response.data.totals ? response.data.totals.total_lines_affected : response.data.paginator.total);
			if (!response.data.totals) return;
			let totals = angular.copy(response.data.totals);
			vm.showTotals(totals);
		}
	});

	vm.quickActions = function (action) {
		vm.confirm('confirm', vm.trans('LANG.TOTAL_AFFECTED_ENTITIES') + ' ' + vm.countLines).then(response => {
			if (response != 'ok') return;
			let postData = angular.copy(vm.infinitScroll.search);
			postData.date_from = cmdate.toServer(postData.date_from);
			postData.date_to = cmdate.toServer(postData.date_to);
			postData.action = action;
			vm.infinitScroll.quickActionsLoading = true;
			vm.rest.update({
				url: vm.entity + '/quickActions',
				data: postData
			}).then(function (data) {
				vm.infinitScroll.items.forEach(item => {
					if (data[item.expense_id]) {
						angular.extend(item, data[item.expense_id]);
					}
				});
				vm.infinitScroll.quickActionsLoading = false;
			});
		});
	};

	vm.use_industrial_minutes = API.USE_INDUSTRIAL_MINUTES;
	vm.reporting_currency = API.REPORTING_CURRENCY;

	vm.isInternalProject = function (entity) {
		return entity.customer_id == null;
	};

	vm.showTotals = function (totals) {
		let string_total_amount = "";
		if (!totals.total_sum) totals.total_sum = 0;
		let total_value_rc = $filter("currency")(totals.total_sum, vm.reporting_currency);
		if(totals.totals){
			angular.forEach(totals.totals, function (v, i) {
				total_amount = v.total_amount_sum.concat(" ", v.currency);
				if(i < totals.totals.length - 1){
					string_total_amount = string_total_amount.concat(total_amount, "<br/> ");
				}else{
					string_total_amount = string_total_amount.concat(total_amount);
				}
			});
		}
		$(".__total_value_rc").html(total_value_rc);
		$(document).ready(function(){
			$("#total_amount").popover({
				title: total_value_rc,
				content: string_total_amount,
				trigger: 'hover',
				html: true,
				placement: 'top',
				container: 'body'
			});
		});
	};

	vm.addEdit = function (entity, $index, duplicate) {
		vm.openModal({
			templateUrl: "expense-edit",
			size: 'lg',
			controller: "ExpenseEditCtrl",
			controllerAs: "vm",
			resolve: {
				params: {entity: entity, index: $index, duplicate: duplicate},
			}
		}).then(response => {
			if (!response) return;
			if (response.index == -1) vm.infinitScroll.items.unshift(response.entity);
			else angular.extend(vm.infinitScroll.items[response.index], response.entity);
		});
	};

	vm.moveEntities = function () {
		vm.openModal({
			templateUrl: 'expense_approval-list-move',
			controller: ExpenseApprovalListMoveCtrl,
			size: 'md',
			resolve: {
				data: function () {
					return {
						searchList: vm.infinitScroll.search,
						countLines: vm.countLines
					};
				}
			},
			backdrop: 'static',
			keyboard: false
		}).then(response => {
			if (response == "ok") vm.infinitScroll.search.refresh++;
		});
	};

	vm.goToTimesheetApproval = function () {
		let url = "/timesheet_approval?clear_filters=1&aggregate_status_type=BILLABLE_EFFORT";
		if(vm.infinitScroll.search.date_from)
			url += "&date_from=" + vm.infinitScroll.search.date_from;
		if(vm.infinitScroll.search.date_to)
			url += "&date_to=" + vm.infinitScroll.search.date_to;
		if(vm.infinitScroll.search.customer_id)
			url += "&customer_id=" + vm.infinitScroll.search.customer_id + "&customer_name=" + vm.infinitScroll.search.customer_id_default;
		if(vm.infinitScroll.search.project_id)
			url += "&project_id=" + vm.infinitScroll.search.project_id + "&project_name=" + vm.infinitScroll.search.project_id_default;
		if(vm.infinitScroll.search.reference_id)
			url += "&reference_id=" + vm.infinitScroll.search.reference_id + "&reference_name=" + vm.infinitScroll.search.reference_id_default;
		if(vm.infinitScroll.search.user_id)
			url += "&user_id=" + vm.infinitScroll.search.user_id + "&user_name=" + vm.infinitScroll.search.user_id_default;
		if(vm.infinitScroll.search.responsible_id)
			url += "&responsible_id=" + vm.infinitScroll.search.responsible_id + "&responsible_name=" + vm.infinitScroll.search.responsible_id_default;
		if(vm.infinitScroll.search.internal_project_id)
			url += "&internal_project_id=" + vm.infinitScroll.search.internal_project_id + "&internal_project_name=" + vm.infinitScroll.search.internal_project_id_default;
		window.open (url, '_blank');
	}
	return vm;
});
