function TimesheetApprovalEditCtrl($uibModalInstance, data, $injector, resource, $filter, cmdate, $scope, $rootScope) {
	var vm = new modalCtrl($uibModalInstance, $injector, 'timesheet_approval', 'timesheet_id', $scope, data, 'timesheet_approval-edit-params');
	if ($rootScope.lookup && $rootScope.lookup.commodity_activity) delete $rootScope.lookup.commodity_activity;
	vm.modal.rendered.then(() => {
		if (!vm.dataHasLoaded) KApp.block('.modal-content');
		if (!vm.isNewRecord || data.duplicate) {
			vm.rest.get({
				id: data.result.timesheet_id
			}).then(res => {
				vm.modalData = {
					form_action: data.form_action,
					form_location: data.form_location
				};
				// divide input minutes by 60
				vm.data = kvUtils.transformEffortsInHours(res);
				vm.readonly = res.invoice_id !== null;
				if (data.duplicate) {
					data.index = -1;
					vm.data.source_timesheet_id = vm.data.timesheet_id;
					delete vm.data.timesheet_id;
					vm.data.status_id = 1;
					vm.data.duplicate = 1;
					delete vm.data.timesheet_ext.timesheet_ext_id;
					delete vm.data.user_id;
					delete vm.data.user;
				}

				if (vm.data.customer_id == null) {
					vm.data.is_internal_hours = true;
				}
				if (data.timesheetFormDifficulty == 1) {
					vm.data.dnb_effort = vm.data.dnb_effort + vm.data.nb_effort + vm.data.nc_effort;
					vm.data.nb_effort = 0;
					vm.data.nc_effort = 0;

				} else if (data.timesheetFormDifficulty == 2) {
					vm.data.eligible_effort = vm.data.total_effort - vm.data.nb_effort - vm.data.nc_effort;
					vm.data.dnb_effort = vm.data.eligible_effort - vm.data.blb_effort;
					vm.data.nc_effort = 0;
				} else {
					//nothing to do
				}
				vm.data.original_project_id = vm.data.project_id;
				vm.data.original_reference_id = vm.data.reference_id;

				vm.checkHoursFormat(true);
				vm.dataHasLoaded = true;
				KApp.unblock('.modal-content');
				$scope.$broadcast('dataLoaded');
			});
		}
	});
	vm.isNewRecord = data.index == -1;
	vm.dataHasLoaded = vm.isNewRecord;

	// for duplication we send index 999 and mark as new record (in order to use method POST)
	if (data.index == -999) {
		vm.isNewRecord = data.duplicate = 1;
	}
	// se foloseste in html pentru a afisa campul de activitati
	vm.selectedProjectIsCommodity = false;

	// se foloseste in html pentru a afisa campul de court hearing
	vm.selectedProjectHaveCourtHearing = false;

	// load sys_timesheet_status from DB and store in VM
	vm.rest.getArray({
		"url": "/filter/sys_timesheet_status/all_sys_timesheet_status"
	}).then(function (res) {
		vm.sys_timesheet_status = res;
	});

	// if action = update/duplicate


	vm.changeStatus = function (status) {
		vm.data.status_id = status.id;
		vm.data.sys_timesheet_status.status_id = status.id;
		vm.data.sys_timesheet_status.status_name = status.name;
		vm.save();
	};
	vm.checkHoursFormat = function () {
		return kvUtils.checkHoursFormat.call(vm, vm.data);
	};
	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		postData.form_action = vm.modalData.form_action;
		postData.form_location = vm.modalData.form_location;
		// transform human effort to minutes
		postData.total_effort = kvUtils.humanTotalEffortToMinutes(postData.total_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.input_effort = kvUtils.humanTotalEffortToMinutes(postData.input_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.eligible_effort = (postData.eligible_effort ? kvUtils.humanTotalEffortToMinutes(postData.eligible_effort, vm.API.USE_INDUSTRIAL_MINUTES) : null);
		postData.blb_effort = kvUtils.humanTotalEffortToMinutes(postData.blb_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.dnb_effort = kvUtils.humanTotalEffortToMinutes(postData.dnb_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.nc_effort = kvUtils.humanTotalEffortToMinutes(postData.nc_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.nb_effort = kvUtils.humanTotalEffortToMinutes(postData.nb_effort, vm.API.USE_INDUSTRIAL_MINUTES);


		if (data.timesheetFormDifficulty == 1) {
			if (vm.data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
				postData.dnb_effort = postData.total_effort - postData.blb_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
			}
		} else if (data.timesheetFormDifficulty == 2) {
			if (vm.data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
				postData.dnb_effort = postData.eligible_effort - postData.blb_effort;
				postData.nb_effort = postData.total_effort - postData.eligible_effort;
				postData.nc_effort = 0;
				postData.total_effort = postData.blb_effort + postData.dnb_effort + postData.nb_effort + postData.nc_effort;
			}
		} else {
			if (vm.data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
				postData.total_effort = postData.blb_effort + postData.dnb_effort + postData.nb_effort + postData.nc_effort;
			}
		}
		if (vm.data.is_internal_hours) {
			postData.customer_id = null;
			postData.project_id = null;
			postData.reference_id = null;
		} else {
			postData.internal_project_id = null;
		}

		if (!postData.timesheet_ext) {
			vm.postData.timesheet_ext = {
				timesheet_id: null
			};
		}

		delete postData.input_effort;

		if (vm.isNewRecord || vm.data.duplicate == 1) {
			// daca se duplica se salveaza fortat ca revizuit sa apare dupa refresh
			// in lista (lista fiind filtrata pe timpi revizuiti)
			if (postData.duplicate == 1) {
				postData.status_id = 2;
			}
		}

		if(!postData.user_id) postData.user_id = postData.invoice_user_id;

		return postData;
	};

	// in momentul in care se schimba valoarea din project_id se seteaza vm.selectedProjectIsCommodity
	// in functie de valoarea campului is_commodity din project
	// se sterge din variabila globala $rootScope.lookup.all_project_with_customer
	// (daca nu, apare un bug la adaugare, in momentul in care project_id este null dar lookup-ul are informatii din vechea adaugare)
	$scope.$watch("vm.data.project_id", function (value) {
		if (value && $rootScope.lookup && $rootScope.lookup.project && $rootScope.lookup.project.all_project_with_customer) {
			vm.selectedProjectIsCommodity = $rootScope.lookup.project.all_project_with_customer.is_commodity;
			delete $rootScope.lookup.project.all_project_with_customer;
		}
		vm.selectedProjectHaveCourtHearing = false;
	});

	// in momentul in care se schimba valoarea din project_id sau reference_id se seteaza vm.selectedProjectHaveCourtHearing
	// daca proiectul este legat de un dosar sau referinta
	// se sterge din variabila globala $rootScope.lookup.all_project_with_customer
	// (daca nu, apare un bug la adaugare, in momentul in care project_id este null dar lookup-ul are informatii din vechea adaugare)
	$scope.$watch("vm.data.reference_id", function (value) {
		if (value) {
			vm.rest.getArray({
				url: data.bladeParams.filtersAction,
				params: {
					project_id: vm.data.project_id,
					reference_id: vm.data.reference_id,
				}
			}).then(function (data) {
				vm.selectedProjectHaveCourtHearing = !!data.length;
			});
		}
	});

	// se face watch pe commodity_activity_id pentru a calcula inputurile de ore
	$scope.$watch("vm.data.commodity_activity_id", function (value) {
		if (value && $rootScope.lookup && $rootScope.lookup.commodity_activity) {
			var commodity_activity = $rootScope.lookup.commodity_activity.all_commodity_activity;
			vm.data.blb_effort = commodity_activity.effort / 60;
			vm.data.comments = commodity_activity.description;
			vm.checkHoursFormat(false);
		}
	});
	return vm;
}
