kv.controller('courtHearingAddCtrl', function ($uibModalInstance, params, $http, saveCallback, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'court_hearing', 'court_hearing_id', $scope, params);

	vm.modal.rendered.then(() => {
		if (!vm.dataHasLoaded) KApp.block('.modal-content');
	});
	vm.data = {};

	if(params.legal_case_id) {
		vm.isNewRecord = true;
		$http.get("/legal_case/" + params.legal_case_id)
			.then(function (response) {
				vm.legal_case = response.data;
				vm.dataHasLoaded = true;
				KApp.unblock('.modal-content');

				vm.data.legal_case_id = params.legal_case_id;
				vm.data.court_phase_id = vm.legal_case.current_court_phase_id;
				vm.data.court_id = vm.legal_case.court_phase.sys_court.court_id;
			});
	}

	vm.prepareDataToSave = function () {
		let dataToSave = angular.copy(vm.data);
		return dataToSave;
	};

	vm.saveCallback = saveCallback;
	return vm;
});
