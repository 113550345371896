kv.controller('invoiceEmailTemplateEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'email_template_id';
	vm.entity = 'invoice_email_template';
	vm.rest = vm.getInjection('resource').init(vm.entity);


	vm.ckeditor = {
		height: 300,
		toolbarGroups: [
			{name: 'clipboard', groups: ['clipboard', 'undo']},
			{
				name: 'editing',
				// groups: ['find', 'selection', 'spellchecker']
			},
			// {name: 'links'},
			// { name: 'insert' },
			// { name: 'forms' },
			// { name: 'tools' },
			// { name: 'document', groups: ['mode', 'document', 'doctools'] },
			// { name: 'others' },
			// { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
			// {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi']},
			{name: 'font'},
			{name: 'styles'},
			{name: 'colors'}
		]
	};

	let url_segments = window.location.pathname.split( '/' );
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];
	if(page_identifier == 'edit') {
		vm.isNewRecord = false;
		$http.get("/invoice_email_template/" + page_entity_id).then(function(response) {
			vm.data = response.data;
		});
	} else {
		vm.isNewRecord = true;
		vm.data = {};
		vm.data.reply_to = vm.API.user.email;
		vm.data.body = "Write your email content here.. <br><br><br> Signature";
	}

	// overwrite function from baseCtrl, for redirect to list, not to view
	vm.saveCallback = function () {
		window.location = "/" + vm.entity;
	};

	return vm;
});
