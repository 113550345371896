kv.controller('taskListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'task_id';
	vm.entity = 'task';

	vm.count = {
		total: 0,
		showing: 0
	};

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let params = queryParamToJson();

	vm.getInfinitScroll({
		params: {
			action: 'task'
		},
		url: "task"
	});

	Object.keys(params).forEach(attr => {
		if(attr !== '' && params[attr] !== null){
			vm.infinitScroll.search[attr] = params[attr];
		}
	});

	if(params.done) {
		vm.infinitScroll.search.done = params.done;
	}else{
		vm.infinitScroll.search.done = "0";
	}
	if(params.user_id !== undefined) {
		if(params.user_id != null) {
			vm.infinitScroll.search.user_id = params.user_id;
		}else{
			vm.infinitScroll.search.user_id = (vm.infinitScroll.search.user_id ? vm.infinitScroll.search.user_id : API.user.user_id);
		}
	}
	if(params.user_name != null) {
		vm.infinitScroll.search.user_name = params.user_name;
	}else{
		vm.infinitScroll.search.user_name = (vm.infinitScroll.search.user_name ? vm.infinitScroll.search.user_name : API.user.full_name);

	}

	$scope.CurrentDate = new Date();

	vm.loadTasks = function loadTasks() {
		vm.infinitScroll.search.freshData = new Date().getTime();
	};

	/**
	 * toggle task status
	 * @param entity
	 * @param event
	 */
	vm.changeTaskStatus = function (entity, event){
		if (event.target.tagName !== 'INPUT') return;
		entity.done = !entity.done;
		var REST = resource.init("task");
		REST.update({
			id: entity.task_id,
			params: {
				action: (entity.done ? 'markToDo' : 'markCompleted')
			}
		}).then(function () {
			vm.infinitScroll.search.freshData = new Date().getTime();
		});
	}

	/**
	 * add or edit task
	 * @param entity
	 * @param index
	 */
	vm.addEditTask = function (entity, index) {
		vm.openModal({
			templateUrl: "task-edit",
			size: 'lg',
			controller: "taskEditCtrl",
			controllerAs: "vm",
			backdrop: 'static',
			keyboard: false,
			resolve: {
				params: function () {
					if (!entity) {
						entity = {
							task_id: page_entity_id
						};
					}
					return entity;
				}
			}
		}).then(response => {
			vm.infinitScroll.search.freshData = new Date().getTime();
		});
	};

	/**
	 * delete task
	 * @param $index
	 */
	vm.delete = function deleteEntity($index) {
		$http.delete("task/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
			vm.infinitScroll.items.splice($index, 1);
		});
	};

	//let params = queryParamToJson();
	if (params.create_from) {
		vm.addEditTask(null, -1);
	}

	if (params.editTaskId) {
		var REST = resource.init("task");
		REST.get({
			id: params.editTaskId,
		}).then(function (entity) {
			vm.addEditTask(entity);
		});
	}

	return vm;
});

