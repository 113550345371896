kv.controller('notificationEditCtrl', function ($uibModalInstance, params, $scope, $http, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'notification', 'notification_id', $scope, params);

	vm.modal.rendered.then(() => {
		if (!vm.dataHasLoaded) KApp.block('.modal-content');
	});

	vm.isNewRecord = false;
	vm.data = {};
	vm.data.entity_id = params.activity_id;

	if (params.index !== undefined || params.index == -1) {
		vm.data = params.notification;
		vm.data.activity_type_id = params.activity_type_id;
		vm.data.entity_type = params.entity_type; // a nu se sterse, suprascrie entity_type din get
		vm.dataHasLoaded = true;
		KApp.unblock('.modal-content');
	} else {
		vm.isNewRecord = true;
		vm.dataHasLoaded = true;
		vm.data.entity_type = params.entity_type;
		vm.data.activity_type_id = params.activity_type_id;
		vm.data.duration = 1;
		vm.data.duration_unit = "h";
		vm.data.notification_type = "before";
		KApp.unblock('.modal-content');
	}

	return vm;
});
