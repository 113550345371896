kv.controller('CustomerAuditReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    // var data = JSON.parse(vm.bladeParams.data);
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    vm.isLoading = true;

    return vm;
});
