kv.controller('sysLedesActivityCodeListCtrl', function ($element, $attrs, $scope, $injector) {
    return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
        primaryKey: "ledes_activity_id",
        entity: "sys_ledes_activity_code",
        modal: {
            templateUrl: "sys_ledes_activity_code-edit",
            controller: "sysLedesActivityCodeEditCtrl"
        }
    });
});
