kv.controller('LegalCaseSearchInPortalCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'legal_case_id';
    vm.entity = 'legal_case';
    vm.filter.value = "";
    vm.rest = vm.getInjection('resource').init('legal_case');
    vm.data=[];

    vm.getInfinitScroll({
        extraData: {totals: 'totals'},
        fixedHead: {
            top: 75,
            zIndex: 90
        },
        search: {
            opened_on_from: moment().startOf('month').format(vm.API.DATE_FORMAT),
            opened_on_to: moment(new Date()).format(vm.API.DATE_FORMAT)
        }
    });
    vm.findCaseInPortal = function () {
        vm.validateSomeInputs();

        // searching in portal is made only if the period is selected
        if(vm.has_error === false){
            KApp.block(".details");
            vm.rest.get({
                url: "legal_case/searchLegalCaseInPortal",
                params: {
                    action: 'searchLegalCaseInPortal',
                    opened_on_from: vm.infinitScroll.search.opened_on_from ? vm.infinitScroll.search.opened_on_from : null,
                    opened_on_to: vm.infinitScroll.search.opened_on_to ? vm.infinitScroll.search.opened_on_to : null,
                    court_id: vm.infinitScroll.search.court_id ? vm.infinitScroll.search.court_id : null,
                    object: vm.infinitScroll.search.object ? vm.infinitScroll.search.object : null,
                    other_party: vm.infinitScroll.search.other_party ? vm.infinitScroll.search.other_party : null,
                    section: vm.infinitScroll.search.section ? vm.infinitScroll.search.section : null,
                }

            }).then(response => {
                if (response) {
                    KApp.unblock(".details");
                    // cast to object in order to get entity on edit(response is an array in the first place)
                    vm.data = response;
                }
            });
        }
    };

    vm.editLegalCase = function (entity) {
        vm.openModal({
            templateUrl: "legal_case_search_in_portal-edit",
            controller: 'LegalCaseSearchInPortalEditCtrl',
            controllerAs: "vm",
            size: 'lg',
            resolve: {
                params: function () {
                    return {
                        entity: entity,
                    };
                }
            }
        }).then(response => {
            if (response) {
                entity = response.entity;
            }
        });
    };

    vm.validateSomeInputs = function () {
        vm.custom_errors = null;
        vm.has_error = false;

        if(!vm.infinitScroll.search.court_id && !vm.infinitScroll.search.other_party){
            $('[ng-model="vm.infinitScroll.search.court_id"] .select2-selection--single').css("border", "1px solid red");
            $('[ng-model="vm.infinitScroll.search.other_party"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.SELECT_MINUMUN_NUMBER_OF_FILTERS'));
            vm.has_error = true;
        }

        if (!vm.infinitScroll.search.opened_on_from) {
            $('[ng-model="vm.infinitScroll.search.opened_on_from"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.DATE_FROM_ERROR'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.infinitScroll.search.opened_on_to"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }

        if (!vm.infinitScroll.search.opened_on_to) {
            $('[ng-model="vm.infinitScroll.search.opened_on_to"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.DATE_TO_ERROR'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.infinitScroll.search.opened_on_to"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }
        if (vm.has_error) {
            $('.custom_error_messages').css('display', 'block');
        } else {
            $('.custom_error_messages').css('display', 'none');
        }
    };

    return vm;
});

