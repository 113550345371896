kv.controller('ExpenseImportEditCtrl', function ($uibModalInstance, params, $scope, $injector) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'expense', 'expense_id', $scope, params);
    vm.data = angular.copy(params.entity);
    vm.columns = params.columns;
    vm.reversedColumns = params.reversedColumns;
    vm.validate = function () {
        let postData = {};
        // this time, processed became source ;
        if (vm.data.processed.user) postData[vm.reversedColumns.user_id] = vm.data.processed.user_id;
        if (vm.data.processed.user) postData[vm.reversedColumns.user_name] = vm.data.processed.user.full_name ? vm.data.processed.user.full_name : vm.data.processed.user;
        if (vm.data.processed.project) postData[vm.reversedColumns.project_number] = vm.data.processed.project.project_number ? vm.data.processed.project.project_number : vm.data.processed.project;
        if (vm.data.processed.project) postData.project_id = vm.data.processed.project_id;
        if (vm.data.processed.internal_project) postData[vm.reversedColumns.project_code] = vm.data.processed.internal_project.project_code ? vm.data.processed.internal_project.project_code : vm.data.processed.internal_project.project_code;
        if (vm.data.processed.internal_project) postData.internal_project_id = vm.data.processed.internal_project_id;
        if (vm.data.processed.date) postData[vm.reversedColumns.date] = vm.data.processed.date;
        if (vm.data.processed.quantity) postData[vm.reversedColumns.quantity] = vm.data.processed.quantity;

        vm.rest.post({
            url:"expense/checkImportRecord",
            data: postData
        }).then(response=>{
            if (response.status)  {
                if (response.data.hasError) vm.data = response.data;
                else vm.close({entity: response.data});
            }
        });
    };
    vm.changeSource = function (fieldName) {
        switch (fieldName) {
            case 'customer_name':
                if (vm.data.processed.customer) vm.data.processed.customer_id = vm.data.processed.customer.customer_id;
                vm.data.processed.project = undefined;
                vm.data.processed.project_id = undefined;
                vm.data.processed.reference = undefined;
                vm.data.processed.reference_id = undefined;
                break;
            case 'court':
                break;
        }
    };
    return vm;
});

