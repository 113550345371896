kv.controller('activityViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'activity_id';
	vm.entity = 'activity';
	vm.entityType = 'activity';
	vm.entityId = vm.bladeParams.activityId;
	vm.mfiles = 'http://www.google.ro';
	vm.data = {
		is_linked_to_mfiles: JSON.parse(vm.bladeParams.isLinkedToMfiles),
		activity_type_id : JSON.parse(vm.bladeParams.activityTypeId)
	};

	vm.commentsConfig = {
		entity_type: vm.entity,
		entity_id: vm.entityId
	};

	vm.widgets = {
		template: 'offers-in-order',
		url: API.URL + "dashboard/ActivityTypeDistribution"
	};
	vm.widgetsb = {
		template: 'offer-by-type',
		url: API.URL + "dashboard/ActivityTypeDistribution"
	};
	vm.widgetsc = {
		template: 'last-offers',
		url: API.URL + "dashboard/ActivityTypeDistribution"
	};

	vm.futureActivities = {
		template: 'activities',
		url: API.URL + "filter/activity/activities_with_contact",
		filter: {
			activity_id: vm.entityId,
			range: 'future',
		},
		title: 'Activitati si proiecte curente',
	};

	vm.past7DaysActivities = {
		template: 'activities',
		url: API.URL + "filter/activity/activities_with_contact",
		filter: {
			activity_id: vm.entityId,
			range: 'last 7 days',
		},
		title: 'Istoric activitati, ultimele 7 zile',
	};

	var REST = resource.init(vm.entity);

	vm.editEntity = function () {
		$state.go('app.' + vm.entity + '.edit', {id: vm.data[vm.primaryKey], data: vm.data});
	};

	KApp.block('#loader');
	vm.page_loaded = false;
	vm.getNotification = function () {
		vm.rest = resource.init('notification');
		vm.rest.get({
			url: "notification/list",
			params: {
				activity_id:vm.entityId,
				activity_type_id: vm.data.activity_type_id
			}
		}).then(function (res) {
			vm.notifications = res.data;
			KApp.unblock('#loader');
			vm.page_loaded = true;
		});
	};

	vm.getNotification();

	vm.addEditNotification = function ($index) {
		vm.openModal({
			templateUrl: "notification-edit",
			controller: 'notificationEditCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: {
					index: $index,
					notification: vm.notifications[$index],
					activity_id: vm.entityId,
					entity_type: vm.entity,
					activity_type_id: vm.data.activity_type_id

				}
			}
		}).then(function(){
			vm.getNotification();
		});
	};

	vm.deleteNotification =  function($index){
		vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then(response => {
			if (response !== 'ok') return;
			$http.delete("/notification/" + vm.notifications[$index]["notification_id"]).then(function () {
				vm.notifications.splice($index, 1);
			});
		});
	};

	vm.changeStatus = function (business_status_id) {
		REST.get({
			url: vm.entity + '/' + vm.entityId + '/status/' + business_status_id,
			params: {
				activity_type: vm.data.activity_type_id
			}
		}).then(function (data) {
			window.location = "/activity/" + vm.entityId;
			// if(data.status === true){
			// 	window.location = "/activity/" + vm.entityId;
			// }else{
			// 	toastr.error(vm.trans('ACTION_REJECTED'));
			// }
		});
	};


	vm.setLinkedToMfiles = function(activity_id) {
		vm.openModal({
			animation: true,
			component: 'modalConfirm',
			resolve: {
				title: function () {
					return $filter('trans')('LANG.ARE_YOU_SURE');
				},
				content: function () {
					return $filter('trans')('LANG.LEGAL_CASE_LINK_TO_MFILES_DESC') + ' - ' + vm.bladeParams.hashByLength;
				}
			}
		}).then(function () {
			REST.update({
				url: "activity/setLinkedToMfiles/" + activity_id,
			}).then(function (data) {
				vm.data.is_linked_to_mfiles = true;
			});
		});
	};

	return vm;
});

