kv.controller('addPaymentPromiseCtrl', function ($uibModalInstance, params, resource, $scope, $injector) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'payment_promises', 'id', $scope, params);
    vm.modal.rendered.then(() => {
        if (!vm.dataHasLoaded) KApp.block('.modal-content');
    });
    vm.data = {};
    vm.dataHasLoaded = false;
    vm.isNewRecord = true;
    vm.data.invoice_id = angular.copy(params.invoice_id);
    vm.dataHasLoaded = true;

    vm.entity = 'collection_logs';
    var REST = resource.init(vm.entity);


    vm.savePayment = function () {
        if(vm.data.pause_collection_until_date == null){
            vm.data.pause_collection_until_date = 0;
        }
        let postData = angular.copy(vm.data);
        REST.post({
            url: 'invoice/' + vm.data.invoice_id + '/collection/actions/new_payment_promise',
            params: {
                payment_promise: postData
            }
        }).then(function (response) {
            if(response.status == 'success'){
                toastr.success(response.message);
            }else{
                toastr.error('Error creating payment promise');
            }
            vm.close();
        });
    };

    return vm;
});
