kv.controller('masterPracticeListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "master_practice_id",
		entity: "master_practice",
		modal: {
			templateUrl: "master_practice-edit",
			controller: "masterPracticeEditCtrl"
		}
	});
});
