kv.controller('InvoicesByCriteriaReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var data = JSON.parse(vm.bladeParams.data);
    let initParams = kvUtils.getAllQueryParams();
    let allowSorting = true;

    if (initParams) {
        if (initParams.generate) {
            $('[href="#results-tab"]').tab("show");
        }
        if (initParams.group_by_detail) {
            allowSorting = false;
        }
    }

    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": allowSorting,
            "info":     false,
            "bLengthChange": false,
            "columnDefs": [ {
                "targets": 1,
                "orderable": false
            } ]
        });
        $('.dataTables_filter input[type="search"]').
        attr('placeholder','Search in table...').
        removeClass('input-small').
        addClass('no-print').
        css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
        addClass('pull-right').addClass('no-print');
    });


    vm.drillDown = function(group_by, agg_id, agg_name = null) {
        let params = angular.copy(initParams);
        params[params.group_by + "_id"] = agg_id;
        params[params.group_by] = agg_name;
        params.group_by = group_by;

        let url = window.location.origin + window.location.pathname + "?" + $filter("objToParams")(params);
        window.open(url);
    }

    vm.isLoading = true;

    var chart_categories = data[2];
    var series = data[1];
    var currency = API.REPORTING_CURRENCY;

    if (initParams) {
        if (initParams.group_by === 'year' || initParams.group_by === 'year_and_month') {
            function chart() {
                $('#chart').hide();
                var config = {
                    chart: {
                        zoomType: 'xy'
                    },
                    title: {
                        text: null
                    },
                    credits: {
                        enabled: false
                    },
                    subtitle: {
                        text: null
                    },
                    xAxis: [{
                        categories: chart_categories,
                        crosshair: true
                    }],
                    yAxis: [{ // Primary yAxis
                        labels: {
                            formatter: function () {
                                return this.value + ' ' + currency;
                            },
                            style: {
                                color: '#769EFE'
                            }
                        },
                        title: {
                            text: $filter('trans')('INVOICED_WITHOUT_VAT'),
                            style: {
                                color: '#769EFE'
                            }
                        }
                    }],
                    tooltip: {
                        shared: true
                    },
                    series: series,
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        x: 0,
                        verticalAlign: 'bottom',
                        y: 0,
                        floating: false,
                        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
                    },
                }

                // hide dataLabels when resolution is xxs (phone)
                if ($(window).width() < 480) {
                    config.plotOptions.pie.dataLabels.enabled = false
                }
                $('#chart').highcharts(config);
                vm.isLoading = false;


                setTimeout(function () {
                    $('#chart').show();
                    $('#chart').highcharts().reflow();
                }, 1);
            }

            if (series) {
                chart();
            }
        } else {

            function chart1() {
                $('#chart1').hide();
                var config = {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                        events: {
                            load: function () {
                                this.reflow();
                            }
                        },
                        height: 550,
                        width: 1087,
                    },
                    title: {
                        text: 'Top ' + $filter('trans')('INVOICED')
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormat: '{point.name}: <b>{point.y}  ({point.percentage:.1f} % din selectie) </b>'
                    },
                    credits: {
                        enabled: false
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                style: {
                                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                                }
                            },
                            showInLegend: false,
                            innerSize: '60%'
                        }
                    },
                    series: [{
                        data: series
                    }],
                    afterPrint: function (a, b, c) {
                        console.log(a, b, c)
                    }
                }

                // hide dataLabels when resolution is xxs (phone)
                if ($(window).width() < 480) {
                    config.plotOptions.pie.dataLabels.enabled = false
                }
                $('#chart1').highcharts(config);
                vm.isLoading = false;

                setTimeout(function () {
                    $('#chart1').show();
                    $('#chart1').highcharts().reflow();
                }, 1);
            }

            if (series) {
                chart1();
            }
        }
    }

    return vm;
});
