kv.controller('activityBudgetEditCtrl', function ($uibModalInstance, resource, params, saveCallback, $scope, $injector, $rootScope) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'activity_budget', 'activity_budget_id', $scope, params);
	if (!params.activity_budget_id) {
		vm.data = params;
		vm.isNewRecord = true;
	} else {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		let rest = vm.getInjection('resource').init('activity_budget');
		rest.get({id: params.activity_budget_id}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) vm.data = response;
			else console.error(response);
		});
	}
	vm.saveCallback = saveCallback;
	return vm;
});
