kv.controller('InvoiceListReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    var params = kvUtils.getAllQueryParams();
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function() {
        $('#table1').DataTable({
            fixedHeader: {
                header: false,
                footer: true
            },
            "paging":   false,
            "ordering": true,
            "order": [[ 2, "desc" ]],
            "info":     false,
            "bLengthChange": false,
            "bShowCounters": true,
        });

        $('#table2').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false
        });

        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            addClass('no-print').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');
    });
    
    return vm;
});
