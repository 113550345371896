kv.controller('DashboardMarketingListPastOffersCtrl', function ($element, $attrs, $scope, $injector) {
	let vm = new DashboardWidgetListCtrl($element, $attrs, $scope, $injector, function() {
		if (vm.data.initial) {
			$scope.count = {
				won: 0,
				submitted: 0,
				in_preparation: 0,
				lost: 0,
				conflicted: 0,
			};

			// count for badge from filter
			angular.forEach(vm.data.initial, function (v, i) {
				if (v.business_status_name == 'WON') {
					$scope.count.won = parseInt($scope.count.won) + 1;
				} else if (v.business_status_name == 'SUBMITTED') {
					$scope.count.submitted = parseInt($scope.count.submitted) + 1;
				} else if (v.business_status_name == 'IN_PREPARATION') {
					$scope.count.in_preparation = parseInt($scope.count.in_preparation) + 1;
				} else if (v.business_status_name == 'LOST') {
					$scope.count.lost = parseInt($scope.count.lost) + 1;
				} else if (v.business_status_name == 'CONFLICTED') {
					$scope.count.conflicted = parseInt($scope.count.conflicted) + 1;
				}
			});
		}
	});

	vm.filter_offers = 'all';

	vm.changeFilter = function(filter) {
		if (filter) this.filter_offers = filter;
		if (this.filter_offers == 'won') this.data.filtered = this.data.initial.filter(item => item.business_status_name == 'WON');
		else if (this.filter_offers == 'submitted') this.data.filtered = this.data.initial.filter(item => item.business_status_name == 'SUBMITTED');
		else if (this.filter_offers == 'in_preparation') this.data.filtered = this.data.initial.filter(item => item.business_status_name == 'IN_PREPARATION');
		else if (this.filter_offers == 'conflicted') this.data.filtered = this.data.initial.filter(item => item.business_status_name == 'CONFLICTED');
		else if (this.filter_offers == 'lost') this.data.filtered = this.data.initial.filter(item => item.business_status_name == 'LOST');
		else this.data.filtered = this.data.initial;
	}

	return vm;
});
