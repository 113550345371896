(function (window, angular) {
	angular.module('kv').component('userAvatar',
		{
			bindings: {
				avatar: "=",
				cropped: "=?",
				original: "=?",
				peopleName: "=?",
				showAvatar: "<",
				showSaveBtn: "<",
				saveCallback: "&",
				size: "@",
			},
			controllerAs: "vm",
			templateUrl: "/assets/custom/js/components/user-avatar/user-avatar.component.html",
			controller: controller
		});
	controller.$inject = ["$scope", "$element", "$attrs", "$timeout", "$http"];

	function controller(scope, $element, $attrs, $timeout, $http) {
		let ctrl = this;
		this.cropped = '';
		this.fileUpload = {
			onChange: function () {
				if (this.$ctrl.$files.length) {
					this.$ctrl.readFileContent(
						this.$ctrl.$files[0],
						(evt) => {
							ctrl.original = evt.target.result;
							scope.$apply();
						});
				}
			}
		};
		this.saveAvatar = function () {
			KApp.block($('img-crop'));
			$http.put("/avatar-update", {avatar: this.cropped}).then(response => {
				KApp.unblock($('img-crop'));
				if (response.status && this.saveCallback) {
					this.saveCallback(response);
					this.cropped = "";
					this.original="";
					this.originalFile=null;
				}
			});
		};
		return this;
	}
})(window, angular);