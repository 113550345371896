kv.controller('InvoicePartialStornoCtrl', function ($uibModalInstance, data, $injector, $scope) {
	var vm = new modalCtrl($uibModalInstance, $injector, 'invoice', 'invoice_id', $scope, data);

	vm.modal.rendered.then(() => {
		if (!vm.dataHasLoaded) KApp.block('.modal-content');
	});
	vm.rest.get({
		id: data.invoice_id
	}).then(function (res) {
		vm.data = res;
		vm.dataHasLoaded = true;
		KApp.unblock('.modal-content');
		vm.data.storno_value = vm.data.total_amount;
		$scope.$broadcast("dataLoaded");
	});

	vm.save = function () {
		debugger;
		let postData = angular.copy(vm.data);
		var error = false;
		var total_amount = vm.data.total_amount;
		if (parseFloat(postData.storno_value) > parseFloat(total_amount)) {
			vm.alert("ALERT", vm.trans('LANG.ERROR_STORNO_VALUE_GREATER_THAN_INVOICE_VALUE'));
			error = true;
		}

		if (postData.storno_value < 0) {
			vm.alert('ALERT', vm.trans("LANG.ERROR_STORNO_VALUE_CANNOT_BE_NEGATIVE"));
			error = true;
		}

		if (postData.storno_value.includes(',')) {
			vm.alert('ALERT', vm.trans("LANG.ERROR_STORNO_VALUE_NEEDS_TO_BE_NUMBER"));
			error = true;
		}

		if (error == true) {
			return false;
		} else {
			vm.confirm('CONFIRM', 'Esti sigur/a ca STORNEZI PARTIAL factura? (valoare stornata: ' + (postData.storno_value ? postData.storno_value : total_amount) + ')').then((response) => {
				if (response !== 'ok') return;
				KApp.block('.modal-content');
				vm.rest.update({
					url: 'invoice/' + data.invoice_id + '/partialStorno',
					data: postData
				}).then(function (response) {
					KApp.unblock('.modal-content');
					vm.close({});
					window.location = vm.params.bladeParams.invoiceIndexAction + "/" + response.data.invoice_id;
				});
			});
		}
	};
	return vm;
});
