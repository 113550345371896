kv.controller('exchangeRateEditCtrl', function ($uibModalInstance, data, $injector, $scope) {
	return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
		entity: "exchange_rate",
		primaryKey: "exchange_rate_id",
		newEntity: {
			date: moment(),
			currency: API.user.tenant.reporting_currency
		},
		prepareDataToSave: function () {
			let cmdate = this.getInjection('cmdate');
			let dataToSend = angular.copy(this.data);
			dataToSend.date = cmdate.toServer(this.data.date);
			return dataToSend;
		}
	});
});
