kv.controller('substitutionDelegationListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'substitution_delegation_id';
    vm.entity = 'substitution_delegation';

    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getInfinitScroll({
        params: {
            action: 'substitution_delegation'
        },
        url: "substitution_delegation",
    });


    return vm;
});

