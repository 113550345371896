kv.controller('targetEditCtrl', function ($uibModalInstance, resource, params, saveCallback, $scope, $injector, $rootScope) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'target', 'target_id', $scope, params);
	if (!params.target_id) {
		vm.data = params;
		vm.isNewRecord = true;
	} else {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		let rest = vm.getInjection('resource').init('target');
		rest.get({id: params.target_id}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) vm.data = response;
			else console.error(response);

			$rootScope.lookup = {
				sys_dimension: {
					all_sys_dimension: {
						table_name: vm.data.sys_dimension.table_name
					}
				}
			};
		});
	}
	vm.saveCallback = saveCallback;
	return vm;
});
