/**
 * This filter is used to convert mysql/api datetime to javascript datetime object
 * Usage
 * {{ object.date | cmdate:'?format' }}
 * @param format {datetime format} e.g. Y-m-d H:i:s
 * @return javascript datetime object
 */
(function ()
{
    'use strict';

    angular
        .module('kv')
        .filter('cmdate', cmdate);

    function cmdate($filter, cmdate) {
        return function(input, format) {
            if(!format) {
                format = "DD.MM.YYYY";
            }
            var date = cmdate.fromServer(input);
            if(date && date != "-") {
                date = date.format(format);
                if(typeof date == "string") {
                    return date;
                }
            }
        };
    }

    // function cmdate($filter, cmdate) {
    //     return function(input, format) {
    //         if (!format) {
    //             format = 'DD.MM.YYYY';
    //         }
    //         var date = cmdate.fromServer(input);
    //         if (date && date !== '-') {
    //             const dt = new Date(date);
    //             if (format === 'dd MMM' && dt.getFullYear() < (new Date().getFullYear())) {
    //                     format = 'dd MMM YYYY';
    //             }
    //             date = dt.format(format);
    //             if(typeof date === 'string') {
    //                 return date;
    //             }
    //         }
    //     };
    // }

})();
