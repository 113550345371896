kv.controller('hearingEditCtrl', function ($uibModalInstance, params, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'court_hearing', 'court_hearing_id', $scope, params);

	if (params.court_hearing_id) {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		let rest = vm.getInjection('resource').init('court_hearing');
		rest.get({id: params.court_hearing_id}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) {
				vm.data = response;
				if (vm.data.court_hearing_attendee) {
					angular.forEach(vm.data.court_hearing_attendee, function (v, i) {
						vm.data.court_hearing_attendee[i].id = v.user.user_id;
						vm.data.court_hearing_attendee[i].name = v.user.user_name;
					});
				}

				vm.data.date = new dateClass(vm.data.date);
			} else {
				console.error(response);
			}
		});
	}


	vm.prepareDataToSave = function () {
		let dataToSave = angular.copy(vm.data);
		dataToSave.date = vm.data.date.PostDatetime;
		return dataToSave;
	};

	return vm;
});
