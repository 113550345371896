kv.controller('inventoryItemTypeListCtrl', function ($element, $attrs, $scope, $injector) {
    return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
        primaryKey: "inventory_item_type_id",
        entity: "inventory_item_type",
        modal: {
            templateUrl: "inventory_item_type-edit",
            controller: "inventoryItemTypeEditCtrl"
        }
    });
});
