kv.controller('bpiFiscalEntityViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'bpi_fiscal_entity_id';
	vm.entity = 'bpi_fiscal_entity';
    vm.entityId = vm.bladeParams.bpiFiscalEntityId;
    vm.fiscal_id = vm.bladeParams.fiscalId;
	vm.entityType = 'bpi_fiscal_entity';

    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3]; 

	vm.commentsConfig = {
		entity_type: vm.entity
	};

	vm.addEditEntity = function (entity, $index) {
        vm.openModal({
            templateUrl: "bpi_fiscal_entity-edit",
            controller: 'bpiFiscalEntityEditCtrl',
            controllerAs: 'vm',
            size: 'md',
			  resolve: {
            	params: {
            		index: $index,
            		entity: entity
					}
			  }
        });
    };

    /**
     * get & show insolvency detail
     *
     * format:
     * 		0: {
     * 			acte: [
     * 				// all details here
     * 				{ident: 187199, nrcrt: 0,…},…
     * 			] 
     * 			created_at: "2016-09-09 16:36:52"
     * 			cui: 14906870
     * 			id: 187199
     * 			ident: 187199
     * 			judet: "Dâmbovita"
     * 			nume: "AGROZOOTEHNICA SRL"
     * 			updated_at: "2016-11-06 00:00:00"
     * 		}
     *
     * 
     * @param  {[type]} fiscal_id [description]
     * @return {[type]}           [description]
     */
	vm.getInsolvencyDetail = function (fiscal_id) {

		var REST = resource.init('bpi_fiscal_entity');

		REST.get({
			url: "bpi_fiscal_entity/getInsolvencyDetail",
			params: {
				fiscal_id: fiscal_id
			}
		}).then(function(response) {
			if(response){
				if(response.data.length > 0){
					// init master data
					vm.bpi = {};
					vm.bpi.nume = response.data[0].company["name"];
					vm.bpi.judet = response.data[0].company["county"];
					vm.bpi.cui = response.data[0].company["cui"];
					vm.bpi.acts = response.data;

					vm.initialBpiActs = response.data;

					//get current year
					vm.bpi.year = "";
					vm.years = [];
					//build data for year filter
					let actYear = [];
					for (let index = vm.bpi.acts.length - 1; index >= 0; index--) {
						if (vm.bpi.acts[index].bpi === null) {
							vm.bpi.acts.splice(index, 1);
						} else {
							let yearFromDate = '-';
							if (vm.bpi.acts[index].bpi.date) {
								yearFromDate = vm.bpi.acts[index].bpi.date.substring(0, 4);
								if (!vm.years.includes(yearFromDate)) {
									vm.years.push(yearFromDate);
								}
							}
							vm.bpi.acts[index].year = yearFromDate;
						}
					}
				}
			}

		});
	};

	vm.getInsolvencyDetail(vm.fiscal_id);

	vm.filterActs = function(year) {
		KApp.block('#acts');
		let newBpiActs = [];
		if(year !== '') {
			for(let index = 0; index < vm.initialBpiActs.length; index ++){
				if(vm.initialBpiActs[index].bpi.date) {
					let bpiYear = vm.initialBpiActs[index].bpi.date.substring(0,4);
					if(bpiYear === year){
						newBpiActs.push(vm.initialBpiActs[index]);
					}
				} else {
					newBpiActs.push(vm.initialBpiActs[index]);
				}
			}

			if(newBpiActs !== []){
				vm.bpi.acts = newBpiActs;
			}
		} else {
			vm.bpi.acts = vm.initialBpiActs;
		}

		vm.dataHasLoaded = true;
		KApp.unblock('#acts');
	};

	vm.download = function (entity) {
		window.open('https://api.keyvision.eu/api/bpi/download/' + entity.year + '/' + entity.bpi.number + '?api_token=crUbjWNsrmQUpqMglN4djlCBEq2Rz6ItAukNxGrychDqiKqrON2SBrqBOhWu');
	};

	return vm;
});

