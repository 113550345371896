kv.controller('BudgetIndicatorsReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
 //   let data = JSON.parse($element.find('#page-data').attr('page-data'));
    let initParams = kvUtils.getAllQueryParams();

    if (initParams) {
        if (initParams.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function () {
        setTimeout(() => {
            let dataTable = $('#table').DataTable({
                "paging": false,
                "ordering": true,
                "info": false,
                "bLengthChange": false,
                "bShowCounters": true,
                fixedHeader: {
                    header: true,
                    footer: true
                },
                // searchHighlight: true,
                // pt. counter tabel
                "columnDefs": [{
                    "searchable": false,
                    "orderable": false,
                    "targets": 0
                }]
            });
            // counter tabel
            dataTable.on('order.dt search.dt', function () {
                dataTable.column(0, {search: 'applied', order: 'applied'}).nodes().each(function (cell, i) {
                    cell.innerHTML = i + 1;
                });
            }).draw();

            $('.dataTables_filter input[type="search"]').attr('placeholder', 'Search in table...').addClass('no-print').removeClass('input-small').css({'width': '350px', 'display': 'inline-block'});

            $('.dataTables_filter').addClass('pull-right').addClass('no-print');

        }, 300);
    });

    vm.isLoading = true;


    return vm;
});
