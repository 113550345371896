kv.controller('inventoryItemViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
        var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
        vm.primaryKey = 'inventory_item_id';
        vm.entity = 'inventory_item';
        vm.entityType = 'inventory_item';
        vm.groups = {};
        vm.rest = resource.init('inventory_item');

        let url_segments = window.location.pathname.split('/');
        let page_entity_id = url_segments[2];
        let page_identifier = url_segments[3];

        vm.entityId = page_entity_id;
        vm.commentsConfig = {
            entity_type: vm.entity
        };

        vm.modificationReport = function (inventory_id) {
                vm.openModal({
                        templateUrl: 'inventory_item_modification_report-list',
                        controller: 'InventoryItemModificationReportListCtrl',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                                data: function () {
                                        return {
                                                inventory_id: inventory_id
                                        };
                                }
                        },
                        backdrop: 'static',
                        keyboard: false
                }).then(response => {
                        if (response == "ok") window.location = "/inventory_item/" + inventory_id;
                });
        };

        vm.scrapeItem = function (inventory_id) {
                vm.openModal({
                        templateUrl: 'scrape_item-list',
                        controller: 'ScrapeItemListCtrl',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                                data: function () {
                                        return {
                                                inventory_id: inventory_id
                                        };
                                }
                        },
                        backdrop: 'static',
                        keyboard: false
                }).then(response => {
                        if (response == "ok") window.location = "/inventory_item/" + inventory_id;
                });
        };

        vm.getInventoryItemHistory = function () {
                var REST = resource.init('inventory_item_history/list');
                REST.get({
                        params: {
                                inventory_item_id: page_entity_id
                        }
                }).then(function (data) {
                        vm.inventory_item_history = data;
                        console.log("history", vm.inventory_item_history);
                });
        };

        vm.getInventoryItemHistory();

        vm.goBack = function () {
            $state.go("app.inventory_item");
        };

        vm.formatData = function (entity) {
                delete entity.inventory_item_id;
                delete entity.created_by;
                delete entity.created_at;
                entity.name = '(Copy of) ' + entity.name;


                if (entity.inventory_item_history) {
                        for ($i = 0; $i < entity.inventory_item_history.length; $i++) {
                                delete entity.inventory_item_history[$i];
                        }
                        delete entity.inventory_item_history;
                }

                return entity;
        };

        vm.duplicate = function (entity) {
                var formattedData = vm.formatData(entity);

                vm.rest.create({
                        url: "inventory_item/",
                        data: formattedData
                }).then(function (data) {
                        window.location = "/inventory_item/" + data.data.inventory_item_id + "/edit";
                });
        };

        vm.edit = function () {
            $state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
        };

        return vm;
    }
);

