kv.controller('affiliateListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "affiliate_id",
		entity: "affiliate",
		modal: {
			templateUrl: "affiliate-edit",
			controller: "affiliateEditCtrl"
		}
	});
});
