kv.controller('expenseTypeEditCtrl', function ($uibModalInstance, data, $injector, $scope, $http, API) {
	return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
		entity: "expense_type",
		primaryKey: "expense_type_id",
		newEntity:{
    		expense_type_translation: [],
    		is_internal: false,
    		is_active: true
		},
		onInit:function(){ 
			//metoda executata la initializarea controllerului
			//this este controllerul vm
			$http.get(API.URL + 'filter/sys_language/all_sys_language').then((res) => {
		        if (res.data.length) {
		            var sysLanguages = res.data;
		            angular.forEach(sysLanguages, (sysLanguage) => {
		                var languageExists = false;
		                
		                angular.forEach(this.data.expense_type_translation, (translation) => {
		                    if (sysLanguage.name == translation.language) {
		                        languageExists = true;
		                    }
		                });

		                if (!languageExists) {
		                    this.data.expense_type_translation.push({
		                        language: sysLanguage.name,
		                        name: ''
		                    });
		                }
		            });
		        }
		    });
		},
		prepareDataToSave: function () {
			let cmdate = this.getInjection('cmdate');
			let dataToSend = angular.copy(this.data);
			dataToSend.date = cmdate.toServer(this.data.date);
			return dataToSend;
		}
	});
	// DE FACUT
	// $http.get(API.URL + 'filter/sys_language/all_sys_language').then(function (res) {
 //        if (res.data.length) {
 //            var sysLanguages = res.data;
 //            angular.forEach(sysLanguages, function (sysLanguage) {
 //                var languageExists = false;
 //                angular.forEach(vm.result.expense_type_translation, function (translation) {
 //                    if (sysLanguage.name == translation.language) {
 //                        languageExists = true;
 //                    }
 //                });
 //                if (!languageExists) {
 //                    vm.result.expense_type_translation.push({
 //                        language: sysLanguage.name,
 //                        name: ''
 //                    });
 //                }
 //            });
 //        }
 //    });

});