kv.controller('submissionViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
        var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
        vm.primaryKey = 'submission_id';
        vm.entity = 'submission';
        vm.entityType = 'submission';
        vm.groups = {};
        let copyData = {};
        let url_segments = window.location.pathname.split('/');
        let page_entity_id = url_segments[2];
        let page_identifier = url_segments[3];

        vm.entityId = page_entity_id;
        vm.commentsConfig = {
            entity_type: vm.entity
        };

    vm.getInfinitScroll({
        params: {
            action: 'submission_project'
        },
        url: "submission_project/list?submission_id=" + page_entity_id + "&direction=asc",
    });

    vm.getSubmissionProject = function () {
        var REST = resource.init('submission');
        REST.getArray({
            url: 'submission/' + page_entity_id,
            params: {
                action: 'submissionList'
            }
        }).then(function (response) {
            vm.projectSubmissionList = response;
            vm.projectSubmissionCount = null;
            copyData = vm.projectSubmissionList;
            $.each(response, function (index, value) {
                vm.projectSubmissionCount++;
            });
        });
    };

    vm.getSubmissionProject();

    vm.addSubmissionProject = function ($index, entity, event) {
        if (event) event.stopPropagation();
        vm.openModal({
            templateUrl: "submission-project-edit",
            controller: 'submissionProjectEditCtrl',
            controllerAs: 'vm',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                params: function () {
                    return {
                        index: $index,
                        entity: entity,
                        submission_id: vm.entityId
                    };
                },
                bladeParams: function () {
                    return vm.bladeParams;
                }
            }
        }).then(response => {
            vm.getSubmissionProject();
        });
    };

    vm.filterList = function (status) {
        if (status) {
            vm.projectSubmissionList = [];
            angular.forEach(copyData, function (v, i) {
                if (v.status == 'not_started' && status == 'not_started') {
                    vm.projectSubmissionList = vm.projectSubmissionList.concat(v);
                }
                if (v.status == 'in_progress' && status == 'in_progress') {
                    vm.projectSubmissionList = vm.projectSubmissionList.concat(v);
                }
                if (v.status == 'completed' && status == 'completed') {
                    vm.projectSubmissionList = vm.projectSubmissionList.concat(v);
                }
                if (v.publishable == 1 && status == '1') {
                    vm.projectSubmissionList = vm.projectSubmissionList.concat(v);
                }
                if ((v.publishable == 0 || v.publishable == null) && status == '0') {
                    vm.projectSubmissionList = vm.projectSubmissionList.concat(v);
                }
                if (status == 'all') {
                    vm.projectSubmissionList = copyData;
                }
            });
        }
    };

    vm.deleteSubmissionProject = function deleteSubmissionProject(submission_project_id, index, event) {
        event.stopPropagation();
        vm.confirm("DELETE", vm.trans('ARE_YOU_SURE_DELETE')).then(response => {
            if (response !== 'ok') return;
            vm.entity = 'submission_project';
            var REST = resource.init(vm.entity);
            REST.delete({
                params: {
                    id: submission_project_id
                }
            }).then(function (response) {
                if(response.error && response.error.error_code){
                    toastr.error((response.error.message + ". </br> " + response.error.details));
                } else {
                    vm.getSubmissionProject();
                }
            });
        });
    };

    vm.goBack = function () {
        $state.go("app.submission");
    };


    vm.edit = function () {
        $state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
    };

    return vm;
});
