kv.controller('EmployeeAccountListCtrl', function ($scope, $element, $attrs, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector);

	vm.primaryKey = 'employee_account_id';
	vm.entity = 'employee_account';
	vm.getInfinitScroll({});

	vm.rest.getArray({
		url: 'filter/sys_employee_account_type/all_sys_employee_account_type'
	}).then(function (res) {
		vm.employee_account_types = res;
	});

	vm.allowChange = function (entity) {
		return (vm.bladeParams.isBillingGroup == '1') ? true : entity.status_id == 1;
	};

	vm.showType = function (typeName) {
		if (typeName == 'RECEIPT') {
			return !vm.infinitScroll.search.type_id || vm.infinitScroll.search.type_id == 1;
		} else if (typeName == 'INVOICE') {
			return !vm.infinitScroll.search.type_id || vm.infinitScroll.search.type_id == 2;
		} else if (typeName == 'REVENUE_ONLY_EXPENSE') {
			return !vm.infinitScroll.search.type_id || vm.infinitScroll.search.type_id == 3;
		} else if (typeName == 'TRANSPORT') {
			return !vm.infinitScroll.search.type_id || vm.infinitScroll.search.type_id == 4;
		} else if (typeName == 'INTERN') {
			return !vm.infinitScroll.search.type_id || vm.infinitScroll.search.type_id == 5;
		} else if (typeName == 'IP_COUNSEL_FEES') {
			return !vm.infinitScroll.search.type_id || vm.infinitScroll.search.type_id == 6;
		} else if (typeName == 'ADVANCE') {
			return !vm.infinitScroll.search.type_id || vm.infinitScroll.search.type_id == 7;
		} else return false;
	};

	vm.addEditEntity = function ($index, typeName) {
		let type = vm.employee_account_types.find(e => {
			return e.name == typeName;
		});
		vm.openModal({
			size: 'md',
			templateUrl: 'employee_account-edit',
			controller: 'EmployeeAccountEditCtrl',
			resolve: {
				data: function () {
					return {
						index: $index,
						entity: null,
						type: type,
						employee_account: vm.employee_account
					};
				}
			}

		}).then(response => {
			if (!response) return;
			// this.infinitScroll.items.unshift(response.entity);
			window.location = vm.bladeParams.employeeAccountIndexAction + "/" + response.entity.employee_account_id;
		});
	};

	vm.delete = function ($index) {
		vm.openModal({
			size: 'md',
			templateUrl: 'employee_account-list-delete-confirm',
			controller: function ($uibModalInstance) {
				var vm = this;

				vm.data = {
					delete_expenses: true
				};

				vm.save = function () {
					$uibModalInstance.close(vm.data);
				};

				vm.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				};
			}
		}).then(data => {
			vm.rest.delete({
				id: vm.infinitScroll.items[$index][vm.primaryKey],
				params: data
			}).then(function (response) {
				vm.infinitScroll.items.splice($index, 1);
			});
		});
	};

	return vm;
});
