kv.controller('domainCategoryListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "domain_category_id",
		entity: "domain_category",
		modal: {
			templateUrl: "domain_category-edit",
			controller: "domainCategoryEditCtrl"
		}
	});
});
