kv.controller('activityEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'activity_id';
	vm.entity = 'activity';
	vm.rest = vm.getInjection('resource').init(vm.entity);
	vm.custom_errors = null;
	vm.has_error = false;

	// let cmdate = vm.getInjection('cmdate');

	let REST = resource.init(vm.entity);
	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];

	// preluare tip activitate din url
	let activity_type = vm.bladeParams.activityType;


	if (page_identifier == 'edit') {
    	vm.isNewRecord = false;
		$http.get("/activity/" + page_entity_id).then(function (response) {
			vm.data = response.data;
			vm.data.all_day_start_date = angular.copy(vm.data.start_date);

			// vm.scopeWatch();
			if (vm.data.attendee_responsible) {
				angular.forEach(vm.data.attendee_responsible, function (v, i) {
					vm.data.attendee_responsible[i].id = v.people_id;
					vm.data.attendee_responsible[i].name = v.people.people_name;
				});
			}
			if (vm.data.attendee_contact) {
				angular.forEach(vm.data.attendee_contact, function (v, i) {
					vm.data.attendee_contact[i].id = v.people_id;
					vm.data.attendee_contact[i].name = v.people.people_name;
				});
			}
			if (vm.data.attendee_coordinator) {
				angular.forEach(vm.data.attendee_coordinator, function (v, i) {
					vm.data.attendee_coordinator[i].id = v.people_id;
					vm.data.attendee_coordinator[i].name = v.people.people_name;
				});
			}
			if (vm.data.attendee_originator) {
				angular.forEach(vm.data.attendee_originator, function (v, i) {
					vm.data.attendee_originator[i].id = v.people_id;
					vm.data.attendee_originator[i].name = v.people.people_name;
				});
			}
			if (vm.data.attendee_internal) {
				angular.forEach(vm.data.attendee_internal, function (v, i) {
					vm.data.attendee_internal[i].id = v.people_id;
					vm.data.attendee_internal[i].name = v.people.people_name;
				});
			}
			if (vm.data.attendee_participant) {
				angular.forEach(vm.data.attendee_participant, function (v, i) {
					vm.data.attendee_participant[i].id = v.people_id;
					vm.data.attendee_participant[i].name = v.people.people_name;
				});
			}
			if (vm.data.attendee_external) {
				angular.forEach(vm.data.attendee_external, function (v, i) {
					vm.data.attendee_external[i].id = v.people_id;
					vm.data.attendee_external[i].name = v.people.people_name;
				});
			}
			if (vm.data.activity_organizer) {
				angular.forEach(vm.data.activity_organizer, function (v, i) {
					vm.data.activity_organizer[i].id = v.offer_source_activity.offer_source_id;
					vm.data.activity_organizer[i].name =  v.offer_source_activity.code;
				});
			}
			if (vm.data.bdoffer_practice) {
				angular.forEach(vm.data.bdoffer_practice, function (v, i) {
					vm.data.bdoffer_practice[i].id = v.practice_area_id;
					vm.data.bdoffer_practice[i].name = v.practice.practice_name;
				});
			}
			if (vm.data.bdoffer_field) {
				angular.forEach(vm.data.bdoffer_field, function (v, i) {
					vm.data.bdoffer_field[i].id = v.field_id;
					vm.data.bdoffer_field[i].name = v.field.field_name;
				});
			}
			if (vm.data.fee_types) {
				angular.forEach(vm.data.fee_types, function (v, i) {
					vm.data.fee_types[i].id = v.fee_type_id;
					vm.data.fee_types[i].name = v.fee_type_name;
				});
			}
			if (vm.data.bdoffer_industry) {
				angular.forEach(vm.data.bdoffer_industry, function (v, i) {
					vm.data.bdoffer_industry[i].id = v.industry_id;
					vm.data.bdoffer_industry[i].name = v.industry.industry_name;
				});
			}
			if (vm.data.bdoffer_customer_fiscal_entity) {
				angular.forEach(vm.data.bdoffer_customer_fiscal_entity, function (v, i) {
					vm.data.bdoffer_customer_fiscal_entity[i].id = v.customer_fiscal_entity_id;
					vm.data.bdoffer_customer_fiscal_entity[i].name = v.customer_fiscal_entity.name;
				});
			}
			if (vm.data.bdoffer_contact) {
				angular.forEach(vm.data.bdoffer_contact, function (v, i) {
					vm.data.bdoffer_contact[i].id = v.contact_id;
					vm.data.bdoffer_contact[i].name = v.contact.contact_name;
				})
			}
			if (vm.data.bdoffer_customer) {
				angular.forEach(vm.data.bdoffer_customer, function (v, i) {
					vm.data.bdoffer_customer[i].id = v.customer_id;
					vm.data.bdoffer_customer[i].name = v.customer.customer_name;
				});
			}
			if (vm.data.attendee_interview) {
				angular.forEach(vm.data.attendee_interview, function (v, i) {
					vm.data.attendee_interview[i].id = v.people_id;
					vm.data.attendee_interview[i].name = v.people.people_name;
				});
			}

			if (vm.data.attendee_initiator) {
				angular.forEach(vm.data.attendee_initiator, function (v, i) {
					vm.data.attendee_initiator[i].id = v.people_id;
					vm.data.attendee_initiator[i].name = v.people.people_name;
				});
			}
			if (vm.data.attendee_recipient) {
				angular.forEach(vm.data.attendee_recipient, function (v, i) {
					vm.data.attendee_recipient[i].id = v.people_id;
					vm.data.attendee_recipient[i].name = v.people.people_name;
				});
			}

			if (vm.data.bdoffer_affiliate) {
				angular.forEach(vm.data.bdoffer_affiliate, function (v, i) {
					vm.data.bdoffer_affiliate[i].id = v.affiliate_id;
					vm.data.bdoffer_affiliate[i].name = v.affiliate.name;
				});
			}
			if (vm.data.attendee_speaker) {
				angular.forEach(vm.data.attendee_speaker, function (v, i) {
					vm.data.attendee_speaker[i].id = v.people_id;
					vm.data.attendee_speaker[i].name = v.people.people_name;
				});
			}

			if (vm.data.attendee_activity_user) {
				angular.forEach(vm.data.attendee_activity_user, function (v, i) {
					vm.data.attendee_activity_user[i].id = v.people_id;
					vm.data.attendee_activity_user[i].name = v.people.people_name;
				});
			}

			if (vm.data.attendee_recommended_customer_contact) {
				angular.forEach(vm.data.attendee_recommended_customer_contact, function (v, i) {
					vm.data.attendee_recommended_customer_contact[i].id = v.people_id;
					vm.data.attendee_recommended_customer_contact[i].name = v.people.people_name;
				});
			}

			if (vm.data.attendee_destination_contact) {
				angular.forEach(vm.data.attendee_destination_contact, function (v, i) {
					vm.data.attendee_destination_contact[i].id = v.people_id;
					vm.data.attendee_destination_contact[i].name = v.people.people_name;
				});
			}

			if (vm.data.attendee_source_contact) {
				angular.forEach(vm.data.attendee_source_contact, function (v, i) {
					vm.data.attendee_source_contact[i].id = v.people_id;
					vm.data.attendee_source_contact[i].name = v.people.people_name;
				});
			}

			$scope.$broadcast('dataLoaded');
		});
	} else {
		vm.isNewRecord = true;
		vm.data = {
			activity_marketing: {},
			activity_ext:{
				activity_id: page_entity_id
			}
		};

		if (activity_type == 'bdMeeting') {
			let url_segments = window.location.href.split("&");
			if(url_segments && url_segments[1]) {
				let page_entity_id = url_segments[1].split('=');
				if (page_entity_id[1] && page_entity_id[1] === "createFromCalendar") {
					vm.data = {
						business_status_id: 10,
						business_status: {
							business_status_name: "PLANNED"
						},
						bdoffer: {},
						activity_type_id: 2,
						activity_type: {
							activity_type_id: 2,
							name: "BD_MEET_LOCAL"
						}
					};
				}
			}else{
				vm.data = {
					business_status_id: 10,
					business_status: {
						business_status_name: "PLANNED"
					},
					bdoffer: {},
					activity_type_id: 2,
					is_bd_meeting:1,
					activity_type:{
						activity_type_id: 2,
						name: "BD_MEET_LOCAL"
					}
				};
			}


			//set the internal attendee with the default value of the user
			vm.data.attendee_internal = [];
			vm.data.attendee_internal.push({
				id: API.user.user_id,
				name: API.user.full_name
			});
			if(parseInt(vm.bladeParams.isBdMeeting) ==1) {
				vm.data.is_bd_meeting = 1;
			}

			let currentDate = new Date();
			let day = currentDate.getDate();
			let month = currentDate.getMonth() + 1;
			let year = currentDate.getFullYear();
			let hours = currentDate.getHours();
			let minutes = currentDate.getMinutes();

			//set the current date as values rounded to 15 minutes
			//e.g. current date: 15:24-> the input will be 15:30
			switch (true) {
				case (minutes > 0 && minutes <= 15) : minutes = 15;
					break;
				case (minutes > 15 && minutes <= 30) : minutes = 30;
					break;
				case (minutes > 30 && minutes <= 45) : minutes = 45;
					break;
				case (minutes > 45 && minutes <= 59) : minutes = 0; hours = hours + 1;
					break;
				case (minutes == 0) :
					break;
			}

			vm.data.start_date =
				year + '-' +
				(month < 10 ?  '0' + month : month) + '-' +
				(day < 10 ? '0' + day : day) + " " +
				(hours < 10 ? '0' + hours : hours) + ':' +
				(minutes < 10 ? '0' + minutes : minutes);

			// the end date will be set by default with the value of the start_date + 1 hour
			let newDate = new Date(vm.data.start_date);
			newDate.setHours(newDate.getHours() + 1);
			vm.data.end_date = newDate;
		}

		if (activity_type == 'bdOffer') {
			vm.data = {
				business_status_id: 3,
				business_status: {
					business_status_name: "IN_PREPARATION"
				},
				bdoffer: {},
				activity_type_id: 4,
				activity_type:{
					activity_type_id: 4,
					name: "BD_OFFER_PROPOSAL_PITCH"
				}
			};
		}

		if (activity_type == 'bdFreeAdvice') {
			vm.data = {
				business_status_id: 26,
				business_status: {
					business_status_name: "DONE"
				},
				activity_type_id: 6,
				activity_type: {
					activity_type_id: 6,
					name: "BD_FA_INBOUND"
				}
			};
		}

		if (activity_type == 'bdRecommendation') {
			vm.data = {
				business_status_id: 19,
				business_status: {
					business_status_name: "N/A"
				},
				activity_type_id: 8,
				activity_type: {
					activity_type_id: 8,
					name: "BD_RECOMMENDATION_INTERN"
				}
			};
		}

		if (activity_type == 'bdConference') {
			vm.data = {
				business_status_id: 27,
				business_status: {
					business_status_name: "PLANNED"
				},
				start_date: moment(),
				end_date: moment().add(1, 'hours'),
				activity_type_id: 11,
				activity_type: {
					activity_type_id: 11,
					name: "BD_CONFERENCE_LOCAL"
				}
			};

			vm.data.attendee_internal = [];
			vm.data.attendee_internal.push({
				id: API.user.user_id,
				name: API.user.full_name
			});

			let currentDate = new Date();
			let day = currentDate.getDate();
			let month = currentDate.getMonth() + 1;
			let year = currentDate.getFullYear();
			let hours = currentDate.getHours();
			let minutes = currentDate.getMinutes();

			//set the current date as values rounded to 15 minutes
			//e.g. current date: 15:24-> the input will be 15:30
			switch (true) {
				case (minutes > 0 && minutes <= 15) : minutes = 15;
					break;
				case (minutes > 15 && minutes <= 30) : minutes = 30;
					break;
				case (minutes > 30 && minutes <= 45) : minutes = 45;
					break;
				case (minutes > 45 && minutes <= 59) : minutes = 0; hours = hours + 1;
					break;
				case (minutes == 0) :
					break;
			}

			vm.data.start_date =
				year + '-' +
				(month < 10 ?  '0' + month : month) + '-' +
				(day < 10 ? '0' + day : day) + " " +
				(hours < 10 ? '0' + hours : hours) + ':' +
				(minutes < 10 ? '0' + minutes : minutes);

			// the end date will be set by default with the value of the start_date + 1 hour
			let newDate = new Date(vm.data.start_date);
			newDate.setHours(newDate.getHours() + 1);
			vm.data.end_date = newDate;

		}

		if (activity_type == 'bdTraining') {
			vm.data = {
				activity_type_id: 13,
				business_status_id: 29
			};
		}

		if (activity_type == 'bdOther') {
			vm.data = {
				business_status_id: 22,
				business_status: {
					business_status_name: "ONGOING"
				},
				activity_type_id: 14
			};
		}

		
		if (activity_type == 'hrTraining') {
			vm.data = {
				business_status_id: 40,
				business_status: {
					business_status_name: "ONGOING"
				},
				activity_type_id: 33,
				activity_type: {
					name: 'HR_TRAINING_INTERNAL_CLASSIC'
				}
			};
		}


		if (activity_type == 'hrCompanyProjectCoordination') {
			vm.data = {
				business_status_id: 40,
				business_status: {
					business_status_name: "ONGOING"
				},
				activity_type_id: 38,
				activity_type: {
					name: 'HR_COMPANY_PROJECT_COORDINATION'
				}
			};
		}

		if (activity_type == 'mkMassMedia') {
			vm.data = {
				business_status_id: 14,
				business_status: {
					business_status_name: "IN_PREPARATION"
				},
				activity_type_id: 15,
				activity_type: {
					name: 'MK_MM_ARTICLE'
				}
			};

		}

		if (activity_type == 'mkEvents') {
			vm.data = {
				business_status_id: 20,
				business_status: {
					business_status_name: "IN_PREPARATION"
				},
				has_meeting_room:1,
				activity_type_id: 18,
				activity_type: {
					name: 'MK_EV_BUSINESS_BREAKFAST'
				}
			};
		}

		if (activity_type == 'mkSubmission') {
			vm.data = {
				business_status_id: 14,
				business_status: {
					business_status_name: "IN_PREPARATION"
				},
				activity_type_id: 22,
				activity_type: {
					name: 'MK_SUB_CHAMBERS_AND_PARTNERS'
				}
			};
		}

		// vm.scopeWatch();

	}


	if (vm.bladeParams.activityType == 'mkEvents') {
		$scope.$watch('[vm.data.activity_type_id]', function (values) {
			if (vm.data.activity_type_id == 37) {
				vm.data.start_date = moment();
				vm.data.end_date = moment().add(1, 'hours');
				vm.data.all_day = false;
			} else {
				vm.data.end_date = null;
				vm.data.all_day = true;
			}
		}, true);
	}

	// if(page_identifier != 'edit'){
	// 	vm.scopeWatch();
	// }

	// vm.scopeWatch = function () {
		$scope.$watch('[vm.data.activity_type_id, vm.data.activity_name, vm.data.customer, vm.data.start_date, vm.data.activity_type, vm.data.activity_type.name, vm.data.bdoffer, vm.data.activity_ext, vm.data.customer.customer_name, vm.data.bdoffer.offer_source_id, vm.data.bdoffer.offer_source_customer, vm.data.activity_ext.media_publication, vm.data.has_meeting_room, vm.data.is_bd_activity, vm.data.all_day, vm.data.activity_ext.has_budget]', function (values) {
			if (!vm.data) return;
			if (vm.data.activity_type_id == 4) {
				vm.data.activity_name = $filter('trans')('LANG.PROPOSAL') + ", " + ((vm.data.customer) ? vm.data.customer.customer_name : '') + " " + ((vm.data.start_date) ? '(' + vm.data.start_date + ')' : '');
			} else if (vm.data.activity_type_id == 5) {
				vm.data.activity_name = $filter('trans')('LANG.PRESENTATION') + ", " + ((vm.data.customer) ? vm.data.customer.customer_name : '') + " " + ((vm.data.start_date) ? '(' + vm.data.start_date + ')' : '');
			} else if (vm.data.activity_type && [22, 23, 24, 25, 26, 27, 28, 29, 30, 31].indexOf(parseInt(vm.data.activity_type_id)) !== -1) {
				vm.data.activity_name = $filter('trans')(vm.data.activity_type.name) + (vm.data.activity_ext && vm.data.activity_ext.media_publication ? ' (' + $filter('trans')(vm.data.activity_ext.media_publication) + ') ' : '');
			}

		}, true);

	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		postData.activity_type = activity_type;

		if(postData.activity_type !== "mkSubmission"){
			if (postData.all_day) {
				postData.start_date = postData.all_day_start_date;
			} else {
				postData.all_day_start_date = null;
			}
		}

		if (postData.all_day_start_date != null) {
			vm.data.all_day_start_date = postData.all_day_start_date;
		} else {
			postData.all_day_start_date = null;
		}

		if (vm.data.bdoffer && ((vm.data.bdoffer.offer_source_id && vm.data.bdoffer.offer_source_id != 1 && vm.data.bdoffer.offer_source_id != 12))) {
			delete postData.customer_fiscal_entity_id;
		}
		if (vm.data.link && !vm.data.link.match(/^.+:\/\/.*/)) {
			postData.link = 'http://' + postData.link;
		}

		if(postData.activity_type == "bdMeeting" || [2, 3].indexOf(parseInt(postData.activity_type_id)) !== -1) {
			if (postData.has_meeting_room) {
				postData.is_bd_activity = 1;
			} else {
				postData.is_bd_activity = 0;
			}

			if (!postData.attendee_responsible) {
				postData.attendee_responsible = [];
				postData.attendee_responsible.push({
					id:  API.user.user_id, 
					name: API.user.full_name
				});
			}
		}
		return postData;
	};

	vm.quickAddContact = function () {
		vm.openModal({
			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'contact';
				}
			}
		}).then(function (result) {
			vm.data.contact_id = result.contact_id;
			vm.data.contact = {
				contact_name: result.contact_name
			};
		});
	};

	vm.quickAddCustomer = function () {
		vm.openModal({
			component: 'quickAddEntity',
			resolve: {
				params: function(){
					vm.data.fiscal_entity_type_id = 2
				},
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'customer';
				}
			}
		}).then(function (result) {
			vm.data.customer_id = result.customer_id;
			vm.data.customer = {
				customer_name: result.customer_name
			};
		});
	};

	vm.quickAddCustomerFiscalEntity = function () {
		vm.openModal({
			animation: true,
			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'customer_fiscal_entity';
				}
			}
		}).then(function (result) {
			vm.data.customer_fiscal_entity_id = result.customer_fiscal_entity_id;
			vm.data.customer_fiscal_entity = {
				customer_fiscal_entity_name: result.contact_name
			};
		});
	};

	vm.deleteIndustryEntity = function deleteIndustryEntity($index, entity, id) {
		if (!id) {
			return vm.data.bdoffer[entity].splice($index, 1);
		}

		if (page_entity_id) {
			REST.delete({
				url: vm.entity + '/:activityId/' + entity + '/:entityId',
				params: {
					activityId: page_entity_id,
					entityId: id
				}
			}).then(function (data) {
				vm.data.bdoffer[entity].splice($index, 1);
			});
		}
	};

	vm.newIndustryEntity = function newIndustryEntity(entity, default_value) {
		if (!vm.data) {
			vm.data = {};
		}
		if (!default_value) {
			default_value = {};
		}
		if (!vm.data.bdoffer[entity]) {
			return vm.data.bdoffer[entity] = [default_value];
		}

		return vm.data.bdoffer[entity].push(default_value);

	};

	vm.goBack = function () {
		if (page_entity_id) {
			$state.go("app." + vm.entity, {id: page_entity_id})
		}
	};

	vm.newEntity = function newEntity(entity, default_value) {
		if (!vm.data) {
			vm.data = {};
		}

		if (!default_value) {
			default_value = {};
		}

		if (!vm.data[entity]) {
			return vm.data[entity] = [default_value];
		}

		return vm.data[entity].push(default_value);
	};

	vm.deleteEntity = function deleteEntity($index, entity_type, id) {
		if (!id) {
			return vm.data[entity_type].splice($index, 1);
		}

		if (page_entity_id) {
			REST.delete({
				url: vm.entity + '/:activityId/' + entity_type + '/:entityId',
				params: {
					activityId: page_entity_id,
					entityId: id
				}
			}).then(function (data) {
				vm.data[entity_type].splice($index, 1);
			});
		}
	};

	vm.deleteNotification = function deleteNotification($index, id) {
		if (!id) {
			return vm.data["notification"].splice($index, 1);
		}

		if (page_entity_id) {
			REST.delete({
				url: 'notification/:entityId',
				params: {
					entityId: id
				}
			}).then(function (data) {
				vm.data.notification.splice($index, 1);
			});
		}
	};

	$("#check-http").blur(function () {
		var input = $(this);
		var val = $(this).val();
		if (val && !val.match(/^.+:\/\/.*/)) {
			vm.data.link = 'http://' + vm.data.link;
		}
	});

	vm.setAllToSecondary = function setAllToSecondary($index, entity) {
		angular.forEach(vm.data[entity], function (value, key) {
			if ($index != key) {
				value.is_primary = 0;
			}
		});
	};

	vm.quickAddReference = function ($index, activity_project) {
		vm.openModal({
			templateUrl: "legal-case-project-reference-quick-add",
			controller: 'activityProjectReferenceQuickAddCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					return {
						index: -1,
						activity_project: activity_project,
						activity: vm.data
					};
				},
				bladeParams: function () {
					return vm.bladeParams;
				}
			}
		}).then(function(response) {
			//autocomplete with new reference
			activity_project.reference = {};
			activity_project.reference_id = activity_project.reference.project_id = response.entity.project_id;
			activity_project.reference.project_name = response.entity.project_name;
		});
	};

	vm.validateSomeInputs = function () {
		vm.custom_errors = null;
		vm.has_error = false;

		if((parseInt(vm.data.activity_type_id) == 4 || parseInt(vm.data.activity_type_id) == 5)) {
			if (!vm.custom_errors) vm.custom_errors = [];
			// casa de avocatura - Firma obligatoriu
			if (vm.data.bdoffer && parseInt(vm.data.bdoffer.offer_source_id) == 1 && !vm.data.customer_fiscal_entity_id) {
				$('[ng-model="vm.data.customer_fiscal_entity_id"] .select2-selection--single').css("border", "1px solid red");
				vm.custom_errors.push(vm.trans('LANG.FIRM') + ' ' + vm.trans('IS_REQUIRED'));
				vm.has_error = true;
			} else {
				$('[ng-model="vm.data.customer_fiscal_entity_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
			}
			if (vm.data.bdoffer && !vm.data.bdoffer.email_financial_offer && API.user.tenant.short_name == 'nndkp') {
				$('[ng-model="vm.data.bdoffer.email_financial_offer"]').css("border", "1px solid red");
				vm.custom_errors.push(vm.trans('LANG.EMAIL_FINANCIAL_OFFER') + ' ' + vm.trans('IS_REQUIRED'));
				vm.has_error = true;
			} else {
				$('[ng-model="vm.data.bdoffer.email_financial_offer"]').css("border", "1px solid #c2cad8");
			}

			// contacte personale - Contact sursa obligatoriu
			if (vm.data.bdoffer && parseInt(vm.data.bdoffer.offer_source_id) == 2 && !vm.data.bdoffer.offer_source_contact_id) {
				$('[ng-model="vm.data.bdoffer.offer_source_contact_id"] .select2-selection--single').css("border", "1px solid red");
				vm.custom_errors.push(vm.trans('LANG.OFFER_SOURCE_CONTACT_PEOPLE') + ' ' + vm.trans('IS_REQUIRED'));
				vm.has_error = true;
			} else {
				$('[ng-model="vm.data.bdoffer.offer_source_contact_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
			}

			// client - Client sursa obligatoriu
			if (vm.data.bdoffer && parseInt(vm.data.bdoffer.offer_source_id) == 7 && !vm.data.bdoffer.source_customer_id) {
				$('[ng-model="vm.data.bdoffer.source_customer_id"] .select2-selection--single').css("border", "1px solid red");
				vm.custom_errors.push(vm.trans('LANG.SOURCE_CUSTOMER') + ' ' + vm.trans('IS_REQUIRED'));
				vm.has_error = true;
			} else {
				$('[ng-model="vm.data.bdoffer.source_customer_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
			}

			// evenimente, ghiduri, publicatii, organizatii si asociatii, altul - Detalii obligatoriu
			if (vm.data.bdoffer && ([4, 5, 6, 9, 11].indexOf(parseInt(vm.data.bdoffer.offer_source_id)) !== -1) && !vm.data.bdoffer.offer_source_comment) {
				$('[ng-model="vm.data.bdoffer.offer_source_comment"]').css("border", "1px solid red");
				vm.custom_errors.push(vm.trans('LANG.OFFER_SOURCE_COMMENT_REQUIRED'));
				vm.has_error = true;
			} else {
				$('[ng-model="vm.data.bdoffer.offer_source_comment"]').css("border", "1px solid #c2cad8");
			}
		}

		if(vm.has_error){
			$('.custom_error_messages').css('display', 'block');
		} else {
			$('.custom_error_messages').css('display', 'none');
			vm.save();
		}

	};


	return vm;
});
