kv.controller('legalCaseProjectReferenceQuickAddCtrl', function ($uibModalInstance, params, bladeParams, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'project', 'project_id', $scope, params);
	vm.isNewRecord = true;
	vm.newReference = JSON.parse(bladeParams.defaultObjectReference);
	vm.data = vm.newReference;
	vm.data.project_name = params.legal_case.number;
	vm.data.parent_id = params.legal_case_project.project_id;
	vm.data.customer_id = params.legal_case.customer_id;

	return vm;
});
