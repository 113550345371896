kv.controller('feeTypeListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "fee_type_id",
		entity: "fee_type",
		modal: {
			templateUrl: "fee_type-edit",
			controller: "feeTypeEditCtrl"
		}
	});
});
