kv.controller('CostPerActivityReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var data = JSON.parse(vm.bladeParams.data);
    var reporting_currency = vm.bladeParams.reportingCurrency;
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }
    
    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": false,
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');
    });

    vm.isLoading = true;

    var chartCategories = data[1];
    var chartSeries = data[2];

    function chart1() {
        $('#chart1').hide();
        
        var config = {
            chart: {
                    type: 'area'
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    categories: chartCategories,
                    tickmarkPlacement: 'on',
                    title: {
                        enabled: false
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    split: true,
                    valueSuffix: ' ' + reporting_currency,
                    shared: true
                },
                plotOptions: {
                    area: {
                        stacking: 'normal',
                        lineColor: '#666666',
                        lineWidth: 1,
                        marker: {
                            lineWidth: 1,
                            lineColor: '#666666'
                        }
                    }
                },
                credits: {
                  enabled: false
                },
                series: chartSeries
        }

        $('#chart1').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#chart1').show();
            $('#chart1').highcharts().reflow();
        }, 1);
    }

    function chart2() {
        $('#chart2').hide();
        
        var config = {
            chart: {
                    type: 'area'
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    categories: chartCategories,
                    tickmarkPlacement: 'on',
                    title: {
                        enabled: false
                    }
                },
                yAxis: {
                    title: {
                        text: 'Percent'
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.0f} ' + reporting_currency + ' )<br/>',
                    shared: true
                    // split: true
                },
                plotOptions: {
                    area: {
                        stacking: 'percent',
                        lineColor: '#ffffff',
                        lineWidth: 1,
                        marker: {
                            lineWidth: 1,
                            lineColor: '#ffffff'
                        }
                    }
                },
                credits: {
                  enabled: false
                },
                series: chartSeries
        }

        $('#chart2').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#chart2').show();
            $('#chart2').highcharts().reflow();
        }, 1);
    }

    if (chartSeries) {
        chart1();
        chart2();
    }

	return vm;
});
