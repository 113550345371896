kv.controller('budgetItemListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "budget_item_id",
		entity: "budget_item",
		modal: {
			templateUrl: "budget_item-edit",
			controller: "budgetItemEditCtrl"
		}
	});
});
