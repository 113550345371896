kv.controller('bpiFiscalEntityListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'bpi_fiscal_entity_id';
	vm.entity = 'bpi_fiscal_entity';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'bpi_fiscal_entity'
		},
		url: "bpi_fiscal_entity",
	});
	
	vm.delete = function deleteEntity($index) {
		$http.delete("bpi_fiscal_entity/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
			vm.infinitScroll.items.splice($index, 1);
		});
	};

	vm.addEditEntity = function (entity, $index) {
        vm.openModal({
            templateUrl: "bpi_fiscal_entity-edit",
            controller: 'bpiFiscalEntityEditCtrl',
            controllerAs: 'vm',
            size: 'md',
			  resolve: {
            	params: {
            		index: $index,
            		entity: entity
					}
			  }
        }).then(function (response) {
        	// vm.infinitScroll.items.push(response.data);
        	window.location = "/bpi_fiscal_entity";
        });
    };

	// vm.getInsolvencyDetail = function(fiscal_id) {
 //        var modalInstance = $uibModal.open({
 //            animation: true,
 //            ariaLabelledBy: 'modal-title',
 //            ariaDescribedBy: 'modal-body',
 //            templateUrl: 'insolvency-detail',
 //            controller: function($scope, $uibModalInstance, resolve, cmdate, $http) {
 //                var vm = this;

 //                vm.cancel = cancel;

 //                vm.$onInit = function() {
 //                    if(resolve && resolve.fiscal_id) {
 //                        vm.fiscal_id = resolve.fiscal_id;
 //                    }
 //                };

 //                $http({
 //                    method: 'GET',
 //                    url: 'https://onrc.dev.softventure.ro/api/bpi?cui=' + fiscal_id,
 //                    headers: {
 //                        "Content-Type": "application/json"
 //                    }
 //                }).then(function(response) {
 //                    if (response.data) {
 //                        vm.nume = response.data.data[0]["nume"];
 //                        vm.judet = response.data.data[0]["judet"];
 //                        vm.cui = response.data.data[0]["cui"];
 //                        vm.acts = response.data.data[0]["acte"];
 //                    } else {
 //                        console.log('error loading')
 //                    }
 //                });

 //                function cancel() {
 //                    $uibModalInstance.dismiss('cancel');
 //                };
 //            },
 //            controllerAs: 'vm',
 //            size: 'xxl',
 //            resolve: {
 //                resolve: function() {
 //                    return {
 //                        fiscal_id: fiscal_id,
 //                    };
 //                }
 //            }
 //        })

 //        modalInstance.result.then(function(data) {
 //            console.log('close');
 //        });
 //    }

	return vm;
});

