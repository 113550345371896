kv.controller('documentSeriesListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "document_series_id",
		entity: "document_series",
		modal: {
			templateUrl: "document_series-edit",
			controller: "documentSeriesEditCtrl"
		}
	});
});
