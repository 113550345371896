kv.controller('CustomerActivityReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    let initParams = kvUtils.getAllQueryParams();
    
    if (initParams) {
        if (initParams.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }


    $(document).ready(function (){

        if (API.user.tenant.short_name == 'nndkp') {
            var table = $('#more-details').DataTable({
                'autoWidth': false,
                'fixedHeader': {
                    header: false,
                    footer: true
                },
                'responsive': true,
                "paging":   false,
                'order': [[1, 'asc']],
                "columns": [
                    {
                        'orderable':      false,
                        'data':           null,
                        'defaultContent': ''
                    },                              // plus
                    null,                           // date
                    null,                           // user
                    null,                           // customer
                    null,                           // project
                    null,                           // comments
                    null,                           // effort
                    null,                           // value
                    {
                        'orderable': false,
                        // 'width': '21%'
                    },                              // status
                    null,                           // extras - invoice number
                    null,                           // extras - fiscal entity name
                    null,                           // extras - reference name
                ]
            });
        } else {
            var table = $('#more-details').DataTable({
                'autoWidth': false,
                'fixedHeader': {
                    header: false,
                    footer: true
                },
                'responsive': true,
                "paging":   false,
                'order': [[1, 'asc']],
                "columns": [
                    {
                        'orderable':      false,
                        'data':           null,
                        'defaultContent': ''
                    },                              // plus
                    null,                           // date
                    null,                           // user
                    null,                           // customer
                    null,                           // project
                    null,                           // comments
                    null,                           // effort
                    null,                           // blb effort
                    null,                           // value
                    {
                        'orderable': false,
                        // 'width': '21%'
                    },                              // status
                    null,                           // extras - invoice number
                    null,                           // extras - fiscal entity name
                    null,                           // extras - reference name
                ]
            });
        }

        $('#more-details').on('click', 'td.details-control', function () {
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            if (row.child.isShown()) {
                // sectiune de extra detalii este deschisa, aplica clasa 'shown' - afiseaza butonul cu '-' rosu
                tr.addClass('shown');
            } else {
                // sectiunea de extra detalii este inchisa, nu se mai aplica clasa 'shown' - afiseaza butonul cu '+' verde
                tr.removeClass('shown');
            }
        });

    });


    return vm;
});
