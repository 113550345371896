kv.controller('calendarViewCompanyEventCtrl', function EventAttendeeFormDialogController($uibModalInstance, calendarEvent, resource) {
	var vm = this;
	// Data
	vm.calendarEvent = calendarEvent;
	vm.closeDialog = closeDialog;
	vm.action = {
		subscribeInterested: subscribeInterested,
		subscribeGoing: subscribeGoing,
	};
	var statusGoing = 5;
	var statusInterested = 6;

	/**
	 * Close the dialog
	 */
	function closeDialog() {
		$uibModalInstance.dismiss('cancel');
	}

	var REST = resource.init('attendee');
	REST.getArray({
		url: 'attendee/checkAttendee/:activity_id/:people_id',
		params: {
			activity_id: vm.calendarEvent.entity_id,
			people_id: '{{ \Auth::user()->user_id }}'
		}
	}).then(function (data) {
		if (data[0] && typeof data[0]['status_id'] != 'undefined') {
			vm.attendeeData = data[0];
			vm.status = data[0]['status_id'];
		} else {
			vm.attendeeData = "";
			vm.status = "";
		}
	})

	if (calendarEvent.end == null) {
		vm.start = calendarEvent.start;
		vm.end = vm.start;
	} else {
		vm.start = calendarEvent.start;
		vm.end = calendarEvent.end;
	}

	function subscribeInterested() {
		if (vm.attendeeData) {
			updateStatus(statusInterested);
		} else {
			addAttendee(statusInterested);
		}
	}

	function subscribeGoing() {
		if (vm.attendeeData) {
			updateStatus(statusGoing);
		} else {
			addAttendee(statusGoing);
		}
	}

	function updateStatus(status) {
		REST.update({
			url: 'attendee/addOrUpdate/' + vm.attendeeData.attendee_id,
			data: {
				status_id: status
			}
		}).then(function (data) {
			closeDialog();
		})
	}

	function addAttendee(status) {
		REST.create({
			url: 'attendee/addOrUpdate',
			data: {
				tenant_id: '{{ \Auth::user()->tenant_id }}',
				activity_id: vm.calendarEvent.entity_id,
				people_id: '{{ \Auth::user()->user_id }}',
				people_type: 'user',
				status_id: status,
			},
		}).then(function (data) {
			closeDialog();
		})
	}
});


