kv.controller('ActivityBudgetListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'activity_budget_id';
	vm.entity = 'activity_budget';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'activity_budget'
		},
		url: "activity_budget"
	});

	vm.addEditEntity = function (activity_budget_id) {
		vm.openModal({
			templateUrl: "activity_budget-edit",
			controller: 'activityBudgetEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					return {
                        activity_budget_id: activity_budget_id,
                    };
				},
				saveCallback: function () {
					vm.infinitScroll.search.refresh++;
				},
			}
		}).then(response => {
			if (!response) return;
			window.location = "/activity_budget";
		});
	};

	vm.deleteEntity = function ($index) {
		vm.openModal({
			component: 'modalConfirm',
			resolve: {
				content: () => {
					return $filter("trans")("ARE_YOU_SURE_DELETE");
				},
				title: () => {
					return $filter("trans")("DELETE");
				}
			}
		}).then(function () {
			var REST = resource.init("activity_budget");

			REST.delete({
				params: {
					id: vm.infinitScroll.items[$index][vm.primaryKey]
				}
			}).then(function () {
				vm.infinitScroll.items.splice($index, 1);
				toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
			});
		});
	};
	return vm;
});

