kv.controller('freemiumSubscriptionCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'subscription', 'tenant_id', $scope, params);

    vm.tenant = params.tenant ? params.tenant : {};
    vm.saveCallback = saveCallback;

    KApp.block('.modal-body');

    let rest = resource.init('subscription');
    rest.get({
        url: "subscription/" + "getEntitiesForFreemium",
    }).then(function (response) {
        KApp.unblock('.modal-body');
        console.log(response);
        vm.data = response;
    });


    vm.save = function () {
        let postData = angular.copy(vm.data);
        console.log("postdata", postData);
        rest.post({
            url: "subscription/addFreemiumSubscription",
            data: postData
        }).then(function (response) {
            console.log(response);
            if (response.status && response.status == true) {
              //  if (vm.saveCallback) vm.saveCallback(response, vm.modal);
                $('#freemium').hide();
                $('#freemium-footer').hide();
                $("#error-dialog").modal("show");

                this.window.location.href = window.origin + '/auth/logout';
            }
        });
    };

    vm.validateSomeInputs = function () {
        vm.custom_errors = null;
        vm.has_error = false;
        vm.countCustomers = 0;
        vm.countlegalCases = 0;
        vm.countCustomerFiscalEntities = 0;

        // se numara entitatile selectate astfel incat numarul lor sa nu depaseasca limita impusa de catre tipul de cont Freemium
        // in cazul in care toate selectiile sunt valide, se va efectua aderarea la contul de tip Freemium, in caz contrar, va fi afisat un mesaj de eroare
        angular.forEach(vm.data.customerNames, function (v, i) {
            if(vm.data.customerNames[i].checkbox_customer && vm.data.customerNames[i].checkbox_customer == true){
                vm.countCustomers++;
            }
        });

        if(vm.countCustomers > 5){
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.NUMBER_OF_CUSTOMERS_SELECTED_IS_EXCEEDED'));
            vm.has_error = true;
        }

        angular.forEach(vm.data.legalCaseNames, function (v, i) {
            if(vm.data.legalCaseNames[i].checkbox_legal_case && vm.data.legalCaseNames[i].checkbox_legal_case == true){
                vm.countlegalCases++;
            }
        });

        if(vm.countlegalCases > 5){
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.NUMBER_OF_LEGAL_CASES_SELECTED_IS_EXCEEDED'));
            vm.has_error = true;
        }

        angular.forEach(vm.data.customerFiscalEntitiesNames, function (v, i) {
            if(vm.data.customerFiscalEntitiesNames[i].checkbox_customer_fiscal_entity && vm.data.customerFiscalEntitiesNames[i].checkbox_customer_fiscal_entity == true){
                vm.countCustomerFiscalEntities++;
            }
        });

        if(vm.countCustomerFiscalEntities > 5){
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.NUMBER_OF_CUSTOMER_FISCAL_ENTITIES_SELECTED_IS_EXCEEDED'));
            vm.has_error = true;
        }

        if(vm.has_error){
            $('.custom_error_messages').css('display', 'block');
        } else {
            $('.custom_error_messages').css('display', 'none');
            vm.save();
        }
    };

    vm.cancel = function () {
        vm.saveCallback('cancel', vm.modal);
    };

    return vm;
});
