kv.controller('calendarSyncCtrl', function ($uibModalInstance, data, resource) {
	var vm = this;
	var REST = resource.init('calendar');
	$uibModalInstance.rendered.then(() => {
		KApp.block('.modal-content', {});
		let bladeParamsEl = angular.element('#calendar-sync-params');
		vm.calendarICalAction = bladeParamsEl.attr('calendar-ical-action');
	});
	REST.get({
		url: 'calendar/ical',
		params: {
			user_id: data.search.user_id,
			filters: [data.search.event_type],
			date_from: data.search.start,
			date_to: data.search.end,
		}
	}).then(function (res) {
		KApp.unblock('.modal-content', {});
		vm.hash = res.hash;
		vm.calendarUrl = 'webcal://' + vm.calendarICalAction.replace('http://','').replace('https://','') + '?hash='+ vm.hash ;
	});

	vm.cancel=function() {
		$uibModalInstance.dismiss('cancel');
	};
});
