kv.controller('offerSourceListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "offer_source_id",
		entity: "offer_source",
		modal: {
			templateUrl: "offer_source-edit",
			controller: "offerSourceEditCtrl"
		}
	});
});
