angular
    .module('kv')
    .directive('onEscape', onEscape);

function onEscape() {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.key === "Escape" || event.key === "Esc") {
                scope.$apply(function (){
                    scope.$eval(attrs.onEscape);
                });

                event.preventDefault();
            }
        });
    };
}
