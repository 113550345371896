kv.controller('internalCategoryListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "internal_category_id",
		entity: "internal_category",
		modal: {
			templateUrl: "internal_category-edit",
			controller: "internalCategoryEditCtrl"
		}
	});
});
