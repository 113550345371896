kv.controller('registerEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'register_id';
    vm.entity = 'register';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');

    // let cmdate = vm.getInjection('cmdate');

    var REST = resource.init(vm.entity);
    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];

    if(page_identifier == 'edit') {
        $http.get("/register/" + page_entity_id).then(function(response) {
            vm.data = response.data;
        });
    } else {
        vm.data = {};
        vm.isNewRecord = true;

        let defaultObjects = JSON.parse($element.find("#defaultObjects").attr("defaultObjects"));
        vm.data = defaultObjects;
        vm.data.user= [];
        vm.data.register_type = null;
        vm.data.user_id = API.user.user_id;
        vm.data.user.full_name = API.user.full_name;
        vm.data.tenant_fiscal_entity_id = vm.data.tenant_fiscal_entity.tenant_fiscal_entity_id;

        /*angular.forEach(vm.data.register_series, function (v, i) {
            vm.data.register_in_prefix = v.register_in_prefix;
            vm.data.register_in_next_number = v.register_in_next_number;
            vm.data.register_out_prefix = v.register_out_prefix;
            vm.data.register_out_next_number = v.register_out_next_number;
        });*/
    }

   /* $scope.$watch('vm.data.register_type', function (value) {
        if(value && vm.data.register_type === 'IN_REGISTER'){
            if((vm.data.register_in_prefix && vm.data.register_in_prefix !== null) || (vm.data.register_in_next_number && vm.data.register_in_next_number !== null)){
                if(vm.data.register_in_prefix === null){
                    vm.data.serial_number = vm.data.register_in_next_number;
                }else{
                    vm.data.serial_number = vm.data.register_in_prefix.concat(vm.data.register_in_next_number);
                }
            }
        } else {
            if(value && vm.data.register_type === 'OUT_REGISTER'){
                if((vm.data.register_out_prefix && vm.data.register_out_prefix !== null) || (vm.data.register_out_next_number && vm.data.register_out_next_number !== null)){
                    if(vm.data.register_out_prefix === null){
                        vm.data.serial_number = vm.data.register_out_next_number;
                    }else{
                        vm.data.serial_number = vm.data.register_out_prefix.concat(vm.data.register_out_next_number);

                    }
                }
            }
        }
    });*/

    vm.newEntity = function newEntity(entity) {
        if(!vm.data) {
            vm.data = {};
        }

        if(!vm.data[entity]) {
            return vm.data[entity] = [{}];
        }

        return vm.data[entity].push({});
    };

    vm.deleteEntity = function deleteEntity($index, entity, id) {
        if(!id) {
            return vm.data[entity].splice($index,1);
        }

        if(page_entity_id) {
            REST.delete({
                url: vm.entity + '/:id/' + entity + '/:entityId',
                params: {
                    id: page_entity_id,
                    entityId: id
                }
            }).then(function(data) {
                vm.data[entity].splice($index,1);
            });
        }
    };

    vm.prepareDataToSave = function () {
        let postData = angular.copy(vm.data);
        // used for validations: if contract is normal created, do validations, else not (case project, where required data is sent from backend)
        postData.basic_create = true;
        return postData;
    };

    vm.saveRegister = function () {
        var REST = vm.getInjection('resource').init('register');
        if(vm.data.register_id) {
           vm.save();
        }else{
            REST.create({
                url: "register",
                data: vm.data
            }).then(response => {
                if (response.data && response.data.status === false ) {
                    toastr.error(response.data.error.message);
                } else {
                    window.location = "/register/" + response.data.register_id;
                }
            });
        }
    };

    return vm;
});
