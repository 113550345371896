(function () {
	'use strict';

	angular.module('kv').factory('searchInstance', search);

	function search(API, $rootScope) {
		return function () {
			var search = {};
			let filterTimeout;
			var appliedFilters = {};

			var filtresReseted = false;
			$rootScope.$on("resetFilters", function () {
				filtresReseted = true;
			});
			search.watch = function (vars) {
				// console.debug("search-instance init", vars);
				var search_object = vars.search;
				var newValues = vars.newValues;
				var oldValues = vars.oldValues;
				var callback = vars.callback;
				var fixPager = vars.fixPager;

				var filters = {};
				var timeoutWait = 200;

				if (!$rootScope.searchNotFirst) {
					$rootScope.searchNotFirst = "INIT";
					timeoutWait = 0;
				}
				angular.forEach(search_object, function (value, key) {
					//if no filter has been made, make list request
					if (!newValues) {
						return;
					}

					if (key === "page") {
						if (newValues[key] === oldValues[key]) {
							try {
								fixPager();
							} catch (e) {
							}
						} else {
							timeoutWait = 0;
						}
					}

					//if in vm.search exist key "q" add filter for this
					if (key === "q") {
						//if "q" new value if not empty and length is equal or more than 3 add filter for this
						if (newValues[key] !== null && newValues[key].length >= 3) {
							filters[key] = newValues[key];
						}
						//if "q" new value is null or emptry, we need to make a request to get all
						if (oldValues && newValues[key] !== oldValues[key] && (newValues[key] === null || newValues[key] === "")) {
							filters[key] = newValues[key];
						}
					}

					//for others keys in vm.search add filter
					if (key !== "q") {
						//if filter input new vaule is not empty, add filter for these
						if (newValues[key] !== null) {
							if (newValues[key] instanceof Date) {
								newValues[key] = newValues[key].toLocaleDateString();
							}
							filters[key] = newValues[key];
						}
						//if filter input has changed filter again
						if (oldValues && newValues[key] !== oldValues[key]) {
							if (newValues[key] instanceof Date) {
								newValues[key] = newValues[key].toLocaleDateString();
							}
							filters[key] = newValues[key];
						}
					}
				});

				//clear previews timeout
				clearTimeout(filterTimeout);
				//set 500 miliseconds timeout to delay search
				if (filters.refreshList !== undefined) {
					delete filters.refreshList;
				}
				filterTimeout = setTimeout(function () {
					filterFn(filters, callback);
				}, timeoutWait);
			};

			function filterFn(filters, callback) {
				var makeRequestWithFilters = false;
				 // console.debug("search-instance filters", filters);

				angular.forEach(filters, function (value, key) {
					// if filters array contains one element with a value othern than null, will make an request with filters
					if (value !== null) {
						makeRequestWithFilters = true;
					}
				});

				// if filters object not equals with applied filters
				// or filters object is empty
				// or filtres reseted
				// apply filters
				if (!angular.equals(appliedFilters, filters) || !filters.length || filtresReseted) {
					if (!makeRequestWithFilters || filtresReseted) {
						filters = {};
					}
					// check if filters elements are object
					// for each elemenet who is object, join element values with comma
					angular.forEach(filters, function (value, key) {
						if (typeof value === "object") {
							var values = [];
							angular.forEach(value, function (v, k) {
								values.push(v.id !== undefined? v.id: v.value);
							});
							if (values.length) {
								filters[key] = values.join(",");
							}
						}
					});
					appliedFilters = angular.copy(filters);
					// console.debug("search-instance applied", appliedFilters);

					if (Object.keys(filters).length || $rootScope.searchNotFirst === "INIT" || filtresReseted) {
						$rootScope.searchNotFirst = "FINAL";
						filtresReseted = false;
						return callback(filters);
					}
					filtresReseted = false;
				}
			}

			return search;
		};
	}
}());
