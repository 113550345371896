(function () {
	'use strict';

	angular
		.module('kv')
		.filter('trans', trans);

	function trans($filter, $localStorage, $parse) {
		return function (input) {
			let lang = $localStorage.lang ? $localStorage.lang.data : [];
			if (!input) {
				return '';
			}
			if (input.startsWith('LANG.')) {
				input = input.toUpperCase();
				input = input.substring(5);
			}
			if (input.indexOf(" ") > -1) {
				return input;
			} else
				try {
					if (input.indexOf(".") > -1) {
						return $parse('LANG.' + input)({LANG: lang});
					} else if (typeof lang[input] !== "undefined") {
						return lang[input];
					} else {
						// console.error(input, "- not found in lang json");
					}
					return input;
				} catch (Exception) {
					//daca sirul contine caractere nepermise se ajunge aici
					return input;
				}
		};
	}

})();
