kv.controller('ExpenseStandardImportEditCtrl', function ($uibModalInstance, params, $scope, $injector) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'expense', 'expense_id', $scope, params);
    vm.data = angular.copy(params.entity);
    vm.columns = params.columns;
    vm.reversedColumns = params.reversedColumns;
    vm.validate = function () {
        let postData = {};
        // this time, processed became source ;
        if (vm.data.processed.user) postData[vm.reversedColumns.user_id] = vm.data.processed.user_id;
        if (vm.data.processed.user) postData[vm.reversedColumns.user_name] = vm.data.processed.user.full_name ? vm.data.processed.user.full_name : vm.data.processed.user;
        if (vm.data.processed.project) postData[vm.reversedColumns.project_number] = vm.data.processed.project.project_number ? vm.data.processed.project.project_number : vm.data.processed.project;
        if (vm.data.processed.project) postData.project_id = vm.data.processed.project_id;
        if (vm.data.processed.reference) postData[vm.reversedColumns.reference_name] = vm.data.processed.reference.project_name ? vm.data.processed.reference.project_name : vm.data.processed.reference;
        if (vm.data.processed.reference) postData.reference_id = vm.data.processed.reference_id;
        if (vm.data.processed.internal_project) postData[vm.reversedColumns.project_code] = vm.data.processed.internal_project.project_code ? vm.data.processed.internal_project.project_code : vm.data.processed.internal_project.project_code;
        if (vm.data.processed.internal_project) postData.internal_project_id = vm.data.processed.internal_project_id;
        if (vm.data.processed.date) postData[vm.reversedColumns.date] = vm.data.processed.date;
        if (vm.data.processed.quantity) postData[vm.reversedColumns.quantity] = vm.data.processed.quantity;
        if (vm.data.processed.in_name_of) postData[vm.reversedColumns.in_name_of] = vm.data.processed.in_name_of;
        if (vm.data.processed.expense_type) postData[vm.reversedColumns.expense_type_name] = vm.data.processed.expense_type.name ? vm.data.processed.expense_type.name : vm.data.processed.expense_type;
        if (vm.data.processed.expense_type) postData.expense_type_id = vm.data.processed.expense_type_id;
        if (vm.data.processed.total_with_vat) postData[vm.reversedColumns.total_with_vat] = vm.data.processed.total_with_vat;
        if (vm.data.processed.currency) postData[vm.reversedColumns.currency] = vm.data.processed.currency.currency_name ? vm.data.processed.currency.currency_name : vm.data.processed.currency;
        if (vm.data.processed.currency) postData.currency_id = vm.data.processed.currency_id;
        if (vm.data.processed.vat_type) postData[vm.reversedColumns.vat_type] = vm.data.processed.vat_type.vat_code ? vm.data.processed.vat_type.vat_code : vm.data.processed.vat_type;
        if (vm.data.processed.vat_type) postData.vat_type_id = vm.data.processed.vat_type_id;
        if (vm.data.processed.billing_type) postData[vm.reversedColumns.billing_type] = vm.data.processed.billing_type;

        vm.rest.post({
            url:"expense/checkImportRecord",
            data: postData
        }).then(response=>{
            if (response.status)  {
                if (response.data.hasError) vm.data = response.data;
                else vm.close({entity: response.data});
            }
        });
    };
    vm.changeSource = function (fieldName) {
        switch (fieldName) {
            case 'customer_name':
                if (vm.data.processed.customer) vm.data.processed.customer_id = vm.data.processed.customer.customer_id;
                vm.data.processed.project = undefined;
                vm.data.processed.project_id = undefined;
                vm.data.processed.reference = undefined;
                vm.data.processed.reference_id = undefined;
                break;
            case 'court':
                break;
        }
    };
    return vm;
});

