kv.controller('customerViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $timeout) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'customer_id';
	vm.entity = 'customer';
	vm.entityType = 'customer';
	vm.entityId = vm.bladeParams.customerId;

	vm.commentsConfig = {
		entity_type: vm.entity
	};

	// loader pt. portlet de Entitati fiscale
	KApp.block('#loader_widget_fiscal_entities');
	// folosit pentru a afisa butoanele din portletul de entitati fiscale dupa incarcarea datelor
    vm.portlet_loading = true;

	$(document).ready(function () {
		$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
			$(e.target.hash).find('.chart').each(function () {
				if ($(this).highcharts()) {
					$(this).highcharts().reflow();
				}
			});
		});
	});

	vm.futureActivities = {
		template: 'activities',
		url: API.URL + "activity/activities_with_contact",
		filter: {
			customer_id: vm.entityId,
			range: 'future',
		},
		title: $filter('trans')('FUTURE_ACTIVITIES')
	};

	var REST = resource.init(vm.entity);
	REST.get({
		id: vm.entityId
	}).then(function (data) {
		vm.portlet_loading = false;
		KApp.unblock('#loader_widget_fiscal_entities');
		vm.data = data;
		vm.getKpiForDashboard();
	});

	vm.getKpiForDashboard = function(filterInterval = null) {
		var REST = resource.init('customer');
		REST.getArray({
			url: 'customer/' + vm.entityId,
			params: {
				action: 'kpi',
				interval: (filterInterval ? filterInterval : 'this_year')
			}
		}).then(function (response) {
			KApp.unblock('#loader_dashboard');
			chartsData = {
				chart1data: response[0].userChartData,
				chart2data: response[0].practiceChartData,
				chart3data: response[0].seniorityChartData,
				chart4data: response[1][0],
				chart5data: response[3]
			};
			vm.customerLastYearOffers = response[2];
			vm.customerKPI = response[0];
			vm.activeProjects = response[4];
			vm.chart1Error = chartsData.chart1data.length < 1;
			vm.chart2Error = chartsData.chart2data.length < 1;
			vm.chart3Error = chartsData.chart3data.length < 1;
			vm.chart4Error = chartsData.chart4data.length < 1;
			vm.chart5Error = Object.keys(chartsData.chart5data).length < 1;
			$timeout(function () {
				renderCharts(chartsData);
			}).catch(response => {
				console.error(response);
			});
		});
	};

	vm.changeInterval = function(interval) {
		vm.filter_customer_data = interval;
		KApp.block('#loader_dashboard');
		vm.getKpiForDashboard(interval);
	}

	vm.chartUser_is_rendered = false;
	vm.chartUser = function(chart1Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartUser_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart1Data

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if(!redraw) {
			$('#chart1').highcharts(config);
		} else {
			$('#chart1').highcharts(config);
			$('#chart1').highcharts().redraw();
		}
	};

	vm.chartPractice_is_rendered = false;
	vm.chartPractice = function(chart2Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartPractice_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart2Data

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart2').highcharts(config);
		} else {
			$('#chart2').highcharts(config);
			$('#chart2').highcharts().redraw();
		}
	};

	vm.chartSeniority_is_rendered = false;
	vm.chartSeniority = function(chart3Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartSeniority_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart3Data

			}]
		}
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart3').highcharts(config);
		} else {
			$('#chart3').highcharts(config);
			$('#chart3').highcharts().redraw();
		}
	};

	vm.chartCurrentYearOffers_is_rendered = false;
	vm.chartCurrentYearOffers = function(chart4Data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
						vm.chartCurrentYearOffers_is_rendered = true;
					}
				},
			},
			tooltip: {
				pointFormat: '<b>{point.percentage:.1f}%</b>'
			},
			title: {
				text: ""
			},
			legend: {
				enabled: false,
				align: "right",
				layout: "vertical",
				borderWidth: 0,
				verticalAlign: "top"
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true
					},
					showInLegend: true
				}
			},
			series: [{
				name: '',
				colorByPoint: true,
				innerSize: '60%',
				data: chart4Data
			}]
		}
		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart4').highcharts(config);
		} else {
			$('#chart4').highcharts(config);
			$('#chart4').highcharts().reflow();
		}
	};

	vm.chartOffersByStatus_is_rendered = false;
	vm.chartOffersByStatus = function(chart5data, redraw) {
		var config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'column',
				events: {
					load: function () {
						this.reflow();
						vm.chartOffersByStatus_is_rendered = true;
					}
				},
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			subtitle: {
				text: null
			},
			xAxis: {
				categories: chart5data['categories'],
				tickmarkPlacement: 'on',
				title: {
					enabled: false
				}
			},
			yAxis: {
				title: {
					text: null
				},
				labels: {
					formatter: function () {
						return this.value;
					}
				}
			},
			tooltip: {
				shared: true,
				valueSuffix: ' ' + ($filter('trans')("OFFERS")).toLowerCase()
			},
			plotOptions: {
				column: {
					stacking: 'normal'
				},
				spline: {
					marker: {
						radius: 4,
						lineColor: '#666666',
						lineWidth: 1
					}
				}
			},
			series: [{
				name: $filter('trans')('SUBMITTED'),
				data: chart5data['SUBMITTED'],
				color: '#769efe'
			}, {
				name: $filter('trans')('LOST'),
				data: chart5data['LOST'],
				color: '#fb899e'
			}, {
				name: $filter('trans')('WON'),
				data: chart5data['WON'],
				color: '#bedd8d'
			}, {
				name: $filter('trans')('IN_PREPARATION'),
				data: chart5data['IN_PREPARATION'],
				color: '#a9a0d6',
				// visible: false
			}, {
				name: $filter('trans')('ABANDONED'),
				data: chart5data['ABANDONED'],
				color: '#ffc57c',
				// visible: false
			}, {
				name: $filter('trans')('CONFLICTED'),
				data: chart5data['CONFLICTED'],
				color: '#A47D7C',
				// visible: false
			}, {
				name: $filter('trans')('SUSPENDED'),
				data: chart5data['SUSPENDED'],
				color: '#bbbbbb',
				// visible: false

			}]
		}

		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if (!redraw) {
			$('#chart5').highcharts(config);
		} else {
			$('#chart5').highcharts(config);
			$('#chart5').highcharts().reflow();
		}
	};

	function renderCharts(chartsData) {
		if (chartsData.chart1data.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartUser_is_rendered) {
				vm.chartUser(chartsData.chart1data, true);
			} else {
				vm.chartUser(chartsData.chart1data, false);
				$('#chart1').show();
			}
		}

		if (chartsData.chart2data.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartPractice_is_rendered) {
				vm.chartPractice(chartsData.chart2data, true);
			} else {
				vm.chartPractice(chartsData.chart2data, false);
				$('#chart2').show();
			}
		}

		if (chartsData.chart3data.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartSeniority_is_rendered) {
				vm.chartSeniority(chartsData.chart3data, true);
			} else {
				vm.chartSeniority(chartsData.chart3data, false);
				$('#chart3').show();
			}
		}

		if (chartsData.chart4data.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartCurrentYearOffers_is_rendered) {
				vm.chartCurrentYearOffers(chartsData.chart4data, true);
			} else {
				vm.chartCurrentYearOffers(chartsData.chart4data, false);
				$('#chart4').show();
			}
		}

		if (Object.keys(chartsData.chart5data).length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chartOffersByStatus_is_rendered) {
				vm.chartOffersByStatus(chartsData.chart5data, true);
			} else {
				vm.chartOffersByStatus(chartsData.chart5data, false);
				$('#chart5').show();
			}
		}
	}
	vm.addExistingFiscal = function() {
		vm.data.customer_fiscal_entity.push({});
	}

	vm.saveExistingFiscal = function($index) {
		var postData = angular.copy(vm.data.customer_fiscal_entity[$index]);
		postData.customer_id = vm.entityId;
		var REST = resource.init('customer_fiscal_entity')
		REST.update({
			url: "customer_fiscal_entity/" + postData.customer_fiscal_entity_id + "/changeCustomer",
			data: postData
		}).then(
			function (data) {
				vm.data.customer_fiscal_entity[$index] = data;
			}
		);
	}

	vm.getCustomerActivities = function () {
		setTimeout(function () {
			// daca avem  date din ajax, le trimitem direct catre grafic
			var REST = resource.init('customer');
			REST.get({
				url: 'customer/' + vm.entityId + '/getCustomerActivities'
			}).then(function (data) {
				vm.customerActivities = data;
				$('#getCustomerActivities').highcharts({
					chart: {
						plotBackgroundColor: null,
						plotBorderWidth: null,
						plotShadow: false,
						type: 'pie'
					},
					title: {
						text: ''
					},
					tooltip: {
						headerFormat: '',
						pointFormat: '{point.name}: <b>{point.y} ' + vm.customerActivities.series[0].curency + '</b>'
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: 'pointer',
							dataLabels: {
								enabled: true
							},
							showInLegend: true
						}
					},
					series: vm.customerActivities.series
				})
			})
		}, 100);
	}

	vm.getCustomerLastTenOffers = function () {
		setTimeout(function () {
			var REST = resource.init('customer');
			REST.getArray({
				url: 'customer/' + vm.entityId + '/getCustomerLastTenOffers'
			}).then(function (data) {
				vm.customerLastTenOffers = data;
			});
		}, 100);
	};

	return vm;
});

