kv.controller('AllocationByCriteriaDetailReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    var params = kvUtils.getAllQueryParams();
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

	$(document).ready(function (){
	    var table = $('#more-details').DataTable({
	        'responsive': true,
            "paging":   false,
	        'order': [[1, 'asc']],
            "columns": [
            	{
	                'orderable':      false,
	                'data':           null,
	                'defaultContent': '' 
	            },
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null,
			    null
		  	]
	    });

	    // Handle buton 'Expand all' - deschide detalii extra pt. toate rezultatele din lista
	    $('#btn-show-all-children').on('click', function(){
	        table.rows(':not(.parent)').nodes().to$().find('td:first-child').trigger('click');
	    });

	    // Handle buton 'Collapse all' - inchide detalii extra pt. toate rezultatele din lista (deschise)
	    $('#btn-hide-all-children').on('click', function(){
	        table.rows('.parent').nodes().to$().find('td:first-child').trigger('click');
	    });
	    
        $('#more-details').on('click', 'td.details-control', function () {
          var tr = $(this).closest('tr');
          var row = table.row(tr);
          	if (row.child.isShown()) {
          		// sectiune de extra detalii este deschisa, aplica clasa 'shown' - afiseaza butonul cu '-' rosu
            	tr.addClass('shown');
          	} else {
          		// sectiunea de extra detalii este inchisa, nu se mai aplica clasa 'shown' - afiseaza butonul cu '+' verde 
              	tr.removeClass('shown');
          	}
      	});
	});

    return vm;
});
