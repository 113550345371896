window.KAppOptions = {
	"colors": {
		"state": {
			"brand": "#5d78ff",
			"metal": "#c4c5d6",
			"light": "#ffffff",
			"accent": "#00c5dc",
			"primary": "#5867dd",
			"success": "#34bfa3",
			"info": "#36a3f7",
			"warning": "#ffb822",
			"danger": "#fd3995",
			"focus": "#9816f4"
		},
		"base": {
			"label": ["#c5cbe3", "#a1a8c3", "#3d4465", "#3e4466"],
			"shape": ["#f0f3ff", "#d9dffa", "#afb4d4", "#646c9a"]
		}
	}
};
//atentie: am modificat fisierul sursa :public/assets/custom/js/vendor/angular-bootstrap/ui-bootstrap-tpls-2.5.0.js
// sa foloseasca aceste optiuni ca implicite
window.uibModalConfig = {
	animation: true,
	backdrop: true,
	keyboard: true,
	windowClass: 'show modal-scroll',
	backdropClass: 'show',
	ariaLabelledBy: 'modal-title',
	ariaDescribedBy: 'modal-body'
};

// $(document).ready(function () {
// 	$('table').floatThead({
// 		top: 75,
// 		zIndex: 90
// 	});
// 	$('.k-aside__brand-aside-toggler').click(function () {
// 		var interval = setInterval(function () {
// 			$('table').floatThead('reflow');
// 		}, 10);
// 		setTimeout(function () {
// 			clearInterval(interval);
// 		}, 500);
// 	});
//
// })

