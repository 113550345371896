(function (angular) {

    angular.module("kv").directive('numberToString', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                // ngModel.$parsers.push(function (value) {
                //     if (value === null) return null;
                //     else return value;
                // });
                ngModel.$formatters.push(function (value) {
                    if (!angular.isDefined(value)) return "";
                    else if (angular.isNumber(value)) return ''+ value;
                    else return value ;
                });
            }
        };
    });
})(angular);