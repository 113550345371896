function InvoiceViewTimesheetEditCtrl($uibModalInstance, data, $injector, $scope, resource, $filter, cmdate) {
	var vm = new modalCtrl($uibModalInstance, $injector, 'timesheet', 'timesheet_id', $scope, data, 'invoice-timesheet-edit-params');
	vm.modal.rendered.then(() => {
		if (!vm.isNewRecord || data.duplicate) {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
			vm.rest.get({
				id: data.timesheet.timesheet_id
			}).then(function (res) {
				vm.modalData = {
					form_action: data.form_action,
					form_location: data.form_location
				};
				// divide input minutes by 60
				vm.data = kvUtils.transformEffortsInHours(res);

				// only allow edit if invoice status = DRAFT
				vm.readonly = data.invoice_status_id != 1; //status diferit de DRAFT

				if (data.duplicate) {
					data.index = -1;
					vm.data.source_timesheet_id = vm.data.timesheet_id;
					delete vm.data.timesheet_id;
					vm.data.duplicate = 1;
					vm.data.duplicate_from_invoice_annex = 1;
					delete vm.data.timesheet_ext.timesheet_ext_id;
					delete vm.data.user_id;
					delete vm.data.user;
				}

				// easy input templates
				if (data.timesheetFormDifficulty == 1) {
					vm.data.dnb_effort = vm.data.dnb_effort + vm.data.nb_effort + vm.data.nc_effort;
					vm.data.nb_effort = 0;
					vm.data.nc_effort = 0;
				} else if (data.timesheetFormDifficulty == 2) { // medium input templates
					vm.data.dnb_effort = vm.data.eligible_effort - vm.data.blb_effort;
					vm.data.nc_effort = 0;
				}
				// hard input templates
				vm.data.original_project_id = vm.data.project_id;
				vm.data.original_reference_id = vm.data.reference_id;
				vm.checkHoursFormat(true);
				vm.dataHasLoaded = true;
				KApp.unblock('.modal-content');
			});
		}
	});
	vm.isNewRecord = data.index == -1;

	// for duplication we send index 999 and mark as new record (in order to use method POST)
	if (data.index == -999) {
		vm.isNewRecord = data.duplicate = 1;
	}

	vm.checkHoursFormat = function () {
		return kvUtils.checkHoursFormat(vm.data, $filter);
	};

	if (data.timesheet.reference) {
		vm.default_timesheet_type = data.timesheet.reference.default_timesheet_type;
	}

	$scope.$watch("lookup.project.all_reference_for_timesheet.default_timesheet_type", function (value) {
		if (value) {
			vm.default_timesheet_type = value;
		}
	});

	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		postData.date = cmdate.toServer(vm.data.date);
		postData.form_action = vm.modalData.form_action;
		postData.form_location = vm.modalData.form_location;

		postData.total_effort = kvUtils.humanTotalEffortToMinutes(postData.total_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.input_effort = kvUtils.humanTotalEffortToMinutes(postData.input_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.eligible_effort = kvUtils.humanTotalEffortToMinutes(postData.eligible_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.blb_effort = kvUtils.humanTotalEffortToMinutes(postData.blb_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.dnb_effort = kvUtils.humanTotalEffortToMinutes(postData.dnb_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.nc_effort = kvUtils.humanTotalEffortToMinutes(postData.nc_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.nb_effort = kvUtils.humanTotalEffortToMinutes(postData.nb_effort, vm.API.USE_INDUSTRIAL_MINUTES);

		if (data.timesheetFormDifficulty == 1) {
			if (vm.data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
				postData.dnb_effort = postData.total_effort - postData.blb_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
			}
		} else if (data.timesheetFormDifficulty == 2) {
			if (vm.data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
				postData.dnb_effort = postData.eligible_effort - postData.blb_effort;
				postData.nb_effort = postData.total_effort - postData.eligible_effort;
				postData.nc_effort = 0;
			}
		} else {
			if (vm.data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			}
		}
		if (vm.data.is_internal_hours) {
			postData.customer_id = null;
			postData.project_id = null;
			postData.reference_id = null;
		} else {
			postData.total_effort = postData.blb_effort + postData.dnb_effort + postData.nb_effort + postData.nc_effort;
			postData.internal_project_id = null;
		}

		if(!postData.user_id) postData.user_id = postData.invoice_user_id;

		return postData;
	};


	$scope.$watch("lookup.project.all_project_with_customer", function (value) {
		if (value) {
			vm.data.customer_id = value.customer_id;
			vm.data.customer = {
				customer_name: value.customer_name
			};
		}
	});

	$scope.$watch("lookup.user.all_user", function (value) {
		if (value) {
			vm.data.user.add_timesheet_no_constraints = value.add_timesheet_no_constraints;
		}
	});
	return vm;
}
