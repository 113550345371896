kv.controller('commodityActivityEditCtrl', function ($uibModalInstance, params, resolve, $scope, $http, $filter, $injector, resource, validator) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'commodity_activity', 'commodity_activity_id', $scope, params);
	vm.isNewRecord = params.index == -1;
	if(vm.isNewRecord){
		vm.data = {};
		vm.data.project_id = params.entity.project_id;
		vm.data.is_editable = false;
	}


	vm.$onInit = function () {
		if (resolve && resolve.data) {
			vm.data = resolve.data;
			if (vm.data.effort) {
				vm.data.effort = vm.data.effort / 60;
			}
		}
	};

	//convert input effort in minutes (ex: 02:00 => 120)
	vm.humanTotalEffortToMinutes = kvUtils.humanTotalEffortToMinutes;

	vm.checkHoursFormat = function () {
		vm.data.effort = $filter("timesheetHoursFormat")(vm.data.effort, API.user.tenant.tenant_ext.use_industrial_minutes);
	};

	vm.prepareDataToSave = function() {
		let postData = angular.copy(vm.data);
		postData.effort = vm.humanTotalEffortToMinutes(vm.data.effort, API.USE_INDUSTRIAL_MINUTES);
		return postData;
	};
	return vm;
});
