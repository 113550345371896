kv.controller('empowermentEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'empowerment_id';
    vm.entity = 'empowerment';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');

    // let cmdate = vm.getInjection('cmdate');

    var REST = resource.init(vm.entity);
    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];    
    
    if(page_identifier == 'edit') {
        $http.get("/empowerment/" + page_entity_id).then(function(response) {
            vm.data = response.data;
            if (vm.data.empowerment_user) {
                angular.forEach(vm.data.empowerment_user, function (v, i) {
                    vm.data.empowerment_user[i].id = v.user_id;
                    vm.data.empowerment_user[i].name = v.user_name;
                });
            }
        });

    } else {
        let defaultObjects = JSON.parse($element.find("#defaultObjects").attr("defaultObjects"));
        vm.data = defaultObjects;
        vm.data.empowerment_status_id = 1;
        vm.data.empowerment_status_name = 'ACTIVE_EMP';
        vm.data.series = vm.bladeParams.series;
        vm.data.number = vm.bladeParams.number;
        vm.isNewRecord = true;


        $scope.$watch('vm.data.date', function (value) {
            if (value && vm.data.date && vm.data.date !== null) {
                var REST = resource.init('empowerment');
                REST.list({
                    params: {
                        check_empowerment_date: value,
                    }
                }).then(function (res) {
                    if (res.data.length) {
                        vm.empowerment_with_greater_date_exists = true;
                    }else{
                        vm.empowerment_with_greater_date_exists = false;
                    }
                });
            }
        });
      
    }

    vm.newEntity = function newEntity(entity) {
        if(!vm.data) {
            vm.data = {};
        }

        if(!vm.data[entity]) {
            return vm.data[entity] = [{}];
        }

        return vm.data[entity].push({});
    };

    $scope.$watch('vm.data.series', function (value) {
        if (value && vm.data.number && vm.data.number !== null) {
            vm.data.serial_number = vm.data.series.concat(vm.data.number);
            vm.data.serial_number = vm.data.serial_number.replace(/\s+/g, '');
        }
    });

    $scope.$watch('vm.data.number', function (value) {
        if (value && vm.data.series && vm.data.series !== null) {
            var nb = vm.data.number;
            vm.data.number= nb.toString().replace(/\s+/g, '');
            vm.data.serial_number = vm.data.series.concat(vm.data.number);
            vm.data.serial_number = vm.data.serial_number.replace(/\s+/g, '');
        }
    });

    $scope.$watch("lookup.contract.all_contract", function (value) {
        if (value) {
            vm.data.contract_status_id = value.contract_status_id;
            }
     });

    vm.quickAddCustomer = function quickAddCustomer() {
        vm.openModal({
            animation: true,
            component: 'quickAddEntity',
            resolve: {
                entity: function () {
                    return $rootScope.quickAddEntityTemplate = 'customer';
                }
            }
        }).then(function (data) {
            vm.data.customer_id = data.customer_id;
            vm.data.customer = {
                customer_name: data.customer_name
            };
        });
    };

    vm.deleteEntity = function deleteEntity($index, entity, id) {
        if(!id) {
            return vm.data[entity].splice($index,1);
        }
        
        if(page_entity_id) {
            REST.delete({
                url: vm.entity + '/:id/' + entity + '/:entityId',
                params: {
                    id: page_entity_id,
                    entityId: id
                }
            }).then(function(data) {
                vm.data[entity].splice($index,1);
            });
        }
    };

    vm.validateSomeInputs = function () {
        vm.custom_errors = null;
        vm.has_error = false;

        if(vm.data.document_series){
            if(vm.data.document_series.current_number > vm.data.document_series.end_number){
                if (!vm.custom_errors) vm.custom_errors = [];
                vm.custom_errors.push(vm.trans('LANG.DOCUMENT_SERIES_NO_AVAILABLE'));
                vm.has_error = true;
            }
        }

        // daca in tenant este setat 'contract_is mandatory_on_project' => validare pe contract
        if(vm.data.contract_status_id){
            if(vm.data.contract_status_id != 5 && vm.data.type === 'ADDITIONAL'){
                if (!vm.custom_errors) vm.custom_errors = [];
                vm.custom_errors.push(vm.trans('LANG.ADDITIONAL_EMPOWERMENT_ERROR_MESSAGE'));
                vm.has_error = true;
            }
        }

        if(vm.has_error){
            $('.custom_error_messages').css('display', 'block');
        } else {
            $('.custom_error_messages').css('display', 'none');
            vm.save();
        }

    };

	return vm;
});
