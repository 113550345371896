(function () {
	'use strict';

	angular
		.module('kv')
		.filter('timesheetHoursFormat', timesheetHoursFormat);

	function timesheetHoursFormat($filter) {
		return function (input, use_industrial, input_as_minutes) {
			 // console.log("timesheetHoursFormat", input, use_industrial, input_as_minutes);
			if (use_industrial) {
				if (typeof input === "undefined" || input === null) {
					return 0;
				} else if (input_as_minutes) {
					return parseFloat(input/60).toFixed(2);
				} else return parseFloat(input).toFixed(2);
			} else {
				if (typeof input === "undefined" || input === null) {
					return "00:00";
				} else if (!isNotHoursMinutes(input)) {
					return input;
				} else {
					input = input.toString();
					if (isHoursFormat(input)) {
						input = fixHourFormat(input);
						return input;
					} else if (isIndustrialFormat(input)) {
						// if we send in minutes, do not multiply with 60
						if (input_as_minutes) {
							return minutesToHours(input);
						} else {
							return minutesToHours(Math.floor(input * 60));
						}
					} else {
						return minutesToHours(input);
					}
				}
			}
		};

		function isNotNumeric(variable) {
			return variable !== parseFloat(variable);
		}

		function isIndustrialFormat(variable) {
			if (variable.length && (variable.indexOf('.') !== -1 || (variable == parseInt(variable, 10))))
				return true;
			return false;
		}

		function isHoursFormat(variable) {
			if (variable.length && variable.indexOf(':') !== -1)
				return true;
			return false;
		}

		function fixHourFormat(variable) {
			if (variable.length && variable.indexOf(':') !== -1 && variable.length !== 5) {
				var tmp = variable.split(":");
				if (tmp[0].length !== 2) {
					tmp[0] = "0" + tmp[0];
				}

				if (tmp[1].length !== 2) {
					tmp[1] = tmp[1] + "0";
				}
				return tmp.join(":");
			}
			return variable;
		}

		function isNotHoursMinutes(variable) {
			if (variable === undefined) {
				return true;
			}
			if (variable.length !== 5) {
				return true;
			}
			if (isNaN(variable.charAt(0))) {
				return true;
			}
			if (isNaN(variable.charAt(1))) {
				return true;
			}
			if (variable.charAt(2) !== ':') {
				return true;
			}
			if (isNaN(variable.charAt(3))) {
				return true;
			}
			if (isNaN(variable.charAt(4))) {
				return true;
			}
			var hours = parseInt(variable.substring(0, 2));
			if (hours < 0 || hours > 24) {
				return true;
			}
			var minutes = parseInt(variable.substring(3, 5));
			if (minutes < 0 || minutes > 59) {
				return true;
			}
			return false;
		}

		function minutesToHours(number) {
			var hours = Math.floor(number / 60);
			var minutes = number - hours * 60;
			if (hours < 10) {
				hours = '0' + hours;
			}
			if (minutes < 10) {
				minutes = '0' + minutes;
			}
			// console.log("MINUTES", minutes);
			return hours + ':' + minutes;
		}
	}

})();
