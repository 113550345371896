kv.controller('efacturaListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'id';
    vm.entity = 'efactura';

    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getInfinitScroll({
        params: {
            action: 'efactura'
        },
        search: {
            scope: 'all',
            invoice_id: vm.bladeParams.entityId
        },
        url: "efactura",
    });

    vm.sendInvoiceToAnaf = function (index, entity) {
        vm.rest.update({
            url: "invoice/" + entity.invoice_id + "/sendInvoiceToAnaf"
        }).then(function (data) {
            if(data.error && data.error.error_code === 1){
                toastr.error(data.error.message);
            }
            else {
                window.location.reload();
            }
        });
    };


    vm.edit = function ($index, entity, $id) {
        let isNewRecord = !entity;
        vm.openModal({
            templateUrl: "efactura-edit",
            controller: 'efacturaEditCtrl',
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                params: function () {
                    return {
                        index: $index,
                        entity: entity,
                        id: $id
                    };
                },
                saveCallback: function () {
                    return function(response){
                        window.location = "/efactura";
                        response.modal.close();
                    }
                },
            }
        });
    }


    return vm;
});

