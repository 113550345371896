kv.controller('seniorityGroupListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "seniority_group_id",
		entity: "seniority_group",
		modal: {
			templateUrl: "seniority_group-edit",
			controller: "seniorityGroupEditCtrl"
		}
	});
});
