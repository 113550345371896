kv.controller('signupCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.rest = vm.getInjection('resource').init('signup');
	vm.data = {country: "RO"};
	vm.isNewRecord = true;
	if (vm.bladeParams.token) {
		vm.saveUrl = 'signup/' + vm.bladeParams.token + '/confirm';
	} else {
		vm.saveUrl = 'signup/create';
	}

	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		if (vm.bladeParams.token && vm.bladeParams.token !== '') {
			postData.hash = vm.bladeParams.token;
		}
		if (vm.bladeParams.email && vm.bladeParams.email !== '') {
			postData.email = vm.bladeParams.email;
		}
		// window captcha is defined in signup.blade captcha script
		postData.captcha = window.captcha;
		return postData;
	};

	vm.saveCallback = function (response) {
		if (vm.bladeParams.token && vm.bladeParams.token !== '')  {
			// console.log('response confirm: ', response);
			if (response.status == true) {
				setTimeout(function() {
					window.location = "/thank-you";
				}, 1000);
			} else {
				swal("There was an error while creating your account!", "Please contact the administrator at <b>office@sofventure.ro</b>.", "error");
			}
		} else {
			// console.log('response create: ', response);
			$("#createAccount").hide();
			if (response.status) swal("Account created successfully!", "Check your email for verification link.", "success");
		}
	};

	$.getJSON("https://extreme-ip-lookup.com/json/", function (data) {
		vm.data.country = (data.countryCode ? data.countryCode : 'RO');
	});

	return vm;
});
