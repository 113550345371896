kv.controller('inventoryItemTypeEditCtrl', function ($uibModalInstance, data, $injector, $scope) {
    return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
        entity: "inventory_item_type",
        primaryKey: "inventory_item_type_id",
        newEntity:{
        },
        prepareDataToSave: function () {
            let dataToSend = angular.copy(this.data);
            return dataToSend;
        }
    });
});