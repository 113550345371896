kv.controller('domainListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "domain_id",
		entity: "domain",
		modal: {
			templateUrl: "domain-edit",
			controller: "domainEditCtrl"
		}
	});
});
