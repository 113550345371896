(function () {
	angular.module('kv').controller("appNotificationsCtrl", appNotificationsCtrl);
	appNotificationsCtrl.$inject = ["$rootScope", "$scope", "$window", "$http", "$timeout", "$element", "$attrs", "$injector", "$filter", "Session"];

	function appNotificationsCtrl($rootScope, $scope, $window, $http, $timeout, $element, $attrs, $injector, $filter, Session) {
		let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);

		vm.primaryKey = 'id';
		vm.entity = 'notifications';
		// avoid loading the next page when opening the sidebar again
		vm.shouldReloadNotifications = true;
		vm.notificationsCount = 0;
		vm.notifications = [];
		vm.nextPage = 1;
		vm.lastPage = 1;

		window.Echo
			.private('App.Models.User.' + Session.user.user_id)
			.notification((notification) => vm.processNotification(notification));

		vm.processNotification = (notification) => {
			// show badge
			const data = notification.data;
			toastr.options = {
				"closeButton": false,
				"debug": false,
				"newestOnTop": true,
				"progressBar": false,
				"positionClass": "toast-top-right",
				"preventDuplicates": false,
				"onclick": null,
				"showDuration": "300",
				"hideDuration": "1000",
				"timeOut": "5000",
				"extendedTimeOut": "1000",
				"showEasing": "swing",
				"hideEasing": "linear",
				"showMethod": "fadeIn",
				"hideMethod": "fadeOut"
			};
			toastr.info(notification.message);
			
			// increment notifications count
			$scope.$apply(() => {
				vm.updateNotificationsCount(vm.notificationsCount + 1);
			});
		};

		let REST = vm.getInjection("resource").init(vm.entity);
		vm.loadNotifications = function () {
			if (vm.shouldReloadNotifications === true) {
				vm.notifications = [];
				vm.nextPage = 1;
			}
			REST.get({
				url:"notifications?page=" + vm.nextPage
			}).then(function (data) {
				vm.notifications = vm.notifications.concat(data.data);
				vm.shouldReloadNotifications = false;
				vm.lastPage = data.last_page;
				vm.nextPage += 1;
			});
		};

		vm.loadUnreadNotificationsCount = function () {
			REST.get({
				url:"notifications/unread-count"
			}).then(function (data) {
				vm.updateNotificationsCount(data.count);
			});
		};

		vm.updateNotificationsCount = function (count) {
			if (vm.notificationsCount === count) {
				return;
			}
			vm.notificationsCount = count;
			vm.shouldReloadNotifications = true;
			$rootScope.$emit("unread_notifications_count", count);
		};

		vm.loadUnreadNotificationsCount();

		// when the side panel is opened, load the notifications
		$scope.$on("open_notifications", () => {
			if (vm.shouldReloadNotifications === true) {
				vm.loadNotifications();
			}
		});

		vm.showLoadMoreButton = function () {
			if (vm.shouldReloadNotifications === true) {
				return false;
			}
			return vm.nextPage <= vm.lastPage;
		};

		vm.markAllAsRead = function () {
			REST.post({
				url:"notifications/mark-all-as-read",
			}).then(function (data) {
				for (let i = 0; i < vm.notifications.length; i++) {
					vm.notifications[i].read_at = new Date();
				}
				vm.updateNotificationsCount(0);
				toastr.info(vm.trans('SUCCESS'));
			});
		};

		vm.markAllAsUnread = function () {
			REST.post({
				url:"notifications/mark-all-as-unread",
			}).then(function (data) {
				for (let i = 0; i < vm.notifications.length; i++) {
					vm.notifications[i].read_at = null;
				}
				vm.loadUnreadNotificationsCount();
				toastr.info(vm.trans('SUCCESS'));
			});
		};

		vm.getIconForNotification = function (type) {
			switch (type) {
				case "App\\Notifications\\Tasks\\NewTask":
					return "fa fa-tasks";
				case "App\\Notifications\\Tasks\\UpdatedTask":
					return "fa fa-tasks";
				case "App\\Notifications\\Tasks\\CompletedTask":
					return "fa fa-check-double completed_task";
				case "App\\Notifications\\Tasks\\AssignedTask":
					return "fa fa-tasks";
				case "App\\Notifications\\Litigation\\NewCourtHearing":
					return "flaticon2-calendar-1  k-font-info";
				case "App\\Notifications\\Insolvency\\NewBpi":
					return "fa fa-briefcase";
				case "App\\Notifications\\Projects\\NewProjectBudget":
					return "fa fa-chart-bar";
				case "App\\Notifications\\Contracts\\NewContractSeriesExpirationDate":
					return "fa fa-file";
				case "App\\Notifications\\EFactura\\SendingErrorEfactura":
					return "fa fa-exclamation-circle sending_error_efactura";
				case "App\\Notifications\\EFactura\\ProcessingErrorEfactura":
					return "fa fa-exclamation-circle processing_error_efactura";
				case "App\\Notifications\\EFactura\\ProcessedEfactura":
					return "fa fa-check-double processed_efactura";
				case "App\\Notifications\\Collection\\ReminderEmailNotDelivered":
					return "fa fa-envelope";
			}
		};
		
		vm.getUrlForNotification = function (type, item_id, parent_id) {
			switch (type) {
				case "App\\Notifications\\Tasks\\NewTask":
					return "/task?editTaskId=" + item_id;
				case "App\\Notifications\\Tasks\\UpdatedTask":
					return "/task?editTaskId=" + item_id;
				case "App\\Notifications\\Tasks\\CompletedTask":
					return "/task?editTaskId=" + item_id;
				case "App\\Notifications\\Tasks\\AssignedTask":
					return "/task?editTaskId=" + item_id;
				case "App\\Notifications\\Litigation\\NewCourtHearing":
					return "/court_hearing/" + item_id;
				case "App\\Notifications\\Insolvency\\NewBpi":
					return "/bpi_fiscal_entity/" + parent_id;
				case "App\\Notifications\\Projects\\NewProjectBudget":
					return "/project/" + item_id;
				case "App\\Notifications\\Contracts\\NewContractSeriesExpirationDate":
					return "/document_series";
				case "App\\Notifications\\EFactura\\SendingErrorEfactura":
					return "/invoice/" + parent_id;
				case "App\\Notifications\\EFactura\\ProcessingErrorEfactura":
					return "/invoice/" + parent_id;
				case "App\\Notifications\\EFactura\\ProcessedEfactura":
					return "/invoice/" + parent_id;
				case "App\\Notifications\\Collection\\ReminderEmailNotDelivered":
					return "/invoice/" + item_id;
			}
		};

		vm.markAsRead = function (item) {
			if (item.read_at === null) {
				REST.post({
					url:"notifications/mark-as-read/" + item.id,
				}).then(function (data) {
					// mark item as read in the list
					for (let i = 0; i < vm.notifications.length; i++) {
						if (vm.notifications[i].id === item.id) {
							vm.notifications[i].read_at = new Date();
						}
					}
					vm.loadUnreadNotificationsCount();
				});
			}
		};

		vm.openNotificationUrl = function (item) {
			vm.markAsRead(item);
			if (item.data.url) {
				$window.open(item.data.url, '_blank');
			} else {
				$window.open(vm.getUrlForNotification(item.type, item.data.item_id, item.data.parent_id), '_blank');
			}
		};

		return vm;
	}
})();
