class dateClass {
	constructor(value, format = "") {
		if (value === undefined || value === null) this.value = value;
		else if (value._isAMomentObject) this.value = value.toDate();
		else if (format) this.value = moment(value, format).utc().toDate();
		else this.value = value;
		if (this.value && this.value.setMinutes)
			this.value.setMinutes(this.value.getMinutes() + this.value.getTimezoneOffset());
	}

	get DateFormat() {
		let date = moment(this.value).format(API.DATE_FORMAT);
		return date;
	}

	set DateFormat(value) {
		if (!value) this.value = null;
		else {
			let date = moment(value, API.DATE_FORMAT);
			this.value = date.toDate();
		}
	}

	get DatetimeFormat() {
		let date = moment(this.value).format(API.DATETIME_FORMAT);
		return date;
	}

	set DatetimeFormat(value) {
		// console.log("set ", value);
		if (!value) this.value = null;
		else {
			let date = moment(value, API.DATETIME_FORMAT);
			this.value = date.toDate();
		}
	}

	get PostDate() {
		let date = moment(this.value).format("YYYY-MM-DD");
		return date;
	}

	get PostDatetime() {
		let date = moment(this.value).format("YYYY-MM-DD HH:mm:ss");
		return date;
	}

	get Hours() {
		return this.value.getHours();
	}

	set Hours(value) {
		this.value.setHours(value);
	}

	get Minutes() {
		return this.value.getMinutes();
	}

	set Minutes(value) {
		this.value.setMinutes(value);
	}

	toString() {
		return this.value.toString();
	}
}

