(function () {
	'use strict';

	angular
		.module('kv')
		.directive('comments', comments)
		.directive('rcomments', rcomments)
		.directive('contactComments', contactComments);

	function comments(API, $http, resource, $filter, $uibModal, isAdmin) {
		return {
			templateUrl: "/assets/custom/js/directives/comments/comments.html",
			scope: {
				entityType: '@',
				entityId: '@',
				sourceType: '@',
				sourceTypeId: '@'
			},
			restrict: 'E',
			link: function (scope, element, attributes, controller) {
				var vm = this;
				vm.entity = 'comment';
				vm.primaryKey = 'comment_id';
				var REST = resource.init(vm.entity);
				scope.$watch("entityId", getComments);

				scope.action = {
					editComment: editComment,
					deleteComment: deleteComment
				};

				function deleteComment($index) {
					var modalInstance = $uibModal.open({
						animation: true,
						component: 'modalConfirm',
						resolve: {
							content: function() {
								return $filter("trans")("ARE_YOU_SURE_DELETE");
							},
							title: function() {
								return $filter("trans")("DELETE");
							}
						}
					});

					modalInstance.result.then(function (selectedItem) {
						REST.delete({
							url: 'comment/' + scope.comments[$index][vm.primaryKey],
							success: function () {
								scope.comments.splice($index, 1);
							}
						})
					});
				}

				function br2nl(str) {
					return str.replace(/<br\s*\/?>/mg, "\r");
				}

				function editComment($index) {
					scope.comment = br2nl(scope.comments[$index].comment);
					scope.comment_id = scope.comments[$index][vm.primaryKey];
				}

				scope.post = function () {
					if (scope.comment_id) {
						REST.update({
							id: scope.comment_id,
							data: {
								"comment": scope.comment,
							}
						}).then(function () {
							scope.comment = "";
							scope.comment_id = null;
							getComments();
						});
					} else {
						REST.create({
							data: {
								'comment': scope.comment,
								'entity_type': scope.entityType,
								'entity_id': scope.entityId,
								'source_type': scope.sourceType,
								'source_type_id': scope.sourceTypeId
							}
						}).then(function () {
							scope.comment = "";
							getComments();
						});
					}
				};

				function getComments() {
					REST.list({
						params: {
							entity_type: scope.entityType,
							entity_id: scope.entityId,
							source_type: scope.sourceType,
							source_type_id: scope.sourceTypeId
						}
					}).then(function (res) {
						var commentsCount = Object.keys(res.data).length;
						if (commentsCount) {
							$('a[href="#comments"]').html($filter('trans')('NOTES') + " <span class='badge badge-primary'>" + commentsCount + "<span>");
						}
						scope.comments = res.data;
					});
				}
			}
		};
	}

	function rcomments(API, $http, resource, $filter) {
		CKEDITOR.config.removePlugins = 'clipboard,colordialog,pastetext,pastefromword,widget,widgetselection,copyformatting,removeformat,tableselection,tabletools,link,list,indentlist,indent,lineutils,sourcearea,scayt,contextmenu,enterkey,maximize,flash,table,horizontalrule,specialchar,blockquote,image,justify,colorbutton,colordialog';
		CKEDITOR.config.disableNativeSpellChecker = true;
		CKEDITOR.config.removeButtons = 'Subscript,Superscript';
		return {
			templateUrl: '/assets/custom/js/directives/comments/rcomments.html',
			scope: {
				entityType: '@',
				entityId: '@',
				sourceType: '@',
				sourceTypeId: '@',
				attendee: '='
			},
			restrict: 'E',
			link: function (scope, element, attributes, controller, isAdmin) {
				var REST = resource.init('comment');
				scope.$watch('entityId', getComments);
				scope.post = function () {
					scope.isLoading = true;
					REST.create({
						data: {
							'comment': scope.comment,
							'entity_type': scope.entityType,
							'entity_id': scope.entityId,
							'source_type': scope.sourceType,
							'source_type_id': scope.sourceTypeId
						}
					}).then(function () {
						scope.comment = '';
						scope.isLoading = false;
						getComments();
					});

				}

				function getComments() {
					scope.isLoading = true;
					if(!scope.entityId) {
						scope.comments = [];
						scope.isLoading = false;
						return ;
					}
					REST.list({
						params: {
							entity_type: scope.entityType,
							entity_id: scope.entityId,
							source_type: scope.sourceType,
							source_type_id: scope.sourceTypeId
						}
					}).then(function (res) {
						var commentsCount = Object.keys(res.data).length;
						if (commentsCount) {
							$('a[href="#comments"]').html($filter('trans')('NOTES') + ' <span class="badge badge-primary">' + commentsCount + '<span>');
						}
						if (scope.attendee) scope.attendee.comment_count = commentsCount;
						scope.comments = res.data;
						scope.isLoading = false;
					});
				}

				scope.action = {
					editComment: editComment,
					deleteComment: deleteComment
				};

				function deleteComment($index) {
					if (isAdmin) {
						var modalInstance = $uibModal.open({
							animation: true,
							component: 'modalConfirm',
							resolve: {
								content: function() {
									return $filter("trans")("ARE_YOU_SURE_DELETE");
								},
								title: function() {
									return $filter("trans")("DELETE");
								}
							}
						});

						modalInstance.result.then(function (selectedItem) {
							REST.delete({
								url: 'comment/' + scope.comments[$index][vm.primaryKey],
								success: function () {
									scope.comments.splice($index, 1);
								}
							})
						});
					}
				}

				function br2nl(str) {
					return str.replace(/<br\s*\/?>/mg, "\r");
				}

				function editComment($index) {
					scope.comment = br2nl(scope.comments[$index].comment);
					scope.comment_id = scope.comments[$index][vm.primaryKey];
				}
			}
		};
	}

	function contactComments(API, $http, resource, $filter, $uibModal) {
		return {
			templateUrl: "/assets/custom/js/directives/comments/contact-comments.html",
			scope: {
				entityType: '=',
				entityId: '=',
				sourceType: '=',
				sourceTypeId: '='
			},
			restrict: "E",
			link: function (scope, element, attributes, controller, isAdmin) {
				var REST = resource.init('comment');
				scope.$watch("entityId", getComments);

				scope.post = function () {
					scope.isLoading = true;
					REST.create({
						data: {
							"comment": scope.comment,
							"entity_type": scope.entityType,
							"entity_id": scope.entityId,
							"source_type": scope.sourceType,
							"source_type_id": scope.sourceTypeId
						}
					}).then(function () {
						scope.comment = "";
						scope.isLoading = false;
						getComments();
					});

				};

				function getComments() {
					scope.isLoading = true;
					if (!scope.entityId) return;
					console.log("comments", scope);
					REST.list({
						params: {
							entity_type: scope.entityType,
							entity_id: scope.entityId,
							source_type: scope.sourceType,
							source_type_id: scope.sourceTypeId
						}
					}).then(function (res) {
						var commentsCount = Object.keys(res.data).length;
						if (commentsCount) {
							$('a[href="#comments"]').html($filter('trans')('NOTES') + " <span class='badge badge-primary'>" + commentsCount + "<span>");
						}
						scope.comments = res.data;
						angular.forEach(scope.comments, function (v, i) {
							if (v.scope_type && v.scope_type == 'activity') {
								REST.get({
									url: 'activity',
									params: {
										activity_id: v.scope_type_id,
									}
								}).then(function (data) {
									v.activity_name = data.activity_name;
									v.activity_id = data.activity_id;
								})
							}
						})
						scope.isLoading = false;
					});
				}

				scope.action = {
					editComment: editComment,
					deleteComment: deleteComment
				};

				function deleteComment($index) {
					var modalInstance = $uibModal.open({
						animation: true,
						component: 'modalConfirm',
						resolve: {
							content: function() {
								return $filter("trans")("ARE_YOU_SURE_DELETE");
							},
							title: function() {
								return $filter("trans")("DELETE");
							}
						}
					});

					modalInstance.result.then(function (selectedItem) {
						REST.delete({
							url: 'comment/' + scope.comments[$index].comment_id,
							success: function () {
								scope.comments.splice($index, 1);
							}
						});
					});
				}

				function br2nl(str) {
					return str.replace(/<br\s*\/?>/mg, "\r");
				}

				function editComment($index) {
					scope.comment = br2nl(scope.comments[$index].comment);
					scope.comment_id = scope.comments[$index].comment_id;
				}
			}
		};
	}
})();
