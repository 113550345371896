kv.controller('UserWorkloadViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'user_workload_id';
	vm.entity = 'user_workload';

	vm.count = {
		total: 0,
		showing: 0
	};

	let startOfWeek = moment().startOf('isoWeek');
	let endOfWeek   = moment().endOf('isoWeek');

	vm.getInfinitScroll({
		params: {
			action: 'user_workload'
		},
		search: {
			date_from: startOfWeek.format('YYYY-MM-DD'),
			date_to: endOfWeek.format('YYYY-MM-DD')
		},
		url: "user_workload/" + vm.bladeParams.userId,
	});

	vm.getChartData = function () {
		vm.rest = resource.init('user_workload');
		vm.rest.get({
			id: vm.bladeParams.userId,
			params: {
				action: 'get_workload_chart_data',
				date_from: vm.infinitScroll.search.date_from,
				date_to: vm.infinitScroll.search.date_to,
			}
		}).then(response => {
			if (response.status) {
				vm.renderCharts(response.data);
			} else {
			}
		});
	};

	vm.getChartData();

	vm.changeCalculationPeriod = function (action) {
		if(action == 'increase') {
			startOfWeek = startOfWeek.add(1, 'week');
			endOfWeek = endOfWeek.add(1, 'week');
		} else if (action == 'decrease') {
			startOfWeek = startOfWeek.subtract(1, 'week');
			endOfWeek = endOfWeek.subtract(1, 'week');
		}

		vm.infinitScroll.search.date_from = startOfWeek.format('YYYY-MM-DD');
		vm.infinitScroll.search.date_to = endOfWeek.format('YYYY-MM-DD');

		vm.getChartData();
	};

	vm.copyLasWeekWorkloads = function () {
		vm.rest = resource.init('user_workload');
		vm.rest.post({
			url: "user_workload/copyLastWeekWorkloads",
			data: {
				'user_id': vm.bladeParams.userId,
				'date_from': startOfWeek.subtract(1, 'week').format('YYYY-MM-DD'),
				'date_to': endOfWeek.subtract(1, 'week').format('YYYY-MM-DD'),
			}
		}).then(response => {
			vm.response = response;
			vm.getChartData();
		});
	};

	vm.copyLastWeekTimesheets = function () {
		vm.rest = resource.init('user_workload');
		vm.rest.post({
			url: "user_workload/copyLastWeekTimesheets",
			data: {
				'user_id': vm.bladeParams.userId,
				'date_from': startOfWeek.subtract(1, 'week').format('YYYY-MM-DD'),
				'date_to': endOfWeek.subtract(1, 'week').format('YYYY-MM-DD'),
			}
		}).then(response => {
			vm.response = response;
			vm.infinitScroll.search.refresh++;
			vm.getChartData();
		});
	};


	vm.addEdit = function ($index, entity, $id) {
		let isNewRecord = !entity;
		vm.openModal({
			templateUrl: "user_workload-edit",
			controller: 'UserWorkloadEditCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: function () {
					return {
						index: $index,
						entity: entity,
						id: $id,
						create_user_id: vm.bladeParams.userId,
						date: vm.infinitScroll.search.date_from
					};
				},
				saveCallback: function () {
					return function(response){
						vm.infinitScroll.search.refresh++;
						vm.getChartData();
						response.modal.close();
					};
				},
			}
		});
	};

	vm.delete = function ($index) {
		$http.delete("/user_workload/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
			vm.infinitScroll.items.splice($index, 1);
		});
	};

	vm.chart_is_rendered = false;
	vm.chart = function(chartData, redraw) {
		var config = {
			chart: {
				type: 'bar',
				events: {
					load: function () {
						this.reflow();
						vm.chart_is_rendered = true;
					}
				},
			},
			title: {
				text: null
			},
			xAxis: {
				categories: [chartData.full_name]
			},
			yAxis: {
				min: 0,
				max: 200,
				title: {
					text: null
				}
			},
			tooltip: {
				shared: true,
				valueSuffix: ' %'
			},
			legend: {
				reversed: true
			},
			plotOptions: {
				series: {
					stacking: 'normal'
				}
			},
			series: chartData.series
		};
		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false;
		}

		vm.isLoading = false;

		// if chart is not rendered, render chart, else redraw chart with new data
		if(!redraw) {
			$('#chart1').highcharts(config);
		} else {
			$('#chart1').highcharts(config);
			$('#chart1').highcharts().redraw();
		}
	};

	vm.renderCharts = function (chartsData) {
		if (chartsData.series.length) {
			// if chart was rendered, reflow, else construct it
			if (vm.chart_is_rendered) {
				vm.chart(chartsData, true);
			} else {
				vm.chart(chartsData, false);
				$('#chart1').show();
			}
		}
	};

	return vm;
});

