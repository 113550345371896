kv.controller('contractListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'contract_id';
	vm.entity = 'contract';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'contract'
		},
		url: "contract",
	});

	
	return vm;
});

