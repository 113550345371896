angular
    .module('kv')
    .filter('objToParams', objToParams);

function objToParams() {
    return function(obj) {
        var str = "";
        for (var key in obj) {
            if(obj[key] != "" && obj[key] != null) {
                if(typeof obj[key] != "object") {
                    if (str != "") {
                        str += "&";
                    }
                    str += key + "=" + encodeURIComponent(decodeURIComponent(obj[key]));
                } else {
                    for(var m in obj[key]) {
                        if (str != "") {
                            str += "&";
                        }
                        if(obj[key][m]["id"]) {
                            str += key + "[]=" + encodeURIComponent(decodeURIComponent(obj[key][m]["id"]));
                        } else {
                            str += key + "[]=" + encodeURIComponent(decodeURIComponent(obj[key][m]));
                        }
                    }
                }
            }
        }
        return str;
    };
}
