kv.controller('practiceAreaEditCtrl', function ($uibModalInstance, data, $injector, $scope) {
    return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
        entity: "practice_area",
        primaryKey: "practice_id",
        newEntity:{
        },
        prepareDataToSave: function () {
            let cmdate = this.getInjection('cmdate');
            let dataToSend = angular.copy(this.data);
            dataToSend.date = cmdate.toServer(this.data.date);
            return dataToSend;
        }
    });
});