kv.controller('CasesByCriteriaReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    let initParams = kvUtils.getAllQueryParams();
    var data = JSON.parse(vm.bladeParams.data);
    
    if (initParams) {
        if (initParams.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "order": [[ 1, "desc" ]],
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
        attr('placeholder','Search in table...').
        removeClass('input-small').
        css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right');
    });
    
    vm.drillDown = function(group_by, agg_id, agg_name = null) {
        let params = angular.copy(initParams);
        params[params.group_by + "_id"] = agg_id;
        params[params.group_by] = agg_name;
        params.group_by = group_by;

        let url = window.location.origin + window.location.pathname + "?" + $filter("objToParams")(params);
        window.open(url);
    };

    vm.isLoading = true;

    var chartData = data[1];
    function chart() {
        $('#casesByCriteriaReportChart').hide();
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    load: function () {
                        this.reflow();
                    }
                },
                height: 550,
            },
            title: {
                text: ''
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}: <b>{point.y} ' + ($filter('trans')('CASES')).toLowerCase() + '</b>'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    innerSize: '60%',
                    dataLabels: {
                        enabled: true,
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    },
                    showInLegend: false
                }
            },
            series: [{
                data: chartData
            }],
            afterPrint: function(a,b,c) {
                console.log(a,b,c)
            }
        }

        // hide dataLabels when resolution is xxs (phone)
        if( $(window).width() < 480 )
        {
            config.plotOptions.pie.dataLabels.enabled = false;
        }
        $('#casesByCriteriaReportChart').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#casesByCriteriaReportChart').show();
            $('#casesByCriteriaReportChart').highcharts().reflow();
        }, 1);
    }

    if (chartData && chartData.length) {
        chart();
    }

	return vm;
});
