kv.controller('sysLedesActivityCodeEditCtrl', function ($uibModalInstance, data, $injector, $scope, $http, API) {
    return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
        entity: "sys_ledes_activity_code",
        primaryKey: "ledes_activity_id",
        newEntity:{
            is_custom: true
        },
        prepareDataToSave: function () {
            let cmdate = this.getInjection('cmdate');
            let dataToSend = angular.copy(this.data);
            dataToSend.date = cmdate.toServer(this.data.date);
            return dataToSend;
        }

    });
});