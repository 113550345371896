kv.controller('TimesheetListCtrl', function ($scope, $element, $attrs, $injector, $http, resource, $uibModal, cmdate, $filter) {
	let vm = new timesheetBaseCtrl($scope, $element, $attrs, $injector);
	vm.advancedSearch = false;
	vm.primaryKey = 'timesheet_id';
	vm.entity = 'timesheet';
	vm.message = '';
	vm.use_industrial_minutes = API.USE_INDUSTRIAL_MINUTES;
	vm.calendar_date = null;
	vm.userData = JSON.parse(vm.bladeParams.userData);
	var REST = resource.init(vm.entity);
	let defaultTimesheet = JSON.parse(vm.bladeParams.defaultObject);

	vm.getInfinitScroll({
		extraData: {totals: 'totals'},
		fixedHead: {
			top: 75,
			zIndex: 90
		},
		search: {
			page: 1,
			date_from: moment().startOf('month').format(vm.API.DATE_FORMAT),
			date_to: moment().format(vm.API.DATE_FORMAT),
			show_internal_option: 'ALL',
			user_id: vm.userData.user_id,
			user_id_default: vm.userData.full_name
		}
	});

	vm.addEdit = function (entity, $index, duplicate, activity) {
		let read_only = true;
		if(duplicate == true){
			read_only = false;
		}

		// edit / create screen
		if(vm.bladeParams.openTimesheet){
			$index = ( $index > -1 ? 'update' : vm.bladeParams.openTimesheet ? 'update' : 'create');
		} 

		// custom validations on edit
		if ($index > -1 && !customUserCanDeleteOrUpdate(entity)){
			read_only = true;
		}

		vm.openModal({
			templateUrl: 'timesheet-edit',
			controller: 'TimesheetEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						index: $index,
						result: entity,
						defaultObject: JSON.parse(vm.bladeParams.defaultObject),
						message: vm.message
					};
				},
				duplicate: duplicate,
				activity: activity,
				params: {
					calendar_date: vm.calendar_date ? vm.calendar_date : null,
					entity: entity,
					index: $index,
					form_action: (duplicate ? 'duplicate' : ( $index > -1 ? 'update' : 'create')),
					form_location: 'timesheet'
				},
				read_only: read_only,
				timesheetListCtrl: vm
			},
			backdrop: 'static',
			keyboard: false
		}).then(function (response) {
			loadTimesheets();
			if (response.saveType == 1) vm.addEdit(null, -1, false);
			else if (response.saveType == 2) vm.addEdit(response.entity, response.index, true);

			// refresh page
			timesheetDatepicker(vm.infinitScroll.search.date_from);
		});
	};
	// console.debug("search", vm.infinitScroll.search, moment().startOf('month').format(vm.API.DATE_FORMAT));
	setTimeout(function() {
		if(vm.bladeParams.openTimesheet){
			$http.get("/timesheet/" + vm.bladeParams.openTimesheet).then(function(response) {
				vm.addEdit(response.data, -1, false, null);
			});
		}
	}, 500);

// extract from link activity id
	let params = queryParamToJson();
	var activityId = params.activity_id;
	if (params.create_from) {
		//create timesheet from stopwatch and others....
		let createEntity = function (data) {
			//get activity form quweryString params
			var activity = getActivityDataForTimesheet(data);
			vm.addEdit(null, null, false, activity);
		};
		if (params.create_from == 'activity' && !isNaN(activityId) && parseInt(Number(activityId)) == activityId && !isNaN(parseInt(activityId, 10))) {
			var REST = resource.init('activity');
			// get activity data
			REST.get({
				url: 'activity/' + activityId
			}).then(createEntity);
		} else createEntity(params);
	}

//construct the object for timesheet popup
	function getActivityDataForTimesheet(data) {
		var result = {};

		result.user_id = vm.bladeParams.userId;
		result.user = {};
		result.user.full_name = vm.bladeParams.userFullname;

		if (data.start_date) {
			result.date = cmdate.fromServer(data.start_date).format('YYYY-MM-DD');
			result.start_date = cmdate.fromServer(data.start_date);
		}

		if (data.end_date) {
			result.end_date = cmdate.fromServer(data.end_date);
		}

		if (data.customer_language) {
			result.customerLanguage = data.customer_language;
		}

		if (data.customer && data.customer.customer_id) {
			result.customer_id = data.customer.customer_id;
			result.customer = {};
			result.customer.customer_name = data.customer.customer_name;
		}

		if (data.project && data.project.project_id) {
			result.project_id = data.project.project_id;
			result.project = {};
			result.project.project_name = data.project.project_name;
		}

		if (!data.customer && data.customer_name) {
			result.customer_id = data.customer_id;
			result.customer = {};
			result.customer.customer_name = data.customer_name;
		}

		if (data.linked_project_id && data.linked_project) {
			result.project_id = data.linked_project_id;
			result.project = {};
			result.project.project_name = data.linked_project.project_name;
		}
		if (!data.linked_project && data.project_name) {
			result.project_id = data.project_id;
			result.project = {};
			result.project.project_name = data.project_name;
		}
		if (!data.internal_project && data.internal_project_name) {
			result.internal_project_id = data.internal_project_id;
			result.internal_project = {project_name: data.internal_project_name};
		}
		if (!data.reference && data.reference_name) {
			result.reference_id = data.reference_id;
			result.reference = {project_name: data.reference_name};
		}
		if (data.practice_name) {
			result.practice_id = data.practice_id;
			result.practice = {practice_name: data.practice_name};
		}
		result.is_internal_hours = parseInt(data.is_internal_hours) ? true : false;
		if (data.input_effort_min) {
			let hours = parseInt(data.input_effort_min / 60);
			let restMin = data.input_effort_min - hours * 60;
			result.input_effort = hours.toString().padStart(2, '0') + ":" + restMin.toString().padStart(2, 0);
			result.blb_effort = hours.toString().padStart(2, '0') + ":" + restMin.toString().padStart(2, 0);
			// vm.checkHoursFormat(result, false, true);
		}
		if (data.activity_ext && data.activity_ext.description) {
			result.comments = data.activity_ext.description;
		}
		if (data.comments) {
			result.comments = data.comments;
		}

		if (data.meeting_room_id) {
			result.meeting_room_id = data.meeting_room_id;
		}
		return result;
	}

	vm.allowChange = function (entity) {
		return true;
	};

	vm.canDelete = function (entity) {
		// no invoice, status draft, you can delete it
		if (entity.status_id == 1 && !entity.invoice_id) {
			return true;
		}

		// if user has special powers, and not billed, can delete
		if (vm.bladeParams.canDelete == "1" && !entity.invoice_id) {
			return true;
		}
	};

	vm.delete = function ($index) {
		if (!customUserCanDeleteOrUpdate(vm.infinitScroll.items[$index])) {
			vm.alert('Cannot delete timesheet', 'You don\'t have enough rights to delete this timesheet');
			return;
		}

		vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then(response => {
			if (response !== 'ok') return;
			$http.delete("timesheet/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
				vm.infinitScroll.items.splice($index, 1);
			});
		});
	};

	/**
	 * custom GNP code
	 * 
	 * @param  {[type]} entity [description]
	 * @return {[type]}        [description]
	 */
	function customUserCanDeleteOrUpdate (entity) {
		if (API.tenant.short_name === 'gnp' && defaultTimesheet.timesheetAllowedPastDays > 0 && vm.bladeParams.canDelete !== '1' && entity != null)
		{
			if (managedTimesheet(entity)) return true;

			var d = new Date();
			var entityDate = cmdate.fromServer(cmdate.toServer(entity.date)).valueOf();
			let minDate = d.setDate(d.getDate() - defaultTimesheet.timesheetAllowedPastDays);
			if (entityDate < minDate) return false;
		}
		return true;
	}

	/**
	 * check if user is project manager or controller
	 * 
	 * @param  {[type]} entity [description]
	 * @return {[type]}        [description]
	 */
	function managedTimesheet(entity) {
		if (entity.project_managers.some(user => user.user_id === API.user.user_id)) return true;
		if (entity.project_controller && entity.project_controller.user_id === API.user.user_id) return true;

		return false;
	}

	vm.calendarUserId = vm.infinitScroll.search.user_id ? vm.infinitScroll.search.user_id : vm.calendarUserId = vm.bladeParams.userId;

	$scope.$watch('vm.infinitScroll.search.user_id', function () {
		timesheetDatepicker(vm.infinitScroll.search.date_from);
	});

	var workedMinutes = [];
	var colorIntervals = [];

	function timesheetDatepicker(startDate) {
		workedMinutes = [];
		colorIntervals = [];
		var displayDate = new Date();
		var year = displayDate.getFullYear();
		var month = displayDate.getMonth() + 1;
		if (startDate !== "") {
			var explodedDate = startDate.split("-");
			year = explodedDate[0];
			month = explodedDate[1];
		}
		getCalendarHeatmap(year, month);

	}

	vm.datepickerLoaded = false;
	vm.selectedMonth = {};

	/**
	 * get calendar heatmap - timesheet, vacation, holiday
	 *
	 * @param year
	 * @param month
	 */
	function getCalendarHeatmap(year, month)
	{
		workedMinutes = [];
		colorIntervals = [];
		if (year.date) {
			let date = moment(year.date);
			year = date.year();
			month = date.month() + 1;
		}

		// Create a moment object for the first day of the given month and year
		var dateFrom = moment({ year: year, month: month - 1, day: 1 }).startOf('month');
		var dateTo = moment({ year: year, month: month - 1, day: 1 }).endOf('month');

		let localStorageFilters = ['vacation', 'holiday'];
		var dateList = {};
		if (!dateFrom && !dateTo) {
			dateFrom = moment().startOf('month');
			dateTo = moment().endOf('month');
		}
		vm.calendarUserId = vm.infinitScroll.search.user_id ? vm.infinitScroll.search.user_id : vm.bladeParams.userId;

		if (vm.selectedMonth.start && vm.selectedMonth.start.toLocaleDateString() == dateFrom.toDate().toLocaleDateString() && vm.selectedUserId == vm.infinitScroll.search.user_id) return;
		vm.selectedUserId = vm.infinitScroll.search.user_id;
		vm.selectedMonth = {
			start: dateFrom.toDate(),
			end: dateTo.toDate()
		};
		KApp.block('div.timesheetDatepicker');
		$http({
			method: 'GET',
			// url: vm.bladeParams.eventsAction,
			url: 'calendar/heatmap',
			params: {
				user_id: vm.calendarUserId,
				filters: [localStorageFilters],
				date_from: cmdate.toServer(dateFrom),
				date_to: cmdate.toServer(dateTo),
				month: month,
				year: year,
				from: 'timesheet'
			}
		}).then(function (response) {
			workedMinutes = response['data']['data'];
			colorIntervals = response['data']['meta'];

			KApp.unblock('div.timesheetDatepicker');
			let date = year + '-' + month + '-01';
			if (vm.datepickerLoaded) $('div.timesheetDatepicker').datepicker('destroy');
			// $('div.timesheetDatepicker').attr('data-date', date);
			$('div.timesheetDatepicker').datepicker({
				rtl: KUtil.isRTL(),
				todayHighlight: false,
				templates: {
					leftArrow: '<i class="la la-angle-left"></i>',
					rightArrow: '<i class="la la-angle-right"></i>'
				},
				changeMonth: false,
				changeYear: false,
				autoSize: false,
				weekStart: 1,
				startView: "month",
				format: 'yyyy-mm-dd',
				defaultViewDate: date,
				inline: true,
				beforeShowDay: beforeShowDay
			}).on('changeMonth', getCalendarHeatmap).on('changeDate', timesheetDatepickerSelect);
			vm.datepickerLoaded = true;
		});
	}

	function timesheetDatepickerSelect(event) {
		let date = moment(event.date).format('YYYY-MM-DD');
		vm.infinitScroll.search.date_from = date;
		vm.calendar_date = date;
		vm.infinitScroll.search.date_to = date;
		vm.getInjection('$timeout')();
	}

	function formatDate(dateText) {
		return cmdate.toServer(cmdate.fromServer(dateText));
	}

	/**
	 * format calendar
	 * @param date
	 * @returns {classes: *, enabled: boolean}
	 */
	function beforeShowDay(date)
	{
		let dayOfMonth = date.getDate();
		if (date < vm.selectedMonth.start || date > vm.selectedMonth.end) {
			return {enabled: false, classes: 'disabledDay'};
		}

		if (workedMinutes[dayOfMonth] === undefined) {
			return {enabled: true, classes: ''};
		}

		let className = workedMinutes[date.getDate()]['class'];
		return {enabled: true, classes: className};
	}

	$scope.$watch('vm.infinitScroll.search.user_id', function (value) {
		if (!value) {
			timesheetDatepicker(vm.bladeParams.currentDate, vm.bladeParams.userId);
		} else {
			timesheetDatepicker(vm.bladeParams.currentDate, vm.infinitScroll.search.user_id);
		}
	});

	// init timeout
	var timeoutPromise;
	vm.searchTimeout = 0;

	var loadTimesheets = function loadTimesheets() {
		vm.infinitScroll.search.freshData = new Date().getTime();
	};

	function writeTotal() {
		if (vm.total) {
			if (!vm.total.total_effort_sum) {
				vm.total.total_effort_sum = 0;
			}
			$(".__total").html($filter("timesheetHoursFormat")(vm.total.total_effort_sum / 60, vm.API.USE_INDUSTRIAL_MINUTES));
		}
	}

	vm.isInternalProject = function (entity) {
		return entity.customer_id == null;
	};

	vm.sendToApproval = function (event) {
		vm.confirm('SEND_TO_APPROVAL', $filter('trans')('LANG.SEND_TO_APPROVAL_ALL_TIMESHEETS_UNTIL'))
			.then((response) => {
				if (response !== 'ok') return;
				var postData = angular.copy(vm.infinitScroll.search);
				REST.update({
					url: vm.entity + '/sendToApproval',
					data: postData
				}).then(function (data) {
					angular.forEach(vm.infinitScroll.items, function (v, i) {
						if (data[v.timesheet_id]) {
							angular.extend(v, data[v.timesheet_id]);
						}
					});
					toastr.success($filter('trans')('DATA_HAS_BEEN_SAVED_WITH_SUCCESS'), 'Success!');
				});
			});
	};


	return vm;
});
