kv.controller('accountingPeriodListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "accounting_period_id",
		entity: "accounting_period",
		modal: {
			templateUrl: "accounting_period-edit",
			controller: "accountingPeriodEditCtrl"
		}
	});
});
