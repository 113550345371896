class modalCtrl extends baseCtrl {
	/**
	 *
	 * @param $uibModalInstance
	 * @param $injector
	 * @param entityName
	 * @param primaryKey
	 * @param $scope
	 * @param params data params if needed
	 * @param bladeParamsId if bladeParams needed the you need to give an id for blade-params element
	 */
	constructor($uibModalInstance, $injector, entityName, primaryKey, $scope, params, bladeParamsId = null) {
		super($scope, null, null, $injector);
		//blade-params pot fi accesate doar dupa randare template
		this.bladeParamsId = bladeParamsId;
		this.entity = entityName;
		this.primaryKey = primaryKey;
		if (entityName) this.rest = this.getInjection('resource').init(entityName);
		this.modal = $uibModalInstance;

		this.params = params;
		this.modal.rendered.then(() => {
			this.bladeParams = this.getBladeParams();
		});
		this.dataHasLoaded  = false;
		this.isNewRecord = false;
	}

	cancel() {
		this.modal.dismiss('cancel');
	}

	close(response) {
		this.modal.close(response);
	}

	save() {
		this.errors = undefined;
		let params = {data: this.prepareDataToSave.apply(this, arguments)};
		if (!this.isNewRecord) params.id = params.data[this.primaryKey];
		let action = this.isNewRecord ? "create" : "update";

		// saveUrl - allows to set custom url for the request
		let saveUrl = this.saveUrl ? this.saveUrl : null;
		if(saveUrl) params.url = saveUrl;

		let trans = this.getInjection('$filter')('trans');
		KApp.block('.modal-content');
		this.rest[action](params).then(response => {
				let index = this.isNewRecord ? -1 : this.params.index;
				KApp.unblock('.modal-content');
				if (response.status || response.status == undefined) {
					if (this.saveCallback) this.saveCallback({entity: response.data, index: index, modal: this});
					else this.close({entity: response.status == undefined? response: response.data, index: index});
				} else {
					this.validator.markErrors(response.error, this.element);
					let messages = {};
					Object.keys(response.error).forEach(field => {
						let errors = response.error[field];
						if (angular.isString(errors[0])) messages[field] = errors;
						else messages[field] = [trans('LANG.' + field + 'S_INVALID')];
					});
					this.errors = messages;
					console.warn(this.errors);
				}
			}
		)
		;
	}

}
