function TimesheetApprovalListChangeTypeToCtrl($uibModalInstance, data, $injector, $scope) {
	var vm = new modalCtrl($uibModalInstance, $injector, 'timesheet_approval', 'timesheet_id', $scope, data);
	vm.count = data.countLines;
	vm.data = {};
	vm.saveMove = function () {

		if (!vm.clientSideValidate({}, 'data', $('.modal-content'))) return;
		let searchList = angular.copy(data.searchList);
		let postData = angular.extend(searchList, vm.data);
		vm.confirm("confirm", vm.trans('LANG.CONFIRM_SAVE')).then(response => {
			if (response !== 'ok') return;
			KApp.block('.modal-content');
			vm.rest.update({
				url: vm.entity + '/moveTimesheetFromTo',
				data: postData,
				success: function () {
					vm.close('ok');
				}
			});
		});
	};
	return vm;
}
