(function ()
{
    'use strict';

    angular
        .module('kv')
        .filter('parseTranslate', parseTranslate);

    function parseTranslate($filter) {
        return function(input) {
            if (!input) {
                return "";
            }

            var matches = input.match(/{(LANG\.[^}]+)}/ig);
            var trans = [];
            angular.forEach(matches, function(v) {
                var nonTranslatedWord = v.replace("LANG.", "");
                var nonTranslatedWord = nonTranslatedWord.replace("}", "");
                var nonTranslatedWord = nonTranslatedWord.replace("{", "");
                var translatedWord = $filter("trans")(nonTranslatedWord);
                if (translatedWord != nonTranslatedWord) {
                    trans.push(translatedWord);
                } else {
                    trans.push(v);
                }
            });
            function replaceArray(replaceString, find, replace) {
                for (var i = 0; i < find.length; i++) {
                    replaceString = replaceString.replace(find[i], replace[i]);
                }
                return replaceString;
            }

            if(matches && matches.length) {
                input = replaceArray(input, matches, trans);
            }

            return input;
        };
    }
})();
