kv.controller('RemindersEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'id';
    vm.entity = 'reminder';
    vm.entityType = 'reminder';
    vm.groups = {};
    vm.allLanguages = ['ro', 'en', 'de', 'es', 'it'];
    vm.availableLanguages = [];

    // debugger;
    let url_segments = window.location.pathname.split('/');
    let pageEntityId = url_segments[2];
    let pageIdentifier = url_segments[3];

    vm.entityId = pageEntityId;
    var REST = resource.init("reminders");

    /**
     * Get data for edit
     */
    /**
     * calculate which languages are available
     */
    vm.updateAvailableLanguages = function() {
        // Initialize an empty array for available languages
        vm.availableLanguages = [];

        // Iterate through allLanguages array
        for (const language of vm.allLanguages) {
            // Assume the language is not found in any email initially
            let found = false;

            // Check if the language is present in any email
            if(vm.data.contents){
                for (const content of vm.data.contents) {
                    if (content.language.toLowerCase() === language.toLowerCase()) {
                        found = true;
                        break; // Language found in an email, no need to check further
                    }
                }
            }

            // If the language was not found in any email, add it to availableLanguages
            if (!found) {
                vm.availableLanguages.push(language);
            }
        }
        // console.log(vm.availableLanguages);
    };

    if (pageIdentifier === 'edit') {
        KApp.block($element.find('.k-content__head'));
        REST.get({url: 'reminders/' + pageEntityId,}).then(function (data) {
            vm.data = data;
            if(vm.data.recurrent_sending_day != null){
                vm.data.recurrent_sending_day = data.recurrent_sending_day.toString();
            }
            vm.updateAvailableLanguages();
            KApp.unblock($element.find('.k-content__head'));
        });
    } else {
        vm.data = {};
        vm.data.type = vm.bladeParams.type;
        vm.data.name = 'Fill in the name here';
        vm.data.trigger = vm.bladeParams.trigger;
        vm.data.contents = [];
        // vm.data.needs_performance_addon = true;
        vm.updateAvailableLanguages();
    }

    vm.goBack = function () {
        $state.go('app.reminders');
    };

    /**
     * ck editor config
     */
    vm.ckeditor = {
        height: 600,
        toolbarGroups: [
            {name: 'editing'},
            {name: 'basicstyles', groups: ['basicstyles'] },
            { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
            {name: 'font'},
            // {name: 'styles'},
            {name: 'colors'}
        ],
        removeButtons: 'Subscript,Superscript,RemoveFormat,Strike,Blockquote'
    };


    /**
     * Add content for a language
     * @param language
     */
    vm.addContent = function (language) {
        vm.data.contents.push({
            language: language,
            subject: 'Please enter subject here',
            body: 'Please enter the notification body here. <br/>' +
                'You can build your text using the keywords on the right. <br/>' +
                'For example, you can use the keyword %InvoiceNumber% to display the invoice number. <br/><br/>' +
                'Thank you, <br/>' +
                'The ' + vm.API.tenant.name + ' team',
            tenant_id: vm.API.tenant.tenant_id
        });
        vm.updateAvailableLanguages();
    };

    /**
     * Remove content for a language
     * @param index
     */
    vm.removeContent = function (index) {
        vm.data.contents.splice(index,1);
        vm.updateAvailableLanguages();
    };

    /**
     * Get available keywords
     */
    vm.getKeywords = function() {
        KApp.block($element.find('keywords'));
        vm.keywords = [
            {
                'name': '%InvoiceNumber%',
                'description': 'Factură'
            },
            {
                'name': '%InvoiceDate%',
                'description': 'Data'
            },
            {
                'name': '%InvoicedPeriodFrom%',
                'description': 'Inceputul perioadei facturate'
            },
            {
                'name': '%InvoicedPeriodTo%',
                'description': 'Sfarșitul perioadei facturate'
            },
            {
                'name': '%DueDate%',
                'description': 'Scadență'
            },
            {
                'name': '%DueDays%',
                'description': 'Zile scadență'
            },
            {
                'name': '%DaysFromDueDate%',
                'description': 'Zile de la scadența facturii'
            },
            {
                'name': '%InvoiceAmount%',
                'description': 'Valoare'
            },
            {
                'name': '%InvoiceCurrency%',
                'description': 'Valută'
            },
            {
                'name': '%DueAmount%',
                'description': 'Neîncasate (inclusiv TVA)'
            },
            {
                'name': '%CustomerName%',
                'description': 'Nume client'
            },
            {
                'name': '%CustomerAddress%',
                'description': 'Adresa clientului'
            },
            {
                'name': '%CustomerContactName%',
                'description': 'Numele persoanei de contact a clientului'
            },
            {
                'name': '%OwnCompanyName%',
                'description': 'Companie'
            },
            {
                'name': '%TotalCustomerDueAmount%',
                'description': 'Suma totala neincasata de la client'
            },
            {
                'name': '%TotalCustomerOverdueAmount%',
                'description': 'Suma totala neincasata de la client'
            },
            {
                'name': '%OpenInvoicesTable%',
                'description': 'Listă facturi neîncasate'
            },
            {
                'name': '%OverdueInvoicesTable%',
                'description': 'Listă facturi cu scadența depășită'
            }
        ];
        KApp.unblock($element.find('keywords'));
    };

    vm.saveForm = function () {
        if (vm.data.needs_performance_addon && !vm.API.tenant.addons.performance)
        {
            vm.alert(
                'Performance Addon Required',
                'Please add the Performance Addon in order to use this feature!',
                'You can add the Performance Addon in the <a href="/subscription">Subscription screen</a>.',
                null,
                600)
            .then(function () {
                return;
            });
        } else {
            let postData = angular.copy(vm.data);
            let data = angular.copy(vm.data);
            if(postData.is_recurrent){
                postData.calculation_days_type = 'after';
                postData.calculation_days_trigger = null;
            }else{
                postData.recurrent_type = null;
                postData.recurrent_max_running_days = null;
                postData.recurrent_sending_day = null;
            }
            try {
                // vm.save();
                REST.update({
                    url: 'reminders/' + pageEntityId,
                    data: postData
                })
                    .then((response) => {
                        window.location = '/reminders';
                    });
            } catch (error) {
                console.log(error);
            }
        }
    };

    vm.getKeywords();

    vm.copyText = function (inputId, hashkey) {
        var input = inputId + '_' + hashkey;
        var inputField = document.getElementById(inputId);

        var tempTextarea = document.createElement('textarea');
        tempTextarea.value = inputField.textContent;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();

        document.execCommand('copy');

    };

    return vm;
});
