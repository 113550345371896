kv.controller('courtHearingListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'court_hearing_id';
	vm.entity = 'court_hearing';

	vm.count = {
		total: 0,
		showing: 0
	};

	/*build filtered date [(yesterday) - (currenDate + 1month)] */
	var dateTo = new Date();
	dateTo.setMonth(dateTo.getMonth() + 1);

	var dateFrom = new Date();
	dateFrom.setDate(dateFrom.getDate() - 1);

	var yearTo = dateTo.getFullYear();
	var monthTo = dateTo.getMonth() + 1;
	var dayTo = dateTo.getDate();

	var yearFrom = dateFrom.getFullYear();
	var monthFrom = dateFrom.getMonth() + 1;
	var dayFrom = dateFrom.getDate();

	if(monthTo < 10) {
		monthTo = '0' + monthTo;
	}
	if(monthFrom < 10) {
		monthFrom = '0' + monthFrom;
	}

	if(dayTo < 10) {
		dayTo = '0' + dayTo;
	}
	if(dayFrom < 10) {
		dayFrom = '0' + dayFrom;
	}

	vm.getInfinitScroll({
		params: {
			action: 'court_hearing'
		},
		url: "court_hearing",
	});
	vm.infinitScroll.search.date_from = (vm.infinitScroll.search.date_from ? vm.infinitScroll.search.date_from : yearFrom + '-' + monthFrom + '-' + dayFrom);
	vm.infinitScroll.search.date_to = (vm.infinitScroll.search.date_to ? vm.infinitScroll.search.date_to : yearTo + '-' + monthTo + '-' + dayTo);
	
	vm.delete = function deleteEntity($index) {
		$http.delete("court_hearing/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
			vm.infinitScroll.items.splice($index, 1);
		});
	};

	return vm;
});

