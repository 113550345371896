/**
 * This directive should not be used directly from view.
 * This is the actual widget, and get data from widget-wrapper
 */
(function ()
{
	'use strict';

	angular
		.module('kv')
		.directive('widgetitem', widgetitem);

	function widgetitem(Session) {
		return {
			scope: {
				widget: '='
			},
			restrict: "E",
		    link: function(scope)
		    {
		      	scope.$watch('widget', function(widget)
		      	{
		      		// dinamically load the widget template
			        if (widget.id && widget.id.length)
			        {
			            scope.widgetTemplate = "/assets/custom/js/directives/widget/widgets/" + widget.id + "/template.html";
			        }
		      	});
		      	scope.baseURL = Session.baseURL;
		      	scope.goToState = goToState;
				function goToState(state, params) {
					 $state.go(state, params);
				}

				function dateDifference(end_date, start_date) {
					var now = moment()
			            var date = cmdate.fromServer(input);
			            if(date) {
			                var diff = moment.duration(date.diff(now));
			                return diff.humanize(true);
			            }
			            return "";
				}
		    },
		    template: '<ng-include src="widgetTemplate" class="col-md-12"></ng-include>'
		};
	}


})();
