angular
    .module('kv')
    .directive('draggable', () => (scope, element) => {
        let el = element[0];
        el.draggable = true;

        el.addEventListener('dragstart', (e) => {
            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.setData('item_id', e.target.dataset.itemId);
            e.target.classList.add('dragging');
            return false;
        }, false);

        el.addEventListener('dragend', (e) => {
            e.target.classList.remove('dragging');
            return false;
        }, false);
    });
