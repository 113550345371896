class SettingsController {
}

kv.component('kvSettings', {
    templateUrl: '/assets/custom/js/components/settings/settings.component.html',
    controller: SettingsController,
    bindings: {
        settingsData: '=',
        entitySettings: '='
    }
});
