angular
	.module('kv')
	.filter('currency', currency);

function currency($filter) {
    return function(input, currency, precision, dontApplyFilter) {
    	if(!dontApplyFilter) {
    		precision = (precision != undefined) ? precision : 2;
	    	input = (input) ? number_format(input, precision, ".", ",") : 0;

	    	let output = '';
	    	if (currency) {
	    		// format currency display
	    		switch (currency) {
	    			case 'EUR': output = '€ ' + input; break; 
	    			case 'USD': output = '$ ' + input; break; 
	    			case 'GBP': output = '£ ' + input; break;
	    			case 'RON': output = 'RON ' + input; break;
	    			default: output = currency + ' ' + input;
	    		}
	    	} else {
	    		// no currency
	    		output = input;
	    	}

	        return output;
	    } else {
	    	return input;
	    }
    };
}
