kv.controller('ExpenseStandardImportCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.data = {all: [], filtered: []};
    vm.filter.value = "";
    vm.columns = {};
    vm.rest = vm.getInjection('resource').init('expense');
    vm.filesUpload = {
        config: {
            onChange: event => {
                KApp.block(".expense-table");
                vm.errors = undefined;
                vm.data = {all: [], filtered: []};
            },
            uploaded: response => {
                KApp.unblock(".expense-table");
                if (response.error !== null) {
                    vm.showErrorMessages(response);
                } else {
                    vm.columns = response.columns;
                    vm.reversedColumns = response.reversedColumns;
                    vm.data.all = response.data;
                    vm.checkForErrors(true);
                    vm.filter.value = vm.data.errorsCount ? 'error' : '';
                    vm.filterApply();
                }
            }
        }
    };
    vm.checkForErrors = function (setIds) {
        vm.data.errorsCount = 0;
        let nextId = 0;
        vm.data.all.forEach(item => {
            vm.data.errorsCount += item.hasError ? 1 : 0;
            if (setIds) {
                item.id = nextId;
                nextId++;
            }
        });
    };
    vm.filterApply = function () {
        vm.data.filtered = vm.data.all.filter(item => {
            if (vm.filter.value == 'error') return item.hasError;
            if (vm.filter.value == 'valid') return !item.hasError;
            return true;
        });
    };
    vm.delete = function ($index, item) {
        vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then(response => {
            if (response !== 'ok') return;
            let id = item.id;
            vm.data.filtered.splice($index, 1);
            let index = vm.data.all.findIndex(item => item.id == id);
            vm.data.all.splice(index, 1);
            vm.checkForErrors();
        });
    };
    vm.edit = function (entity) {
        vm.openModal({
            templateUrl: "expense-standard-import-edit",
            controller: 'ExpenseStandardImportEditCtrl',
            size: 'lg',
            resolve: {
                params: function () {
                    return {
                        entity: entity,
                        columns: vm.columns,
                        reversedColumns: vm.reversedColumns
                    };
                }
            }
        }).then(response => {
            if (response.entity) {
                //  entity.source = response.entity.source;
                entity.source = [
                    response.entity.source.user,
                    response.entity.source.project,
                    response.entity.source.internal_project,
                    response.entity.source.reference,
                    response.entity.source.date,
                    response.entity.source.quantity,
                    response.entity.source.expense_type,
                    response.entity.source.total_with_vat,
                    response.entity.source.currency,
                    response.entity.source.vat_type
                ];
                entity.error = response.entity.error;
                entity.processed = response.entity.source;
                entity.hasError = response.entity.hasError;
                vm.checkForErrors();
            }
        });
    };
    vm.import = function () {
        if (vm.importing) {
            vm.confirm('IMPORT', "Anulati importul?").then(response => {
                if (response !== 'ok') return;
                vm.cancelImport();
            });
        } else {
            vm.confirm('IMPORT', 'Efectuati importul?')
                .then(response => {
                    if (response !== 'ok') return;
                    // import doar inregistrarile valide
                    vm.filter.value = 'valid';
                    vm.importing = true;
                    vm.filterApply();
                    vm.currentRecord = 0;
                    vm.importCancel = false;
                    vm.importRecord(false);
                });
        }
    };
    vm.cancelImport = function () {
        vm.importing = false;
        vm.importCancel = true;
    };
    vm.importRecord = function (increment) {
        if (increment) vm.currentRecord++;
        if (vm.currentRecord >= vm.data.filtered.length) return vm.cancelImport();
        let data = vm.data;
        if (data.imported) {
            return vm.importRecord(true);
        }
        KApp.block(".expense-table");
        vm.rest.post({url: "expense/doImport", data: data}).then(response => {
            if (response.status) data.imported = response.data;
            if (vm.importCancel) return vm.cancelImport();
            else vm.importRecord(true);
            KApp.unblock(".expense-table");
            toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
        });
    };
    return vm;
});

