kv.controller('seniorityListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "seniority_id",
		entity: "seniority",
		modal: {
			templateUrl: "seniority-edit",
			controller: "seniorityEditCtrl"
		}
	});
});
