kv.controller('ProjectPercentShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.date = moment();
    var REST = resource.init('report');
    vm.result = [];
    vm.percent = {};
    vm.action = {
        updateProjectStatus: updateProjectStatus,
        getData: getData,
        reset: reset,
    };
    var diff = '';
    vm.update_date = moment();
    
    var params = kvUtils.getAllQueryParams();
    
    function getData() {
        $('#table').DataTable().destroy();
        $scope.isLoading = true;
        REST.getArray({
            url: "report/projectPercent?getData=true;",
            params: vm.filter
        }).then(function (data) {
            vm.result = data;
            angular.forEach(vm.result, function (value, key) {
                if (value.status_updated_at) {
                    var updated_at = moment(value.status_updated_at);
                    diff = updated_at.diff(vm.date, 'days')
                    //diff == 0, then it's in less than a day
                    if (diff == 0) {
                        vm.result[key].blur = true;
                    }
                }
            });
            $scope.isLoading = false;
            setTimeout(function() {
                $('#table').DataTable({
                    fixedHeader: {
                        header: false,
                        footer: true
                    },
                    "bPaginate": false,

                    "paging":   false,
                    "info":     false,
                    "bLengthChange": false,
                    "bDestroy": true
                });
                $('.dataTables_filter input[type="search"]').
                    attr('placeholder','Search in table...').
                    removeClass('input-small').
                    css({'width':'350px','display':'inline-block'});

                $('.dataTables_filter').addClass('pull-right');
            }, 1500);
        })
    };

    function updateProjectStatus(entity) {
        if (!vm.update_date) {
            $('[ng-model="vm.update_date"] input, [ng-model="vm.update_date"] span button').addClass("warning-border");
            return false;
        }

        vm.newDate = moment(vm.update_date, "DD/MM/YYYY").format('DD-MM-YYYY');
        vm.newDate2 = moment().format('DD-MM-YYYY');

        if (vm.newDate.toString() == vm.newDate2.toString()) {
            vm.update_date = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        if (entity) {
            if (
                (!vm.percent[entity.project_id]) ||
                (parseFloat(entity.status_percent) >= parseFloat(vm.percent[entity.project_id])) ||
                (vm.percent[entity.project_id] > 100)
            ) {
                $('#' + entity.project_id).addClass("warning-border");
                return false;
            }

            $('[ng-model="vm.percent[entity.project_id]"] input, [ng-model="vm.percent[entity.project_id]"]').removeClass("warning-border");

            //create postData to update project_status
            var postData = {};
            postData.project_status_id = entity.project_status_id;
            postData.project_id = entity.project_id;
            postData.percent_completed = vm.percent[entity.project_id];
            // postData.created_at = cmdate.toServer(vm.update_date);

            vm.percent = {};

            var updated_at = moment(entity.status_updated_at);
            diff = updated_at.diff(vm.result, 'days');

            if (entity.project_status_id && (diff == 0)) {
                REST.update({
                    url: "project_status/" + entity.project_status_id,
                    params: postData
                }).then(function (response){
                    if (response) {
                        updateResult(response);
                    }
                })
            } else {
                delete postData.project_status_id;
                postData.tenant_id = API.user.tenant_id;
                // postData.date =  cmdate.toServer(moment());
                postData.created_by = API.user.user_id;

                REST.create({
                    url: "project_status",
                    data: postData
                }).then(function (response) {
                    if (response) {
                        updateResult(response);
                    }
                })
            }
        }
    }

    function updateResult(data) {
        var aux = true;
        angular.forEach(vm.result, function (value, key) {
            if (aux) {
                if (value.project_id == data.project_id) {
                    vm.result[key].project_status_id = data.project_status_id
                    vm.result[key].status_percent = data.percent_completed;
                    vm.result[key].status_updated_at = data.created_at;
                    vm.result[key].blur = true;
                    aux = false;
                }
            }
        })
    }

    function reset() {
        vm.filter = {};
        vm.result= [];
        for (var x = 0; x < $("lookup").length; x++) {
            var placeholder = $($("lookup")[x]).attr('placeholder');
            $($('.select2-selection__rendered')[x]).html('<span class="select2-selection__placeholder">' + placeholder + '</span>');
        }
    }
    
    return vm;
});
