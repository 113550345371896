kv.controller('SunInvoicesReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    var params = kvUtils.getAllQueryParams();
    vm.entity = 'sun-export-detail';
    var REST = resource.init(vm.entity);
    vm.exportInvoice = exportInvoice;
    var url = vm.bladeParams.url;
    var journal_number = vm.bladeParams.journalNumber;
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function () {
        tableInit();

        function tableInit() {
            $('#table').DataTable({
                destroy: true, //for reinitialization purposes
                paging: false,
                searching: true,
                order: [[ 19, "asc" ]],
                scrollY: '50vh',
                scrollCollapse: true,
            });

            $('.dataTables_filter input[type="search"]').
                attr('placeholder','Search in table...').
                removeClass('input-small').
                css({'width':'350px','display':'inline-block'});

            $('.dataTables_filter').addClass('pull-right');
        }
    });
    
    getLocalJournalNumber();
    //check if exist journal_number from filters, if not if it's send from exportSun
    function getLocalJournalNumber(journalNo) {
        if (journalNo){
            vm.journalNoFilter = journalNo;
        } else if (journal_number) {
            vm.journalNoFilter = journal_number;
        } else {
            vm.journalNoFilter = '';
        }
    }

    function exportInvoice() {
        var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'sun-export',
            controller: function ($scope, $uibModalInstance, resolve) {
                var vm = this;
                vm.cancel = cancel;
                vm.exportSun = exportSun;
                vm.entity_type = 'invoice';

                getJournalNo();
                //get max+1 journal_number from sun_export_journal
                function getJournalNo() {
                    var REST = resource.init('sun_export_journal');
                    REST.get({
                        url: 'sun_export_journal/journal_number/'
                    }).then(function (data) {
                        vm.journalNo = data.number;
                    })
                }

                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                };

                function exportSun() {
                    resolve.getLocalJournalNumber(vm.journalNo);
                    window.location.href = url;
                    $uibModalInstance.close();
                }
            },
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                resolve: function () {
                    return {
                        getLocalJournalNumber: getLocalJournalNumber,
                    };
                }
            }
        })
    }

	return vm;
});
