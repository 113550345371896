kv.controller('EmployeeAccountEditCtrl', function ($uibModalInstance, data, $scope, $injector, resource, cmdate, validator, $filter, $http) {
	var vm = new modalCtrl($uibModalInstance, $injector, 'employee_account', 'employee_account_id', $scope, data);
	vm.isNewRecord = data.index == -1;
	$scope.$watch('[vm.data.type_id]', function (value) {
		if (parseInt(value) == 5) { // intern
			vm.data.currency = API.user.tenant.reporting_currency;
		}
	});
	if (vm.isNewRecord) {
		vm.rest.get({
			url: 'employee_account/getNextNumber'
		}).then(function (res) {
			vm.data.number = res.number;
		});
	}

	if (data.index > -1) {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		$scope.modalTitle = $filter('trans')('EDIT');
		$http.get('/employee_account/' + data.entity.employee_account_id)
			.then(function (response) {
				vm.data = response.data;
				vm.dataHasLoaded = true;
				KApp.unblock('.modal-content');
				$scope.$broadcast('dataLoaded');
			});
	} else {
		$scope.modalTitle = $filter('trans')('ADD');
		vm.data = {
			user_id: API.user.user_id,
			user: {
				full_name: API.user.full_name
			},
			date: moment().format('YYYY-MM-DD HH:mm'),
			type_id: data.type.id,
			sys_employee_account_type: {
				type_name: data.type.name
			}
		};
	}
	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		if ([3, 4, 5, 6].indexOf(parseInt(vm.data.type_id)) > -1) {
			postData.status_id = 4;
		}
		postData.date = cmdate.toServer(vm.data.date);

		return postData;
	};


	return vm;
});
