kv.controller('courseViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $timeout) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'course_id';
	vm.entity = 'course';
	vm.entityType = 'course';
	vm.entityId = vm.bladeParams.courseId;

	vm.commentsConfig = {
		entity_type: vm.entity
	};

	vm.reloadTooltip = function($index) {
		setTimeout(function() {
			$('#tooltip-' + $index).tooltip();
		}, 200);
	};

	$scope.range = function(min, max, step) {
		step = step || 1;
		var input = [];
		for (var i = min; i <= max; i += step) {
			input.push(i);
		}
		return input;
	};

	var REST = resource.init();

	if(vm.bladeParams.getmemberswithprogress){
		REST.getArray({
			url: "/course/" + vm.entityId + "/getMembersWithProgress"
		}).then(function(res) {
			vm.members = res;
			vm.members_count = res.length;
		});
	}

	if(vm.bladeParams.getcountbyuser){
		REST.getArray({
			url: "/course/" + vm.entityId + "/getCountByStatus"
		}).then(function(res) {
			vm.stats = res;
		});
	}

	if(vm.bladeParams.countstars){
		REST.getArray({
			url: "/course/" + vm.entityId + "/countStars"
		}).then(function(res) {
			vm.rating = res;
		});
	}

	$('#media-player').on('hide.bs.modal', function () {location.reload(); });

	$.get(vm.bladeParams.getcountbystatusaction, function (response) {
		$('.dashboard-stat span[data-counter="counterup"]').each(function (id, val) {
			var status = $(this).data('status');
			$(this).html(response[status]);
			$(this).attr('data-value', response[status]);
		});
	});

	vm.confirmAbandon = function(url) {
		vm.confirm('Confirm', vm.trans('LANG.CONFIRM_ABANDON_QUESTION')).then(response => {
			if (response !== 'ok') return;
			window.location.href = url;
		});
	};

	vm.confirmComplete = function () {
		var modal = $('#rate');
		$(modal).modal('show');
		// $('input#rate').ionRangeSlider({min: 1, max: 5, from: 3});
	};

	vm.setRate = function(i) {
		console.log(i);
		// $(".rates").addClass('btn-secondary').removeClass('btn-brand');
		$("#rate" + i).addClass('btn-brand').removeClass('btn-secondary');
		$('.modal#rate #inputRate').val(i);
	};

	return vm;
});

