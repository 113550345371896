kv.controller('DashboardLitigationsListInsolvencyActsCtrl', function ($element, $attrs, $scope, $injector) {
	let vm = new DashboardWidgetListCtrl($element, $attrs, $scope, $injector);
	vm.getInsolvencyDetail = function (fiscal_id) {
		vm.openModal({
			templateUrl: "insolvency-detail",
			controller: 'DashboardLitigationsListInsolvencyActsDetailsCtrl',
			controllerAs: 'vm',
			size: 'xl',
			resolve: {
				params: function () {
					return fiscal_id;
				},
				resolve: function () {
					return {
						fiscal_id: fiscal_id,
					};
				}
			}
		});
	};


	vm.download = function (item) {
		let bpi_date = new Date(item.date);
		let year = bpi_date.getFullYear();
		window.open('https://api.keyvision.eu/api/bpi/download/' + year + '/' + item.number + '?api_token=ce2U78TbjfSWGnmfbqJeHNfe');
	};

	return vm;
});
