kv.controller('partyMonitorListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'party_monitor_id';
	vm.entity = 'party_monitor';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'party_monitor'
		},
		url: "party_monitor",
	});

	vm.delete = function ($index) {
		vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then(response => {
			if (response !== 'ok') return;
			$http.delete("party_monitor/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
				vm.infinitScroll.items.splice($index, 1);
			});
		});
	};

	vm.addEditEntity = function (entity, $index) {
        vm.openModal({
            templateUrl: "party_monitor-edit",
            controller: 'partyMonitorEditCtrl',
            controllerAs: 'vm',
            size: 'md',
			  resolve: {
            	params: {
            		index: $index,
            		entity: entity
					}
			  }
        }).then(function (response) {
        	if (!response) return;
			if (response.index == -1) vm.infinitScroll.items.unshift(response.entity);
			else angular.extend(entity, response.entity);
        });
    };

	return vm;
});

