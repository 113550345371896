kv.controller('courtPhaseEditCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'court_phase', 'court_phase_id', $scope, params);

	if (!params.court_phase_id) {
		vm.data = params;
		vm.isNewRecord = true;
	} else {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
			vm.syncHearingsAfterPhaseSave();
		});
		let rest = vm.getInjection('resource').init('court_phase');
		rest.get({id: params.court_phase_id}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) vm.data = response;
			else console.error(response);
		});
	}
	vm.saveCallback = saveCallback;

	vm.callJustBuffer = function () {
		vm.data.calling_just_buffer = true;
		var REST = resource.init('court_phase');

		REST.post({
			url: "court_phase/callJustBuffer",
			data: vm.data,
			params: {
				callJustBuffer: true,
			}
		}).then(function (data) {
			if (data.hasError) {
				// set variables for verify if buffer was called, and to display a warning
				vm.hasErrorWhileCallingBuffer = true;
				vm.hasErrorWhileCallingBufferShowMessage = true;
				vm.data.calling_just_buffer = false;
			} else {
				if (data.case_number) {
					vm.data = data;
					if(vm.data.link && vm.data.link !==null){
						vm.data.portal_sync_link = vm.data.link;
					}else{
						vm.data.portal_sync_link = "http://portal.just.ro/"+ data.portal_court_id +"/SitePages/Rezultate_dosare.aspx?k=" + data.case_number + "&a=%20MJmpIdInstitutie=" + data.portal_court_id;
					}

					if(data.sync_link) {
						vm.data.sync_link = data.sync_link;
					}
					vm.data.calling_just_buffer = false;
					vm.data.calling_just_buffer_error = false;
				} else {
					vm.data.calling_just_buffer = false;
					vm.data.calling_just_buffer_error = true;
				}
				vm.hasErrorWhileCallingBuffer = false;
				vm.hasErrorWhileCallingBufferShowMessage = false;
			}
		}, function () {
			vm.data.calling_just_buffer = false;
			vm.data.calling_just_buffer_error = true;
		});
	};

	vm.getCourtPhases = function () {
		var REST = resource.init('court_phase/list');
		REST.getArray({
			params: {
				legal_case_id: vm.bladeParams.LegalCaseId
			}
		}).then(function (data) {
			vm.court_phases = data;
		});
	};

	vm.syncHearingsAfterPhaseSave = function () {
		var REST = resource.init('court_hearing/sync');
		REST.get({
			params: {
				legal_case_id: vm.bladeParams.LegalCaseId,
				fresh: new Date()
			}
		}).then(function (result) {
			sync_result = result.data;
			vm.getCourtPhases();
			vm.syncingCourtHearing = false;

			// show sync result
			// if (sync_result.status != 'success') {
			// 	toastr.error(sync_result.status_details);
			// } else {
			// 	toastr.success(sync_result.status_details);
			// }
		});
	};

	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);

		postData.sync_link = (postData.sync_link ? postData.sync_link : postData.portal_sync_link);
		return postData;
	};

	return vm;
});
