kv.controller('sysRoutesListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'route_id';
	vm.entity = 'sys_routes';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'sys_routes'
		},
		url: "sys_routes",
	});

	vm.addEdit = function (route_id) {
		vm.openModal({
			templateUrl: "sys_routes-edit",
			controller: 'sysRoutesEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					return {
                        route_id: route_id,
                    };
				},
				saveCallback: function () {
					vm.infinitScroll.search.refresh++;
				},
			}
		}).then(response => {
			if (!response) return;
			window.location = "/sys_routes";
		});
	}


	return vm;
});

