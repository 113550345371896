kv.controller('TimesheetAnalysisOverviewReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var data = JSON.parse(vm.bladeParams.data);
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }
    
    $(document).ready(function() {
        var t = $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
            "columnDefs": [
                {
                    "searchable": false,
                    "orderable": false,
                    "targets": 0
                }
            ],
            "order": [[ 1, 'asc' ]]
        });

        t.on( 'order.dt search.dt', function () {
            t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                cell.innerHTML = i+1;
            } );
        } ).draw();

        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            addClass('no-print').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');

        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            $(e.target.hash).find('.chart').each(function () {
                if ($(this).highcharts()) {
                    $(this).highcharts().reflow();
                }
            });
        });
    });

    vm.isLoading = true;

    var chart_categories = data[2];
    var series = data[1];
    var labels_rotation = data[3];

    // chart general
    vm.chartGeneral = function() {
        $('#chartGeneral').hide();
        var config = {
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            subtitle: {
                text: null
            },
            xAxis: [{
                categories: chart_categories,
                crosshair: true,
                labels: {
                    rotation: labels_rotation
                }
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value} ' + $filter('trans')('HOURS')
                },
                title: {
                    text: $filter('trans')('TOTAL_EFFORT')
                }
            }],
            tooltip: {
                shared: true
            },
            series: series.general,
            legend: {
                layout: 'horizontal',
                align: 'center',
                x: 0,
                verticalAlign: 'bottom',
                y: 0,
                floating: false,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
            },
        };

        // hide dataLabels when resolution is xxs (phone)
        if($(window).width() < 480) {
            config.plotOptions.pie.dataLabels.enabled = false;
        }

        $('#chartGeneral').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#chartGeneral').show();
            $('#chartGeneral').highcharts().reflow();
        }, 1);
    };

    // chart customers
    vm.chartCustomers = function() {
        $('#chartCustomers').hide();
        var config = {
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            subtitle: {
                text: null
            },
            xAxis: [{
                categories: chart_categories,
                crosshair: true,
                labels: {
                    rotation: labels_rotation
                }
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}'
                },
                title: {
                    text: $filter('trans')('CUSTOMERS')
                }
            }],
            tooltip: {
                formatter: function() {
                    return '<span style="font-size:1.1em;font-weight:bold;">' + this.x + '</span><br />' + $filter('trans')('CUSTOMERS') + ': <b>'+ this.y + '</b>';
                },
                shared: true
            },
            series: series.customers,
            legend: {
                enabled: false
            }
        };

        // hide dataLabels when resolution is xxs (phone)
        if($(window).width() < 480) {
            config.plotOptions.pie.dataLabels.enabled = false;
        }

        $('#chartCustomers').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#chartCustomers').show();
            $('#chartCustomers').highcharts().reflow();
        }, 1);
    };

    // chart efficiency
    vm.chartEfficiency = function() {
        $('#chartEfficiency').hide();
        var config = {
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            subtitle: {
                text: null
            },
            xAxis: [{
                categories: chart_categories,
                crosshair: true,
                labels: {
                    rotation: labels_rotation
                }
            }],
            yAxis: {
                title: {
                    text: $filter('trans')('EFFICIENCY') + ' %'
                },
                min: 0,
                stackLabels: {
                    enabled: true
                },
                plotLines: [{
                    value: series.efficiency.total_efficiency,
                    width: 3,
                    zIndex: 999,
                    color: '#ffc57c',
                    dashStyle: 'shortdash',
                    label: {
                        text: $filter('trans')('AVERAGE'),
                        style: {
                            color: '#ffc57c',
                        },
                    }
                }],
            },
            tooltip: {
                formatter: function() {
                    return '<span style="font-size:1.1em;font-weight:bold;">' + this.x + '</span><br />' + $filter('trans')('CUSTOMERS') + ': <b>'+ this.y + ' %</b>';
                },
                shared: true
            },
            plotOptions: {
                column: {
                    zones: [{
                        value: series.efficiency.total_efficiency, // Values up to efficiency average (not including) ...
                        color: '#f47272' // ... have the color red.
                    },{
                        color: '#769EFE' // Values from efficiency average (including) and up have the color blue
                    }]
                }
            },
            series: series.efficiency.user,
            legend: {
                enabled: false
            }
        };

        // hide dataLabels when resolution is xxs (phone)
        if($(window).width() < 480) {
            config.plotOptions.pie.dataLabels.enabled = false;
        }

        $('#chartEfficiency').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#chartEfficiency').show();
            $('#chartEfficiency').highcharts().reflow();
        }, 1);
    };

    vm.renderCharts = function() {
        if (series && series.general) {
            vm.chartGeneral();
        }
        if (series && series.customers) {
            vm.chartCustomers();
        }
        if (series && series.efficiency) {
            vm.chartEfficiency();
        }
    }

    vm.renderCharts();

	return vm;
});
