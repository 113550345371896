kv.controller('documentTemplatesEditCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'document_templates', 'document_templates_id', $scope, params);
    params.entity = params.entity ? params.entity : '';
    if(params.entity.document_templates_id) {
        vm.modal.rendered.then(() => {
            if (!vm.dataHasLoaded) KApp.block('.modal-content');
        });
        vm.isNewRecord = false;
        vm.saveCallback = saveCallback;
        vm.rest = resource.init('document_templates');
        vm.rest.get({
            id: params.entity.document_templates_id
        }).then(function(res) {
            KApp.unblock('.modal-content');
            if(res) {
                vm.data = res;
                vm.data.upload_file_name = vm.data.file_name;
            }
        })
    } else {
        vm.data = {};
        vm.dataHasLoaded = true;
        vm.isNewRecord = true;
        vm.saveCallback = saveCallback;
        vm.data.upload_file_name = null;
    }

    vm.filesUpload = {
        loaded: true,
        loaderSelector: "#doc",
        uploaded: function (response, loader) {
            if (response && response.status == true) {
                vm.data.upload_file_name = vm.data.file_name = response.data.original_file_name;
                vm.data.stored_file_name = response.data.file_name;
            } else {
            }
            if (loader) loader.remove();
        }
    };

    vm.prepareDataToSave = function () {
        let postData = angular.copy(vm.data);
        postData.entity_type = vm.params.display_entity;
        return postData;
    };
    return vm;
});
