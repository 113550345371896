kv.controller('travelOrderViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'travel_order_id';
    vm.entity = 'travel_order';
    vm.entityType = 'travel_order';
    vm.groups = {};

    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];

    vm.entityId = page_entity_id;
    vm.commentsConfig = {
        entity_type: vm.entity
    };

    vm.goBack = function () {
        $state.go("app.registers");
    };

    vm.edit = function () {
        $state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
    };

    return vm;
});
