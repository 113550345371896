kv.controller('UserWorkloadEditCtrl', function ($uibModalInstance, params, $http, saveCallback, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'user_workload', 'user_workload_id', $scope, params);
	if (params.id) {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});

		vm.dataHasLoaded = false;
		vm.rest = resource.init('user_workload');

		vm.rest.get({
			url: 'user_workload/' + params.id,
			params: {
				action: 'get_workload_data'
			}
		}).then(function (response) {
			vm.data = response;
			vm.data.date = params.date;
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
		});

		// $http.get("/user_workload/" + params.id).then(function (response) {
		// 	vm.data = response.data;
		// 	vm.dataHasLoaded = true;
		// 	KApp.unblock('.modal-content');
		// 	// in case of multiple select ( chips ) use this below as model
		// 	// if (vm.data.internal_project_practice) {
		// 	// 	angular.forEach(vm.data.internal_project_practice, function (v, i) {
		// 	// 		vm.data.internal_project_practice[i].id = v.practice_id;
		// 	// 		vm.data.internal_project_practice[i].name = v.practice.practice_name;
		// 	// 	});
		// 	// }
		// });
	} else {
		vm.data = {
			user_id: params.create_user_id,
			date: params.date
		};
		vm.isNewRecord = true;
	}

	vm.saveCallback = saveCallback;
	return vm;
});
