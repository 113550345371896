kv.controller('contractViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal,cmdate, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'contract_id';
	vm.entity = 'contract';
	vm.entityType = 'contract';
	vm.groups = {};

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];

	vm.entityId = page_entity_id;
	vm.commentsConfig = {
		entity_type: vm.entity
	};

	vm.goBack = function () {
		$state.go("app.contracts");
	};


	vm.edit = function () {
		$state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
	};
	
	vm.createEmpowerment = function (contract) {
		if(contract.contract_status_id === 4){
			let REST = resource.init('empowerment');
			REST.post({
				url: "empowerment/createFromContract",
				data: {
					contract_id: contract.contract_id,
					customer_id: contract.customer_id,
					customer_fiscal_entity_id: contract.customer_fiscal_entity_id,
					date : contract.date,
					requested_by: contract.source_user_id,
					empowerment_status_id : 1
				}
			}).then(function (response) {
				window.open('/empowerment/' + response.empowerment_id, '_blank');
			});
		}else{
			toastr.error(vm.trans('EMPOWERMENT_FROM_CONTRACT_RESTRICTION'));
		}
	};

	vm.changeStatus = function (contract_status_id) {
		var REST = resource.init("contract");
		REST.update({
			id: page_entity_id,
			data: {
				contract_status_id: contract_status_id
			},
			params: {
				force_update_entity: true
			}
		}).then(function (data) {
			window.location = "/contract/" + data.data.contract_id;
		});
	};

	return vm;
});
