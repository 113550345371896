kv.controller('StatisticsByUserReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var data = JSON.parse(vm.bladeParams.data);
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }
    
    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
            "columnDefs": [ {
                "targets": 1,
                "orderable": false
            } ]
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            addClass('no-print').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
            addClass('pull-right').addClass('no-print');
    });

    vm.isLoading = true;

    var chart_categories = data[2];
    var series = data[1];
    function chart() {
        $('#chart').hide();
        var config = {
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            subtitle: {
                text: null
            },
            xAxis: [{
                categories: chart_categories,
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value} ore',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: $filter('trans')('TOTAL_EFFORT'),
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
            }, { // Secondary yAxis
                title: {
                    text: $filter('trans')('EFFICIENCY'),
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                labels: {
                    format: '{value} %',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                opposite: true
            }],
            tooltip: {
                shared: true
            },
            series: series,
            legend: {
                layout: 'horizontal',
                align: 'center',
                x: 0,
                verticalAlign: 'bottom',
                y: 0,
                floating: false,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
            },
        }

        // hide dataLabels when resolution is xxs (phone)
        if( $(window).width() < 480 )
        {
          config.plotOptions.pie.dataLabels.enabled = false
        }
        $('#chart').highcharts(config);
        vm.isLoading = false;


        setTimeout(function() {
            $('#chart').show();
            $('#chart').highcharts().reflow();
        }, 1);
    }

    if (series) {
        chart();
    }

	return vm;
});
