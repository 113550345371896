kv.controller('ipWhitelistListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "ip_whitelist_id",
		entity: "ip_whitelist",
		modal: {
			templateUrl: "ip_whitelist-edit",
			controller: "ipWhitelistEditCtrl"
		}
	});
});
