kv.controller('DashboardLitigationsListHearingsNextWeekCtrl', function ($element, $attrs, $scope, $injector) {
	var vm = new DashboardWidgetListCtrl($element, $attrs, $scope, $injector, function() {
		if (vm.data.initial) {
			$scope.count = {
				all: 0,
				mine: 0,
				other: 0
			};

			// count for badge from filter
			angular.forEach(vm.data.initial, function (v, i) {
				$scope.count.all = parseInt($scope.count.all) + 1;
				if (v.assigned_to_me == 1) {
					$scope.count.mine = parseInt($scope.count.mine) + 1;
				} else if (v.assigned_to_me !== 1) {
					$scope.count.other = parseInt($scope.count.other) + 1;
				}
			});
		}
	});
	vm.filter_court_hearings = 'all';
	vm.changeFilter = function (filter) {
		if (filter) this.filter_court_hearings = filter;
		if (this.filter_court_hearings == 'other') this.data.filtered = this.data.initial.filter(item => item.assigned_to_me !== 1);
		else if (this.filter_court_hearings == 'mine') this.data.filtered = this.data.initial.filter(item => item.assigned_to_me == 1);
		else this.data.filtered = this.data.initial;
	};
	return vm;
});
