kv.controller('userVacationListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'vacation_id';
	vm.entity = 'user_vacation';
	vm.sessionUserId = API.user.user_id;

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'user_vacation'
		},
		url: 'user_vacation',
	});

	vm.changeStatus = function(status_id, status_name, entity) {
		let rest = this.getInjection('resource').init(this.entity);
		entity.vacation_status_id = status_id;
		entity.sys_user_vacation_status.user_vacation_status_id = status_id;
		entity.sys_user_vacation_status.user_vacation_status_name = status_name;
		rest.update({
			url: 'user_vacation/' + entity.vacation_id,
			data: entity

		}).then(function (data) {
			//	window.location = "/user_vacation";
		});

	};

	return vm;
});

