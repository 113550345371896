class ParamValueListCtrl extends baseCtrl {
	constructor($scope, $element, $attrs, $injector) {
		super($scope, $element, $attrs, $injector);
		this.entity = 'param_value';
		this.primaryKey = 'param_value_id';
		this.references = {};
		this.getInfinitScroll({
			callback: function (response) {
				response.data.paginator.data.forEach(item=>{
					if(item.field_type == 'DATE') item.param_value = moment(item.param_value, 'YYYY-MM-DD');
				});
			}
		});
		this.controls = {};
		this.rest = this.getInjection('resource').init('param_value');
	}

	getControl(item) {
		let controlType = item.field_type, element;
		switch (controlType) {
			case "BOOLEAN":
				element = createElement('mt-checkbox', {ngModel: "entity.param_value", ngChange: 'vm.saveChange(entity)'});
				break;
			case "TEXT":
				element = createElement('input', {type: 'text', ngModel: "entity.param_value", ngBlur: 'vm.saveChange(entity)'});
				break;
			case "NUMERIC":
				element = createElement('input', {type: 'number', step: 1, ngModel: "entity.param_value", ngBlur: 'vm.saveChange(entity)', castToNumber: "float"});
				break;
			case "DATE":
				element = createElement('datetimepicker', {type: "noTime", ngModel: "entity.param_value", ngChange: "vm.saveChange(entity)", inputFormat: "DD/MM/YYYY"});
				break;
			case "SELECT":
				element = createElement('select', {ngModel: 'entity.param_value', ngChange: "vm.saveChange(entity,$event)", style: "width: 100%;"});
				let values = item.select_values.split(',');
				values.forEach(value => {
					createElement("option", {value: value}, value, element);
				});
				break;
		}
		if (!element) console.error("No definition found for ", controlType, "!");
		return element.outerHTML;
	}

	prepareDataToSave(entity, $event) {
		this.isNewRecord = !entity[this.primaryKey];
		return super.prepareDataToSave(entity);
	}

	saveCallback() {

	}

	saveChange(entity) {
		if (['BOOLEAN', 'DATE'].indexOf(entity.field_type) == -1) return this.save(entity);
		// for mt-checkbox need one digest cycle to update value for ng-model
		else this.getInjection("$timeout")(() => {
			return this.save(entity);
		});
	}

	saveChange1(entity) {
		let $http = this.getInjection("$http"), loaderObj;
		entity.$saving = true;
		this.getInjection("$timeout")(() => {
			this.isNewRecord = entity[this.primaryKey];
			let method = this.isNewRecord ? 'put' : 'post';
			let params = {data: Object.assign({}, entity)};
			if (!this.isNewRecord) params.id = params.data[this.primaryKey];
			$http[method]("param_value", params).then((response) => {
				entity.$saving = false;
				if (!response || !response.data.status) this.showErrorMessages(response);
			});

			// if (id) {
			// 	let data = Object.assign({}, entity);
			// 	data.param_value = entity.param_value.toString();
			// 	$http.put("param_value/" + entity.param_value_id, data).then((response) => {
			// 		entity.$saving = false;
			// 		if (!response || !response.data.status) this.showErrorMessages(response);
			//
			// 	});
			// } else $http.post("param_value", entity).then((response) => {
			// 	entity.$saving = false;
			// 	if (!response || !response.data.status) console.error("error saving data", response);
			// 	if (loaderObj) loaderObj.remove();
			// });
		});
	}
}

ParamValueListCtrl.$inject = ["$scope", "$element", "$attrs", "$injector"];
kv.controller('ParamValueListCtrl', ParamValueListCtrl);
