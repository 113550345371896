kv.controller('PaymentActualVsBudgetReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var data = JSON.parse(vm.bladeParams.data);
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }
    
    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
            "columnDefs": [
                { "orderable": false, targets: 0 }
            ]
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
            addClass('pull-right').addClass('no-print');

        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            $(e.target.hash).find('.chart').each(function() {
                if($(this).highcharts()) {
                    $(this).highcharts().reflow();
                }
            });
        });
    });

    vm.isLoading = true;

    if (data[0]) {
        var chart1Data = data[0][2];
    }
    var chart2Data = data[1];

    function chart() {
        var config = {
            chart: {
                events: {
                    load: function () {
                        this.reflow();
                    }
                },
                type: 'solidgauge'
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [{
                            text: 'Export to PDF',
                            onclick: function () {
                                this.exportChart({
                                    type: 'application/pdf'
                                });
                            },
                            separator: false
                        }, {
                            text: 'Export to JPG',
                            onclick: function () {
                                this.exportChart({
                                    type: 'image/jpeg'
                                });
                            },
                            separator: false
                        }, {
                            text: 'Export to PNG',
                            onclick: function () {
                                this.exportChart({
                                    type: 'image/png'
                                });
                            },
                            separator: false
                        }]
                    }
                }
            },
            title: null,
            pane: {
                center: ['50%', '85%'],
                size: '140%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            tooltip: {
                enabled: false
            },
            // the value axis
            yAxis: {
                stops: [
                    [0.25, '#fb8a9e'], // red
                    [0.5, '#ffc57c'], // yellow
                    [0.75, '#bedd8d'] // green
                ],
                lineWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -70
                },
                labels: {
                    y: 16
                }
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            }
        }

        var percent_actual_vs_budget_ytd = 0;
        var percent_actual_vs_budget_gen = 0;

        if (chart1Data != null && chart1Data.length) {
            percent_actual_vs_budget_ytd = parseFloat(chart1Data[0]['percent_actual_vs_budget_ytd']);
            percent_actual_vs_budget_gen = parseFloat(chart1Data[0]['percent_actual_vs_budget_gen']);
        }
        $('#gauge-1-1').highcharts(Highcharts.merge(config, {
            yAxis: {
                min: 0,
                max: 100,
                title: {
                    text: $filter('trans')('ACTUAL_VS_BUDGET_YTD')
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'SERIES NAME',
                data: [percent_actual_vs_budget_ytd],
                dataLabels: {
                    format: '<div style="text-align:center"><span style="font-size:25px;color:' +
                        ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.2f} %</span><br/>' +
                           '<span style="font-size:14px;color:silver">YTD</span></div>'
                },
                tooltip: {
                    valueSuffix: ' %'
                }
            }]

        }));
        $('#gauge-1-2').highcharts(Highcharts.merge(config, {
            yAxis: {
                min: 0,
                max: 100,
                title: {
                    text: $filter('trans')('ACTUAL_VS_BUDGET_TOTAL')
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'SERIES NAME',
                data: [percent_actual_vs_budget_gen],
                dataLabels: {
                    format: '<div style="text-align:center"><span style="font-size:25px;color:' +
                        ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.2f} %</span><br/>' +
                           '<span style="font-size:14px;color:silver">Total</span></div>'
                },
                tooltip: {
                    valueSuffix: ' %'
                }
            }]

        }));

        vm.isLoading = false;

        setTimeout(function() {
            $('#gauge-1-1').show();
            $('#gauge-1-1').highcharts().reflow();
            $('#gauge-1-2').show();
            $('#gauge-1-2').highcharts().reflow();

        }, 1);
    }
           
    function chart2() {
        $('#secondary-chart').hide();

        var categories = 0;
        var series1 = 0;
        var series0 = 0;

        if(chart2Data != null && chart2Data.categories.length) {
            categories = chart2Data.categories;
            series1 = chart2Data['series'][1]['data'];
            series0 = chart2Data['series'][0]['data'];
        }
        
        var config = {
            chart: {
                events: {
                    load: function () {
                        this.reflow();
                    }
                },
                type: 'column'
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [{
                            text: 'Export to PDF',
                            onclick: function () {
                                this.exportChart({
                                    type: 'application/pdf'
                                });
                            },
                            separator: false
                        }, {
                            text: 'Export to JPG',
                            onclick: function () {
                                this.exportChart({
                                    type: 'image/jpeg'
                                });
                            },
                            separator: false
                        }, {
                            text: 'Export to PNG',
                            onclick: function () {
                                this.exportChart({
                                    type: 'image/png'
                                });
                            },
                            separator: false
                        }]
                    }
                }
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: categories
            },
            yAxis: [{
                min: 0,
                title: {
                    text: ''
                }
            }, {
                title: {
                    text: ''
                },
                opposite: true
            }],
            legend: {
                shadow: false
            },
            tooltip: {
                shared: true
            },
            plotOptions: {
                column: {
                    grouping: false,
                    shadow: false,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Buget',
                color: 'rgba(165,170,217,0.7)',
                data: series1
                //pointPadding: 0.3,
                //pointPlacement: 0.2
            },{ 
                name: 'Actual',
                color: '#b684cb',
                data: series0,
                pointPadding: 0.2
            }]
        }

        $('#secondary-chart').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#secondary-chart').show();
            $('#secondary-chart').highcharts().reflow();
        }, 1);
    }

    if (chart1Data) {
        chart();
    }

    if (chart2Data) {
        chart2();
    }

	return vm;
});
