function ExpenseApprovalListMoveCtrl($uibModalInstance, data, $scope, $injector, cmdate) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'expense-approval', 'expense_id', $scope, data);
	vm.count = data.countLines;

	vm.data = {
		is_internal: false,
	};


	$scope.$watch("vm.data.is_internal", function () {
		if (vm.data.is_internal) {
			vm.data.project_id = null;
			vm.data.reference_id = null;
		} else {
			vm.data.internal_project_id = null;
		}
	}, true);

	vm.saveMove = function () {
		let errors = {};
		// comentat MihaitaBarbu (10.10.2019) - referinta sa nu mai fie obligatorie
		// if (!vm.data.is_internal && vm.data.project_id && !vm.data.reference_id) errors.reference_id = "required";
		if (!vm.clientSideValidate(errors, 'data', $('.modal-content'))) return;

		vm.confirm('confirm', vm.trans('LANG.CONFIRM_SAVE')).then((response) => {
			if (response !== 'ok') return;
			let postData = angular.copy(data.searchList);
			postData.changeTo = angular.copy(vm.data);
			KApp.block('.modal-content');
			vm.rest.update({
				url: vm.entity + '/moveExpenses',
				data: postData,
				success: function () {
					vm.close('ok');
				}
			});
		});
	};
	return vm;
}
