function ProjectListMoveCtrl($uibModalInstance, data, resource, cmdate, $injector, $scope) {
    var vm = new modalCtrl($uibModalInstance, $injector, 'project', 'project_id', $scope, data);
    vm.count = data.countLines;
    vm.data = {};

    /**
     * vm.data = new project id, new reference id, new user_id
     */
    vm.saveMove = function () {
        let errors = {};
        if (vm.data.project_id && !vm.data.reference_id) errors.reference_id = "required";
        if (!vm.clientSideValidate(errors, 'data', $('.modal-content'))) return;
        vm.confirm(vm.trans("LANG.CONFIRM_MOVE"), vm.trans("LANG.CONFIRM_THE_CHANGES")).then(response => {
            if (response !== "ok") return;
            let postData = angular.copy(data.searchList);
            postData.changeTo = angular.copy(vm.data);
            postData.date_from = cmdate.toServer(postData.date_from);
            postData.date_to = cmdate.toServer(postData.date_to);
            KApp.block('.modal-content');
            vm.rest.update({
                url: vm.entity + '/changeEntitiesProject',
                data: postData,
                success: function () {
                    vm.close('ok');
                }
            });
        });
    };

    return vm;
}
