kv.controller('securityGroupEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'security_group_id';
    vm.entity = 'security_group';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');

    // let cmdate = vm.getInjection('cmdate');

    var REST = resource.init(vm.entity);
    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];    
    

    if(page_identifier == 'edit') {
        $http.get("/security_group/" + page_entity_id).then(function(response) {
            vm.data = response.data;
        });

        $http.get("/security_group/" + page_entity_id + "/members").then(function(response) {
            vm.members = response.data;
        });

        vm.getRoutesGroupedByCategory = function() {
            var REST = resource.init(vm.entity);
            REST.getArray({
                url: "security_group/getGroupRouteAccess/:security_roup_id/?grouped=true",
                params: {
                    security_roup_id: page_entity_id
                }
            }).then(function (res) {
                vm.route = res;
            });
        }
        
        vm.getRoutesGroupedByCategory();

        vm.getRoutesByCategory = function ($index) {
           var REST = resource.init(vm.entity);
           REST.getArray({
               url: "security_group/getGroupRouteAccess/:security_roup_id/?category=" + vm.route[$index].category,
               params: {
                   security_roup_id: page_entity_id
               }
           }).then(function (res) {
               vm.route[$index].actions = res;
           });
        }

        vm.updateRouteAccess = function (route, grouped) {
            var REST = resource.init(vm.entity);
            route.grouped = grouped;
            REST.update({
                url: "security_group/updateRouteAccess/:security_roup_id",
                params: {
                    security_roup_id: page_entity_id
                },
                data: route
            }).then(function (res) {
                console.log(res);
            });
        }

        let segments = [];

        vm.checkIfCategoryIsSegmented = function ($index) {
            if (!segments[vm.route[$index].category]) {
                vm.route[$index].notSegmented = true;
                segments[vm.route[$index].category] = $index;
            } else {
                vm.route[$index].notSegmented = false;
                // getRoutesByCategory(segments[vm.route[$index].category]);
            }
        }

        vm.getMembers = function () {
            var REST = resource.init(vm.entity);
            REST.getArray({
                url: "security_group/:security_group_id/members",
                params: {
                    security_group_id: page_entity_id
                }
            }).then(function (res) {
                vm.members = res;
            });
        }

        vm.deleteUser = function (user_security_group_id) {
            var modalInstance = $uibModal.open({
                component: 'modalConfirm',
                resolve: {
                    content: () => {
                        return $filter("trans")("ARE_YOU_SURE_DELETE");
                    },
                    title: () => {
                        return $filter("trans")("DELETE");
                    }
                }
            });

            modalInstance.result.then(function () {
                REST.delete({
                    url: "user_security_group/:id/",
                    params: {
                        id: user_security_group_id,
                    },
                    success: function () {
                    }
                }).then(function () {
                    vm.getMembers();
                });
            });
        }

        vm.addUser = function (type, data, $id) {
            if (!data) {
                data = {};
            }

            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'add_user',
                controller: function ($scope, $uibModalInstance, resolve) {
                    var vm = this;

                    vm.save = save;
                    vm.cancel = cancel;
                    vm.addEntity = addEntity;
                    vm.deleteEntity = deleteEntity;
                    vm.data = {
                        user_security_group: [{}]
                    };

                    vm.$onInit = function () {
                    };

                    function save() {
                        $uibModalInstance.close(vm.data);
                    }

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    };

                    function addEntity(entity) {
                        if (!vm.data) {
                            vm.data = {};
                        }
                        if (!vm.data[entity]) {
                            vm.data[entity] = [];

                        }
                        vm.data[entity].push({});
                    }

                    function deleteEntity(entity, $index) {
                        vm.data[entity].splice($index, 1);
                    }
                },
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    resolve: function () {
                        return {
                            data: angular.copy(data),
                            type: type
                        };
                    }
                }
            })

            modalInstance.result.then(function (data) {
                if (typeof data == "undefined") {
                    data = {};
                }
                var REST = resource.init("user_security_group");
                angular.forEach(data.user_security_group, function (v, i) {
                    data.user_security_group[i].tenant_id = API.tenant_id;
                    data.user_security_group[i].security_group_id = page_entity_id;
                    REST.create({data: data.user_security_group[i]}).then(function () {
                        vm.getMembers();
                    });
                })
            });
        }
    } else {
        vm.data = {};
        vm.isNewRecord = true;
    }

    vm.filter = function (category, categoryTranslated) {
        if (!vm.filterRoute) {
            return true;
        } else {
            if (category.toLowerCase().indexOf(vm.filterRoute.toLowerCase()) >= 0 || categoryTranslated.toLowerCase().indexOf(vm.filterRoute.toLowerCase()) >= 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    $scope.resetFilters = function () {
        $scope.filters = {};
        for (var x = 0; x < $("lookup").length; x++) {
            var placeholder = $($("lookup")[x]).attr('placeholder');
            $($('.select2-selection__rendered')[x]).html('<span class="select2-selection__placeholder">' + placeholder + '</span>');
        }
        vm.search = {};
    };

    vm.saveCallback = function () {
        if(page_identifier == "edit"){
            window.location = "/security_group/" + page_entity_id + '/edit';
        } else {
            window.location = "/security_group";
        }
    }
    

	return vm;
});
