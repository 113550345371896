kv.controller('efacturaEditCtrl', function ($uibModalInstance, params, $http, saveCallback, $scope, $injector, resource) {
        let vm = new modalCtrl($uibModalInstance, $injector, 'efactura', 'id', $scope, params);
        if (params.id) {
                vm.data = params.entity;
        } else {
                vm.data = {};
                vm.isNewRecord = true;
        }

        vm.saveCallback = saveCallback;
        console.log(vm.data);
        return vm;
});
