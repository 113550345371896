/**
 * Usage
 * {{ object.date | humanDate }}
 * @return {human readable text} e.g.: in 3 minutes
 */
(function ()
{
	'use strict';

	angular
		.module('kv')
		.filter('humanDate', humanDate);

	function humanDate($filter, cmdate) {
        return function(input) {
            var now = moment();
            var date = cmdate.fromServer(input);
            var serverDate = moment(date);
            var returned_endate = moment(serverDate);

            if(date) {
                var diff = moment.duration(returned_endate.diff(now));
                return diff.humanize(true);
            }
            return "";
        };
    }

})();
