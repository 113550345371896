kv.controller('fiscalYearEditCtrl', function ($uibModalInstance, data, $injector, $scope) {
	return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
		entity: "fiscal_year",
		primaryKey: "id",
		newEntity:{
		},
		prepareDataToSave: function () {
			let cmdate = this.getInjection('cmdate');
			let dataToSend = angular.copy(this.data);
			dataToSend.start_date = cmdate.toServer(this.data.start_date);
			dataToSend.end_date = cmdate.toServer(this.data.end_date);
			dataToSend.allocation_cutoff_date = cmdate.toServer(this.data.allocation_cutoff_date);
			return dataToSend;
		}
	});
});