function AddTimesheetOnInvoiceCtrl($uibModalInstance, data, resource, cmdate, $injector, $scope) {
    var vm = new modalCtrl($uibModalInstance, $injector, 'timesheet_approval', 'timesheet_id', $scope, data);
    vm.count = data.countLines;
    vm.project_id = data.project_id;
    vm.data = {};

    vm.saveMove = function () {
        let errors = {};
        if (!vm.project_id && !vm.data.invoice_id) errors.reference_id = "required";
        if (!vm.clientSideValidate(errors, 'data', $('.modal-content'))) return;
        vm.confirm('CONFIRM', vm.trans('LANG.TOTAL_AFFECTED_ENTITIES') + ' ' + vm.count).then(response => {
            if (response !== "ok") return;
            let postData = angular.copy(data.searchList);
            postData.invoice_id = vm.data.invoice_id;
            postData.project_id = vm.project_id;
            postData.date_from = cmdate.toServer(postData.date_from);
            postData.date_to = cmdate.toServer(postData.date_to);
            KApp.block('.modal-content');
            vm.rest.update({
                url: vm.entity + '/addTimesheetsToInvoice',
                data: postData,
                success: function () {
                    vm.close('ok');
                 //   window.location.reload();
                }
            });
        });
    };

    return vm;
}
