kv.controller('StatisticsByProjectReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    var params = kvUtils.getAllQueryParams();
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }
    vm.openDetails = function(reference){
        vm.collapse_code = null;
        let className = $('#reference_icon_' + reference).attr('class');
        if(className == 'fa fa-caret-right'){
            vm.collapse_code = reference + "-open";
            $('#reference_icon_' + reference).removeClass('fa fa-caret-right').addClass('fa fa-caret-down');

        } else {
            vm.collapse_code = reference + "-close";
            $('#reference_icon_' + reference).removeClass('fa fa-caret-down').addClass('fa fa-caret-right');
        }

    };

	return vm;
});
