kv.controller('accountListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "account_id",
		entity: "account",
		modal: {
			templateUrl: "account-edit",
			controller: "accountEditCtrl"
		}
	});
});
