kv.controller('InvoicedVsPaidReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var data = JSON.parse(vm.bladeParams.data);
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }
    
    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
            "columnDefs": [ {
                "targets": 1,
                "orderable": false
            } ]
        });
        $('.dataTables_filter input[type="search"]').
        attr('placeholder','Search in table...').
        removeClass('input-small').
        css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
        addClass('pull-right').addClass('no-print');
    });

    vm.isLoading = true;

    var chart_categories = data[3];
    var series = data[2];
    function chart() {
        $('#chart').hide();
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'area',
                events: {
                    load: function () {
                        this.reflow();
                    }
                },
                height: 550
            },
            title: {
                text: $filter('trans')('INVOICED_VS_PAID_REPORT.NAME')
            },
            subtitle: {
                text: null
            },
            xAxis: {
                categories: chart_categories
            },
            yAxis: {
                title: {
                    text: $filter('trans')('LANG.PERCENT') +  ' (%)'
                },
                // min: 0
                type: 'logarithmic',
            },
            tooltip: {
                enabled: true,
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        radius: 4
                    },
                    lineWidth: 5,
                    states: {
                        hover: {
                            lineWidth: 5
                        }
                    },
                    threshold: null,
                    dataLabels: {
                        enabled: true,
                        format: '{y} %'
                    },
                    enableMouseTracking: true
                },
            },
            credits: {
                enabled: false
            },
            series: [{
                name: $filter('trans')('LANG.INVOICED_VS_PAID_REPORT.NAME'),
                data: series
            }]



            // tooltip: {
            //     headerFormat: '',
            //     pointFormat: '{point.name}: <b>{point.y} % ({point.percentage:.1f} % din selectie) </b>'
            // },
            // plotOptions: {
            //     series: {
            //         label: {
            //             connectorAllowed: false
            //         }
            //     },
            //     showInLegend: false
            // },
        }

        // hide dataLabels when resolution is xxs (phone)
        if( $(window).width() < 480 )
        {
            config.plotOptions.pie.dataLabels.enabled = false
        }
        $('#chart').highcharts(config);
        vm.isLoading = false;


        setTimeout(function() {
            $('#chart').show();
            $('#chart').highcharts().reflow();
        }, 1);
    }

    if (series) {
        chart();
    }

	return vm;
});
