(function () {
    'use strict';

    angular
        .module('kv')
        .directive('actionReport', actionReport);

    function actionReport() {
        return {
            templateUrl: "/assets/custom/js/directives/action-report/templates/action-report.html",
            restrict: "E",
            scope: {
                filter: "=",
                cookie: "="
            },
            transclude: true,
            link: function ($scope, $cookies) {
                $scope.resetFilters = function () {
                    $('#daterange').val('');
                    $('.dateTimePicker ').val('')

                    $scope.filter = {};
                    $cookies.remove($scope.cookie);

                    for (var x = 0; x < $("select[ui-select2]").length + 10; x++) {
                        $($('.select2-selection__rendered')[x]).html();
                    }
                    for (var x = 0; x < $("lookup").length; x++) {
                        var placeholder = $($("select[ui-select2]" )[x]).attr('data-placeholder');
                        $($('.select2-selection__rendered')[x]).html('<span class="select2-selection__placeholder"></span>');
                    }
                };

            }
        };
    }
})();