kv.controller('sunExportJournalListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'sun_export_journal_id';
    vm.entity = 'sun_export_journal';

    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getInfinitScroll({
        params: {
            action: 'sun_export_journal'
        },
        url: "sun_export_journal",
    });

    vm.deleteEntity = function deleteEntity(entity) {
        var REST = resource.init('sun_export_journal');
        var modalInstance = $uibModal.open({
            component: 'modalConfirm',
            resolve: {
                content: function() {
                    return $filter("trans")("ARE_YOU_SURE_DELETE");
                },
                title: function() {
                    return $filter("trans")("DELETE");
                }
            }
        });

        modalInstance.result.then(function () {
            REST.update({
                url: 'sun_export_journal/' + entity.sun_export_journal_id,
                data: {
                    status: "CANCELLED",
                },
                success: function () {
                    getData();
                }
            });
        });
    };

    function getData() {
        var REST = resource.init('sun_export_journal');
        REST.get().then(function (response) {
            vm.result = response.data;
        });
    }

    vm.exportXMLSun = function () {
        if (vm.infinitScroll.search.type == "all" || vm.infinitScroll.search.type == "") {
            toastr.error(vm.trans('LANG.SUN_EXPORT_TYPE_REQUIRED'));
        }else if (vm.infinitScroll.search.type == "invoice"){
            window.open( "report/sunInvoicesReport/export?format=xml&" + $filter("objToParams")(vm.infinitScroll.search));
        }else if (vm.infinitScroll.search.type == "expense"){
            window.open( "report/sunExpensesReport/export?format=xml&" + $filter("objToParams")(vm.infinitScroll.search));
        }
    };

    return vm;
});

