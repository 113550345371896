kv.controller('budgetItemValueModalEditCtrl', function ($uibModalInstance, data, $injector, $scope) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'budget_item_value', 'budget_item_value_id', $scope, data);
	if (data.index == -1) vm.isNewRecord = true;
	vm.modal.rendered.then(() => {
		if (!vm.dataHasLoaded) KApp.block('.modal-content');
		let rest = vm.getInjection('resource').init('budget_item');
		rest.get({id: data.budget_item_id}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) vm.data = response;
			else console.error(response);

			if (response.data.length) {
				vm.hasbudgetItem = true;
			} else {
				vm.hasbudgetItem = false;
			}

			vm.data = {};
			vm.data.year = (new Date()).getFullYear();
			vm.data = angular.copy(data.result);

			// if vm.data is empty, make year, current year and master_practice_id null
			if (!vm.data) {
				vm.data = {};
				vm.data.year = (new Date()).getFullYear();
				vm.data.master_practice_id = null;
			}
		});
	});
	vm.hasbudgetItem = true;

	// overwrite function from baseCtrl, for adding supplimentary item, target
	// target is used in model, for verifying where the request is done(modal or edit), to make some fields required
	vm.prepareDataToSave = function () {
		let dataToSend = angular.copy(this.data);
		dataToSend.target = 'modal';
		return dataToSend;
	};

	return vm;
});
