var calendarCtrl = function ($scope, $element, $attrs, $injector, $filter, $rootScope, $calendarAddEvent) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);

	vm.events = [];
	vm.search = $rootScope._search;
	vm.search.refresh=0;
	vm.cmdate = vm.getInjection('cmdate');
	vm.$uibModal = vm.getInjection('$uibModal');
	vm.bladeParams.accessToStore = vm.bladeParams.accessToStore === 'true';
	vm.bladeParams.accessToUpdate = vm.bladeParams.accessToUpdate === 'true';
	vm.bladeParams.accessToDestroy = vm.bladeParams.accessToDestroy === 'true';
	let trans = $filter('trans');
	vm.selectionPlaceholder = "Select Activity Types";
	vm.calendarFilters = [
		{name: trans('COURT_HEARING'), id: 'court_hearing'},
		{name: trans('ACTIVITIES'), id: 'activity'},
		{name: trans('HOLIDAYS'), id: 'holiday'},
		{name: trans('USER_VACATION'), id: 'vacation'},
		{name: trans('LANG.BIRTHDAYS'), id: 'birthday'}
	];
	vm.selectPickerInit(vm.calendarFilters, '.k_selectpicker');

	/**
	 * caledar sync screen
	 */
	vm.sync = function () {
		vm.openModal({
			templateUrl: 'calendar-sync',
			controller: 'calendarSyncCtrl',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						search: vm.search
					};
				}
			}
		});
	};


	/**
	 * search
	 */
	$scope.$watch('vm.search', function (newValue, oldValue) {
		// console.log("Calendar type", vm.bladeParams.calendarType);
		if (vm.bladeParams.calendarType === 'legal') {
			vm.search.event_type = ['court_hearing_legal'];
		} else if (typeof vm.search.event_type == 'undefined' || vm.search.event_type.length === 0) {
			vm.search.event_type = ['court_hearing', 'birthday', 'vacation', 'company_event', 'activity', 'holiday'];
		}
		if (typeof vm.search.start == 'undefined') {
			vm.search.start = vm.searchInit.start;
		}
		if (typeof vm.search.end == 'undefined') {
			vm.search.end = vm.searchInit.end;
		}

		var result = [];

		var REST = vm.getInjection('resource').init();

		if (vm.search.start && vm.search.end) {
			REST.get({
				url: vm.bladeParams.loadUrl,
				params: {
					user_id: vm.search.user_id,
					filters: [vm.search.event_type],
					date_from: vm.search.start,
					date_to: vm.search.end
				}
			}).then(function (res) {
				angular.forEach(res, function (events, i) {
					if (vm.search.event_type.indexOf(i) !== -1) {
						angular.forEach(events, function (event, i) {
							result.push(event);
						});
					}
				});
				vm.events.splice(0, vm.events.length);
				vm.events.push(angular.copy(result));
			});
		}
	}, true);

	/**
	 * Open modal with event details
	 *
	 * @param calendarEvent
	 * @param e
	 */
	vm.viewEventDetail = function (calendarEvent, e) {
		e.stopPropagation();
		if (calendarEvent.entity_type === 'task') {
			vm.openModal({
				templateUrl: 'task-edit',
				size: 'lg',
				controller: 'taskEditCtrl',
				resolve: {
					params: {
						task_id: calendarEvent.entity_id
					}
				}
			}).then(() => {
				vm.search.refresh++;
			});
		} else if (calendarEvent.entity_type === 'activity') {
			window.location.href = '/activity/' + calendarEvent.activity_id;
		} else if (calendarEvent.entity_type === 'company_event') {
			vm.openModal({
				templateUrl: 'calendar-view-company_event',
				controller: 'calendarViewCompanyEventCtrl',
				size: 'md',
				resolve: {
					calendarEvent: function () {
						return calendarEvent;
					},
				}
			});

		} else if (calendarEvent.entity_type === 'court_hearing') {
			vm.openModal({
				templateUrl: 'calendar-view-court_hearing',
				controller: 'calendarViewCourtHearingCtrl',
				size: 'md',
				resolve: {
					calendarEvent: function () {
						return calendarEvent;
					},
					refreshCalendar: function() {
						return function() {
							vm.search.refresh++;
						}
					}
				}
			}).then(() => {
				vm.search.refresh++;
			});
		}
	};


	vm.addEvent = function (event) {
		event.preventDefault();
		let date = moment().format(API.DATE_FORMAT);
		vm.select(date, date);
	};


	//se activeaza popup-ul de creare event
	vm.select = function (start, end, e, view, room) {
		let saveCallback = function (response) {
			vm.search.refresh++;
			// let event = {
			// 	id: response.activity_id,
			// 	resourceId: response.meeting_room_id,
			// 	title: response.activity_name,
			// 	start: moment(response.start_date).format("YYYY-MM-DD\THH:mm:ss"),
			// 	end: moment(response.end_date).format("YYYY-MM-DD\THH:mm:ss"),
			// 	entity_id: response.activity_id,
			// 	className: "activity",
			// 	entity_type: "activity",
			// 	allDay: response.all_day,
			// 	editable: true
			// };
			// vm.events[0].push(event);
		};
		$calendarAddEvent(saveCallback, start, end, 'add', e, room, false, null);
	};

	vm.changeEventFromCalendar = function (event) {
		if (event.editable) {
			let entityEditableTypes = ['activity', 'court_hearing'];
			let postData = {
				entity_type: event.entity_type,
				entity_id: event.entity_id,
				start_date: (event.start) ? event.start.format("YYYY-MM-DD HH:mm:ss") : null,
				end_date: (event.end /*&& event.entity_type == 'activity'*/) ? event.end.format("YYYY-MM-DD HH:mm:ss") : null,
				updateEventDate: true
			};
			if (!entityEditableTypes.includes(event.entity_type)) {
				console.error('Not editable event type', event.entity_type, event);
			}

			var REST = vm.getInjection('resource').init('calendar');
			if (event.id) {
				REST.update({
					id: event.entity_id,
					data: postData
				}).then(function (data) {
				});
			}
		} else {
			return false;
		}
	};

	// calendar configurations
	vm.calendarUiConfig = {
		minTime: vm.API.START_DAY_AT,
		maxTime: vm.API.END_DAY_AT,
		firstDay: 1,
		height: 'auto',
		customButtons: {
			sync: {
				text: trans("LANG.SYNC"),
				click: function () {
					vm.sync();
				}
			}
		},
		header: {
			left: 'prev,next today',
			center: 'title',
			right: 'month,agendaWeek,agendaDay,listWeek'
		},
		views: {
			listWeek: {buttonText: 'List'},
			listDay: {buttonText: 'Day'}
		},
		dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		viewRender: function (view) {
			vm.searchInit = {
				start: vm.cmdate.toServer(view.start),
				end: vm.cmdate.toServer(view.end),
				event_type: vm.search.event_type ? vm.search.event_type : (vm.bladeParams.calendarType == 'legal' ?
					['court_hearing_legal'] :
					['court_hearing', 'birthday', 'vacation', 'company_event', 'activity', 'holiday'])
			};

			Object.assign(vm.search, vm.searchInit);

			angular.element('#calendar').fullCalendar('removeEventSource', vm.events);

			vm.calendar = view.calendar;
		},
		eventClick: vm.viewEventDetail,
		select: vm.select,
		selectable: vm.bladeParams.accessToStore,
		editable: vm.bladeParams.accessToUpdate,
		eventDrop: vm.changeEventFromCalendar,
		eventResize: vm.changeEventFromCalendar,
		defaultView: 'month',
		schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
		eventOverlap: true,
		eventLimit: 10,
		eventAfterRender: function (event, element) {
			if (!event.description) return;

			element.popover({
				title: event.title,
				content: event.description,
				trigger: 'hover',
				html: true,
				placement: 'top',
				container: 'body'
			});

			if (element.hasClass('fc-day-grid-event')) {
				element.data('content', event.description);
				element.data('placement', 'bottom');
				KApp.initPopover(element);
			} else if (element.hasClass('fc-time-grid-event')) {
				element.find('.fc-title').append('<div class="fc-description">' + event.description + '</div>');
			} else if (element.find('.fc-list-item-title').length !== 0) {
				element
					.find('.fc-list-item-title')
					.append('<div class="fc-description">' +
						'<div class="col-12 pl-0"><div class="row"><div class="col-8">' +
						event.description +
						'</div><div class="col-3"><span class="pull-right text-right">' +
						trans(event.entity_type.toUpperCase()) +
						'</span></div></div></div>'
					);
			}
		},
		nowIndicator: true,
		timeFormat:
			'H(:mm) a'
	};
	return vm;

};
kv.controller('calendarCtrl', calendarCtrl);
