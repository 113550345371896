kv.controller('subscriptionViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'tenant_id';
	vm.entity = 'subscription';
	vm.rest = vm.getInjection('resource').init(vm.entity);
	vm.element = $element.find(".pageIsLoading");
	$scope.$parent.mainCtrl.showGoToSubscription = false;

	$(document).ready(function() {
		$('#table1').DataTable({
			fixedHeader: {
				header:         true,
				footer:         true
			},
			"bPaginate":        false,
			"paging":           true,
			"ordering":         true,
			"info":             false,
			"bLengthChange":    false
		});

		$('#table2').DataTable({
			"paging":   true,
			"ordering": true,
			"info":     false,
			"bLengthChange": false
		});
	});

	if(vm.bladeParams.subscriptionId) {
		KApp.block(vm.element);
		vm.isNewRecord = false;

		vm.data = vm.bladeParams.subscription;

		if(vm.bladeParams.downgrade_description) {
			vm.data.parsed_downgrade_description = JSON.parse(vm.bladeParams.downgrade_description);
		} else {
			vm.data.parsed_downgrade_description = null;
		}

		vm.data.parsed_addons ='';
		if(vm.data.parsed_downgrade_description){
			angular.forEach(vm.data.parsed_downgrade_description.changes.modules, function (value, key) {
				vm.data.parsed_addons = vm.data.parsed_addons + vm.trans(value);
				if(key < vm.data.parsed_downgrade_description.changes.modules.length - 1){
					vm.data.parsed_addons = vm.data.parsed_addons + ', ';
				}
			});
		}
		$scope.$broadcast('dataLoaded');
		KApp.unblock(vm.element);
	} else {
		vm.isNewRecord = true;
		vm.data = {};
		// let defaultObjects = JSON.parse(vm.bladeParams.defaultObjects);
		// vm.data = defaultObjects;
	}

	return vm;
});
