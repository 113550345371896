kv.controller('FinishedOffersReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var data = JSON.parse(vm.bladeParams.data);
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    function setOrderBy() {
        var orderBy = $('[aria-sort="ascending"], [aria-sort="descending"]').attr("data-order-by");
        var direction = $('[aria-sort="ascending"], [aria-sort="descending"]').attr('aria-sort');
        $("#print").attr("href", $("#print").attr("href") + "&orderBy=" + orderBy + "&direction=" + direction);
    }
    
    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false
        });
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');
    });

    vm.isLoading = true;

    var chart1Data = data[1];
    var chart2Data = data[2];

    function chart() {
        $('#chart').hide();
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    load: function () {
                        this.reflow();
                    }
                },
                height: 480,
            },
            title: {
                text: $filter('trans')('OFFERS_BY_STATUS')
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}: <b>{point.y} ({point.percentage:.1f}%) </b>'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    },
                    showInLegend: false
                }
            },
            series: [{
                data: chart1Data
            }],
            afterPrint: function(a,b,c) {
                console.log(a,b,c)
            }
        }

        // hide dataLabels when resolution is xxs (phone)
        if( $(window).width() < 480 )
        {
          config.plotOptions.pie.dataLabels.enabled = false
        }
        $('#chart').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#chart').show();
            $('#chart').highcharts().reflow();
        }, 1);
    }

    function chartCoordinators() {
        $('#chart-coordinators').hide();
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                events: {
                    load: function () {
                        this.reflow();
                    }
                },
                height: 480,
            },
            title: {
                text: $filter('trans')('OFFERS_BY_COORDINATOR')
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name}: <b>{point.y} ({point.percentage:.1f}%) </b>'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    },
                    showInLegend: false
                }
            },
            series: [{
                data: chart2Data
            }],
            afterPrint: function(a,b,c) {
                console.log(a,b,c)
            }
        }

        // hide dataLabels when resolution is xxs (phone)
        if( $(window).width() < 480 )
        {
          config.plotOptions.pie.dataLabels.enabled = false
        }
        $('#chart-coordinators').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#chart-coordinators').show();
            $('#chart-coordinators').highcharts().reflow();
        }, 1);
    }

    if (chart1Data) {
        chart();
    }

    if (chart2Data) {
        chartCoordinators();
    }

	return vm;
});
