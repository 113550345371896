kv.controller('LegalCaseSearchInPortalEditCtrl', function ($uibModalInstance, params, $scope, resource, $http, $injector) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'legal_case', 'legal_case_id', $scope, params);
    vm.data = angular.copy(params.entity);

    //redirect to legal case create with the given parameters
    vm.saveLegalCase = function () {
        window.open(vm.data.url);
    };

    return vm;
});

