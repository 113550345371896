// Activitati : planificare sali meeting
kv.controller('calendarSchedulerCtrl', function calendarSchedulerCtrl($scope, $element, $attrs, $injector, resource, search, $uibModal, cmdate, $filter, $rootScope, $calendarSchedulerEditEvent) {
	let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);


	let primaryKey = 'activity_id';
	let entity = 'activity';
	let REST = resource.init(entity);

	vm.search = $rootScope._search;
	vm.search.refresh=0;
	vm.search.meeting_room_id = null;
	vm.events = [];
	vm.bladeParams.accessToStore = vm.bladeParams.accessToStore == "true";
	vm.bladeParams.accessToUpdate = vm.bladeParams.accessToUpdate == "true";
	vm.bladeParams.accessToDestroy = vm.bladeParams.accessToDestroy == "true";
	vm.cmdate = vm.getInjection('cmdate');

	vm.loadMeetingRooms = function () {
		let primaryKey = 'meeting_room_id';
		let entity = 'meeting_room';

		let REST = resource.init(entity);
		vm.loading = true;
		REST.list().then(function (data) {
			let result = [];
			if (data && data.data) {
				for (let index in data.data) {
					if(data.data[index].enabled === true){
						vm.calendarUiConfig.resources.push(
							{
								id: data.data[index].meeting_room_id,
								title: "#" + data.data[index].number + " - " + data.data[index].name + "\n(" + data.data[index].capacity + " pers)",
								eventBorderColor: data.data[index].color,
								eventTextColor: data.data[index].color
							}
						);
					}
				}
				vm.allMeetingRoom = angular.copy(vm.calendarUiConfig.resources);
				if (vm.allMeetingRoom.length===0) {
					vm.alert("ALERT", "No meeting room exists!");
				}
			}
			vm.loading = false;
		});
	};

	vm.loadMeetingRooms();

	$scope.$watch('vm.search', function (newValue, oldValue) {

		if (typeof vm.search.start == 'undefined') {
			vm.search.start = vm.searchInit.start;
		}
		if (typeof vm.search.end == 'undefined') {
			vm.search.end = vm.searchInit.end;
		}

		if (vm.search.meeting_room_id) {
			if (vm.allMeetingRoom.length) {
				vm.allMeetingRoom.forEach(function(event, index){
					if (event.id == vm.search.meeting_room_id) {
						vm.calendarUiConfig.resources = [angular.copy(vm.allMeetingRoom[index])];
					}
				});
			}
		} else {
			if (newValue.meeting_room_id != oldValue.meeting_room_id) {
				vm.calendarUiConfig.resources = angular.copy(vm.allMeetingRoom);
			}
		}

		if (vm.search.start && vm.search.end) {

			vm.loading = true;
			REST.get({
				url: vm.bladeParams.loadUrl,
				params: {
					from_scheduler: true,
					meeting_room_id: vm.search.meeting_room_id,
					date_from: vm.search.start,
					date_to: vm.search.end
				}
			}).then(function (data) {
				// vm.events = [];
				let result = [];
				if (data && data.data) {
					if (data.data.length) {
						for (let index in data.data) {
							if (!data.data.hasOwnProperty(index)) {
								continue;
							}
							let title = "";
							if(data.data[index].meeting_room_id) {
								if (data.data[index].user) {
									title = data.data[index].user.full_name + "\n" + data.data[index].activity_name;
								} else {
									title = data.data[index].activity_name;
								}
								result.push(
									{
										id: data.data[index].activity_id,
										resourceId: data.data[index].meeting_room_id,
										title: title,
										start: moment(data.data[index].start_date).format("YYYY-MM-DD\THH:mm:ss"),
										end: moment(data.data[index].end_date).format("YYYY-MM-DD\THH:mm:ss")
									}
								);
							}
						}
					} else {
						result.push(
							{
								id: 0,
								resourceId: 1,
								title: "",
								start: "1970-01-01T10:00:00",
								end: "1970-01-01T10:00:00",
							}
						);
					}
				}

				vm.events.splice(0, vm.events.length);
				vm.events.push(angular.copy(result));

				vm.loading = false;
				vm.search.refresh = 0;
			});
		}
	}, true);

	vm.addEvent = function addEvent(e) {
		let start = new Date(),
			end = new Date();

		$calendarSchedulerEditEvent('add', false, start, end, e, null, null);

	};
	vm.next = function next() {
		vm.calendarView.calendar.next();
	};
	vm.prev = function prev() {
		vm.calendarView.calendar.prev();
	};


	vm.viewEvent = function (calendarEvent, e) {
		$uibModal.open({
			templateUrl: 'calendar-scheduler-view-event',
			controller: 'calendarSchedulerViewEventCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				calendarEvent: function () {
					return calendarEvent;
				},
				deleteEvent: function () {
					return vm.deleteEvent;
				},
				saveCallback: function () {
					return vm.saveCallback;
				},
				$calendarSchedulerEditEvent: function () {
					return $calendarSchedulerEditEvent;
				},
				event: function () {
					return e;
				}
			}
		});

	};
	vm.saveCallback = function (response, type) {
		if (type == 'add') {
			vm.events[0].push({
				id: response.activity_id,
				resourceId: response.meeting_room_id,
				title: response.user.full_name + " " + response.activity_name,
				start: moment(response.start_date).format("YYYY-MM-DD\THH:mm"),
				end: moment(response.end_date).format("YYYY-MM-DD\THH:mm")
			});
		} else {
			let events = $('#calendar').fullCalendar('clientEvents');
			for (let i = 0; i < events.length; i++) {
				// Update
				if (events[i].id == response.activity_id) {
					events[i].resourceId = response.meeting_room_id;
					events[i].title = (response.user ? response.user.full_name + "\n" : "") + response.activity_name;
					events[i].start = moment(response.start_date).format("YYYY-MM-DD\THH:mm:ss");
					events[i].end = moment(response.end_date).format("YYYY-MM-DD\THH:mm:ss");
					$('#calendar').fullCalendar('updateEvent', events[i]);
					break;
				}
			}

		}
	};
	vm.select = function (start, end, e, view, room) {
		$calendarSchedulerEditEvent('add', false, start, end, e, room, null, vm.saveCallback);
	};


	vm.changeEvent = function (event) {
		let data = {
			meeting_room_id: event.resourceId
		};
		data.start_date = (event.start) ? event.start.format("YYYY-MM-DD HH:mm:ss") : null;
		data.end_date = (event.end) ? event.end.format("YYYY-MM-DD HH:mm:ss") : null;
		// comentat MBarbu 11.10.2019 - sa nu ii mai schimbe si tipul
		// data.activity_type_id = 1; // meeting type
		data.fromScheduler = true;

		let REST = resource.init('scheduler');
		if (event.id) {
			REST.update({
				id: event.id,
				data: data
			}).then(function (response) {
				if (!response.status) console.error(response.error);
			});
		}
	};

	vm.deleteEvent = function (activity) {
		if (!vm.bladeParams.accessToDestroy) return;
		for (let i = 0; i < vm.events[0].length; i++) {
			// Delete
			if (vm.events[0][i].id == activity.activity_id) {
				vm.calendar.removeEvents(activity.activity_id);
				break;
			}
		}
	};
	vm.calendarUiConfig = {
		resourceAreaWidth: 200,
		slotDuration: '00:15',
		height: 'auto',
		resourceLabelText: $filter('trans')('MEETING_ROOMS'),
		handleWindowResize: false,
		minTime: vm.API.START_DAY_AT,
		maxTime: vm.API.END_DAY_AT,
		eventLimit: true,
		selectHelper: true,
		select: vm.select,
		header: {
			left: "prev,title,next",
			center: "",
			right: "agendaDay,month,agendaWeek"
		},
		views: {
			timelineThreeDays: {
				type: 'timeline',
				duration: {days: 3}
			}
		},
		dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		viewRender: function (view) {
			vm.searchInit = {
				start: vm.cmdate.toServer(view.start),
				end: vm.cmdate.toServer(view.end),
				meeting_room_id: vm.search.meeting_room_id ? vm.search.meeting_room_id : null
			};

			Object.assign(vm.search, vm.searchInit);

			vm.calendarView = view;
			vm.calendar = vm.calendarView.calendar;
			vm.currentMonthShort = vm.calendar.getDate().format('MMM');
		},
		columnFormat: {
			month: 'ddd',
			week: 'ddd D',
			day: 'ddd M'
		},
		eventClick: vm.viewEvent,
		selectable: vm.bladeParams.accessToStore,
		editable: vm.bladeParams.accessToUpdate,
		eventDrop: vm.changeEvent,
		eventResize: vm.changeEvent,
		defaultView: 'agendaDay',
		schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
		eventOverlap: false,
		resources: [],
		nowIndicator: true,
		axisFormat: 'HH:mm',
		// timeFormat          : 'H:mm',
		allDaySlot: false,
		locale: 'ro',

		aspectRatio: 1,
		timeFormat: 'HH:mm'
	};

	return vm;
});
