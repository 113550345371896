kv.component('auditTransaction', {
	templateUrl: 'audit-transaction',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	controller: function ($scope) {
		var vm = this;

		vm.$onInit = function () {
			vm.title = vm.resolve.title;
			vm.result = vm.resolve.content;
			if (vm.resolve.width) {
				$('.modal-dialog').width(vm.resolve.width);
			}
		};

		vm.ok = function () {
			vm.close();
		};

		vm.cancel = function () {
			vm.dismiss({
				$value: 'cancel'
			});
		};
	},
	controllerAs: 'vm',
});
