kv.controller('customerFiscalEntityListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'customer_fiscal_entity_id';
	vm.entity = 'customer_fiscal_entity';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'customer_fiscal_entity'
		},
		url: "customer_fiscal_entity",
	});

	vm.disableCustomerFiscalEntities = function () {
		vm.confirm( vm.trans("LANG.LINES_NO")  + ' ' + vm.infinitScroll.total, vm.trans("LANG.CONFIRM_THE_CHANGES")).then(response => {
			if (response !== "ok") return;
			let postData = angular.copy(vm.infinitScroll.search);
			KApp.block('.modal-content');
			vm.rest.getArray({
				url: vm.entity + '/bulk/disableCustomerFiscalEntities?' + $filter("objToParams")(vm.infinitScroll.search),
				data: postData
			}).then(function (data) {
				KApp.unblock('.modal-content');
				if(data.error && data.error.error_code === 1){
					toastr.error(data.error.message);
				}else{
					vm.infinitScroll.search.refresh++;
				}
			});
		});
	};

	return vm;
});

