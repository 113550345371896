kv.controller('InvoiceViewEmailCtrl', function (API, $scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
		var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);

		var REST = resource.init(vm.entity);
		vm.result = {};
		vm.filesUpload = {
			selected: [],
			config: {}
		};
		vm.ckeditor = {
			height: 300,
			toolbarGroups: [
				{name: 'clipboard', groups: ['clipboard', 'undo']},
				{
					name: 'editing',
					// groups: ['find', 'selection', 'spellchecker']
				},
				// {name: 'links'},
				// { name: 'insert' },
				// { name: 'forms' },
				// { name: 'tools' },
				// { name: 'document', groups: ['mode', 'document', 'doctools'] },
				// { name: 'others' },
				// { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
				// {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi']},
				{name: 'font'},
				{name: 'styles'},
				{name: 'colors'}
			]
		};

		if (vm.bladeParams.invoiceId) {
			KApp.block($element);
			REST.get({
				url: 'invoice/' + vm.bladeParams.invoiceId + '/email',
			}).then(function (response) {
				KApp.unblock($element);
				if (response.status)
					vm.data = response.data;
				else vm.data = {};

				if (!vm.data.invoiceEmailTemplate) {
					vm.data.invoiceEmailTemplate = {};
				}
				if(vm.data.invoiceEmailTemplate.from_name !== null){
					vm.data.invoiceEmailTemplate.from_name = vm.data.invoiceEmailTemplate.from_name;
				}else{
					vm.data.invoiceEmailTemplate.from_name = vm.bladeParams.sendInvoiceEmailsFrom;
				}
				if(vm.data.invoiceEmailTemplate.from !== null){
					vm.data.invoiceEmailTemplate.from = vm.data.invoiceEmailTemplate.from;
				}else{
					vm.data.invoiceEmailTemplate.from = vm.bladeParams.sendInvoiceEmailsFrom;
				}

				if(vm.data.email_to_free_text == "null"){
					vm.data.email_to_free_text = '';
				}

			});
		}

		vm.send = function () {
			let url = '/invoice/' + vm.bladeParams.invoiceId + '/sendEmail';
			if (vm.sending) return;
			vm.errors = null;
			let postData = angular.copy(vm.data);

			// validate FROM field - mandatory if empty
			if (!postData.invoiceEmailTemplate.from) {
				$('[ng-model="vm.data.invoiceEmailTemplate.from"] input, [ng-model="vm.data.invoiceEmailTemplate.from"]').css("border", "1px solid red");
				if (!vm.errors) vm.errors = {};
				if (!vm.errors.from) vm.errors.from = [];
				vm.errors.from.push(vm.trans('LANG.FROM') + ' ' + vm.trans('IS_REQUIRED'));
			} else {
				$('[ng-model="vm.data.invoiceEmailTemplate.from"] input, [ng-model="vm.data.invoiceEmailTemplate.from"]').css("border", "1px solid #c2cad8");
			}

			// validate contact field
			if (!postData.contact) {
				postData.contact = [];
				// make mandatory field only if free text input for emails is empty
				if (!postData.email_to_free_text) {
					$('[ng-model="vm.data.contact"] .select2-selection--multiple').css("border", "1px solid red");
					if (!vm.errors) vm.errors = {};
					if (!vm.errors.required_fields) {
						vm.errors.required_fields = [];
						vm.errors.required_fields.push(vm.trans('LANG.INVOICE_EMAIL_REQUIRED_FIELDS'));
					}
				}
			} else {
				$('[ng-model="vm.data.contact"] .select2-selection--multiple').css("border", "1px solid #c2cad8");
			}

			if (postData.email_to_free_text) {
				let freeEmails = postData .email_to_free_text.split(" ").join(',').split(";").join(',').split(',');
				freeEmails.forEach(email => {
					if (email == "") return;
					if (kvUtils.validateEmail(email))
						postData.contact.push({id: -1, name: email+ " <" + email + ">"});
					else {
						if (!vm.errors) vm.errors = {};
						if (!vm.errors.email_to_free_text) vm.errors.email_to_free_text = [];
						vm.errors.email_to_free_text.push(email + " " + vm.trans('LANG.EMAIL_IS_NOT_VALID'));
					}
				});

				$('[ng-model="vm.data.email_to_free_text"] input, [ng-model="vm.data.email_to_free_text"]').css("border", "1px solid #c2cad8");
			// validate field for free text emails - make mandatory only if contact input is not set
			} else if (!postData.contact.length) {
				$('[ng-model="vm.data.email_to_free_text"] input, [ng-model="vm.data.email_to_free_text"]').css("border", "1px solid red");
				if (!vm.errors) vm.errors = {};
				if (!vm.errors.required_fields) {
					vm.errors.required_fields = [];
					vm.errors.required_fields.push(vm.trans('LANG.INVOICE_EMAIL_REQUIRED_FIELDS'));
				}
			}
			if (vm.errors) return;
			vm.sending = true;
			KApp.block('#loader');
			vm.filesUpload.config.$ctrl.uploadAll(url, postData)
				.then(response => {
					KApp.unblock('#loader');
					vm.sending = false;
					if (response && response.data.status) {
						toastr.success(vm.trans('LANG.EMAIL_SUCCESS'));
						setTimeout(function () {
							window.location = '/invoice/' + vm.bladeParams.invoiceId;
						}, 3000);
					}
					else this.showErrorMessages(response.data);
				}).catch(response => {
				vm.sending = false;
				KApp.unblock('#send-btn');
				console.error(response);
				// toastr.success("{{ trans('LANG.EMAIL_SUCCESS') }}", "{{ trans('LANG.EMAIL_ERROR') }}")
			});
		};

		return vm;
	}
)
;

// @include("legal-case.view.popups.court_phase")
// @include("legal-case.view.popups.court_hearing")
