kv.controller('noSubscriptionViewCtrl',function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $timeout) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);

    vm.addFreemiumAccount = function (postData) {
        vm.openModal({
            templateUrl: "freemium-subscription",
            controller: 'freemiumSubscriptionCtrl',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                params: {
                    tenant: vm.tenant
                },
                saveCallback: function () {
                    return function(response, modal){
                        if(response == 'cancel') {
                            KApp.unblock('.changeUserActiveStatus');
                        } else {
                           // vm.changeStatus(postData);
                        }
                        modal.close();
                    };
                }
            }
        });
    }


   /* let rest = resource.init('user');
    rest.get({
        url: "user/" + vm.user.user_id +"/getResponsibleEntities",
    }).then(function (response) {
        KApp.unblock('.modal-body');
        vm.data = response;
    });

    vm.save = function () {
        vm.data.user_id = vm.user.user_id;
        let postData = angular.copy(vm.data);
        rest.post({
            url: "user/replaceUser",
            data: postData
        }).then(function (response) {
            if (response.status || response.status == undefined) {
                if (vm.saveCallback) vm.saveCallback(response, vm.modal);
            }
        });
    }*/

    vm.cancel = function () {
        vm.saveCallback('cancel', vm.modal);
    }

    return vm;
});
