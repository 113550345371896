kv.controller('internalProjectEditCtrl', function ($uibModalInstance, params, $http, saveCallback, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'internal_project', 'internal_project_id', $scope, params);

	if (params.id) {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		$http.get("/internal_project/" + params.id).then(function (response) {
			vm.data = response.data;
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (vm.data.internal_project_practice) {
				angular.forEach(vm.data.internal_project_practice, function (v, i) {
					vm.data.internal_project_practice[i].id = v.practice_id;
					vm.data.internal_project_practice[i].name = v.practice.practice_name;
				});
			}
			if (vm.data.internal_project_industry) {
				angular.forEach(vm.data.internal_project_industry, function (v, i) {
					vm.data.internal_project_industry[i].id = v.industry_id;
					vm.data.internal_project_industry[i].name = v.industry.industry_name;
				});
			}
			if (vm.data.internal_project_seniority) {
				angular.forEach(vm.data.internal_project_seniority, function (v, i) {
					vm.data.internal_project_seniority[i].id = v.seniority_id;
					vm.data.internal_project_seniority[i].name = v.seniority.seniority_name;
				});
			}
			if (vm.data.internal_project_field) {
				angular.forEach(vm.data.internal_project_field, function (v, i) {
					vm.data.internal_project_field[i].id = v.field_id;
					vm.data.internal_project_field[i].name = v.field.field_name;
				});
			}
		});
	} else {
		vm.data = {};
		vm.isNewRecord = true;
	}

	vm.saveCallback = saveCallback;
	return vm;
});
