kv.controller('inventoryItemListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'inventory_item_id';
    vm.entity = 'inventory_item';

    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getInfinitScroll({
        params: {
            action: 'inventory_item'
        },
        url: "inventory_item",
    });

    vm.formatData = function (entity) {
        delete entity.inventory_item_id;
        delete entity.created_by;
        delete entity.created_at;
        entity.name = '(Copy of) ' + entity.name;


        if (entity.inventory_item_history) {
            for ($i = 0; $i < entity.inventory_item_history.length; $i++) {
                delete entity.inventory_item_history[$i];
            }
            delete entity.inventory_item_history;
        }

        return entity;
    };

    vm.duplicate = function (entity) {
        var formattedData = vm.formatData(entity);

        vm.rest.create({
            url: "inventory_item/",
            data: formattedData
        }).then(function (data) {
            window.location = "/inventory_item/" + data.data.inventory_item_id + "/edit";
        });
    };

    return vm;
});

