kv.controller('newsListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'news_id';
	vm.entity = 'news';

	vm.count = {
		total: 0,
		showing: 0
	};


	vm.getInfinitScroll({
		params: {
			action: 'news'
		},
		url: "news",
	});

	vm.delete = function deleteEntity($index) {
		vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then(response => {
			if (response !== 'ok') return;
			$http.delete("news/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
				vm.infinitScroll.items.splice($index, 1);
			});
		});
	};

	vm.addEditEntity = function (entity, $index) {
		vm.openModal({
			templateUrl: "news-edit",
			controller: 'newsEditCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: {
					index: $index,
					entity: entity
				}
			}
		}).then(response => {
			window.location.reload();
			if (response.index == -1) vm.infinitScroll.items.unshift(response.entity);
			else angular.extend(entity, response.entity);
		});
	};
	return vm;
});


