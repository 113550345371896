kv.controller('documentTemplatesListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'document_templates_id';
	vm.entity = 'document_templates';

	vm.rest = resource.init('document_templates');
	vm.loadData = function() {
		vm.dataHasLoaded = true;
		vm.rest.get({
			url: "document_templates"
		}).then(function(res) {
			vm.dataHasLoaded = true;
			vm.data = res;
		});
	};

	vm.loadData();
	return vm;
});

