kv.controller('DashboardWidgetWipCtrl', function ($element, $attrs, $scope, $injector, $timeout) {
	var vm = new DashboardWidgetCtrl($element, $attrs, $scope, $injector, function (response) {
		$timeout(() => {
			if (typeof (response[0]) !== 'undefined' && response[0][0].data.length > 0) {
				renderChart(response);
			} else {
				this.error[1] = this.trans("NO_DATA");
				this.loading[1] = false;
			}
		}, null);
	});

	function renderChart(data) {
		let config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						$timeout(() => {
							this.reflow();
						});
					}
				}
			},
			credits: {
				enabled: false
			},
			navigation: {
				buttonOptions: {
					theme: {
						zIndex: 9999
					}
				}
			},
			legend: {
				enabled: true
			},
			title: {
				text: ''
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					innerSize: '60%',
					dataLabels: {
						enabled: false,
						style: {
							color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
						}
					},
					showInLegend: true
				}
			},
			series: [{
				innerSize: '60%',
				data: data[0][0]['data']
			}]
		};
		if (vm.bladeParams.percentageOnly == '0') {
			config.tooltip = {
				headerFormat: '',
				pointFormat: '{point.name}: <b>{point.y} ' + data[0][0].suffix + ' ({point.percentage:.1f} %) </b>'
			};
		} else if (vm.bladeParams.percentageOnly == '1') {
			config.tooltip = {
				headerFormat: '',
				pointFormat: '{point.name}: <b>' + ' {point.percentage:.1f} % </b>'
			};
		}
		// config the chart for different sizes and add clases with paddings to fit it better
		vm.values = data[1];

		// style the percentages if pozitive = green else red
		if (vm.values['effort_value_percent'] > 0) {
			$("div.managing-wip-percent-effort").addClass('font-green-haze').removeClass('font-red');
		} else if (vm.values['effort_value_percent'] < 0) {
			$("div.managing-wip-percent-effort").addClass('font-red').removeClass('font-green-haze');
		}

		if (vm.values['hold_value_percent'] > 0) {
			$("div.managing-wip-percent-hold").addClass('font-green-haze').removeClass('font-red');
		} else if (vm.values['hold_value_percent'] < 0) {
			$("div.managing-wip-percent-hold").addClass('font-red').removeClass('font-green-haze');
		}

		if (vm.values['expense_value_percent'] > 0) {
			$("div.managing-wip-percent-expense").addClass('font-green-haze').removeClass('font-red');
		} else if (vm.values['expense_value_percent'] < 0) {
			$("div.managing-wip-percent-expense").addClass('font-red').removeClass('font-green-haze');
		}
		$element.find('#dashboard-wip-1-chart').highcharts(config);

		vm.loading[1] = false;
	}

	return vm;
});
