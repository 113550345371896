(function (angular) {

    angular.module("kv").directive('castToNumber', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$formatters.push(function (value) {
                    // console.log("value to conevrt", value);
                    if (!value || isNaN(value)) return 0;
                    else if (angular.isNumber(value)) return value;
                    else if (attrs.castToNumber === 'float') return parseFloat(value) ;
                    else return parseInt(value) ;
                });
            }
        };
    });
})(angular);
