kv.controller('documentTemplatesModalCtrl', function ($uibModalInstance, resource, params, $scope, $injector) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'document_templates', 'document_templates_id', $scope, params);

    let rest = resource.init("document_templates");
    vm.dataHasLoaded = false;
    vm.modal.rendered.then(() => {
        if (!vm.dataHasLoaded) KApp.block('.modal-content');
    });

    // console.log('PARAMS: ', params);
    vm.getList = function () {
        rest.get({
            url: 'document_templates/' + params.entity + '/list'
        }).then(function (res) {
            if(res.status == true) {
                vm.data = res.data;
                vm.document_entity = params.entity;
                vm.document_entity_id = params.entity_id;
            }
            vm.dataHasLoaded = true;
            KApp.unblock('.modal-content');
        });
    }

    vm.getList();

    return vm;
});
