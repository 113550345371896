kv.controller('expenseTypeListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "expense_type_id",
		entity: "expense_type",
		modal: {
			templateUrl: "expense_type-edit",
			controller: "expenseTypeEditCtrl"
		}
	});
});
