kv.controller('AuditTrailReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.entity = 'audit_trail';
    var REST = resource.init(vm.entity);
    vm.expandDetails = expandDetails;
    
    var params = kvUtils.getAllQueryParams();
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    function setOrderBy() {
        var orderBy = $('[aria-sort="ascending"], [aria-sort="descending"]').attr("data-order-by");
        var direction = $('[aria-sort="ascending"], [aria-sort="descending"]').attr('aria-sort');

        $("#print").attr("href", $("#print").attr("href") + "&orderBy=" + orderBy + "&direction=" + direction);
    }
        
    $(document).ready(function() {
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right');
    });

    // functie necesara pentru butonul de expand modal extra detalii(se transmite id-ul tranzactiei si se aduc datele din tabela audit_trail corespunzatoare tranzactiei cu id-ul respectiv)
    function expandDetails(transaction_id){
        var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'audit-trail-details',
            controller: function ($uibModalInstance, data, resource) {
                var vm = this;
                vm.cancel = cancel;
                vm.primaryKey = 'transaction_id';
                var audit_trail_url = $element.find('#page_data').attr('audit_trail_url');

                var REST = resource.init('');
                REST.get({
                    url: audit_trail_url,
                    params: {
                        transaction_id: data.transaction_id
                    }
                }).then(function(res) {
                    res.data.forEach(function(line) {
                        if (line.audit_transaction.action == 'create') {
                            line.new_value = line.new_value ? JSON.parse(line.new_value, true) : null;
                        }
                    })
                    vm.data = res.data;
                })


                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                };
            },
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                data: function () {
                    return {
                       transaction_id: transaction_id,
                    };
                }
            }
        });
    }

    return vm;
});
