class DashboardListCtrl extends baseCtrl {
	constructor($element, $attrs, $scope, $injector, $filter) {
		super($scope, $element, $attrs, $injector);
		this.requests = {};
		this.activateCurrentTabFromRequestUrl($element);
		this.currentTab = $element.find("ul.dashboard > li > a.active").attr("id").replace("-tab", "");
		this.resource = this.getInjection('resource');

	}

	activateCurrentTabFromRequestUrl($element) {
		let url = document.location.toString();
		if (url.match('#')) {
			let tabId = url.split('#')[1];
			$element.find('ul.dashboard > li > a[href="#' + tabId + '"]').tab('show');
		}
	}

	makeRequests(tabId) {
		let requests = this.requests[tabId];
		if (!requests || requests.done) return;
		// console.debug("make requests for tab", tabId);
		requests.items.forEach(request => {
			request.fn.apply(request, request.params);
		});
		requests.done = true;
	}

	addRequest(tabId, fn, params) {
		if (!tabId) return;
		let request = {
			fn: fn,
			params: params
		};
		if (!this.requests[tabId]) this.requests[tabId] = {done: false, items: []};
		this.requests[tabId].items.push(request);
		if (tabId == this.currentTab) request.fn.apply(request, request.params);
		// console.log( tabId, this.currentTab);
	}

	tabClick($event) {
		if (!$event.target.hasAttribute('href')) return;
		let tabId = $event.target.getAttribute('href').substring(1);
		// console.log('tab click', tabId, $event.target);
		window.location.hash = tabId;

		this.makeRequests(tabId);
	}


}

DashboardListCtrl.$inject = ["$element", "$attrs", "$scope", "$injector", "$filter"];
angular.module("kv").controller("DashboardListCtrl", DashboardListCtrl);
