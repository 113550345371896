kv.controller('travelOrderListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'travel_order_id';
    vm.entity = 'travel_order';

    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getInfinitScroll({
        params: {
            action: 'travel_order'
        },
        url: "travel_order",
    });



    return vm;
});

