angular
    .module('kv')
    .filter('br2nl', br2nl);

function br2nl() {
    return function(text) {
        if(text != undefined) {
            return text.replace(/<br\s*[\/]?>/gi, "\n");
        } else {
            return "";
        }
    }
};