kv.controller('bdSourceTypeEditCtrl', function ($uibModalInstance, data, $injector, $scope) {
    return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
        entity: "bd_source_type",
        primaryKey: "bd_source_type_id",
        newEntity:{
        },
        prepareDataToSave: function () {
            let dataToSend = angular.copy(this.data);
            return dataToSend;
        }
    });
});