kv.controller('invoiceViewCollectionCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'id';
    vm.entity = 'collection_logs';
    var REST = resource.init(vm.entity);

    vm.count = {
        total: 0,
        showing: 0
    };

    vm.getCollection = function () {
        if(vm.bladeParams.entityId) {
            REST.get({
                url: 'invoice/' + vm.bladeParams.entityId + '/collection',
            }).then(function (data) {
                vm.data = {};
                vm.collection = data.collection;
                vm.due_days = data.due_days;
                vm.last_activity = data.last_activity;
                vm.logs = data.logs;
                vm.project_responsibles = data.project_responsibles;
                vm.data.active_payment_promise = data.active_payment_promise;
                vm.data.client_responsible = data.client_responsible;
                vm.email_status = data.email_status;
                vm.needs_performance_addon = data.needs_performance_addon;

                if(data.last_activity && data.last_activity.source_reminder_id == null){
                    let parts = vm.last_activity.related_entity_type.split('\\');
                    vm.last_activity_name = parts[parts.length - 1];
                }else{
                    vm.last_activity_name = null;
                }
            });
        }
    };

    vm.getCollection();

    vm.paymentPromiseKept = function (payment_promise) {
        REST.get({
            url: 'invoice/' + vm.bladeParams.entityId + '/collection/actions/payment_promise_kept',
            params: {
                payment_promise_id: payment_promise.id
            }
        })
            .then((response) => {
                if(response.status === 'success'){
                    toastr.success(response.message);
                    payment_promise.promise_was_kept = true;
                    vm.getCollection();
                }else{
                    toastr.error(vm.trans('Error marking payment promise as kept'));
                }
            })
            .catch(err => {
                console.log('Error marking payment promise as kept');
                console.log(err); // bum related error
            });
    };


    vm.paymentPromiseNotKept = function (payment_promise) {
        REST.get({
            url: 'invoice/' + vm.bladeParams.entityId + '/collection/actions/payment_promise_not_kept',
            params: {
                payment_promise_id: payment_promise.id
            }
        })
            .then((response) => {
                if(response.status === 'success'){
                    toastr.success(response.message);
                    payment_promise.promise_was_kept = false;
                    vm.getCollection();
                }else{
                    toastr.error(vm.trans('Error marking payment promise as kept'));
                }

            })
            .catch(err => {
                console.log('Error marking payment promise as not kept');
                console.log(err); // bum related error
            });
    };

    /**
     * add a payment promise. Only if performance addon is active
     */
    vm.addPaymentPromise = function () {
        if (vm.needs_performance_addon) {
            vm.alert(
                'Performance Addon Required',
                'Please add the Performance Addon in order to use this feature!',
                'You can add the Performance Addon in the <a href="/subscription">Subscription screen</a>.',
                null,
                600)
                .then(function () {
                    return;
                });
        } else {
            vm.openModal({
                templateUrl: "add-payment-promise",
                controller: 'addPaymentPromiseCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    params: {
                        invoice_id: vm.bladeParams.entityId
                    }
                }
            }).then(response => {
                vm.getCollection();
            });
        }
    };

    /**
     * Add notes. Only if performance addon is active
     */
    vm.addNotes = function () {
        if (vm.needs_performance_addon) {
            vm.alert(
                'Performance Addon Required',
                'Please add the Performance Addon in order to use this feature!',
                'You can add the Performance Addon in the <a href="/subscription">Subscription screen</a>.',
                null,
                600)
                .then(function () {
                    return;
                });
        } else {
            vm.openModal({
                templateUrl: "add-notes",
                controller: 'addNotesCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    params: {
                        invoice_id: vm.bladeParams.entityId
                    }
                }
            }).then(response => {
                vm.getCollection();
            });
        }
    };

    /**
     * Add task. Only if performance addon is active
     */
    vm.addTask = function () {
        if (vm.needs_performance_addon) {
            vm.alert(
                'Performance Addon Required',
                'Please add the Performance Addon in order to use this feature!',
                'You can add the Performance Addon in the <a href="/subscription">Subscription screen</a>.',
                null,
                600)
                .then(function () {
                    return;
                });
        } else {
            vm.openModal({
                templateUrl: "add-task",
                controller: 'addTaskCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    params: {
                        invoice_id: vm.bladeParams.entityId
                    }
                }
            }).then(response => {
                vm.getCollection();
            });
        }
    };

    vm.mockEmail = function (template) {
        if (!vm.API.tenant.addons.performance && (template === 'REMINDER_CLIENT_STATEMENT' || template === 'REMINDER_INVOICE_NOT_DUE' || template === 'REMINDER_INVOICE_OVERDUE' || template === 'REMINDER_UNPAID_INVOICE')) {
            vm.alert(
                'Performance Addon Required',
                'Please add the Performance Addon in order to use this feature!',
                'You can add the Performance Addon in the <a href="/subscription">Subscription screen</a>.',
                null,
                600)
                .then(function () {
                    return;
                });
        } else {
            vm.openModal({
                templateUrl: 'send-email',
                controller: 'sendEmailCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    params: {
                        invoice_id: vm.bladeParams.entityId,
                        customer_id: vm.bladeParams.customerId,
                        has_annex: vm.bladeParams.hasAnnex,
                        template: template
                    }
                }
            }).then(response => {
                vm.last_activity_name = null;
                vm.getCollection();
            });
        }
    };


    vm.toggleCollectionStatus = function(is_active) {
        if (vm.needs_performance_addon) {
            vm.alert(
                'Performance Addon Required',
                'Please add the Performance Addon in order to use this feature!',
                'You can add the Performance Addon in the <a href="/subscription">Subscription screen</a>.',
                null,
                600)
                .then(function () {
                    return;
                });
        } else {
            let toggleAction = is_active ? 'pause' : 'resume';
            REST.get({
                url: 'invoice/' + vm.bladeParams.entityId + '/collection/actions/' + toggleAction,
                params: {}
            })
                .then((response) => {
                    if (response.status === 'success') {
                        toastr.success(response.message);
                        vm.collection.is_active = response.data.is_active;

                    } else {
                        toastr.error(vm.trans('Error changing state'));
                    }

                })
                .catch(err => {
                    console.log('Error changing state');
                    console.log(err); // bum related error
                });
        }
    };

    vm.updateCollectionContacts = function () {
        vm.confirm(vm.trans('LANG.WARNING') + ' ' + vm.trans('LANG.UPDATE_COLLECTION_CONTACTS'), vm.trans('LANG.UPDATE_COLLECTION_CONTACTS_INVOICE')).then(response => {
            if (response !== "ok") return;
            KApp.block('.modal-content');
            let rest = vm.getInjection('resource').init('invoice');
            rest.get({
                url: 'invoice/' + vm.bladeParams.entityId + '/collection/actions/update_billing_contact_on_collection',
                params: {
                    invoice_id: vm.bladeParams.entityId,
                }
            }).then(function (data) {
                KApp.unblock('.modal-content');
                if(data.error && data.error.error_code === 1){
                    toastr.error(data.error.message);
                }else{
                    toastr.success(data.message);
                    vm.getCollection();
                }
            });
        });
    }

    return vm;
});

