kv.controller('legalCaseTeamListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "legal_case_team_id",
		entity: "legal_case_team",
		modal: {
			templateUrl: "legal_case_team-edit",
			controller: "legalCaseTeamEditCtrl"
		}
	});
});
