kv.controller('DashboardLitigationsListInsolvencyActsDetailsCtrl', function ($uibModalInstance, params, $scope, $injector, $http, resolve) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'bpi_detail', 'bpi_detail_id', $scope, params);

	vm.modal.rendered.then(() => {
		if (!vm.dataHasLoaded) KApp.block('.modal-content');
	});

	vm.$onInit = function () {
		if (resolve && resolve.fiscal_id) {
			vm.fiscal_id = resolve.fiscal_id;
		}
	};

	$.ajax({
		url: 'https://api.keyvision.eu/api/bpi/company/' + vm.fiscal_id + '?api_token=crUbjWNsrmQUpqMglN4djlCBEq2Rz6ItAukNxGrychDqiKqrON2SBrqBOhWu',
		dataType: 'json',
		type: 'GET',
		success: function (response) {
			if (response) {
				if (response.data.length > 0) {
					vm.nume = response.data[0].company["name"];
					vm.judet = response.data[0].company["county"];
					vm.cui = response.data[0].company["cui"];
					vm.acts = response.data;
				} else {
					console.log('error loading');
				}
			}
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
		}
	});
	return vm;
});
