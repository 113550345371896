	kv.controller('TenantViewTabBankAccountsCtrl', function ($scope, $http, $uibModal, resource, search) {
		var vm = this;

		vm.primaryKey = 'bank_account_id';
		vm.entity = 'bank_account';
		vm.result = null;
		vm.search = {};
		vm.action = {
			listEntity: listEntity,
			addEditEntity: addEditEntity,
			deleteEntity: deleteEntity
		};
		var REST = resource.init(vm.entity);
		vm.action.listEntity();

		$scope.$watch('vm.search', function (newValues, oldValues) {
			search.watch({
				search: vm.search,
				newValues: newValues,
				oldValues: oldValues,
				callback: function (filters) {
					REST.list({
						params: filters
					}).then(function (data) {
						vm.result = data.data;
					})
				},
				fixPager: function () {
					vm.search.page = 1;
				}
			});
		}, true);

		function listEntity() {
			REST.list().then(function (data) {
				vm.result = data.data;
			});
		}

		function addEditEntity(bankAccountID) {
			var modalInstance = $uibModal.open({
				animation: true,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'bank-account-edit',
				controller: function ($uibModalInstance, resource) {
					var vm = this;
					vm.primaryKey = 'bank_account_id';
					vm.entity = 'bank_account';
					vm.errors = {};
					vm.action = {
						save: save,
						cancel: cancel,
					};

					if (bankAccountID) {
						REST.get({
							id: bankAccountID
						}).then(function (data) {
							vm.newResult = data;
						});
					} else {
						vm.newResult = {};
						vm.newResult.is_default = 0;
					}

					function save() {
						if (bankAccountID) {
							var savePromise = REST.update({
								id: bankAccountID,
								data: vm.newResult
							});
						} else {
							var savePromise = REST.create({
								data: vm.newResult
							});
						}

						savePromise.then(function (response) {
							$uibModalInstance.close({response: response});
						});
					}

					function cancel() {
						$uibModalInstance.dismiss('cancel');
					};
				},
				controllerAs: 'vm',
				size: 'lg'
			});

			modalInstance.result.then(function (data) {
				vm.action.listEntity();
			});
		};


		function deleteEntity(bankAccountID) {
			var modalInstance = $uibModal.open({
				component: 'modalConfirm',
				resolve: {
					content: () => {
						return $filter("trans")("ARE_YOU_SURE_DELETE");
					},
					title: () => {
						return $filter("trans")("DELETE");
					}
				}
			});

			modalInstance.result.then(function (selectedItem) {
				REST.delete({
					params: {
						id: bankAccountID
					},
					success: function () {
						vm.action.listEntity();
					}
				});
			});
		}
	});
