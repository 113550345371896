kv.controller('calendarViewCourtHearingCtrl', function calendarViewEventCtrl($uibModalInstance, calendarEvent, refreshCalendar, resource, $http) {
	var vm = this;
	vm.modal = $uibModalInstance;
	// Data
	vm.calendarEvent = calendarEvent;
	vm.calendarEvent.attendees = '';
	vm.closeDialog = closeDialog;
	vm.calendarEvent.viewAttendee = true;

	function closeDialog() {
		vm.modal.dismiss('cancel');
	}

	if (calendarEvent.end == null) {
		vm.start = calendarEvent.start.format("YYYY-MM-DD HH:mm");
		vm.time = calendarEvent.start.format("HH:mm");
		vm.end = vm.start;
	} else {
		vm.start = calendarEvent.start.format("YYYY-MM-DD HH:mm");
		vm.end = calendarEvent.end.format("YYYY-MM-DD HH:mm");
		vm.time = calendarEvent.start.format("HH:mm");
	}

	vm.modal.rendered.then(() => {
		KApp.block('.modal-content');
	});
	//build attendee to show
	vm.buildAttendee = function (attendees){
		vm.calendarEvent.attendees = "";
		if (attendees !== null){
			for (let index = 0; index < attendees.length; index++) {
				if (index !== attendees.length - 1) {
					vm.calendarEvent.attendees += attendees[index].name + ', ';
				} else {
					vm.calendarEvent.attendees += attendees[index].name;
				}
			}
		}
	};

	$http.get("/court_hearing/" + vm.calendarEvent.entity_id).then(function(response) {
		vm.data = response.data;
		if (vm.data.court_hearing_attendee) {
			angular.forEach(vm.data.court_hearing_attendee, function (v, i) {
				vm.data.court_hearing_attendee[i].id = v.user.user_id;
				vm.data.court_hearing_attendee[i].name = v.user.user_name;
			});
		}
		vm.legal_case_id = vm.data.legal_case_id;
		vm.data.date = new dateClass(vm.data.date);
		vm.buildAttendee(vm.data.court_hearing_attendee);
		vm.dataHasLoaded = true;
		KApp.unblock('.modal-content');
	});

	vm.addEditCourtHearingAttendee = function(){
		vm.calendarEvent.viewAttendee = false;
	};

	vm.saveAttendees = function(){
		KApp.block('.modal-content');
		let postData = angular.copy(vm.data);
		postData.date = vm.data.date.PostDatetime;
		vm.rest = resource.init("court_hearing");
		vm.rest.update({
			id: vm.data.court_hearing_id,
			data: postData
		}).then(function(response){
			if(response.status && response.status == true) {
				vm.calendarEvent.viewAttendee = true;
				// build new attendees to show
				vm.buildAttendee(postData.court_hearing_attendee);

				// update date in modal view
				vm.start = moment(vm.data.date.value).format("YYYY-MM-DD HH:mm");
				vm.time = moment(vm.data.date.value).format("HH:mm");
				vm.end = moment(vm.data.date.value).add(2, 'hours').format("YYYY-MM-DD HH:mm");

				// refresh calendar
				refreshCalendar();

				vm.dataHasLoaded = true;
				KApp.unblock('.modal-content');
			}
		});
	};

	vm.cancel = function() {
		vm.calendarEvent.viewAttendee = true;
	};
});
