kv.controller('groupListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'group_id';
	vm.entity = 'group';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'group'
		},
		url: "group",
	});

	vm.delete = function deleteEntity($index) {
		vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then(response => {
			if (response !== 'ok') return;
			$http.delete("group/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
				vm.infinitScroll.items.splice($index, 1);
			});
		});
	};

	vm.addEditEntity = function (entity, $index) {
		vm.openModal({
			templateUrl: "group-edit",
			controller: 'groupEditCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: {
					index: $index,
					entity: entity
				}
			}
		}).then(response => {
			if (response.index == -1) this.infinitScroll.items.push(response.entity);
			else angular.extend(entity, response.entity);
			window.location = "/group/" + response.entity.group_id;
		});
	};


	return vm;
});

