kv.controller('ReportFilterCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $cookies, cmdate) {
	/*var _the_filters = [
		'timesheetStatisticsReport',
		'timesheetStatisticsExtraReport',
		'timesheetByCriteriaReport',
		 'allocationByCriteriaReport',
		'paymentAllocationByCriteriaReport',
		'topInvoicedByAllocationsReport',
		'allocationBilledVsCollectedReport'
	];
*/
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);

	// get filters sent from blade as object and parse to string
	var filtersFromUrl = JSON.stringify(JSON.parse(vm.bladeParams.filters));

	vm.cookieKey = cookieKey;
	if (typeof defaultFilters != "undefined") {
		vm.filter = defaultFilters;
	} else {
		vm.filter = {};
	}
	var params = kvUtils.getAllQueryParams();
	// console.debug("params", params);

	if (!params) {
		$cookies.remove(cookieKey);
	} else {
		$cookies.put(cookieKey, filtersFromUrl);
	}

	var cookie = $cookies.get(cookieKey);
	if (cookie) {
		vm.filter = JSON.parse(cookie);
		// comentat 19.12.2018 - MihaitaB - nu mai este nevoie de cmdate
		// if (vm.filter.date_from) {
		//     vm.filter.date_from = cmdate.toServer(vm.filter.date_from);
		// }
		// if (vm.filter.date_to) {
		//     vm.filter.date_to = cmdate.toServer(vm.filter.date_to);
		// }
		// if (vm.filter.invoice_due_date_from) {
		// 	vm.filter.invoice_due_date_from = cmdate.toServer(vm.filter.invoice_due_date_from);
		// }
		// if (vm.filter.invoice_due_date_to) {
		// 	vm.filter.invoice_due_date_to = cmdate.toServer(vm.filter.invoice_due_date_to);
		// }
		// if (vm.filter.invoice_date_from) {
		// 	vm.filter.invoice_date_from = cmdate.toServer(vm.filter.invoice_date_from);
		// }
		// if (vm.filter.invoice_date_to) {
		// 	vm.filter.invoice_date_to = cmdate.toServer(vm.filter.invoice_date_to);
		// }
		// if (vm.filter.due_date_from) {
		// 	vm.filter.due_date_from = cmdate.toServer(vm.filter.due_date_from);
		// }
		// if (vm.filter.due_date_to) {
		// 	vm.filter.due_date_to = cmdate.toServer(vm.filter.due_date_to);
		// }
		// if (vm.filter.created_at_to) {
		// 	vm.filter.created_at_to = cmdate.toServer(vm.filter.created_at_to);
		// }
		// if (vm.filter.created_at_from) {
		// 	vm.filter.created_at_from = cmdate.toServer(vm.filter.created_at_from);
		// }
		// if (vm.filter.end_date_from) {
		// 	vm.filter.end_date_from = cmdate.toServer(vm.filter.end_date_from);
		// }
		// if (vm.filter.end_date_to) {
		// 	vm.filter.end_date_to = cmdate.toServer(vm.filter.end_date_to);
		// }
		// if (vm.filter.report_date) {
		// 	vm.filter.report_date = cmdate.toServer(vm.filter.report_date);
		// }
		// if (vm.filter.reporting_date) {
		// 	vm.filter.reporting_date = cmdate.toServer(vm.filter.reporting_date);
		// }
		// if (vm.filter.due_amount_date) {
		// 	vm.filter.due_amount_date = cmdate.toServer(vm.filter.due_amount_date);
		// }
		if (typeof vm.filter.overdue_only != undefined) {
			vm.filter.overdue_only = vm.filter.overdue_only == 'true' ? true : false;
		}
		if (typeof vm.filter.include_hold != undefined) {
			vm.filter.include_hold = vm.filter.include_hold == 'true' ? true : false;
		}
		if (typeof vm.filter.no_commodity != undefined) {
			vm.filter.no_commodity = vm.filter.no_commodity == 'true' ? true : false;
		}
		if (typeof vm.filter.only_billed_greater_than_blb != undefined) {
			vm.filter.only_billed_greater_than_blb = vm.filter.only_billed_greater_than_blb == 'true' ? true : false;
		}
		if (vm.filter.hide_less_than_eur) {
			vm.filter.hide_less_than_eur = parseInt(vm.filter.hide_less_than_eur);
		}
		if (vm.filter.max_value) {
			vm.filter.max_value = parseInt(vm.filter.max_value);
		}
		if (vm.filter.min_value) {
			vm.filter.min_value = parseInt(vm.filter.min_value);
		}
        if (vm.filter.lower_limit) {
            vm.filter.lower_limit = Number(vm.filter.lower_limit);
        }
		if (typeof vm.filter.exact_match != undefined) {
			vm.filter.exact_match = vm.filter.exact_match == 'true' ? true : false;
		}
		if (typeof vm.filter.simulate != undefined) {
			vm.filter.simulate = vm.filter.simulate == 'true' ? true : false;
		}
		if (typeof vm.filter.force_delete_invoice_cache != undefined) {
			vm.filter.force_delete_invoice_cache = vm.filter.force_delete_invoice_cache == 'true' ? true : false;
		}
		if (vm.filter.top) {
			vm.filter.top = Number(vm.filter.top);
		}
		if (vm.filter.calculation_months) {
			vm.filter.calculation_months = Number(vm.filter.calculation_months);
		}
	}


	let error = false;

	// get the report name to check if the watch applies
	let _link = window.location.href;
	let _split_link = _link.split("/");
	let _split_link2 = _split_link[4];

	// split pt link dupa filtrare
	_split_link2 = _split_link2.split("?");
	let _report_name = _split_link2[0];

	// set current year, jan, 01
	let year;
	if (typeof year == "undefined") {
		year = new Date().getFullYear();
	}

	let _prevYear = new Date(year - 1, 00, 01);
	// check if current report is in the DATE_FROM_FILTER_LIST (must not allow date to be under current year)

	// Comentat de Ioana Gubavu la 08-03-2020
	// nu se mai doresste limitarea datei rapoartelor la o perioada anume
	/*if (jQuery.inArray(_report_name, _the_filters) !== -1) {
		$scope.$watch("vm.filter", function (value) {
			$cookies.put(cookieKey, JSON.stringify(value));
			// split the date string to build the date afterwards
			if (vm.filter.date_from) {
				let _date_from = new Date(vm.filter.date_from);
				// apply styles or not, if date from is less than prev_year
				if (_date_from < _prevYear) {
					// $('[ng-model="vm.filter.date_from"] input, [ng-model="vm.filter.date_from"] span button').css("border", "1px solid red");
					$('div.not-current-year').attr('hidden', false);
					error = true;
				} else {
					// $('[ng-model="vm.filter.date_from"] input, [ng-model="vm.filter.date_from"] span button').css("border", "1px solid #c2cad8");
					$('div.not-current-year').attr('hidden', true);
					error = false;
				}
			}
		}, true);
	}*/
	vm.validate = function () {
		let errors = {};
		if (error) errors.date_from = "not-current-year";
		return vm.clientSideValidate(errors, 'filter');
	};
	$("#filter").submit(function (event) {
		event.preventDefault();
		if (vm.validate()) {
			$('#filter_title').text($filter('trans')('LANG.PLEASE_WAIT'));
			KApp.block('#loader');
			// console.log("filter", vm.filter);
			if (cookieKey == "reportPreBill") {
				// in case of reset filters, it will generate error. Force generate with default filters.
				if (!Object.keys(vm.filter).length) {
					vm.filter = {
						rule_type: 'ALL',
						include_standard_projects : 1,
						exclude_projects_with_zero_value: 1,
						format: 'html'
					}
				}
				window.open($(this).attr("action") + "?" + $filter("objToParams")(vm.filter), '_blank');
			} else {
				window.location = $(this).attr("action") + "?" + $filter("objToParams")(vm.filter);
			}
		}
	});

	return vm;
});
