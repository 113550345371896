/**
 * Usage
 * {{ object.date | dateDiff }}
 * @return date difference from now
 */
(function ()
{
	'use strict';

	angular
		.module('kv')
		.filter('dateDiff', dateDiff);

	function dateDiff($filter, cmdate) {
        return function(start_date) {
            var now = moment()
            var date = cmdate.fromServer(start_date);
            return now.diff(start_date);
        };
    }

})();
