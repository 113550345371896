kv.controller('AllocationGeneralReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    var params = kvUtils.getAllQueryParams();
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    return vm;
});
