//blade = resources/views/contact/contact-view.blade.php
kv.controller('contactViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'contact_id';
	vm.entity = 'contact';
	vm.groups = {};
	//activeaza directiva contact-comments din sidebar sa execute call-api pt. download comments
	vm.entityType = 'contact';
	vm.entityId = vm.bladeParams.contactId;

	vm.futureActivities = {
		template: 'activities',
		url: "/activity/activities_with_contact",
		filter: {
			contact_id: vm.bladeParams.contactId,
			range: 'future'
		},
		title: $filter('trans')('LANG.FUTURE_ACTIVITIES')
	};

	vm.last10Activities = {
		template: 'activities',
		url: "/activity/activities_with_contact",
		filter: {
			contact_id: vm.bladeParams.contactId,
			range: 'last 10 activities`'
		},
		title: $filter('trans')('LANG.LAST_TEN_ACTIVITIES')
	};

	vm.getGroupList = function () {
		var REST = resource.init("group");
		REST.list({
			url: "group/list?contact_id=" + vm.bladeParams.contactId
		}).then(function (data) {
			vm.groups = data.data;
		});
	};

	vm.getGroupList();
	
	vm.commentsConfig = {
		entity_type: vm.entity
	};


	vm.goBack = function () {
		$state.go("app.contacts");
	};


	vm.edit = function () {
		$state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
	};

	vm.addToGroup = function () {
		$mdDialog.show({
			controller: 'GroupsListController',
			controllerAs: 'vm',
			templateUrl: 'app/main/groups/groups.modal.html',
			parent: angular.element(document.body),
			clickOutsideToClose: true,
			locals: {
				isModal: true,
				contact_id: id
			}
		}).then(function (data) {
			getGroupList();
		});
	};

	vm.deleteFromGroup = function ($index) {
		var REST = resource.init("group");
		REST.delete({
			url: "group/:group_id/people_group/:people_group_id",
			params: {
				group_id: vm.groups[$index].group_id,
				people_group_id: vm.groups[$index].people_group_id
			}
		}).then(function (data) {
			vm.groups.splice($index, 1);
		});
	};

	return vm;
});
