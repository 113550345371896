kv.controller('ScrapeItemListCtrl', function ($uibModalInstance, data, $injector, $scope) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'inventory_item', 'inventory_item_id', $scope, data);

   vm.data ={};
    vm.data = {
        scrapped_by: API.user.user_id,
        scrapped_by_user: {
            full_name: API.user.full_name
        },
        scrapped_at: moment().format('YYYY-MM-DD'),
    };

    vm.save = function () {
        let errors = {};
        if (!vm.clientSideValidate(errors, 'data', $('.modal-content'))) return;
        vm.confirm(vm.trans("LANG.CONFIRM_MOVE"), vm.trans("LANG.CONFIRM_SCRAPPING")).then(response => {
            if (response !== "ok") return;
            let postData = angular.copy(vm.data);
            postData.event = 'SCRAPING';
            vm.rest.update({
                id: data.inventory_id,
                data: postData,
                success: function () {
                    vm.close('ok');
                }
            });
        });
    };

    return vm;
});
