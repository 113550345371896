kv.controller('RemindersListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'id';
    vm.entity = 'reminder';
    vm.count = {
        total: 0,
        showing: 0
    };
    vm.reminders = [];

    vm.init = function () {
        vm.reminders = JSON.parse(vm.bladeParams.data);
        console.log(vm.reminders);
    };

    vm.addEditGeneralEmailSettings = function (){
        vm.openModal({
            templateUrl: "general-email-settings",
            controller: 'generalEmailSettingsCtrl',
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                params: {
                  //  entity: entity
                }
            }
        }).then(response => {
            window.location = "/reminders/";
        });
    };

    vm.addEditManualReminder = function (category){
        vm.openModal({
            templateUrl: "general-email-settings",
            controller: 'generalEmailSettingsCtrl',
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                params: {
                  //  entity: entity
                }
            }
        }).then(response => {
            window.location = "/reminder/";
        });
    };

    vm.toggleReminderStatus = function toggleReminderStatus(reminder) {
        let toggleAction = reminder.is_active ? 'disableReminder' : 'enableReminder';
        let confirmationMessage = reminder.is_active ? 'Reminder was disabled' : 'Reminder was enabled';

        let rest = vm.getInjection('resource').init('reminders');
        KApp.block($element.find('#reminder_' + reminder.id));
        rest.update({
            url: 'reminders/' + reminder.id,
            params: {
                action: toggleAction
            }
        }).then((response) => {
            if (!response.status) {
                vm.alert('ALERT', vm.trans('Error changing status'));
            } else {
                // find the vm.reminders index
                let index = vm.reminders.findIndex(r => r.id === reminder.id);
                vm.reminders[index] = response.data;

                // console.log(vm.reminders[index]);
                toastr.success(confirmationMessage);
                KApp.unblock($element.find('#reminder_' + reminder.id));
            }
        })
        .catch(err => {
            console.log('Error changing project status');
            console.log(err); // bum related error
        })
        .finally(() => {
            KApp.unblock($element.find('#reminder_' + reminder.id));
        });
    };

    vm.init();


    vm.deleteReminder = function (reminder_id) {
        vm.confirm('DELETE', vm.trans('LANG.ARE_YOU_SURE_DELETE')).then(response => {
            if (response !== 'ok') return;
            $http.delete("/reminders/" + reminder_id).then(function () {
                let index = vm.reminders.findIndex(r => r.id === reminder_id);
                vm.reminders.splice(index, 1);
            });
        });
    };
    return vm;
});

