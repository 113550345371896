kv.controller('UserWorkloadListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'user_workload_id';
	vm.entity = 'user_workload';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'user_workload'
		},
		url: "user_workload",
	});

	return vm;
});

