kv.controller('documentTemplatesListGroupedCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'document_templates_id';
    vm.entity = 'document_templates';
    let display_entity = vm.bladeParams.entity;
    vm.rest = resource.init('document_templates');
    vm.loadData = function() {
        vm.dataHasLoaded = true;
        vm.rest.get({
            url: "document_templates",
            params: {
                entity: display_entity
            }
        }).then(function(res) {
            vm.dataHasLoaded = true;
            vm.data = res;
        })
    };


    vm.downloadRawTemplate = function(entity) {
        let url = "/document_templates" + '/' + entity.document_templates_id + "?action=download";
        window.open (url);
    };

    vm.addEditEntity = function (entity) {
        vm.openModal({
            templateUrl: "document_templates-edit",
            controller: 'documentTemplatesEditCtrl',
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                saveCallback: function () {
                    return function(response){
                        vm.loadData();
                        response.modal.close();
                    };
                },
                params: function () {
                    if(!entity) return {document_templates_id: null, display_entity : display_entity};
                    return {entity, display_entity : display_entity};
                }
            }
        });
    };

    vm.delete = function ($index, entity) {
        msg = vm.getInjection('$filter')('trans')('LANG.ARE_YOU_SURE_DELETE');
        if (!vm.primaryKey) throw "No primaryKey defined in vm controller!";
        return vm.confirm('DELETE', msg)
            .then((response) => {
                if (response !== 'ok') return response;
                KApp.block(vm.element);
                return vm.rest.delete({
                    params: {
                        id: vm.data[entity]['data'][$index][this.primaryKey],
                        params: null
                    },
                    success: (response) => {
                        KApp.unblock(vm.element);
                        if(typeof response.status !== 'undefined') {
                            if(response.status == true) {
                                vm.data[entity]['data'].splice($index, 1);
                                return response;
                            } else if (response.status == false) {
                                vm.alert(vm.trans('LANG.ERROR') + ' ' + response.error.error_code, response.error.message, response.error.details, response.error.fullError);
                            }
                        } else {
                            return response;
                        }
                    },
                    error: (response) => {
                        KApp.unblock(vm.element);
                        return response;
                    }
                });
            });
    }

    vm.loadData();
    console.log(vm.data);
    return vm;
});

