kv.controller('InvoiceCustomAllocationCtrl', function ($uibModalInstance, $scope, data, resource, $injector) {
	var vm = new modalCtrl($uibModalInstance, $injector, 'invoice', 'invoice_id', $scope, data);
	vm.newResult = {
		custom_allocation: {}
	};
	vm.action = {
		cancel: cancel,
		save: save,
		newEntity: newEntity,
		deleteEntity: deleteEntity,
		duplicateEntity: duplicateEntity
	};

	vm.rest.getArray({
		url: "invoice/" + data.invoice.invoice_id + "/customAllocation"
	}).then(function (res) {
		vm.newResult.custom_allocation = res;
	});


	function save() {
		vm.postData = angular.copy(vm.newResult);

		angular.forEach(vm.postData.custom_allocation, function (value, key) {
			if (value.date) {
				vm.postData.custom_allocation[key].date = value.date;
			} else {
				vm.postData.custom_allocation[key].date = null;
			}
		});

		vm.rest.post({
			url: "invoice/" + data.invoice.invoice_id + "/customAllocation",
			data: vm.postData
		}).then(function (data) {
			vm.custom_allocation_message = data.message;

			if (vm.custom_allocation_message == "ALLOCATION SUCCESSFUL") {
				toastr.success(vm.trans("LANG.SUCCESS_ALLOCATION_INSERT"), vm.trans("LANG.SUCCESS"));
				$uibModalInstance.close({});
			}

			if (vm.custom_allocation_message == "DUPLICATE ENTRY") {
				toastr.error(vm.trans("LANG.ERROR_ALLOCATION_DUPLICATE"), vm.trans("LANG.ERROR"));
			}

			if (vm.custom_allocation_message == "NO DATA") {
				toastr.error(vm.trans("LANG.ERROR_ALLOCATION_NO_DATA"), vm.trans("LANG.ERROR"));
			}

			if (vm.custom_allocation_message == "INSUFFICIENT DATA") {
				toastr.error(vm.trans("LANG.ERROR_ALLOCATION_INSUFFICIENT_DATA"), vm.trans("LANG.ERROR"));
			}
		});
	}

	function cancel() {
		$uibModalInstance.dismiss('cancel');
	}

	function newEntity(entity) {
		if (!vm.newResult) {
			vm.newResult = {};
		}

		if (!vm.newResult[entity]) {
			return vm.newResult[entity] = [
				{
					project_id: null,
					project_name: null,
					reference_id: null,
					reference_name: null,
					master_practice_id: null,
					master_practice_name: null,
					user_id: null,
					user_name: null,
					alloc_percent: null,
					alloc_value: null,
					date: null
				}
			];
		}

		return vm.newResult[entity].push(
			{
				project_id: null,
				project_name: null,
				reference_id: null,
				reference_name: null,
				master_practice_id: null,
				master_practice_name: null,
				user_id: null,
				user_name: null,
				alloc_percent: null,
				alloc_value: null,
				date: null,
			}
		);
	}

	function duplicateEntity(entity, $index) {
		vm.duplicateData = angular.copy(vm.newResult.custom_allocation[$index]);
		if ($index == null) {
			return vm.newResult[entity].push(
				{
					project_id: null,
					project_name: null,
					reference_id: null,
					reference_name: null,
					master_practice_id: null,
					master_practice_name: null,
					user_id: null,
					user_name: null,
					alloc_percent: null,
					alloc_value: null,
					date: null
				}
			);
		} else {
			return vm.newResult[entity].push(
				{
					project_id: vm.duplicateData.project_id,
					project_name: vm.duplicateData.project_name,
					reference_id: vm.duplicateData.reference_id,
					reference_name: vm.duplicateData.reference_name,
					master_practice_id: vm.duplicateData.master_practice_id,
					master_practice_name: vm.duplicateData.master_practice_name,
					user_id: vm.duplicateData.user_id,
					user_name: vm.duplicateData.user_name,
					alloc_percent: vm.duplicateData.alloc_percent,
					alloc_value: vm.duplicateData.alloc_value,
					date: vm.duplicateData.date
				}
			);
		}
	}

	function deleteEntity($index, entity, id) {
		if (!id) {
			return vm.newResult[entity].splice($index, 1);
		}

		if (data.invoice.invoice_id)
			vm.rest.delete({
				url: 'invoice/:id/customAllocation/:entityId',
				params: {
					id: data.invoice.invoice_id,
					entityId: id,
				}
			}).then(function (data) {
				vm.newResult[entity].splice($index, 1);
				toastr.warning(vm.trans("LANG.WARNING_ALLOCATION_DELETE"), vm.trans('LANG.WARNING'));
			});
	}

	return vm;
});
