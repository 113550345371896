kv.controller('InventoryItemModificationReportListCtrl', function ($uibModalInstance, data, $injector, $scope) {
    let vm = new modalCtrl($uibModalInstance, $injector, 'inventory_item', 'inventory_item_id', $scope, data);

  /*  vm.modal.rendered.then(() => {
        if (!vm.dataHasLoaded) KApp.block('.modal-content');
    });

    console.log(data);
    vm.rest.get({
        id: data.inventory_id
    }).then(function (res) {
        vm.data = res;
        vm.dataHasLoaded = true;
        KApp.unblock('.modal-content');
        $scope.$broadcast("dataLoaded");
    });
*/
    vm.data ={};
    vm.data = {
        received_by: API.user.user_id,
        received_by_user: {
            full_name: API.user.full_name
        },
        received_at: moment().format('YYYY-MM-DD'),
    };

    vm.save = function () {
        let errors = {};
        if (!vm.clientSideValidate(errors, 'data', $('.modal-content'))) return;
        vm.confirm(vm.trans("LANG.CONFIRM_MOVE"), vm.trans("LANG.CONFIRM_MODIFICATION_REPORT")).then(response => {
            if (response !== "ok") return;
            let postData = angular.copy(vm.data);
              postData.event = 'CHANGE';
            vm.rest.update({
                id: data.inventory_id,
                data: postData,
                success: function () {
                    vm.close('ok');
                }
            });
        });
    };

    return vm;
});
