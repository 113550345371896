angular
    .module('kv')
    .directive('droppable', () => (
        {
            scope: {
                onDrop: '&',
            },
            link: (scope, element) => {
                let el = element[0];

                // Add event listeners
                el.addEventListener(
                    'dragover',
                    function (e) {
                        e.preventDefault(); // Allow the drop

                        // Set effects
                        e.dataTransfer.dropEffect = 'move';
                        this.classList.add('dragover');
                        return false;
                    }, false
                );

                el.addEventListener(
                    'dragenter',
                    function () {
                        this.classList.add('dragover');
                        return false;
                    }, false
                );

                el.addEventListener(
                    'dragleave',
                    function () {
                        this.classList.remove('dragover');
                        return false;
                    }, false
                );

                el.addEventListener(
                    'drop',
                    function (e) {
                        this.classList.remove('dragover');

                        // Get the data
                        const destination = this.dataset.destinationId;
                        const itemToMove = e.dataTransfer.getData('item_id');
                        scope.onDrop()(destination, itemToMove);

                        return false;
                    }, false
                );
            }
        }));
