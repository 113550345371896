kv.controller('TimesheetApprovalListCtrl', function ($scope, resource, $element, $attrs, $injector, cmdate, $filter, $uibModal) {
	let vm = new timesheetBaseCtrl($scope, $element, $attrs, $injector);
	vm.primaryKey = 'timesheet_id';
	vm.entity = 'timesheet_approval';
	vm.bladeParamstimesheetFormDifficulty = JSON.parse(vm.bladeParams.timesheetFormDifficulty);
	vm.timesheetFormDifficulty = parseInt(vm.bladeParamstimesheetFormDifficulty.param_value);
	vm.countLines = 0;
	vm.edit_inline = [];
	vm.getInfinitScroll({
		search: {
			show_internal_option: 'customer_only',
			date_from: moment().startOf('year').format(vm.API.DATE_FORMAT),
			has_effort_on: 'all',
			aggregate_status_type: 'BILLABLE_EFFORT',
			group_by: 'ungrouped'
		},
		extraData: {totals: 'totals'},
		callback: function (response) {
			vm.countLines = 0;
			if (!response.status) return;
			vm.countLines = (response.data.totals ? response.data.totals.total_lines_affected : response.data.paginator.total);
			if (!response.data.totals) return;
			let totals = angular.copy(response.data.totals);
			vm.showTotals(totals);
		}
	});

	$scope.$watch("vm.infinitScroll.search.customer_id", function (value) {
		if (!value) {
			vm.infinitScroll.search.group_by = 'ungrouped';
			$('#group_by_button').attr('disabled', 'disabled');
		} else {
			$('#group_by_button').removeAttr("disabled");
		}
	});

	
	/**
	 * quick actions at timesheet level
	 * @param  {[type]} action [description]
	 * @return {[type]}        [description]
	 */
	vm.quickActions = function (action) {
		vm.confirm('CONFIRM', vm.trans('LANG.TOTAL_AFFECTED_ENTITIES') + ' ' + vm.countLines).then(response => {
			if (response != 'ok') return;
			let postData = angular.copy(vm.infinitScroll.search);
			postData.action = action;
			vm.infinitScroll.isLoading = true;
			vm.rest.update({
				url: vm.entity + '/quickActions',
				data: postData
			}).then(function (response) {
				if(response.task_id) toastr.success("Task - " + response.task_name);
				if(vm.infinitScroll.search.group_by && vm.infinitScroll.search.group_by != 'ungrouped') {
					vm.infinitScroll.items.forEach(grouped_by => {
						grouped_by['data'].forEach(item => {
							if (response[item.timesheet_id]) {
								angular.extend(item, response[item.timesheet_id]);
							}
						});
					});
				} else {
					vm.infinitScroll.items.forEach(item => {
						if (response[item.timesheet_id]) {
							angular.extend(item, response[item.timesheet_id]);
						}
					});
				}
				vm.infinitScroll.isLoading = false;
			});
		});
	};
	vm.loaded = false;
	vm.use_industrial_minutes = API.USE_INDUSTRIAL_MINUTES;
	vm.reporting_currency = API.REPORTING_CURRENCY;

	/**
	 * totals calculation
	 * @param  {[type]}   response [description]
	 * @return {Function}          [description]
	 */
	// vm.infinitScroll.callback = function (response) {
	// 	countLines = 0;
	// 	if (!response.status) return;
	// 	countLines = response.data.paginator.total;
	// 	if (!response.data.totals) return;
	// 	let totals = angular.copy(response.data.totals);
	// 	vm.showTotals(totals);
	// 	// clean data
	// };
	vm.showTotals = function (totals) {
		let showTotalToInvoice = 1;
		if (totals.total_to_invoice == null) {
			showTotalToInvoice = 0;
		}

		if (!totals.total_effort_sum) totals.total_effort_sum = 0;
		if (!totals.total_dnb_effort_sum) totals.total_dnb_effort_sum = 0;
		if (!totals.total_nb_effort_sum) totals.total_nb_effort_sum = 0;
		if (!totals.total_nc_effort_sum) totals.total_nc_effort_sum = 0;
		if (!totals.total_blb_effort_sum) totals.total_blb_effort_sum = 0;
		if (!totals.total_value_rc_sum) totals.total_value_rc_sum = 0;
		if (!totals.total_to_invoice) totals.total_to_invoice = 0;
		totals.total_eligible_effort_sum = totals.total_effort_sum - totals.total_nb_effort_sum - totals.total_nc_effort_sum;
		let total_effort = $filter("timesheetHoursFormat")(totals.total_effort_sum, vm.use_industrial_minutes, 1);
		let total_eligible_effort = $filter("timesheetHoursFormat")(totals.total_eligible_effort_sum, vm.use_industrial_minutes, 1);
		let total_dnb_effort = $filter("timesheetHoursFormat")(totals.total_dnb_effort_sum, vm.use_industrial_minutes, 1);
		let total_nb_effort = $filter("timesheetHoursFormat")(totals.total_nb_effort_sum, vm.use_industrial_minutes, 1);
		let total_nc_effort = $filter("timesheetHoursFormat")(totals.total_nc_effort_sum, vm.use_industrial_minutes, 1);
		let total_blb_effort = $filter("timesheetHoursFormat")(totals.total_blb_effort_sum, vm.use_industrial_minutes, 1);
		let total_value_rc = $filter("currency")(totals.total_value_rc_sum, vm.reporting_currency);
		let total_to_invoice_rc = $filter("currency")(totals.total_to_invoice, vm.reporting_currency);

		$(".__total_effort").html(total_effort);
		$(".__total_eligible_effort").html(total_eligible_effort);
		$(".__total_dnb_effort").html(total_dnb_effort);
		$(".__total_nb_effort").html(total_nb_effort);
		$(".__total_nc_effort").html(total_nc_effort);
		$(".__total_blb_effort").html(total_blb_effort);
		$(".__total_value_rc").html(kvUtils.ifNaNReturnZero(total_value_rc));
		if (showTotalToInvoice == 0) {
			$(".__total_to_invoice_td").css('display','none');
		} else {
			$(".__total_to_invoice_td").css('display','table-cell');
			$(".__total_to_invoice_rc").html(kvUtils.ifNaNReturnZero(total_to_invoice_rc));
		}

	};
	vm.isInternalProject = function (entity) {
		return entity.customer_id == null;
	};

	vm.addEdit = function (entity, $childIndex, $parentIndex) {
		vm.openModal({
			templateUrl: 'timesheet_approval-edit',
			controller: TimesheetApprovalEditCtrl,
			size: 'lg',
			resolve: {
				data: function () {
					return {
						index: $childIndex,
						entity: entity,
						result: entity,
						duplicate: $childIndex == -999 && entity !== null,
						bladeParams: vm.bladeParams,
						timesheetFormDifficulty: vm.timesheetFormDifficulty,
						form_action: ($childIndex == -999 && entity !== null ? 'duplicate' : ( $childIndex > -1 ? 'update' : 'create')),
						form_location: 'timesheet_approval'
					};
				}
			}
		}).then(function (response) {
			if(angular.isNumber($parentIndex) && $parentIndex >= 0) {
				if (response.index == -1) vm.infinitScroll.items[$parentIndex]['data'].unshift(response.entity);
				else angular.extend(entity, response.entity);
			} else {
				if (response.index == -1) vm.infinitScroll.items.unshift(response.entity);
				else angular.extend(entity, response.entity);
			}

			vm.recalculateGrandTotals();
		});
	};

	vm.editInline = function (entity, $childIndex, $parentIndex) {
		vm.inline_data = {};

		if(angular.isNumber($parentIndex) && $parentIndex >=0) {
			if(vm.edit_inline[$parentIndex + '-' + $childIndex] == true) {
				vm.edit_inline[$parentIndex + '-' + $childIndex] = false;
			} else {
				vm.edit_inline = [];
				vm.edit_inline[$parentIndex + '-' + $childIndex] = true;
				vm.getDataForEditInLine($childIndex, $parentIndex);
			}
		} else {
			if(vm.edit_inline[$childIndex] == true) {
				vm.edit_inline[$childIndex] = false;
			} else {
				vm.edit_inline = [];
				vm.edit_inline[$childIndex] = true;
				vm.getDataForEditInLine($childIndex);
			}
		}

	};

	vm.getDataForEditInLine = function($childIndex, $parentIndex) {
		let tsh_id = (angular.isNumber($parentIndex) && $parentIndex >= 0? vm.infinitScroll.items[$parentIndex]['data'][$childIndex].timesheet_id :  vm.infinitScroll.items[$childIndex].timesheet_id);
		vm.rest.get({
			id: tsh_id
		}).then(res => {
			// divide input minutes by 60
			vm.inline_data = kvUtils.transformEffortsInHours(res);
			vm.inline_data.form_location = 'timesheet_approval';
			vm.inline_data.form_action = 'update';
			vm.readonly = res.invoice_id !== null;

			if (vm.inline_data.customer_id == null) {
				vm.inline_data.is_internal_hours = true;
			} else {
				vm.inline_data.is_internal_hours = false;
			}

			if (vm.timesheetFormDifficulty == 1) {
				vm.inline_data.dnb_effort = vm.inline_data.dnb_effort + vm.inline_data.nb_effort + vm.inline_data.nc_effort;
				vm.inline_data.nb_effort = 0;
				vm.inline_data.nc_effort = 0;

			} else if (vm.timesheetFormDifficulty == 2) {
				vm.inline_data.eligible_effort = vm.inline_data.total_effort - vm.inline_data.nb_effort - vm.inline_data.nc_effort;
				vm.inline_data.dnb_effort = vm.inline_data.eligible_effort - vm.inline_data.blb_effort;
				vm.inline_data.nc_effort = 0;
			} else {
				//nothing to do
			}
			vm.inline_data.original_project_id = vm.inline_data.project_id;
			vm.inline_data.original_reference_id = vm.inline_data.reference_id;

			vm.checkHoursFormat(true);
			vm.dataHasLoaded = true;
			$scope.$broadcast('dataLoaded');

		});
	}

	vm.checkHoursFormat = function () {
		return kvUtils.checkHoursFormat.call(vm, vm.inline_data);
	};

	vm.saveInline = function ($childIndex, $parentIndex) {
		this.errors = undefined;
		let params = {data: this.prepareDataToSaveInline.apply(this, arguments)};
		params.id = params.data[this.primaryKey];
		let action = "update";

		let saveUrl = this.saveUrl ? this.saveUrl : null;
		if (saveUrl) params.url = saveUrl;
		if(params.data.has_error){
			$('.custom_error_messages').css('display', 'block');
		}else{
			$('.custom_error_messages').css('display', 'none');
			KApp.block(this.element);
			this.rest[action](params).then(response => {
				KApp.unblock(this.element);
				if (response.status || response.status == undefined) {
					if (response.data) {
						if(angular.isNumber($parentIndex) && $parentIndex >= 0) {
							vm.infinitScroll.items[$parentIndex]['data'][$childIndex] = response.data;
							vm.editInline(null, $childIndex, $parentIndex);
						} else {
							vm.infinitScroll.items[$childIndex] = response.data;
							vm.editInline(null, $childIndex);
							// vm.infinitScroll.search.refresh++;
						}
						vm.recalculateGrandTotals();
						vm.recalculateTotals($childIndex, $parentIndex);
						// vm.infinitScroll.search.refresh++;

					}
				} else this.showErrorMessages(response);
			});
		}
	};

	vm.recalculateTotals = function ($childIndex, $parentIndex) {
		if(angular.isNumber($parentIndex) && $parentIndex >= 0) {
			vm.infinitScroll.items[$parentIndex].blb_effort = vm.infinitScroll.items[$parentIndex].nb_effort = vm.infinitScroll.items[$parentIndex].nc_effort = vm.infinitScroll.items[$parentIndex].dnb_effort = vm.infinitScroll.items[$parentIndex].total_effort = vm.infinitScroll.items[$parentIndex].total_value_rc = 0;
				angular.forEach(vm.infinitScroll.items[$parentIndex]['data'], (timesheet) => {
					vm.infinitScroll.items[$parentIndex].blb_effort += parseInt(timesheet.blb_effort);
					vm.infinitScroll.items[$parentIndex].nb_effort += parseInt(timesheet.nb_effort);
					vm.infinitScroll.items[$parentIndex].nc_effort += parseInt(timesheet.nc_effort);
					vm.infinitScroll.items[$parentIndex].dnb_effort += parseInt(timesheet.dnb_effort);
					vm.infinitScroll.items[$parentIndex].total_effort += parseInt(timesheet.total_effort);
					vm.infinitScroll.items[$parentIndex].total_value_rc += parseInt(timesheet.timesheet_ext.total_value_rc);
			});
		}
	};

	// recalculate totals
	vm.recalculateGrandTotals = function () {
		let paramsTotals = angular.copy(vm.infinitScroll.search);
		paramsTotals.effort_total = true;
		vm.rest.get({
			url: "timesheet_approval",
			params: paramsTotals
		}).then(response => {
			if (response.status) vm.data.totals = response.data;
			else vm.data.totals = {};
			vm.showTotals(vm.data.totals);
		});
	};

	$scope.$watch("lookup.project.all_project_with_customer", function (value) {
		if (value) {
			vm.inline_data.customer_id = value.customer_id;
			vm.inline_data.customer = {
				customer_name: value.customer_name
			};
		}
	});

	vm.prepareDataToSaveInline = function () {
		let postData = angular.copy(vm.inline_data);
		// transform human effort to minutes
		postData.total_effort = kvUtils.humanTotalEffortToMinutes(postData.total_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.input_effort = kvUtils.humanTotalEffortToMinutes(postData.input_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.eligible_effort = (postData.eligible_effort ? kvUtils.humanTotalEffortToMinutes(postData.eligible_effort, vm.API.USE_INDUSTRIAL_MINUTES) : null);
		postData.blb_effort = kvUtils.humanTotalEffortToMinutes(postData.blb_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.dnb_effort = kvUtils.humanTotalEffortToMinutes(postData.dnb_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.nc_effort = kvUtils.humanTotalEffortToMinutes(postData.nc_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.nb_effort = kvUtils.humanTotalEffortToMinutes(postData.nb_effort, vm.API.USE_INDUSTRIAL_MINUTES);


		if (vm.timesheetFormDifficulty == 1) {
			if (vm.inline_data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
				postData.dnb_effort = postData.total_effort - postData.blb_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
			}
		} else if (vm.timesheetFormDifficulty == 2) {
			if (vm.inline_data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
				postData.dnb_effort = postData.eligible_effort - postData.blb_effort;
				postData.nb_effort = postData.total_effort - postData.eligible_effort;
				postData.nc_effort = 0;
				postData.total_effort = postData.blb_effort + postData.dnb_effort + postData.nb_effort + postData.nc_effort;
			}
		} else {
			if (vm.inline_data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
			//	postData.total_effort = postData.input_effort;
				vm.custom_errors = null;
				postData.has_error = false;

				if((postData.blb_effort + postData.dnb_effort + postData.nb_effort + postData.nc_effort) < postData.total_effort){
					if (!vm.custom_errors) vm.custom_errors = [];
					vm.custom_errors.push(vm.trans('LANG.EFFORT_SUM_SHOULD_EQUAL_TOTAL_EFFORT'));
					postData.has_error = true;
				}
			}
		}
		if (vm.inline_data.is_internal_hours) {
			postData.customer_id = null;
			postData.project_id = null;
			postData.reference_id = null;
		} else {
			postData.internal_project_id = null;
		}

		if (vm.inline_data.status_id == 4) {
			postData.is_reviewed = 1;
		} else {
			postData.is_reviewed = 0;
		}

		if (!postData.timesheet_ext) {
			vm.postData.timesheet_ext = {
				timesheet_id: null
			};
		}

		delete postData.input_effort;

		if (vm.isNewRecord || vm.data.duplicate == 1) {
			// daca se duplica se salveaza fortat ca revizuit sa apare dupa refresh
			// in lista (lista fiind filtrata pe timpi revizuiti)
			if (postData.duplicate == 1) {
				postData.status_id = 2;
			}
		}

		if(!postData.user_id) postData.user_id = postData.invoice_user_id;

		return postData;
	};

	vm.moveEntities = function () {
		vm.openModal({
			templateUrl: 'timesheet_approval-list-move',
			controller: TimesheetApprovalListMoveCtrl,
			size: 'md',
			resolve: {
				data: function () {
					return {
						searchList: vm.infinitScroll.search,
						countLines: vm.countLines,
						timesheetFormDifficulty: vm.timesheetFormDifficulty
					};
				}
			},
			backdrop: 'static',
			keyboard: false
		}).then(response => {
			if (response == "ok") vm.infinitScroll.search.refresh++;
		});
	};

	vm.addTimesheetOnInvoice = function (project_id) {
		if (project_id){
			vm.openModal({
				templateUrl: 'add-timesheet-on-invoice',
				controller: AddTimesheetOnInvoiceCtrl,
				size: 'md',
				resolve: {
					data: function () {
						return {
							searchList: vm.infinitScroll.search,
							countLines: vm.countLines,
							project_id: project_id,
							timesheetFormDifficulty: vm.timesheetFormDifficulty
						};
					}
				},
				backdrop: 'static',
				keyboard: false
			}).then(response => {
				if (response == "ok") vm.infinitScroll.search.refresh++;
			});
		}else{
			$('[ng-model="vm.infinitScroll.search.project_id"] .select2-selection--single').css("border", "1px solid red");
			toastr.error(vm.trans('PROJECT_IS_REQUIRED'));
		}
	};

    vm.changeType = function () {
		vm.openModal({
			templateUrl: 'timesheet_approval-list-change-type',
			controller: TimesheetApprovalListChangeTypeToCtrl,
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				data: function () {
					return {
						searchList: vm.infinitScroll.search,
						countLines: vm.countLines,
						timesheetFormDifficulty: vm.timesheetFormDifficulty
					};
				}
			},
			backdrop: 'static',
			keyboard: false
		}).then(response => {
			if (response == "ok") vm.infinitScroll.search.refresh++;
		});
	};

	vm.markAsReviewed = function (entity, $childIndex, $parentIndex){
		let element = $element.find('div.table-responsive');
		let postData = angular.copy({
							'timesheet_id': entity.timesheet_id, 
							'is_reviewed': (entity.is_reviewed ? 0 : 1), // !entity.is_reviewed, 
						});
		vm.rest.update({
			url: "timesheet/" + postData.timesheet_id,
			data: postData,
			params: {'force_update_entity': true}
		}).then(response => {
			if(angular.isNumber($parentIndex) && $parentIndex >= 0) {
				vm.infinitScroll.items[$parentIndex]['data'][$childIndex].is_reviewed = response.data.is_reviewed;
			} else {
				vm.infinitScroll.items[$childIndex].is_reviewed = response.data.is_reviewed;
			}
		});
	};

	vm.goToExpenseApproval = function () {
		let url = "/expense-approval?clear_filters=1";
		if(vm.infinitScroll.search.date_from)
			url += "&date_from=" + vm.infinitScroll.search.date_from;
		if(vm.infinitScroll.search.date_to)
			url += "&date_to=" + vm.infinitScroll.search.date_to;
		if(vm.infinitScroll.search.customer_id)
			url += "&customer_id=" + vm.infinitScroll.search.customer_id + "&default_customer_name=" + vm.infinitScroll.search.customer_name;
		if(vm.infinitScroll.search.project_id)
			url += "&project_id=" + vm.infinitScroll.search.project_id + "&default_project_name=" + vm.infinitScroll.search.project_name;
		if(vm.infinitScroll.search.reference_id)
			url += "&reference_id=" + vm.infinitScroll.search.reference_id + "&default_reference_name=" + vm.infinitScroll.search.reference_name;
		if(vm.infinitScroll.search.user_id)
			url += "&user_id=" + vm.infinitScroll.search.user_id + "&default_user_name=" + vm.infinitScroll.search.user_name;
		if(vm.infinitScroll.search.responsible_id)
			url += "&responsible_id=" + vm.infinitScroll.search.responsible_id + "&default_responsible_name=" + vm.infinitScroll.search.responsible_name;
		if(vm.infinitScroll.search.internal_project_id)
			url += "&internal_project_id=" + vm.infinitScroll.search.internal_project_id + "&default_internal_project_name=" + vm.infinitScroll.search.internal_project_name;
		window.open (url, '_blank');
	}

	vm.delete = function($childIndex, $parentIndex, params) {
		let tsh_id,array_to_splice;
		if(angular.isNumber($parentIndex) && $parentIndex >= 0) {
			tsh_id = this.infinitScroll.items[$parentIndex]['data'][$childIndex][vm.primaryKey];
			array_to_splice = this.infinitScroll.items[$parentIndex]['data'];
		} else {
			tsh_id = this.infinitScroll.items[$childIndex][vm.primaryKey];
			array_to_splice = this.infinitScroll.items;
		}
		let msg = this.getInjection('$filter')('trans')('LANG.ARE_YOU_SURE_DELETE');
		if (!vm.primaryKey) throw "No primaryKey defined in vm controller!";
		return this.confirm('DELETE', msg)
			.then((response) => {
				if (response !== 'ok') return response;
				KApp.block(this.element);
				return this.rest.delete({
					params: {
						id: tsh_id,
						params: params
					},
					success: (response) => {
						KApp.unblock(this.element);
						if(typeof response.status !== 'undefined') {
							if(response.status == true) {
								vm.edit_inline[$childIndex] = false;
								array_to_splice.splice($childIndex, 1);
								vm.recalculateGrandTotals();
								return response;
							} else if (response.status == false) {
								this.alert(this.trans('LANG.ERROR') + ' ' + response.error.error_code, response.error.message, response.error.details, response.error.fullError);
							}
						} else {
							return response;
						}
					},
					error: (response) => {
						KApp.unblock(this.element);
						console.error(response);
						return response;
					}
				});
			});
	}

	vm.getEntitiesComments = function ($parentIndex, $childIndex) {
		let project_id = (angular.isNumber($parentIndex) && $parentIndex >= 0? vm.infinitScroll.items[$parentIndex]['data'][$childIndex].project_id :  vm.infinitScroll.items[$childIndex].project_id);
		vm.project_comments = null;
		let REST = resource.init('comment');
		REST.list({
			params: {
				entity_id: project_id,
				entity_type: 'project'
			}
		}).then(response => {
			vm.project_comments = response.data;
			vm.showMoreComments(vm.project_comments);
		});
	}

	vm.showMoreComments = function (project_comments){
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'comment_detail_view',
			controller: function ($uibModalInstance, data, resource) {
				var vm = this;
				vm.data = {};
				vm.data.project = data.project;
				vm.cancel = function cancel() {
					$uibModalInstance.dismiss('cancel');
				};
			},
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						project: project_comments,
					};
				}
			}
		});
	}

	return vm;
});
