kv.controller('TenantViewWidgetEditCtrl', function ($uibModalInstance, params, bladeParams, saveCallback, $scope, $injector, resource, validator, $http) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'tenant', 'tenant_id', $scope, params);
	vm.dataHasLoaded = true;
	vm.filesUpload = {
		loaded: true,
		loaderSelector: "#logo-image",
		uploaded: function (response, loader) {
			if (response && response.status) vm.data.logo_path = response.data + "?v=" + Math.random();
			else vm.data.logo_path = "";
			if (loader) loader.remove();
		}
	};

	$scope.colorPickerOptions = {
		// html attributes
		required: true,
		disabled: false,
		allowEmpty: false,
		// color
		//format: ['hsl', 'hsv', 'rgb', 'hex', 'hexString', 'hex8', 'hex8String', 'raw'],
		format: 'hex8String',
		// sliders
		hue: true,
		saturation: false,
		lightness: false, // Note: In the square mode this is HSV and in round mode this is HSL
		alpha: false,
		dynamicHue: false,
		dynamicSaturation: false,
		dynamicLightness: false,
		dynamicAlpha: false,
		// swatch
		swatch: true,
		swatchPos: 'left',
		swatchBootstrap: true,
		swatchOnly: false,
		// popup
		round: false,
		pos: 'bottom left',
		inline: false,
		horizontal: true,
		// // buttons
		close: {
			show: true,
			label: 'Close',
			class: 'btn btn-default btn-xm',
		},
		reset: {
			show: true,
			label: 'Reset',
			class: 'btn btn-default btn-xm',
		},
	};

	if (!params.tenant_id) {
		vm.data = params;
		vm.isNewRecord = true;
	} else {
		vm.modal.rendered.then(() => {if (!vm.dataHasLoaded) KApp.block('.modal-content');});
		let rest = vm.getInjection('resource').init('tenant');
		rest.get().then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) {
				vm.data = response;
				if (vm.data.tenant_currency) {
					if (vm.data.tenant_currency) {
						angular.forEach(vm.data.tenant_currency, function (v, i) {
							vm.data.tenant_currency[i].id = v.currency_name;
							vm.data.tenant_currency[i].name = v.currency_name;
						});
					}
					vm.force_changing_password_periodically = false;
					if (vm.data.tenant_ext.password_expiration_days){
						vm.force_changing_password_periodically = true;
					}
					if (!vm.data.billing_owner_name){
						vm.data.billing_owner_name = vm.data.tenant_ext.billing_owner_name;

					}
				}
			}
			else console.error(response);
		});
	}

	vm.saveCallback = saveCallback;
	vm.save = function() {
		vm.errors = undefined;
		var postData = angular.copy(vm.data);

		if(!vm.force_changing_password_periodically){
			postData.tenant_ext.password_expiration_days = null;
		}
		if(!vm.billing_owner_name){
			postData.billing_owner_name = vm.data.billing_owner_name;
		}
		KApp.block('.modal-content');
		$http.put(bladeParams.updateAction, postData).then(function (response) {
			KApp.unblock('.modal-content');
			if (response.data.status == true || response.data.status == undefined) {
				if (vm.saveCallback) vm.saveCallback({entity: response.data});
				else vm.close({entity: response.data});
				vm.close(response);
			} else {
				vm.validator.markErrors(response.error, vm.element);
				let messages = {};
				Object.keys(response.data.error).forEach(field => {
					let errors = response.data.error[field];
					if (angular.isString(errors[0])) messages[field] = errors;
					else messages[field] = [trans('LANG.' + field + 'S_INVALID')];
				});
				vm.errors = messages;
				console.warn(vm.errors);

			}
		});
	};

	return vm;
});
