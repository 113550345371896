kv.controller('activityListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'activity_id';
	vm.entity = 'activity';

	vm.count = {
		total: 0,
		showing: 0
	};

	$scope.activityTypes = [
		{
			name: 'Meeting Rooms',
			id: "other_general",
			code: 'MEETING_ROOMS'
		},
		{
			name: "Marketing",
			id: "marketing",
			code: 'MK'
		},
		{
			name: "Business development",
			id: "business_development",
			code: "BD"
		},
		{
			name: "HR",
			id: "hr",
			code: 'HR'
		}
	];


	vm.addActivity = function (activity_type) {

	};


	vm.activityCategoriesAll = {
		"MEETING_ROOMS": [
			{
				name: "MEETING_ROOM",
				id: 1
			}
		],
		"BD": [
			{
				name: "BD_MEETING",
				id: 2
			},
			{
				name: "SERVICES_OFFER",
				id: 3
			},
			{
				name: "FREE_ADVICE",
				id: 4
			},
			{
				name: "CONFERENCE",
				id: 5
			},
			{
				name: "RECOMMENDATION",
				id: 6
			},
			{
				name: "TRAINING_WEBINAR_PRESENTATION",
				id: 7
			},
			{
				name: "OTHER_BD_INITIATIVES",
				id: 8
			}
		],
		"HR": [
			{
				name: "HR_COMPANY_PROJECT_COORDINATION",
				id: 16
			},
			{
				name: "HR_TRAINING",
				id: 9
			}
		],
		"MK": [
			{
				name: "MASS_MEDIA_RELATIONSHIPS",
				id: 11
			},
			{
				name: "EVENTS",
				id: 12
			},
			{
				name: "SUBMISSION",
				id: 13
			},
		]
	};
	// vm.changeActivityType();

	// vm.delete = function deleteEntity($index) {
	// 	$http.delete("activity/" + vm.infinitScroll.items[$index][vm.primaryKey]).then(function () {
	// 		vm.infinitScroll.items.splice($index, 1);
	// 	});
	// };
	vm.changeActivityType = function () {
		if (vm.infinitScroll.search.activity_type) {
			let activityType = "";
			$scope.activityTypes.some(item => {
				if (item.id !== vm.infinitScroll.search.activity_type) return false;
				activityType = item.code;
				return true;
			});
			
			vm.activityCategories = {};
			vm.activityCategories[activityType] = $scope.vm.activityCategoriesAll[activityType]
			let find = vm.activityCategories[activityType].find(item => {
				return item.id == parseInt(vm.infinitScroll.search.activity_type_category_id);
			});
			if (!find) vm.infinitScroll.search.activity_type_category_id = null;
		} else {
			$scope.vm.activityCategories = $scope.vm.activityCategoriesAll;
			vm.infinitScroll.search.activity_type_category_id = null;
		}
	};

	vm.getInfinitScroll({
		params: {
			action: 'activity'
		},
		callback: vm.changeActivityType,
		url: "activity",
	});

	vm.categories = [
		{
			name: $filter('trans')('ALL_ACTIVITIES'),
			id: "all_activities"
		},
		{
			name: $filter('trans')('MY_ACTIVITIES'),
			id: "my_activities"
		},
		{
			name: $filter('trans')('PAST_ACTIVITIES'),
			id: "past_activities"
		},
		{
			name: $filter('trans')('FUTURE_ACTIVITIES'),
			id: "future_activities"
		},
	];

	vm.customList = document.location.href.split('/').pop();
	for (var i = 0; i < $scope.activityTypes.length; i++) {
		var elem = $scope.activityTypes[i];
		if (vm.customList == elem.id) {
			vm.infinitScroll.search.activity_type = '';
			vm.infinitScroll.search.activity_type = vm.customList;
		}
	}

	if (vm.customList == 'ALL_ACTIVITIES') {
		vm.infinitScroll.search.categories = vm.customList;
	}
	return vm;
});

