kv.controller('practiceListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "practice_id",
		entity: "practice",
		modal: {
			templateUrl: "practice-edit",
			controller: "practiceEditCtrl"
		}
	});
});
