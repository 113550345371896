kv.controller('courtHearingEditCtrl', function ($uibModalInstance, params, $http, saveCallback, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'court_hearing', 'court_hearing_id', $scope, params);

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	vm.modal.rendered.then(() => {
		if (!vm.dataHasLoaded) KApp.block('.modal-content');
	});

	if (page_entity_id) {
		$http.get("/court_hearing/" + page_entity_id)
			.then(function (response) {
				vm.data = response.data;
				vm.dataHasLoaded = true;
				KApp.unblock('.modal-content');

				if (vm.data.court_hearing_attendee) {
					angular.forEach(vm.data.court_hearing_attendee, function (v, i) {
						vm.data.court_hearing_attendee[i].id = v.user.user_id;
						vm.data.court_hearing_attendee[i].name = v.user.user_name;
					});
				}

				vm.data.date = new dateClass(vm.data.date);
			});

	}

	vm.prepareDataToSave = function () {
		let dataToSave = angular.copy(vm.data);
		dataToSave.date = vm.data.date.PostDatetime;
		return dataToSave;
	};

	// if (!params.court_hearing_id) {
	// 	vm.data = params;
	// 	vm.isNewRecord = true;
	// } else {
	// 	vm.modal.rendered.then(() => KApp.block('.modal-content'));
	// 	let rest = vm.getInjection('resource').init('court_phase');
	// 	rest.get({id: params.court_phase_id}).then((response) => {
	// 		KApp.unblock('.modal-content');
	// 		if (response.$resolved) vm.data = response;
	// 		else console.error(response);
	// 	});
	// }
	vm.saveCallback = saveCallback;
	return vm;
});
