kv.controller('empowermentViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'empowerment_id';
	vm.entity = 'empowerment';
	vm.entityType = 'empowerment';
	vm.rest = vm.getInjection('resource').init('empowerment');
	vm.groups = {};

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];

	vm.entityId = page_entity_id;
	vm.commentsConfig = {
		entity_type: vm.entity
	};

	vm.goBack = function () {
		$state.go("app.contracts");
	};

	vm.edit = function () {
		$state.go('app.' + vm.entity + '.edit', {id: vm.result[vm.primaryKey], result: vm.result});
	};

	vm.printEmpowermentPDF = function (empowerment, has_access) {
		if((empowerment.contract_id !== null && empowerment.contract_status_id != 5) && (empowerment.contract_id !== null && empowerment.contract_status_id != 4)){
			if(has_access === 'true'){
				vm.confirm(vm.trans("LANG.CONFIRM_PRINT"), vm.trans("LANG.UNSIGNED_CONTRACT_MESSAGE")).then(response => {
					if (response !== "ok") return;
					vm.rest.get({
						url: "empowerment/exportPDF",
						params: {
							empowerment_id: empowerment.empowerment_id
						}
					}).then(() => {
						window.open('/empowerment/export/empowerment/' + empowerment.empowerment_id, '_blank');
					});
				});
			}else{
				vm.alert("", vm.trans('LANG.UNSIGNED_CONTRACT_MESSAGE'));
			}
		}else{
			vm.rest.get({
				url: "empowerment/exportPDF",
				params: {
					empowerment_id: empowerment.empowerment_id
				}
			}).then(() => {
				window.open('/empowerment/export/empowerment/' + empowerment.empowerment_id, '_blank');
			});
		}
	};

	return vm;
});
