angular
	.module('kv')
	.filter('newlines', newlines);

function newlines() {
    return function(text) {
        if(text != undefined) {
        	return text.replace(/\n/g, '<br/>');
        } else {
        	return "";
        }
    }
};