kv.controller('InvoiceViewCtrl', function ($scope, $element, $attrs, $injector, $http, validator, resource, $uibModal, $filter) {
	let vm = new timesheetBaseCtrl($scope, $element, $attrs, $injector);
	vm.invoice = JSON.parse(vm.bladeParams.invoice);
	vm.result = {};
	vm.timesheet = {};
	vm.expense = {};
	vm.billingRule = {};
	vm.errorValidationMessage = [];
	vm.custom_allocation_message = null;
	vm.annexTimesheetShowMoreIndex = -1;
	vm.annexExpenseShowMoreIndex = -1;
	vm.hasSameCurrency = false;
	vm.edit_inline = [];
	if (vm.bladeParams.timesheetFormDifficulty != "") {
		vm.bladeParamsTimesheetFormDifficulty = JSON.parse(vm.bladeParams.timesheetFormDifficulty);
		vm.timesheetFormDifficulty = parseInt(vm.bladeParamsTimesheetFormDifficulty);
	} else {
		vm.timesheetFormDifficulty = 1; 
	}
	vm.rest = resource.init("invoice");
	let currency_rate_exchange = [];
	vm.use_industrial_minutes = API.USE_INDUSTRIAL_MINUTES;
	vm.invoice_email_date = null;
	vm.checking_email_opened = false;
	vm.annexTimesheetShowMore = function (index) {
		if (vm.annexTimesheetShowMoreIndex > -1) {
			$('.annex-timesheet[index=' + vm.annexTimesheetShowMoreIndex + '] [data-toggle=collapse]> i').removeClass('fa-caret-down').addClass('fa-caret-right');
		}
		if (index !== vm.annexTimesheetShowMoreIndex) $('.annex-timesheet[index=' + index + '] [data-toggle=collapse]> i').removeClass('fa-caret-right').addClass('fa-caret-down');
		if (index == vm.annexTimesheetShowMoreIndex) vm.annexTimesheetShowMoreIndex = -1;
		else vm.annexTimesheetShowMoreIndex = index;
	};

	vm.annexExpenseShowMore = function (index) {
		if (vm.annexExpenseShowMoreIndex > -1) {
			$('.annex-expense[index=' + vm.annexExpenseShowMoreIndex + '] [data-toggle=collapse]> i').removeClass('fa-caret-down').addClass('fa-caret-right');
		}
		if (index !== vm.annexExpenseShowMoreIndex) $('.annex-expense[index=' + index + '] [data-toggle=collapse]> i').removeClass('fa-caret-right').addClass('fa-caret-down');
		if (index == vm.annexExpenseShowMoreIndex) vm.annexExpenseShowMoreIndex = -1;
		else vm.annexExpenseShowMoreIndex = index;
	};

	toastr.options = {
		"closeButton": true,
		"debug": false,
		"positionClass": "toast-top-center",
		"onclick": null,
		"showDuration": "500",
		"hideDuration": "500",
		"timeOut": "5000",
		"extendedTimeOut": "1000",
		"showEasing": "swing",
		"hideEasing": "linear",
		"showMethod": "fadeIn",
		"hideMethod": "fadeOut"
	};
	vm.primaryKey = 'invoice_id';
	vm.entity = 'invoice';
	vm.entityId = vm.invoice.invoice_id;
	vm.entityType = "invoice";
	vm.business_status = vm.trans(vm.invoice.sys_invoice_business_status.name);



	vm.initInvoice = function(invoiceData) {
		KApp.unblock('.loader');
		vm.page_loaded = true;
		vm.data = invoiceData;
		vm.invoice = invoiceData;

		console.log(vm.data);
		showInvoiceEmailStatus(vm.data);

		if(vm.data.currency == vm.bladeParams.globalCurrency.trim()){
			vm.hasSameCurrency = true;
		}
	}

	vm.getInvoiceData = function() {
		KApp.block('.loader');
		vm.page_loaded = false;

		var REST = resource.init('invoice');
		REST.get({
			url: "invoice/" + vm.entityId
		}).then(function (res) {
			vm.initInvoice(res);
		});
	};
	// gbarcun 2 feb 2023 - deja este incarcat prin blade-params
	// vm.getInvoiceData();

	function showInvoiceEmailStatus(response) {
		if (response.invoice_ext.email_opened_at) {
			vm.invoice_email_date = moment(response.invoice_ext.email_opened_at).format("YYYY-MM-DD HH:mm:ss");
			vm.invoice_email_status = 'LANG.EMAIL_OPENED';
		} else if (response.invoice_ext.email_sent_at) {
			vm.invoice_email_date = moment(response.invoice_ext.email_sent_at).format("YYYY-MM-DD HH:mm:ss");
			vm.invoice_email_status = 'LANG.EMAIL_SENT';
			// vm.checking_email_opened = true;
			try {
				let REST = resource.init('invoice');
				REST.get({
					url: 'invoice/' + vm.entityId + '/email/status'
				}).then(function (response) {
					if (response && response.email_opened_at) {
						vm.invoice_email_date = moment(response.email_opened_at).format("YYYY-MM-DD HH:mm:ss");
						vm.invoice_email_status = 'LANG.EMAIL_OPENED';
					}
					vm.checking_email_opened = false;
				});
			} catch (e) {
				vm.checking_email_opened = false;
			}
		}
	}

	// other calls
	// trebuie rescris codul de afisare paymanent cu angular (acum este in php)
	// getPayments();

	vm.allowChange = function () {
		return vm.business_status_id;
	};

	vm.errorInvoice = function ($index) {
		vm.alert("ALERT", vm.trans("ERROR_CREATE_FISCAL_INVOICE"));
	};

	vm.validateInvoice = function () {
		vm.rest.update({
			url: "invoice/" + vm.invoice.invoice_id + "/validateInvoice"
		}).then(function (data) {
			if(data.error && data.error.error_code === 1){
				toastr.error(data.error.message);
			}
			 else {
				 window.location = "/invoice/" + vm.invoice.invoice_id;
			}
		});
	};

	vm.sendInvoiceToAnaf = function (invoice_id) {
		let has_error = false;

		// prevalidation req
		vm.rest.get({
			url: "invoice/" + vm.invoice.invoice_id + "/actions",
			params: {
				action: 'anaf_prevalidation',
			},
		}).then(function (data) {
			has_error = (data.status === false);
			if( has_error ) {
				angular.forEach(data.errors, function (v, i) {
					toastr.error(v);
				});

			} else {
				vm.rest.update({
					url: "invoice/" + vm.invoice.invoice_id + "/sendInvoiceToAnaf"
				}).then(function (data) {
					if(data.error && data.error.length > 0 && data.status === false){
						angular.forEach(data.error, function (v, i) {
							toastr.error(v);
						});

					}
					else {
						toastr.success(vm.trans('LANG.INVOICE_SENT_TO_ANAF'));
						window.location = "/invoice/" + vm.invoice.invoice_id;
					}
				});
			}
		});
	};

	vm.draftInvoice = function () {
		vm.rest.update({
			url: "invoice/" + vm.invoice.invoice_id + "/draftInvoice"
		}).then(() => {
			window.location.reload();
		});
	};

	vm.totalInvoiceRecalc = function () {
		// se executa doar daca facutra este in draft!!!!!!!!!!!!!!!
		if (vm.invoice.invoice_status_id != 1) return;
		KApp.block('#totalInvoiceRecalc');
		vm.rest.update({
			url: "invoice/" + vm.invoice.invoice_id + "/totalInvoiceRecalc"
		}).then(function (response) {
			if(response.success === true){
				vm.initInvoice(response.data);
				// vm.getInvoiceData();
				vm.getInvoiceAnnexTotals(response);
				toastr.success(vm.trans('LANG.INVOICE_WAS_RECALCULATED'));
			}else{
				toastr.error('Invoice not found');
			}
		});

	};

	vm.cancelInvoice = function () {
		vm.confirm('confirm', vm.trans('Esti sigur/a ca ANULEZI factura?')).then((response) => {
			if (response !== 'ok') return;
			vm.rest.update({
				url: "invoice/" + vm.invoice.invoice_id + "/cancelInvoice"
			}).then(() => {
				window.location.reload();
			});
		});
	};

	function getCurrencyExchange() {
		var REST = resource.init("exchange_rate");
		return REST.getArray({
			url: 'filter/exchange_rate/get_daily_currency_rate',
			params: {
				date: moment().format('YYYY-MM-DD'),
				currency: vm.invoice.currency,
			},
		}).then(function (data) {
			if (data[0] && data[0].rate && typeof data[0].rate !== 'undefined') {
				currency_rate_exchange = data[0].rate;
			} else {
				currency_rate_exchange = '';
			}

			return currency_rate_exchange;
		});
	}

	/**
	 * get daily currency rate and then open popup for currency rate if invoice currency <> proforma currency
	 */
	vm.createFiscal = function (currency) {
		getCurrencyExchange().then((currency_rate_exchange) => {
			if (!currency) {
				// factura in RON => currency_rate = 1
				vm.doFiscalInvoice(vm.invoice.currency, 1);
			} else {
				if (currency == 'RON') {
					vm.openModal({
						templateUrl: 'invoice-view-currency-rate',
						controller: 'InvoiceCurrencyRate',
						controllerAs: 'vm',
						size: 'md',
						resolve: {
							currency_rate_exchange: function () {
								return currency_rate_exchange;
							}
						}
					}).then((data) => {
						vm.doFiscalInvoice(currency, data.currency_rate);
					});
				} else {
					vm.doFiscalInvoice(currency, vm.data.currency_rate);
				}
			}
		});
	};

	/**
	 * create fiscal invoice
	 * @param  invoice currency
	 * @param  currency rate
	 * @return {[type]}
	 */
	vm.doFiscalInvoice = function (currency, currency_rate) {
		vm.rest.update({
			url: "invoice/" + vm.invoice.invoice_id + "/createFiscal",
			data: {
				currency: currency,
				currency_rate: currency_rate
			}
		}).then((res) => {
			window.location = vm.bladeParams.invoiceIndexAction + "/" + res.data.invoice_id;
		});
	};


	vm.storno = function () {
		vm.confirm('confirm', vm.trans('Esti sigur/a ca STORNEZI factura?')).then((response) => {
			if (response !== 'ok') return;
			vm.rest.update({
				url: "invoice/" + vm.invoice.invoice_id + "/storno"
			}).then(function (res) {
				window.location = vm.bladeParams.invoiceIndexAction + "/" + res.data.invoice_id;
			});
		});
	};

	vm.partialStorno = function ($invoice_id) {
		vm.openModal({
			templateUrl: 'invoice-view-partial-storno-edit',
			controller: 'InvoicePartialStornoCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				data: function () {
					return {
						invoice_id: $invoice_id,
						bladeParams: vm.bladeParams
					};
				}
			}
		});
	};

	/**
	 * get payments on invoice
	 * @return {[type]} [description]
	 */
	// function getPayments() {
	// 	var REST = resource.init("payment");
	// 	if (vm.bladeParams.hasAccessToPayment !== '1') return;
	// 	REST.get({
	// 		url: "payment/list?invoice_id=" + vm.invoice.invoice_id
	// 	}).then(function (res) {
	// 		vm.result.payments = res.data;
	// 	});
	// }

	vm.calculateAllocation = function () {
		vm.rest.getArray({
			url: "invoice/" + vm.invoice.invoice_id + "/calculateAllocation"
		}).then(function (res) {
			window.location.reload();
		});
	};

	vm.customAllocation = function () {
		vm.openModal({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'custom-allocation',
			controller: 'InvoiceCustomAllocationCtrl',
			controllerAs: 'vm',
			size: '90',
			resolve: {
				data: function () {
					return {
						invoice: vm.invoice
					};
				}
			}
		});
		// allocationDatatable();
	};

	vm.getAllocation = function () {
		vm.openModal({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'calculate-allocation-view',
			controller: function ($uibModalInstance, data, resource) {
				var vm = this;
				var REST = resource.init("invoice");
				vm.action = {
					cancel: cancel,
				};

				REST.getArray({
					url: "invoice/" + data.invoice.invoice_id + "/getAllocation"
				}).then(function (res) {
					vm.users = res[0];
					vm.users.length = Object.keys(res[0]).length;
					if(vm.users.length == 0) vm.users = [];
					vm.total = res[1];
				});

				function cancel() {
					$uibModalInstance.dismiss('cancel');
				}

			},
			controllerAs: 'vm',
			size: 'xl',
			resolve: {
				data: function () {
					return {invoice: vm.invoice};
				}
			}
		});
	};

	vm.addEditPayment = function (payment_id) {
		vm.openModal({
			templateUrl: 'invoice-view-payment-edit',
			controller: 'InvoiceViewPaymentEditCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				data: function () {
					return {
						payment_id: payment_id,
						invoice: vm.invoice,
						bladeParams: vm.bladeParams
					};
				}
			}
		}).then(() => {
			window.location = vm.bladeParams.invoiceIndexAction + "/" + vm.invoice.invoice_id + "#payments";
			window.location.reload();
		});
	};

	vm.deletePayment = function (payment_id) {
		vm.confirm(vm.trans("WARNING"), vm.trans("LANG.ARE_YOU_SURE_DELETE")).then(() => {
			var REST = resource.init("payment");
			REST.delete({
				id: payment_id,
			}).then(() => {
				window.location = vm.bladeParams.invoiceIndexAction + "/" + vm.invoice.invoice_id + "#payments";
				location.reload();
			});
		});
	};

	vm.deleteInvoice = function ($invoice_id) {
		vm.openModal({
			component: 'modalConfirm',
			resolve: {
				content: () => {
					return $filter("trans")("ARE_YOU_SURE_DELETE");
				},
				title: () => {
					return $filter("trans")("DELETE");
				}
			}
		}).then(function () {
			vm.rest = resource.init("invoice");

			vm.rest.delete({
				params: {
					id: $invoice_id
				}
			}).then(function (response) {
				if (response.status) {
					toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
					window.location = "/invoice";
				} else {
					toastr.error(response.error.status_id);
				}
			});
		});
	};

	vm.changeInvoiceBusinessStatus = function (business_status_id) {
		vm.rest.update({
			id: vm.invoice.invoice_id,
			data: {
				business_status_id: business_status_id
			},
			params: {
				force_update_entity: true
			}
		}).then(function () {
			window.location = vm.bladeParams.invoiceIndexAction + "/" + vm.invoice.invoice_id;
		});
	};


	vm.loadInitialAnnexData = function () {
		if (vm.timesheet.length == undefined) {
			loadAnnexData();
			vm.getInvoiceAnnexTotals();
		}
	};

	function loadAnnexData(category) {
		var REST = resource.init("invoice-view-annex");
		KApp.block("a[href='#annex']");
		REST.get({
			url: 'invoice_annex/showEntity/' + vm.invoice.invoice_id,
		}).then(function (response) {
			KApp.unblock("a[href='#annex']");
			if (category) vm[category] = response[category];
			else {
				vm.timesheet = response.timesheet;
				vm.timesheetCount = response.timesheet.length;

				vm.expense = response.expense;
				vm.expenseCount = response.expense.length;

				vm.billingRule = response.billingRule;
				vm.billingRuleCount = response.billingRule.length;

				// get users and projects, and pass to filters
				vm.annex_users = response.filters.annex_users;
				vm.annex_projects = response.filters.annex_projects;
			}
		});
	}

	vm.getInvoiceAnnexTotals = function (invoice) {
		angular.extend(vm.invoice, invoice);
		vm.rest.get({
			url: 'invoice_annex/' + vm.invoice.invoice_id + '/entities-totals',
			params: {
				action: 'timesheet'
			}
		}).then(function (response) {
			KApp.unblock("a[href='#annex']");
			let use_industrial_minutes = API.USE_INDUSTRIAL_MINUTES;

			vm.total_effort = $filter("timesheetHoursFormat")(response.total_effort_sum, use_industrial_minutes, 1);
			vm.blb_effort = $filter("timesheetHoursFormat")(response.total_blb_effort_sum, use_industrial_minutes, 1);
			KApp.unblock('#totalInvoiceRecalc');
		});
	};

	function writeTotal(total, param) {
		if (total) {
			vm.total = total;
			if (!vm.total.total_effort_sum) {
				vm.total.total_effort_sum = 0;
			}
			if (!vm.total.total_dnb_effort_sum) {
				vm.total.total_dnb_effort_sum = 0;
			}
			if (!vm.total.total_nb_effort_sum) {
				vm.total.total_nb_effort_sum = 0;
			}
			if (!vm.total.total_nc_effort_sum) {
				vm.total.total_nc_effort_sum = 0;
			}
			if (!vm.total.total_blb_effort_sum) {
				vm.total.total_blb_effort_sum = 0;
			}
			if (!vm.total.total_value_rc_sum) {
				vm.total.total_value_rc_sum = 0;
			}
			$("." + param + " .__total_effort").html($filter("timesheetHoursFormat")(vm.total.total_effort_sum, API.USE_INDUSTRIAL_MINUTES, 1));
			$("." + param + " .__total_dnb_effort").html($filter("timesheetHoursFormat")(vm.total.total_dnb_effort_sum, API.USE_INDUSTRIAL_MINUTES, 1));
			$("." + param + " .__total_nb_effort").html($filter("timesheetHoursFormat")(vm.total.total_nb_effort_sum, API.USE_INDUSTRIAL_MINUTES, 1));
			$("." + param + " .__total_nc_effort").html($filter("timesheetHoursFormat")(vm.total.total_nc_effort_sum, API.USE_INDUSTRIAL_MINUTES, 1));
			$("." + param + " .__total_blb_effort").html($filter("timesheetHoursFormat")(vm.total.total_blb_effort_sum, API.USE_INDUSTRIAL_MINUTES, 1));
			$("." + param + " .__total_value_rc").html($filter("number")(vm.total.total_value_rc_sum));
		}
	}

	vm.deleteBillingRule = function (billing_rule_id, entity_type) {
		if(entity_type == 'SUBSCRIPTION'){
			vm.confirm(vm.trans("WARNING"), vm.trans("LANG.ARE_YOU_SURE_DELETE"))
				.then(function () {
					vm.rest.delete({
						url: 'invoice_annex/deleteEntity/' + billing_rule_id + '/subscription',
						success: function () {
							loadAnnexData('subscription');
							vm.totalInvoiceRecalc();
						}
					});
				});
		}else if(entity_type == 'HEARING'){
			vm.confirm(vm.trans("WARNING"), vm.trans("LANG.ARE_YOU_SURE_DELETE"))
				.then(function () {
					vm.rest.delete({
						url: 'invoice_annex/deleteEntity/' + billing_rule_id + '/hearing',
						success: function () {
							vm.totalInvoiceRecalc();
							window.location.reload();
						}
					});
				});
		}else{
			vm.confirm(vm.trans("WARNING"), vm.trans("LANG.ARE_YOU_SURE_DELETE"))
				.then(function () {
					let REST = resource.init('invoice_annex');
					REST.delete({
						url: 'invoice_annex/deleteEntity/' + billing_rule_id + '/billingRule',
					}).then(function () {
						loadAnnexData('billingRule');
						vm.totalInvoiceRecalc();
					});
				});
		}
	};

	vm.validateForAnaf = function(invoice_id, primary_address, primary_city){
		if(primary_address == null || primary_city == null){
			toastr.options = {
				"closeButton": true,
				"positionClass": "toast-top-center",
				"onclick": null,
				"showEasing": "swing",
				"hideEasing": "linear",
				"showMethod": "fadeIn",
				"hideMethod": "fadeOut",
				"timeOut": "0",
				"extendedTimeOut": "0"
			}
			toastr.error(vm.trans('LANG.ANAF_VALIDATE_ERROR'));
		}else{
			vm.rest.get({
				url: "invoice/exportPDF",
				id: invoice_id,
				params: {
					format: 'anaf',
					id: invoice_id,
				}
			}).then(function () {
				window.open('/invoice/export/anaf/' + invoice_id + '?format=anaf', '_blank');
			});
		}
	};

	vm.deleteSubscription = function ($index) {
		vm.confirm(vm.trans("WARNING"), vm.trans("LANG.ARE_YOU_SURE_DELETE"))
			.then(function () {
				vm.rest.delete({
					url: 'invoice_annex/deleteEntity/' + vm.subscription[$index].rule_subscription_id + '/subscription',
					success: function () {
						loadAnnexData('subscription');
						vm.totalInvoiceRecalc();
					}
				});
			});
	};

	vm.editTimesheet = function (entity, index) {
		vm.openModal({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'invoice-view-timesheet-edit',
			controller: InvoiceViewTimesheetEditCtrl,
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						index: index,
						timesheet: entity,
						message: vm.message,
						timesheetFormDifficulty: parseInt(vm.bladeParams.timesheetFormDifficulty),
						invoice_status_id: vm.invoice.invoice_status_id,
						form_action: (index == -999 && entity !== null ? 'duplicate' : ( index > -1 ? 'update' : 'create')),
						form_location: 'invoice_annex'
					};
				}
			}
		}).then(response => {
			if (response.index == undefined) return;
			if (response.index > -1) {
				// angular.extend(entity, response.entity);
				loadAnnexData('timesheet');
			}
			vm.totalInvoiceRecalc();
			loadAnnexData('timesheet');

		});
	};

	vm.deleteTimesheet = function (timesheet_id) {
		vm.confirm(vm.trans("WARNING"), vm.trans("ARE_YOU_SURE_DELETE"))
			.then(function () {
				vm.rest.delete({
					url: 'invoice_annex/deleteEntity/' + timesheet_id + '/timesheet',
					success: vm.totalInvoiceRecalc
				}).then( function (){
					loadAnnexData('timesheet');
					vm.totalInvoiceRecalc();
				});
			});
	};

	vm.editExpense = function (entity, index) {
		vm.openModal({
			templateUrl: "expense-edit",
			size: 'lg',
			controller: "ExpenseEditCtrl",
			controllerAs: "vm",
			resolve: {
				params: {
					entity: entity,
					index: index,
					duplicate: false,
					invoice_data: {
						invoice_status_id: vm.invoice.invoice_status_id,
					}
				},
			}
		}).then(response => {
			if (response.index == undefined) return;
			if (response.index > -1) {
				// angular.extend(entity, response.entity);
				loadAnnexData('expense');
			}
			vm.totalInvoiceRecalc();
			loadAnnexData('expense');

		});
	};

	vm.deleteExpense = function ($index) {
		vm.confirm(vm.trans("WARNING"), vm.trans("LANG.ARE_YOU_SURE_DELETE"))
			.then(function () {
				let REST = resource.init('invoice_annex');
				REST.delete({
					url: 'invoice_annex/deleteEntity/' + vm.expense[$index].expense_id + '/expense',
				}).then(function () {
					loadAnnexData('expense');
					vm.totalInvoiceRecalc();
				});
			});
	};

	vm.addTimesheetInInvoice = function (invoiceId) {
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'invoice-view-add-timesheet',
			controller: function ($uibModalInstance, data, resource, $filter, cmdate, validator, $scope, search) {
				var vm = this;
				vm.primaryKey = 'timesheet_id';
				vm.entity = 'timesheet_approval';
				vm.search = {};
				var timesheetIdsList = {};
				var REST = resource.init(vm.entity);
				vm.action = {
					save: save,
					cancel: cancel,
					addEdit: add,
				};


				// get all timesheet to update with the saveAll() button
				REST.get({
					url: 'invoice_annex/showEntity/' + invoiceId,
				}).then(function (res) {
					if (res[0]) {
						vm.allTimesheetForMassUpdate = res[0];
					} else {
						vm.allTimesheetForMassUpdate = {};
					}
				});

				function add($index) {
					var timesheetId = vm.result[$index][vm.primaryKey];

					if (!vm.result[$index].checked) {
						timesheetIdsList[timesheetId] = vm.result[$index][vm.primaryKey];
						$("#" + timesheetId + ' > .pb-10').hide();
						$("#" + timesheetId).prepend('<td width="5px" class="check" rowspan="1"><i class="fa fa-check"></i></td>');
						vm.result[$index].checked = true;
					} else {
						delete timesheetIdsList[timesheetId];
						$("#" + timesheetId + '> td.check').remove();
						$("#" + timesheetId + ' > .pb-10').show();
						vm.result[$index].checked = false;
					}
				}


				function save($saveAll = null) {
					var REST = resource.init('timesheet');
					if ($saveAll != null) {
						REST.update({
							url: "timesheet/updateInvoiceId",
							data: vm.search,
							params: {
								save_all: 1,
								invoice_id: data.invoice.invoice_id
							}
						}).then(function (response) {
							$uibModalInstance.close({response: response, index: data.index});
							// recalculate invoice after saving a draft invoice
							if (data.invoice.invoice_status_id == 1) {
								REST.update({
									url: 'invoice/' + data.invoice.invoice_id + '/totalInvoiceRecalc'
								}).then(function (response) {
									//
								});
							} else if (data.invoice.invoice_status_id == 2 || data.invoice.invoice_status_id == 5) {
								console.log("trigger invoice allocation");
								var invREST = resource.init('invoice');
								invREST.getArray({
									url: "invoice/" + data.invoice.invoice_id + "/calculateAllocation"
								}).then(function (res) {
									window.location.reload();
								});
							}
						});
					} else {
						REST.update({
							url: "timesheet/updateInvoiceId",
							params: {
								timesheet_ids: JSON.stringify(timesheetIdsList),
								invoice_id: data.invoice.invoice_id
							}
						}).then(function (response) {
							$uibModalInstance.close({response: response, index: data.index});
							// recalculate invoice after saving a draft invoice
							if (data.invoice.invoice_status_id == 1) {
								REST.update({
									url: 'invoice/' + data.invoice.invoice_id + '/totalInvoiceRecalc'
								}).then(function (response) {
									//
								});
							} else if (data.invoice.invoice_status_id == 2 || data.invoice.invoice_status_id == 5) {
								console.log("trigger invoice allocation");
								var invREST = resource.init('invoice');
								invREST.getArray({
									url: "invoice/" + data.invoice.invoice_id + "/calculateAllocation"
								}).then(function (res) {
									window.location.reload();
								});
							}
						});
					}

				}

				function cancel() {
					$uibModalInstance.dismiss('cancel');
				}

				vm.search.no_invoice = true;
				vm.search.customer_ids = data.bladeParams.customerIds;

				$scope.$watch('vm.search', function (newValues, oldValues) {
					values = angular.copy(newValues);
					if (values.date_from) {
						values.date_from = values.date_from;
					} else values.date_from = "1970-01-01";
					if (values.date_to) {
						values.date_to = values.date_to;
					}
					search.watch({
						search: vm.search,
						newValues: values,
						oldValues: oldValues,
						callback: function (filters) {
							REST.list({
								params: filters
							}).then(function (data) {
								vm.total = data.data.total;
								writeTotal(vm.total, 'popupTimesheet');
								if (oldValues.page == newValues.page || oldValues.page > newValues.page) {
									vm.result = data.data.paginator.data;
								} else {
									vm.result = vm.result.concat(data.data.paginator.data);
								}

								vm.last_page = (data.data.paginator.last_page == 1 ? 1 : 0);
								if (!filters.page || filters.page < data.data.paginator.last_page) {
									readyToLoadMore = true;
								}
							});
						},
						fixPager: function () {
							vm.search.page = 1;
						}
					});
				}, true);

				var readyToLoadMore = false;
				vm.loadMore = function () {
					if (readyToLoadMore && !vm.last_page) {
						if (!vm.search.page) {
							vm.search.page = 1;
						}
						vm.search.page = vm.search.page + 1;
						readyToLoadMore = false;
						ngRepeatFinished = false;
					}
				};
			},
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						getData: loadAnnexData('timesheet'),
						invoice: vm.invoice,
						bladeParams: vm.bladeParams
					};
				}
			}
		});

		//used to refresh the list
		modalInstance.result.then(function () {
			loadAnnexData('timesheet');
			vm.totalInvoiceRecalc();

		});
	};

	vm.addExpenseInInvoice = function (invoiceId) {
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'invoice-view-add-expense',
			controller: function ($uibModalInstance, data, resource, $filter, cmdate, validator, $scope, search) {
				var vm = this;
				vm.primaryKey = 'expense_id';
				vm.entity = 'expense-approval';
				vm.search = {};
				var expenseIdsList = {};
				var REST = resource.init(vm.entity);
				vm.action = {
					save: save,
					cancel: cancel,
					addEdit: add,
				};


				// get all expense to update with the saveAll() button
				REST.get({
					url: 'invoice_annex/showEntity/' + invoiceId,
				}).then(function (res) {
					if (res[0]) {
						vm.allExpenseForMassUpdate = res[0];
					} else {
						vm.allExpenseForMassUpdate = {};
					}
				});

				function add($index) {
					var expenseId = vm.result[$index][vm.primaryKey];

					if (!vm.result[$index].checked) {
						expenseIdsList[expenseId] = vm.result[$index][vm.primaryKey];
						$("#" + expenseId + ' > .pb-10').hide();
						$("#" + expenseId).prepend('<td width="5px" class="check" rowspan="1"><i class="fa fa-check"></i></td>');
						vm.result[$index].checked = true;
					} else {
						delete expenseIdsList[expenseId];
						$("#" + expenseId + '> td.check').remove();
						$("#" + expenseId + ' > .pb-10').show();
						vm.result[$index].checked = false;
					}
				}


				function save($saveAll = null) {
					var REST = resource.init('expense');
					if ($saveAll != null) {
						REST.update({
							url: "expense/updateInvoiceId",
							data: vm.search,
							params: {
								save_all: 1,
								invoice_id: data.invoice.invoice_id
							}
						}).then(function (response) {
							$uibModalInstance.close({response: response, index: data.index});
							// recalculate invoice after saving a draft invoice
							if (data.invoice.invoice_status_id == 1) {
								// se face de 2 ori si se dubleaza liniile din invoice_detail
								/*REST.update({
									url: 'invoice/' + data.invoice.invoice_id + '/totalInvoiceRecalc'
								}).then(function (response) {
									//
								});*/
							} else if (data.invoice.invoice_status_id == 2 || data.invoice.invoice_status_id == 5) {
								console.log("trigger invoice allocation");
								var invREST = resource.init('invoice');
								invREST.getArray({
									url: "invoice/" + data.invoice.invoice_id + "/calculateAllocation"
								}).then(function (res) {
									window.location.reload();
								});
							}
						});
					} else {
						REST.update({
							url: "expense/updateInvoiceId",
							params: {
								expense_ids: JSON.stringify(expenseIdsList),
								invoice_id: data.invoice.invoice_id
							}
						}).then(function (response) {
							$uibModalInstance.close({response: response, index: data.index});
							// recalculate invoice after saving a draft invoice
							if (data.invoice.invoice_status_id == 1) {
								/*REST.update({
									url: 'invoice/' + data.invoice.invoice_id + '/totalInvoiceRecalc'
								}).then(function (response) {
									//
								});*/
							} else if (data.invoice.invoice_status_id == 2 || data.invoice.invoice_status_id == 5) {
								console.log("trigger invoice allocation");
								var invREST = resource.init('invoice');
								invREST.getArray({
									url: "invoice/" + data.invoice.invoice_id + "/calculateAllocation"
								}).then(function (res) {
									window.location.reload();
								});
							}
						});
					}

				}

				function cancel() {
					$uibModalInstance.dismiss('cancel');
				}

				vm.search.no_invoice = true;
				vm.search.customer_ids = data.bladeParams.customerIds;
				vm.search.project_id = JSON.parse(data.bladeParams.masterProjectIds)[0];
				vm.search.project_name = JSON.parse(data.bladeParams.masterProjectNames)[0];

				$scope.$watch('vm.search', function (newValues, oldValues) {
					values = angular.copy(newValues);
					if (values.date_from) {
						values.date_from = values.date_from;
					} else values.date_from = "1970-01-01";
					if (values.date_to) {
						values.date_to = values.date_to;
					}
					search.watch({
						search: vm.search,
						newValues: values,
						oldValues: oldValues,
						callback: function (filters) {
							REST.list({
								params: filters
							}).then(function (data) {
								vm.total = data.data.total;
								writeTotal(vm.total, 'popupExpense');
								if (oldValues.page == newValues.page || oldValues.page > newValues.page) {
									vm.result = data.data.paginator.data;
								} else {
									vm.result = vm.result.concat(data.data.paginator.data);
								}

								vm.last_page = (data.data.paginator.last_page == 1 ? 1 : 0);
								if (!filters.page || filters.page < data.data.paginator.last_page) {
									readyToLoadMore = true;
								}
							});
						},
						fixPager: function () {
							vm.search.page = 1;
						}
					});
				}, true);

				var readyToLoadMore = false;
				vm.loadMore = function () {
					if (readyToLoadMore && !vm.last_page) {
						if (!vm.search.page) {
							vm.search.page = 1;
						}
						vm.search.page = vm.search.page + 1;
						readyToLoadMore = false;
						ngRepeatFinished = false;
					}
				};
			},
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						getData: loadAnnexData('expense'),
						invoice: vm.invoice,
						bladeParams: vm.bladeParams
					};
				}
			}
		});

		//used to refresh the list
		modalInstance.result.then(function () {
			loadAnnexData('expense');
			vm.totalInvoiceRecalc();

		});
	};

	vm.isEmpty = function (obj) {
		if (obj) {
			return Object.keys(obj).length == 0;
		}
	};

	vm.editInline = function (entity, $childIndex, $parentIndex) {
		vm.inline_data = {}; 
			if(vm.edit_inline[$parentIndex + '-'+ $childIndex] == true) {
				vm.edit_inline[$parentIndex + '-'+ $childIndex] = false;
			} else {
				vm.edit_inline = [];
				vm.edit_inline[$parentIndex + '-'+ $childIndex] = true;
				vm.getDataForEditInLine(entity, $childIndex, $parentIndex);
			}
	};

	vm.getDataForEditInLine = function(entity, $childIndex, $parentIndex) {
		// default
		let tsh_id = ($parentIndex ? vm.timesheet[$parentIndex]['data'][$childIndex].timesheet_id :  vm.timesheet[$childIndex].timesheet_id);

		// if we get entity, use it
		if (entity) {
			tsh_id = entity.timesheet_id;
		}

		let REST = resource.init('timesheet');
		REST.get({
			id: tsh_id
		}).then(res => {
			// divide input minutes by 60
			vm.inline_data = kvUtils.transformEffortsInHours(res);
			vm.inline_data.form_location = 'invoice_annex';
			vm.inline_data.form_action = 'update';
			// make fields readonly if invocie status is other than DRAFT
			vm.readonly = vm.data.invoice_status_id != 1;

			if (vm.inline_data.customer_id == null) {
				vm.inline_data.is_internal_hours = true;
			} else {
				vm.inline_data.is_internal_hours = false;
			}

			if (vm.timesheetFormDifficulty == 1) {
				vm.inline_data.dnb_effort = vm.inline_data.dnb_effort + vm.inline_data.nb_effort + vm.inline_data.nc_effort;
				vm.inline_data.nb_effort = 0;
				vm.inline_data.nc_effort = 0;

			} else if (vm.timesheetFormDifficulty == 2) {
				vm.inline_data.eligible_effort = vm.inline_data.total_effort - vm.inline_data.nb_effort - vm.inline_data.nc_effort;
				vm.inline_data.dnb_effort = vm.inline_data.eligible_effort - vm.inline_data.blb_effort;
				vm.inline_data.nc_effort = 0;
			} else {
				//nothing to do
			}
			vm.inline_data.original_project_id = vm.inline_data.project_id;
			vm.inline_data.original_reference_id = vm.inline_data.reference_id;

			vm.checkHoursFormat(true);
			vm.dataHasLoaded = true;
			$scope.$broadcast('dataLoaded');
		});
	}

	vm.checkHoursFormat = function () {
		return kvUtils.checkHoursFormat.call(vm, vm.inline_data);
	};

	/**
	 * save inline
	 * @param  {[type]} $childIndex  [description]
	 * @param  {[type]} $parentIndex [description]
	 * @return {[type]}              [description]
	 */
	vm.saveInline = function ($childIndex, $parentIndex) {
		this.errors = undefined;
		let params = {data: this.prepareDataToSaveInline.apply(vm.timesheet, arguments)};
		params.id = params.data.timesheet_id;
		let action = "update";

		let saveUrl = this.saveUrl ? this.saveUrl : null;
		if (saveUrl) params.url = saveUrl;
		let REST = resource.init('timesheet');

		KApp.block(this.element);
		REST[action](params).then(response => {
			KApp.unblock(this.element);
			if (response.status || response.status == undefined) {
				if (response.data) {
					if($parentIndex) {
						vm.timesheet[$parentIndex]['data'][$childIndex] = mapTimesheetSaveResponseToAnnex(response.data);
					} else {
						vm.timesheet[$childIndex] = mapTimesheetSaveResponseToAnnex(response.data);
					}

					if(vm.edit_inline[$parentIndex + '-'+ $childIndex] == true) {
						vm.edit_inline[$parentIndex + '-'+ $childIndex] = false;
					} else {
						vm.edit_inline = [];
						vm.edit_inline[$parentIndex + '-'+ $childIndex] = false;
					}

					vm.totalInvoiceRecalc();
				}
			} else this.showErrorMessages(response);
		});
	};

	/**
	 * get response from timesheet save and apply to vm.timesheet
	 * @param  {[type]} $childIndex  [description]
	 * @param  {[type]} $parentIndex [description]
	 * @return {[type]}              [description]
	 */
	function mapTimesheetSaveResponseToAnnex (response) {
		return {
			avatar: response.user.avatar,
			blb_effort: response.blb_effort,
			comments: response.comments,
			currency: response.currency,
			customer_id: response.customer_id,
			customer_name: response.customer.customer_name,
			date: response.date,
			dnb_effort: response.dnb_effort,
			entity_id: response.timesheet_id,
			entity_type: 'timesheet',
			invoice_id: response.invoice_id,
			invoice_user_name: response.invoice_user.full_name,
			is_reviewed: response.is_reviewed,
			nb_effort: response.nb_effort,
			nc_effort: response.nc_effort,
			project_id: response.project_id,
			project_name: response.project.project_name,
			reference_name: response.reference.project_name,
			status_name: response.sys_timesheet_status.status_name,
			timesheet_id: response.timesheet_id,
			total_effort: response.total_effort,
			total_value: response.total_value,
			user_id: response.user_id,
			user_name: response.user.full_name
		}
	}

	vm.prepareDataToSaveInline = function () {
		let postData = angular.copy(vm.inline_data);
		// set source = annex -> invoice_user is overriten
		postData.form_location = 'invoice_annex';
		// transform human effort to minutes
		postData.total_effort = kvUtils.humanTotalEffortToMinutes(postData.total_effort, vm.use_industrial_minutes);
		postData.input_effort = kvUtils.humanTotalEffortToMinutes(postData.input_effort, vm.use_industrial_minutes);
		postData.eligible_effort = (postData.eligible_effort ? kvUtils.humanTotalEffortToMinutes(postData.eligible_effort, vm.use_industrial_minutes) : null);
		postData.blb_effort = kvUtils.humanTotalEffortToMinutes(postData.blb_effort, vm.use_industrial_minutes);
		postData.dnb_effort = kvUtils.humanTotalEffortToMinutes(postData.dnb_effort, vm.use_industrial_minutes);
		postData.nc_effort = kvUtils.humanTotalEffortToMinutes(postData.nc_effort, vm.use_industrial_minutes);
		postData.nb_effort = kvUtils.humanTotalEffortToMinutes(postData.nb_effort, vm.use_industrial_minutes);


		if (vm.timesheetFormDifficulty == 1) {
			if (vm.data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
				postData.dnb_effort = postData.total_effort - postData.blb_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.input_effort = postData.total_effort;
			}
		} else if (vm.timesheetFormDifficulty == 2) {
			if (vm.data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
				postData.dnb_effort = postData.eligible_effort - postData.blb_effort;
				postData.nb_effort = postData.total_effort - postData.eligible_effort;
				postData.nc_effort = 0;
				postData.total_effort = postData.blb_effort + postData.dnb_effort + postData.nb_effort + postData.nc_effort;
			}
		} else {
			if (vm.data.is_internal_hours) {
				postData.dnb_effort = postData.total_effort;
				postData.nb_effort = 0;
				postData.nc_effort = 0;
				postData.blb_effort = 0;
			} else {
				postData.total_effort = postData.blb_effort + postData.dnb_effort + postData.nb_effort + postData.nc_effort;
			}
		}
		if (vm.data.is_internal_hours) {
			postData.customer_id = null;
			postData.project_id = null;
			postData.reference_id = null;
		} else {
			postData.internal_project_id = null;
		}

		if (!postData.timesheet_ext) {
			vm.postData.timesheet_ext = {
				timesheet_id: null
			};
		}

		// delete postData.input_effort;

		if (vm.isNewRecord || vm.data.duplicate == 1) {
			// daca se duplica se salveaza fortat ca revizuit sa apare dupa refresh
			// in lista (lista fiind filtrata pe timpi revizuiti)
			if (postData.duplicate == 1) {
				postData.status_id = 2;
			}
		}

		if(!postData.user_id) postData.user_id = postData.invoice_user_id;

		return postData;
	};

	vm.markAsReviewed = function (entity, index){
		let postData = angular.copy({
			'timesheet_id': entity.timesheet_id,
			'is_reviewed': (entity.is_reviewed ? 0 : 1), // !entity.is_reviewed,
		});
		let REST = resource.init('timesheet');
		REST.update({
			url: "timesheet/" + postData.timesheet_id,
			data: postData,
			params: {'force_update_entity': true}
		}).then(response => {
			vm.timesheet[index].is_reviewed = response.data.is_reviewed;
		});
	};

	vm.addEditEfacturaFields = function (invoice_id, type){
		vm.openModal({
			templateUrl: "efactura-fields-configuration",
			controller: 'efacturaFieldsConfigurationCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: {
					entity_id: invoice_id,
					type: type
				}
			}
		}).then(response => {
			window.location = "/invoice/" + invoice_id;
		});
	};

	// used for validations: if action is change_status, don't validate, just change status, else make validations
	vm.prepareDataToSave = function () {
		let postData = angular.copy(vm.data);
		// postData.action = 'change_status';
		return postData;
	};
	kvUtils.activateCurrentTabFromRequestUrl('ul.nav.nav-tabs', $element, tabId => {
		if (tabId == 'annex') loadAnnexData();
	});

	/****************************************************************************
	 * get all invoice related entities
	 ****************************************************************************/
	vm.initInvoice(vm.invoice);

	return vm;
});
