kv.controller('fiscalYearListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "id",
		entity: "fiscal_year",
		modal: {
			templateUrl: "fiscal_year-edit",
			controller: "fiscalYearEditCtrl"
		}
	});
});
