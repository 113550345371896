kv.controller('internalProjectListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'internal_project_id';
	vm.entity = 'internal_project';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		params: {
			action: 'internal_project'
		},
		url: "internal_project",
	});

	vm.edit = function ($index, entity, $id) {
		let isNewRecord = !entity;
		vm.openModal({
			templateUrl: "internal_project-edit",
			controller: 'internalProjectEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					return {
						index: $index,
						entity: entity,
						id: $id
					};
				},
				saveCallback: function () {
					return function(response){
						window.location = "/internal_project";
						response.modal.close();
					}
				},
			}
		});
	}

	return vm;
});

