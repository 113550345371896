kv.controller('tenantFiscalEntityEditCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'tenant_fiscal_entity', 'tenant_fiscal_entity_id', $scope, params);

	var eFacturaREST = resource.init('efactura');

	vm.filesUpload = {
		loaded: true,
		loaderSelector: "#logo-image",
		uploaded: function (response, loader) {
			if (response && response.status) vm.data.logo_path = response.data + "?v=" + Math.random();
			else vm.data.logo_path = "";
			if (loader) loader.remove();
		}
	};

	if (!params.tenant_fiscal_entity_id) {
		vm.data = params;
		vm.isNewRecord = true;
		vm.data.is_default = 0;
		vm.data.pay_vat_on_invoice = 0;
		vm.data.display_logo = 0;
		vm.data.display_annex_subtotals = 0;
		vm.data.show_due_invoices_on_annex = 0;
	} else {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		let rest = vm.getInjection('resource').init('tenant_fiscal_entity');
		rest.get({id: params.tenant_fiscal_entity_id, query: {with_settings: true}}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) vm.data = response;
			else console.error(response);
		});
	}

	$scope.$watch('[vm.data.send_invoices_automatically_to_anaf]', function (value) {
		if (parseInt(value) == 0) { // intern
			vm.data.send_invoice_to_anaf_days = 0;
		}
	});

	$scope.$watch('[vm.data.anaf_city]', function (value) {
		if (parseInt(value) == 5) { // intern
			vm.data.currency = API.user.tenant.reporting_currency;
		}
	});

	$scope.$watch('[vm.data.country_id]', function (value) {
		if (parseInt(value) != 85) {
			vm.data.region_id = '';
			vm.data.sector = '';
		}
	});

	vm.saveData = function () {
		return vm.save();
	};
	vm.saveCallback = saveCallback;

	vm.refreshTokenEFactura = function (tenant_fiscal_entity_id) {
		try {
			eFacturaREST.post({
				url: 'efactura/refresh/',
				data: {
					tenant_fiscal_entity_id: tenant_fiscal_entity_id,
				}
			})
			.then((response) => {
				if (response.status) {
					vm.data.efactura_token = response.data.efactura_token;
					vm.data.efactura_token_expiration = response.data.efactura_token_expiration;
					toastr.success(vm.trans('LANG.OPERATION_SUCCESSFULLY'));
				}
			});
		} catch (error) {
			console.error(error);
		}
	};

	return vm;
});
