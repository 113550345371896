kv.controller('DocumentStorageCtrl', function (
    $scope, $element, $attrs, $http, resource, $filter, $uibModal,
    $rootScope, $injector, Session,
    FileUploader
) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.entityId = vm.bladeParams.entityId;
    vm.entity = vm.bladeParams.entity;
    vm.uploadUrl = null;
    vm.currentDocumentSrc = null;

    let setUploadUrl = () => {
        let folder_path = vm.current_folder_id ? '/' + vm.current_folder_id : '';
        vm.uploadUrl = `/${vm.entity}/${vm.entityId}/upload_file${folder_path}`;
    };
    setUploadUrl();

    $scope.$watch('vm.current_folder_id', () => {
        setUploadUrl();
    });

    // listen for load_event
    vm.scope.$on("load_files", (event, entityId) => {
        vm.entityId = entityId;
        vm.getFiles(null, null);
    });

    let uploader = vm.uploader = new FileUploader({
        url: vm.uploadUrl,
        removeAfterUpload: true
    });

    // CALLBACKS
    uploader.onWhenAddingFileFailed = function(item /*{File|FileLikeObject}*/, filter, options) {
        toastr.error(vm.trans('UPLOAD_FILE_TYPE_NOT_ALLOWED'));
    };
    uploader.onAfterAddingAll = function(addedFileItems) {
        uploader.uploadAll();
    };
    uploader.onProgressAll = function(progress) {
        const pb = document.querySelector('.progress-bar > div');
        pb.style.display = 'inherit';
        pb.style.width = progress + '%';

        if (progress >= 100) {
            setTimeout(() => {
                pb.style.display = 'none';
                pb.style.width = '0%';
            }, 1000);
        }
    };
    uploader.onSuccessItem = function(fileItem) {
        toastr.success(`${vm.trans('SUCCESS')}: ${fileItem.file.name}`);
    };
    uploader.onErrorItem = function(fileItem, response, status, headers) {
        toastr.error(`${vm.trans('ERROR')}: ${fileItem.file.name}`);
    };

    uploader.onCompleteAll = function() {
        vm.getFiles(vm.current_folder_id, vm.current_folder_name);
    };

    vm.files_storage_breadcrumbs = [];
    vm.current_folder_id = null;
    vm.current_folder_name = null;
    vm.canGoBack = false;
    vm.iconMapping = {
        "pdf": "fa fa-file-pdf",
        "xls": "fa fa-file-excel",
        "xlsx": "fa fa-file-excel",
        "csv": "fa fa-file-csv",
        "ppt": "fa fa-file-powerpoint",
        "pptx": "fa fa-file-powerpoint",
        "zip": "fa fa-file-archive",
        "rar": "fa fa-file-archive",
        "jpg": "fa fa-file-image",
        "jpeg": "fa fa-file-image",
        "png": "fa fa-file-image",
        "gif": "fa fa-file-image",
        "doc": "fa fa-file-word",
        "docx": "fa fa-file-word",
    };

    /**
     * get documents
     */

    vm.filesUpload = {
        loaded: true,
        uploaded: (response) => {
            if(response.status && response.status === 500){
                toastr.error(vm.trans('ACTION_REJECTED'));
            } else {
                vm.getFiles(vm.current_folder_id, vm.current_folder_name);
            }
        }
    };

    /**
     * get files for current entity & folder
     * @param  {int|null} folder_id [ID of the folder we are currently browsing]
     * @param  {string|null} folder_name [Name of the folder we are currently browsing]
     */
    vm.getFiles = (folder_id, folder_name) => {
        if (!vm.rest) vm.rest = vm.getInjection("resource").init(vm.entity);

        // lock screen
        KApp.block("#document_storage-table");

        // get URL based on entity
        let folder_path = folder_id ? folder_id : '';
        let resourceURL = `${vm.entity}/${vm.entityId}/get_files/${folder_path}`;

        vm.rest.get({
            url: resourceURL,
        }).then((response) => {
            if(response.status && response.status === 500){
                toastr.error(vm.trans('ACTION_REJECTED'));
            } else {
                response.data = response.data ? vm.sortFiles(response.data) : response.data;
                vm.files = response.data;
                $rootScope.$broadcast("update_files_total_count", response.total_count);
                vm.updateBreadcrumbs(folder_id, folder_name);
                vm.computeCanGoBack();
                vm.current_folder_id = folder_id;
                vm.current_folder_name = folder_name;
            }
            // unlock screen
            KApp.unblock("#document_storage-table");
        });
    };

    /**
     * check if document tree navigation allows "back button"
     * @return {[type]} [description]
     */
    vm.computeCanGoBack = () => {
        vm.canGoBack = vm.files_storage_breadcrumbs.length > 0;
    };

    /**
     * sort all files in current directory by name asc
     * @param  {[type]} files [description]
     * @return {[type]}       [description]
     */
    vm.sortFiles = (files) => {
        let isFolder = (file) => {
            return file.type === 'directory';
        };
        files = files.sort(function(a, b) {
            return (isFolder(b) - isFolder(a)) || (a.name.toString().localeCompare(b.name));
        });
        return files;
    };

    /**
     * open a folder
     * @param  {int|null} folder_id [ID of the folder we are currently browsing]
     * @param folder_name
     */
    vm.browse = (folder_id, folder_name) => {
        vm.getFiles(folder_id, folder_name);
    };

    /**
     * add directory to current structure
     * @param {[type]} file [description]
     */
    vm.addEditDirectory = (file) => {
        let directory_id = file ? file.id : null;
        let directory_name = file ? file.name : "";
        vm.openModal({
            templateUrl: 'add_directory',
            controller: 'addEditDirectoryCtrl',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                params:{
                    entity: vm.entity,
                    parent_id: vm.current_folder_id,
                    directory_id,
                    entity_id: vm.entityId,
                    directory_name
                },
                saveCallback: () => {
                    return (response, modal) => {
                        if(response.status && response.status === 500){
                            toastr.error(vm.trans('ACTION_REJECTED'));
                        } else {
                            vm.getFiles(vm.current_folder_id, vm.current_folder_name);
                        }
                        modal.close();
                    };
                },
            }
        });
    };

    /**
     * rename file
     * @param  {[type]} file [description]
     * @return {[type]}       [description]
     */
    vm.renameFile = (file) => {
        let file_id = file ? file.id : null;
        let file_name = file ? file.name : "";
        vm.openModal({
            templateUrl: 'rename_file',
            controller: 'renameFileCtrl',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                params:{
                    entity: vm.entity,
                    entity_id: vm.entityId,
                    file_id,
                    parent_id: vm.current_folder_id,
                    file_name,
                },
                saveCallback: () => {
                    return (response, modal) => {
                        if(response.status && response.status === 500){
                            toastr.error(vm.trans('ACTION_REJECTED'));
                        } else {
                            vm.getFiles(vm.current_folder_id, vm.current_folder_name);
                        }
                        modal.close();
                    };
                },
            }
        });
    };

    /**
     * Add breadcrumbs
     * @param {int|null} id
     * @param {string} name
     */
    vm.updateBreadcrumbs = (id, name) => {
        if (id === null || id === undefined) {
            vm.files_storage_breadcrumbs = [];
            return;
        }

        // if we find the current folder to exist in breadcrumbs, we delete it and everything after
        // it will be added again on the next step
        for (let index = 0; index < vm.files_storage_breadcrumbs.length; index++) {
            if (vm.files_storage_breadcrumbs[index].id === id) {
                vm.files_storage_breadcrumbs.length = index;
            }
        }

        vm.files_storage_breadcrumbs.push(
            {
                id,
                name
            }
        );
    };

    /**
     * go up one level in the directory tree
     * @return {[type]} [description]
     */
    vm.goBack = () => {
        let length = vm.files_storage_breadcrumbs.length;
        if (length > 1) {
            let parent = vm.files_storage_breadcrumbs[length-2];
            vm.files_storage_breadcrumbs.pop();
            vm.getFiles(parent.id, parent.name);
        } else if (length === 1) {
            vm.getFiles(null, null);
        }
    };

    vm.getParentDirectoryId = () => {
       const parent = vm.files_storage_breadcrumbs[vm.files_storage_breadcrumbs.length - 2];
       if (parent) {
           return parent.id;
       }
       return null;
    };

    /**
     * delete one file
     * @param  {[type]} file [description]
     * @return {[type]}        [description]
     */
    vm.deleteFile = (file) => {
        if (!confirm(vm.trans('ARE_YOU_SURE_DELETE'))) {
            return null;
        }
        KApp.block("#document_storage-table");

        // get URL based on entity - e.g. project/{project_id}/delete_file/{file_id}
        let resourceURL = `${vm.entity}/${vm.entityId}/delete_file/${file.id}`;

        vm.rest.delete({
            url: resourceURL
        }).then((response) => {
            if(response.status && response.status === 500){
                toastr.error(vm.trans('ACTION_REJECTED'));
            } else {
                vm.getFiles(vm.current_folder_id, vm.current_folder_name);
            }
            KApp.unblock("#document_storage-table");
        });
    };

    /**
     * get file icon from mapping / defaults to fa-file
     * @param  {[type]} file [description]
     * @return {string}      [description]
     */
    vm.getIcon = (file) => {
        if (file.type === 'directory') {
            return "fa fa-folder";
        }
        if (file && file.extension) {
            let fileExtension = file.extension.toLowerCase();
            if (fileExtension in vm.iconMapping) {
                return vm.iconMapping[fileExtension];
            }
        }
        return "fa fa-file";
    };

    vm.fileHasPreview = (file) => {
        if (!file.extension) {
            return false;
        }

        const extensionsWithPreview = ['pdf', 'docx', 'png', 'jpg', 'jpeg', 'gif'];

        return extensionsWithPreview.includes(file.extension.toLowerCase());
    };

    /**
     * Format bytes as human-readable text.
     *
     * @param bytes Number of bytes.
     * @param si True to use metric (SI) units, aka powers of 1000. False to use
     *           binary (IEC), aka powers of 1024.
     * @param dp Number of decimal places to display.
     *
     * @return Formatted string.
     */
    vm.humanFileSize = (bytes, dp=1) => {
        const thresh = 1024;
        const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        let u = -1;
        const r = Math.pow(10, dp);

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    };

    vm.clearDocumentSrc = () => {
        vm.currentDocumentSrc = null;
    };

    vm.previewDocument = (file, $event) => {
        $event.preventDefault();
        const imageExtensions = ['jpeg', 'jpg', 'png', 'gif'];
        if (imageExtensions.includes(file.extension.toLowerCase())) {
            vm.currentDocumentSrc = `${Session.baseURL}/${file.entity_type}/preview-image/${file.id}?model=${file.entity_type}`;
        }
        else if (file.extension === 'docx') {
            vm.currentDocumentSrc = `${Session.baseURL}/${file.entity_type}/preview-docx/${file.id}?model=${file.entity_type}`;
        }
        else if (file.extension === 'pdf') {
            vm.currentDocumentSrc = `${Session.baseURL}/${file.entity_type}/get-document-content/${file.id}?model=${file.entity_type}`;
        } else {
            throw new Error(`There is no type of preview for this extension: ${file.extension}`);
        }
    };

    vm.moveItem = (destination, itemToMove) => {
        KApp.block("#document_storage-table");

        // get URL based on entity - e.g. project/{project_id}/delete_file/{file_id}
        let resourceURL = `${vm.entity}/${vm.entityId}/move_file/${itemToMove}`;

        vm.rest.update({
            url: resourceURL,
            data: {
                destination
            }
        }).then((response) => {
            if(response.status && response.status === 500){
                toastr.error(vm.trans('ACTION_REJECTED'));
            } else {
                vm.getFiles(vm.current_folder_id, vm.current_folder_name);
            }
            KApp.unblock("#document_storage-table");
        });
    };

    // if vm.entityId is not set, for example is to available in blade, then don't trigger the load
    // instead, wait for the event to load files
    if (vm.entityId) {
        vm.getFiles(null, null);
    }

    // we notify parent components that this component has finished loading so they can send events to it
    $scope.$emit("document_storage_loaded", true);

    return vm;
});
