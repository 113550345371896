kv.controller('overdueCustomersReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	let data = JSON.parse($element.find('#page-data').attr('page-data'));
	let initParams = kvUtils.getAllQueryParams();

	if (initParams) {
		if (initParams.generate) {
			$('[href="#results-tab"]').tab("show");
		}
	}

	$(document).ready(function () {
		setTimeout(() => {
			let dataTable = $('#table').DataTable({
				"paging": false,
				"ordering": true,
				"info": false,
				"bLengthChange": false,
				"bShowCounters": true,
				fixedHeader: {
					header: true,
					footer: true
				},
				// searchHighlight: true,
				// pt. counter tabel
				"columnDefs": [{
					"searchable": false,
					"orderable": false,
					"targets": 0
				}],
				"order": [[3, 'desc']]
			});
			// counter tabel
			dataTable.on('order.dt search.dt', function () {
				dataTable.column(0, {search: 'applied', order: 'applied'}).nodes().each(function (cell, i) {
					cell.innerHTML = i + 1;
				});
			}).draw();

			$('.dataTables_filter input[type="search"]').attr('placeholder', 'Search in table...').addClass('no-print').removeClass('input-small').css({'width': '350px', 'display': 'inline-block'});

			$('.dataTables_filter').addClass('pull-right').addClass('no-print');

		}, 300);
	});

	vm.isLoading = true;

	let chartData = data[1];
	vm.chart = function () {
		$('#overdueCustomersChart').hide();
		let config = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				events: {
					load: function () {
						this.reflow();
					}
				},
				height: 550,
			},
			title: {
				text: 'Top ' + vm.trans("OVERDUE_CUSTOMERS")
			},
			tooltip: {
				headerFormat: '',
				pointFormat: '{point.name}: <b>{point.y} ({point.percentage:.1f} % din selectie) </b>'
			},
			credits: {
				enabled: false
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						style: {
							color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
						}
					},
					showInLegend: false,
					innerSize: '60%',
				}
			},
			series: [{
				data: chartData
			}],
			afterPrint: function (a, b, c) {
				console.log(a, b, c)
			}
		};

		// hide dataLabels when resolution is xxs (phone)
		if ($(window).width() < 480) {
			config.plotOptions.pie.dataLabels.enabled = false;
		}

		$('#overdueCustomersChart').highcharts(config);
		vm.isLoading = false;

		setTimeout(function () {
			$('#overdueCustomersChart').show();
			$('#overdueCustomersChart').highcharts().reflow();
		}, 1);
	};
	if (chartData) vm.chart();
	return vm;
});
