(function ()
{
	'use strict';

	angular
		.module('kv')
		.directive('sortable', sortable);

	function sortable($rootScope, $compile) {
		return {
			restrict: "A",
			link: function( scope, element, attributes, controller ) {
				element.addClass("sorting");
				element.bind('click', function() {
					angular.forEach(element.parent().find("[sortable]"), function(v, i) {
						var e = angular.element(v);
						if(element.attr("sortable") != e.attr("sortable")) {
							e.addClass("sorting").removeClass("sorting_desc sorting_asc");
						}
					});
					element.removeClass("sorting");
					var action = {};
					if(element.hasClass("sorting_asc")) {
						action.removeClass = "sorting_asc";
						action.addClass = "sorting_desc";
						action.direction = "DESC";
					} else {
						action.removeClass = "sorting_desc";
						action.addClass = "sorting_asc";
						action.direction = "ASC";
					}

					element.removeClass(action.removeClass);
					element.addClass(action.addClass);
					scope.vm.infinitScroll.search.orderBy = element.attr("sortable");
					scope.vm.infinitScroll.search.direction = action.direction;
					scope.$apply();

				});
			}
		};
	}
})();
