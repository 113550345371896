kv.controller('industryListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "industry_id",
		entity: "industry",
		modal: {
			templateUrl: "industry-edit",
			controller: "industryEditCtrl"
		}
	});
});
