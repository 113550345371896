kv.controller('practiceAreaListCtrl', function ($element, $attrs, $scope, $injector) {
    return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
        primaryKey: "practice_id",
        entity: "practice_area",
        modal: {
            templateUrl: "practice_area-edit",
            controller: "practiceAreaEditCtrl"
        }
    });
});
