kv.controller('activityProjectReferenceQuickAddCtrl', function ($uibModalInstance, params, bladeParams, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'project', 'project_id', $scope, params);
	vm.isNewRecord = true;
	vm.newReference = JSON.parse(bladeParams.defaultObjectReference);
	vm.data = vm.newReference;
	vm.data.project_name = params.activity.activity_name;
	vm.data.parent_id = params.activity.project_id;
	vm.data.customer_id = params.activity.customer_id;

	return vm;
});
