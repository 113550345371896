kv.controller('TenantViewTabFiscalEntitiesCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'tenant_fiscal_entity_id';
	vm.entity = 'tenant_fiscal_entity';
	vm.entityType = 'tenant_fiscal_entity';
	var REST = resource.init(vm.entity);

	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];

	vm.listEntity = function() {
		REST.list().then(function (data) {
			vm.data = data.data;
		});
	};

	vm.listEntity();

	vm.addEditTenantFiscalEntity = function (entity) {
		vm.openModal({
			templateUrl: "tenant_fiscal_entity-edit",
			controller: 'tenantFiscalEntityEditCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				params: function () {
					if (!entity) {
						entity = {
							tenant_fiscal_entity_id: page_entity_id
						};
					}
					return entity;
				},
				saveCallback: function () {
					return function(response){
						vm.listEntity();
						response.modal.close();
					};
				},
			}
		});
	};

	vm.deleteTenantFiscalEntity = function ($index) {
		vm.confirm('Confirm', $filter('trans')('LANG.ARE_YOU_SURE_DELETE')).then(response => {
			if (response == 'cancel') return;

			let rest = this.getInjection('resource').init("tenant_fiscal_entity");
			let ctrl = this;
			rest.delete({
				params: {
					id: vm.data[$index].tenant_fiscal_entity_id
				},
				success: (response) => {
					KApp.unblock(this.element);
					if(typeof response.status !== 'undefined') {
						if(response.status == true) {
							this.infinitScroll.items.splice($index, 1);
							return response;
						} else if (response.status == false) {
							this.alert(this.trans('LANG.ERROR') + ' ' + response.error.error_code, response.error.message, response.error.details, response.error.fullError);
						}
					} else {
						return response;
					}
				},
				error: (response) => {
					KApp.unblock(this.element);
					console.error(response);
					return response;
				}
			});
		});
	};
	return vm;
});
