kv.controller('AdvanceReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    var params = kvUtils.getAllQueryParams();
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    function setOrderBy() {
        var orderBy = $('[aria-sort="ascending"], [aria-sort="descending"]').attr("data-order-by");
        var direction = $('[aria-sort="ascending"], [aria-sort="descending"]').attr('aria-sort');
        $("#print").attr("href", $("#print").attr("href") + "&orderBy=" + orderBy + "&direction=" + direction);
    }

    vm.action = {
        showDetails: showDetails
    };

    function showDetails($cID,$pID){
        var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'details_advance',
            controller: function($uibModalInstance, cmdate, validator, data, resource) {
                var vm = this;
                var data = JSON.parse($element.find('#page_data').attr('data'));

                vm.modal_results = data[$cID][$pID];
                vm.modal_action = {
                    cancel: cancel,
                };

                // modal cancel function (button)
                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                };
            },
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                data: function() {
                    return {};
                }
            }
        });

        modalInstance.result.then(function(data) {
            return true;
        });
    };

    return vm;
});
