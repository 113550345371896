kv.controller('DueInvoicesReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    var params = kvUtils.getAllQueryParams();
    vm.entity = 'invoice';
    var REST = resource.init(vm.entity);
    vm.expandDetails = expandDetails;
    var data = {};
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function() {
        $('#table1').DataTable({
            fixedHeader: {
                header:         true,
                footer:         true
            },
            "bPaginate":        false,
            "paging":           false,
            "ordering":         true,
            "info":             false,
            "bLengthChange":    false,
            "columnDefs": [{
                "searchable": false,
                "orderable": false,
                "targets": [0, 11]
            }],
        });

        $('#table2').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false
        });

        $('#table-collection').DataTable({
            fixedHeader: {
                header:         true,
                footer:         false
            },
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
        });

        $('.dataTables_filter input[type="search"]').
        attr('placeholder','Search in table...').
        removeClass('input-small').
        css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');
    });

    function setOrderBy() {
        var orderBy = $('[aria-sort="ascending"], [aria-sort="descending"]').attr("data-order-by");
        var direction = $('[aria-sort="ascending"], [aria-sort="descending"]').attr('aria-sort');

        $("#print").attr("href", $("#print").attr("href") + "&orderBy=" + orderBy + "&direction=" + direction);
    }

    function expandDetails($data){
        var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'due-invoices-details',
            controller: function ($uibModalInstance) {
                var vm = this;
                vm.cancel = cancel;
                vm.data = $data;

                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                };
            },
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                data: function () {
                    return {
                        data: vm.data,
                    };
                }
            }
        });
    }

    return vm;
});
