/**
 * This directive should be used only near <action-menu></action-menu> directive
 * E.G.:
 * 	<action-menu> 
 * 		<action-menu-item></action-menu-item>
 * 	</action-menu>
 * 	<action-button> Othe thing this button does </action-button>
 */
(function ()
{
	'use strict';

	angular
		.module('kv')
		.directive('actionButton', actionButton);

	function actionButton() {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			scope: {
				href: '@',
				ngClick: '&'
			},
			templateUrl: "/assets/custom/js/directives/action/action-button/templates/action-button.html",
			link: function($scope, elem) {}
		};
	}
})();
