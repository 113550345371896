/**
 * This directive should be used only inside <action-menu></action-menu> directive
 * <action-menu-item>Show</action-menu-item>
 */
(function ()
{
	'use strict';

	angular
		.module('kv')
		.directive('actionMenuItem', actionMenuItem);

	function actionMenuItem() {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			scope: {
				href: '@',
				ngClick: '&'
			},
			templateUrl: "/assets/custom/js/directives/action-menu/action-menu-item/templates/action-menu-item.html",
			link: function(scope, attr) {
			}
		};
	}
})();
