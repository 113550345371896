kv.controller('LegalCaseImportEditCtrl', function ($uibModalInstance, params, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'legal_case', 'legal_case_id', $scope, params);
	vm.data = angular.copy(params.entity);
	vm.columns = params.columns;
	vm.reversedColumns = params.reversedColumns;
	vm.validate = function () {
		let postData = {};
		// this time, processed became source ;
		if (vm.data.processed.number) postData[vm.reversedColumns.number] = vm.data.processed.number;
		if (vm.data.processed.customer) postData[vm.reversedColumns.customer_name] = vm.data.processed.customer.customer_name;
		if (vm.data.processed.responsable) postData[vm.reversedColumns.responsable_name] = vm.data.processed.responsable.full_name;
		if (vm.data.processed.follower1) postData[vm.reversedColumns.follower_name_1] = vm.data.processed.follower1.full_name;
		if (vm.data.processed.follower2) postData[vm.reversedColumns.follower_name_2] = vm.data.processed.follower2.full_name;
		if (vm.data.processed.follower3) postData[vm.reversedColumns.follower_name_3] = vm.data.processed.follower3.full_name;
		if (vm.data.processed.controller) postData[vm.reversedColumns.controller] = vm.data.processed.controller.full_name;
		if (vm.data.processed.echipa) postData[vm.reversedColumns.echipa] = vm.data.processed.echipa.team_name;
		if (vm.data.processed.other_party) postData[vm.reversedColumns.other_party] = vm.data.processed.other_party;
		if (vm.data.processed.legal_case_stage) postData[vm.reversedColumns.legal_case_stage_name] = vm.data.processed.legal_case_stage.legal_case_stage_name;
		if (vm.data.processed.court) postData[vm.reversedColumns.court_name] = vm.data.processed.court.name;
		if (vm.data.processed.open_date) postData[vm.reversedColumns.open_date] = vm.data.processed.open_date;
		if (vm.data.processed.project) postData[vm.reversedColumns.project_number] = vm.data.processed.project.project_number;
		if (vm.data.processed.project) postData.project_id = vm.data.processed.project_id;
		if (vm.data.processed.reference) postData[vm.reversedColumns.reference_name] = vm.data.processed.reference.project_name;

		vm.rest.post({
			url:"legal_case/checkImportRecord",
			data: postData
		}).then(response=>{
			if (response.status)  {
				if (response.data.hasError) vm.data = response.data;
				else vm.close({entity: response.data});
			}
		});
	};
	vm.changeSource = function (fieldName) {
		switch (fieldName) {
			case 'customer_name':
				if (vm.data.processed.customer) vm.data.processed.customer_id = vm.data.processed.customer.customer_id;
				vm.data.processed.project = undefined;
				vm.data.processed.project_id = undefined;
				vm.data.processed.reference = undefined;
				vm.data.processed.reference_id = undefined;
				break;
			case 'court':
				break;
		}
	};
	return vm;
});

