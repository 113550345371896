kv.controller('CustomerFiscalEntityViewConflictCheckEditCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'conflict_check', 'conflict_check_id', $scope, params);
	if (!params.conflict_check_id) {
		vm.data = params;
		vm.isNewRecord = true;
	} else {
		vm.modal.rendered.then(() => {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
		});
		vm.rest.get({id: params.conflict_check_id}).then((response) => {
			vm.dataHasLoaded = true;
			KApp.unblock('.modal-content');
			if (response.$resolved) vm.data = response;
			else console.error(response);
		});
	}
	vm.saveCallback = saveCallback;

	return vm;
});
