//blade = resources/views/contact/contact-view.blade.php
kv.controller('courtHearingViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'court_hearing_id';
	vm.entity = 'court_hearing';
    vm.entityId = vm.bladeParams.courtHearingId;
	vm.entityType = 'court_hearing';



    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3]; 

	vm.commentsConfig = {
		entity_type: vm.entity
	};

    // let legalCaseId = $element.find("#legal_case_id").attr("legal_case_id");


    vm.syncCourtHearing = function () {
        vm.syncingCourtHearing = true;
        var REST = resource.init('court_hearing/sync');
        REST.get({
            params: {
                legal_case_id: vm.bladeParams.LegalCaseId,
                court_hearing_id: page_entity_id
            }
        }).then(function (data) {
            vm.syncingCourtHearing = false;
            location.reload();
        });
    }

    vm.addEditEntity = function (entity) {
        let isNewRecord = !entity;
        vm.openModal({
            templateUrl: "court_hearing-edit",
            controller: 'courtHearingEditCtrl',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                params: function () {
                    if (!entity) {
                        entity = {
                            legal_case_id: page_entity_id
                        };
                    }
                    return entity;
                },
                saveCallback: function () {
                    /**/
                },
            }
        }).then(response => {
            if (!response) return;
            window.location = "/court_hearing/" + response.entity.court_hearing_id ;
        });
    };
        

	// function addEditEntity($index, duplicate, activity) {
 //                 vm.inEdit = true;
 //                 var modalInstance = $uibModal.open({
 //                     animation: true,
 //                     ariaLabelledBy: 'modal-title',
 //                     ariaDescribedBy: 'modal-body',
 //                     templateUrl: 'timesheet-edit',
 //                     controller: function ($uibModalInstance, data, resource, $filter, cmdate, validator, $uibModal, $rootScope, $scope) {
 //                         try {
 //                             delete $rootScope.lookup.commodity_activity;
 //                         } catch (e) {
 //                             // console.log(e);
 //                         }

 //                         var vm = this;
 //                         vm.primaryKey = 'timesheet_id';
 //                         vm.entity = 'timesheet';
 //                         vm.quickAddCustomer = quickAddCustomer;
 //                         validator.init($scope, "timesheet", vm.related_models);
 //                         // var REST = resource.init(vm.entity);

 //                         // se foloseste in html pentru a afisa campul de activitati
 //                         vm.selectedProjectIsCommodity = false;

 //                         // in momentul in care se schimba valoarea din project_id se seteaza vm.selectedProjectIsCommodity
 //                         // in functie de valoarea campului is_commodity din project
 //                         // se sterge din variabila globala $rootScope.lookup.all_project_with_customer (daca nu, apare un bug la adaugare, in momentul in care project_id este null dar lookup-ul are informatii din vechea adaugare)
 //                         $scope.$watch("vm.newResult.project_id", function (value) {
 //                             if (value && $rootScope.lookup && $rootScope.lookup.project && $rootScope.lookup.project.all_project_with_customer) {
 //                                 vm.selectedProjectIsCommodity = $rootScope.lookup.project.all_project_with_customer.is_commodity;
 //                                 delete $rootScope.lookup.project.all_project_with_customer;
 //                             }

 //                             // if project input changes get data to check if new selected project has ledes activity code
 //                             if (value) {
 //                                 REST.get({
 //                                     url: "{{ action('ProjectController@index') }}/" + value,
 //                                     params: {
 //                                         getLedesInfo: true,
 //                                     }
 //                                 }).then(function (data) {
 //                                     // if customer_fiscal_entity on the selected project has electronic billing set hasLedes true
 //                                     if (data) {
 //                                         if (data.use_electronic_billing == 1) {
 //                                             vm.hasLedes = true;
 //                                         } else {
 //                                             vm.hasLedes = false;
 //                                         }
 //                                     } else {
 //                                         vm.hasLedes = false;
 //                                     }
 //                                 })
 //                             }

 //                             vm.selectedProjectHaveCourtHearing = false;
 //                         });


 //                         // se foloseste in html pentru a afisa campul de court hearing
 //                         vm.selectedProjectHaveCourtHearing = false;

 //                         // in momentul in care se schimba valoarea din project_id sau reference_id se seteaza vm.selectedProjectHaveCourtHearing
 //                         // daca proiectul este legat de un dosar sau referinta
 //                         // se sterge din variabila globala $rootScope.lookup.all_project_with_customer (daca nu, apare un bug la adaugare, in momentul in care project_id este null dar lookup-ul are informatii din vechea adaugare)
 //                         $scope.$watch("vm.newResult.reference_id", function (value) {
 //                             if (value) {
 //                                 REST.getArray({
 //                                     url: '{{ action('FilterController@get',['court_hearing', 'all_timesheet_court_hearing']) }}',
 //                                     params: {
 //                                         project_id: vm.newResult.project_id,
 //                                         reference_id: vm.newResult.reference_id,
 //                                     }
 //                                 }).then(function (data) {
 //                                     if (data.length) {
 //                                         vm.selectedProjectHaveCourtHearing = true;
 //                                     } else {
 //                                         vm.selectedProjectHaveCourtHearing = false;
 //                                     }
 //                                 })
 //                             }
 //                         });

 //                         // se face watch pe commodity_activity_id pentru a calcula inputurile de ore
 //                         $scope.$watch("vm.newResult.commodity_activity_id", function (value) {
 //                             if (value && $rootScope.lookup && $rootScope.lookup.commodity_activity) {
 //                                 var commodity_activity = $rootScope.lookup.commodity_activity.all_commodity_activity;
 //                                 vm.newResult.input_effort = commodity_activity.effort / 60;
 //                                 vm.newResult.blb_effort = commodity_activity.effort / 60;
 //                                 vm.newResult.comments = commodity_activity.description;
 //                                 vm.checkHoursFormat(false);
 //                             }
 //                         });

 //                         var REST = resource.init(vm.entity);

 //                         function quickAddCustomer() {
 //                             var modalInstanceContact = $uibModal.open({
 //                                 animation: true,
 //                                 component: 'quickAddEntity',
 //                                 resolve: {
 //                                     entity: function () {
 //                                         return $rootScope.quickAddEntityTemplate = 'customer';
 //                                     }
 //                                 }
 //                             }).result.then(function (result) {
 //                                 vm.newResult.customer_id = result.customer_id;
 //                                 vm.newResult.customer = {
 //                                     customer_name: result.customer_name
 //                                 }
 //                             });
 //                         }

 //                         if (data.result) {
 //                             vm.newResult = {
 //                                 date: data.result.date
 //                             };

 //                             REST.get({
 //                                 id: data.result.timesheet_id
 //                             }).then(function (res) {
 //                                 if (duplicate) {
 //                                     delete res.blb_effort;
 //                                     delete res.dnb_effort;
 //                                     delete res.nb_effort;
 //                                     delete res.nc_effort;
 //                                     delete res.timesheet_id;
 //                                     delete res.invoice_id;
 //                                     delete res.timesheet_ext.timesheet_ext_id;
 //                                 }

 //                                 if (duplicate && res.commodity_activity_id == null) {
 //                                     delete res.input_effort;
 //                                 }

 //                                 vm.newResult = res;
 //                                if (res.is_internal_hours == undefined && res.internal_project) vm.newResult.is_internal_hours = true;
 //                                if (res.is_internal_hours == undefined && !res.internal_project) vm.newResult.is_internal_hours = false;
 //                                 if (duplicate) {
 //                                     $index = data.index = null;
 //                                     vm.newResult.source_timesheet_id = vm.newResult.timesheet_id;
 //                                     vm.newResult.status_id = 1;
 //                                 }
 //                                 vm.newResult.original_project_id = vm.newResult.project_id;
 //                                 vm.newResult.original_reference_id = vm.newResult.reference_id;
 //                                 //make fields read-only if timesheet status is not draft
 //                                 if (parseInt(vm.newResult.status_id) != 1) {
 //                                     $('.timesheet-edit select').prop('disabled', true);
 //                                     $('.timesheet-edit input').prop('disabled', true);
 //                                     $('.timesheet-edit textarea').prop('disabled', true);
 //                                     $('.timesheet-edit .btn-primary').css('display', 'none');
 //                                     $('.timesheet-edit').parent().find('.save-timesheet').css('display', 'none');
 //                                 }
 //                                 formatResultData(duplicate);
 //                             });
 //                         } else {
 //                             vm.newResult = {!! \Helper::defaultObject("timesheet") !!};
 //                         }
 //                        vm.newResult.is_internal_hours = false;

 //                         if (activity) {
 //                             vm.newResult = activity;
 //                             if (activity.meeting_room_id) {
 //                                 var diff = new Date(activity.end_date - activity.start_date);
 //                                 vm.newResult.input_effort = moment.utc(diff).format('HH:mm');
 //                                 vm.newResult.blb_effort = moment.utc(diff).format('HH:mm');
 //                             }
 //                         }

 //                         function formatResultData(duplicate) {
 //                             if (vm.newResult != null) {
 //                                 if (vm.newResult.customer_id == null) {
 //                                     vm.newResult.is_internal_hours = true;
 //                                 }
 //                                 vm.newResult.date = cmdate.fromServer(vm.newResult.date);
 //                             }

 //                             vm.errors = {};
 //                             if (!duplicate || vm.newResult.commodity_activity_id != null) {
 //                                 vm.checkHoursFormat(false);
 //                             }
 //                         }

 //                         var firstCheckHoursFormat = true;
 //                         vm.checkHoursFormat = function (use_industrial_minutes) {
 //                             if (typeof use_industrial_minutes == "undefined") {
 //                                 use_industrial_minutes = {{ (Auth::user()->tenant->tenantExt->use_industrial_minutes) ? "true" : "false" }};
 //                             }


 //                             if (duplicate && firstCheckHoursFormat) {
 //                                 firstCheckHoursFormat = false;
 //                                 if (vm.newResult.commodity_activity_id == null) {
 //                                     // la duplicate, lasam impresia ca input = blb, dar se modifica pe la spate in functie de tipul referintei (NB/NC/BLB)
 //                                     //vm.newResult.blb_effort = 0;
 //                                 }
 //                             }
 //                             // permite timesheet billable sa fie 0 si sa fie trecut ca si input in momentul completarii input-ului
 //                             if (typeof vm.newResult.blb_effort == "undefined") {
 //                                 vm.newResult.blb_effort = vm.newResult.input_effort;
 //                             }
 //                             vm.newResult.input_effort = $filter("timesheetHoursFormat")(vm.newResult.input_effort, use_industrial_minutes);
 //                             vm.newResult.dnb_effort = $filter("timesheetHoursFormat")(vm.newResult.dnb_effort, use_industrial_minutes);
 //                             vm.newResult.nb_effort = $filter("timesheetHoursFormat")(vm.newResult.nb_effort, use_industrial_minutes);
 //                             vm.newResult.nc_effort = $filter("timesheetHoursFormat")(vm.newResult.nc_effort, use_industrial_minutes);
 //                             vm.newResult.blb_effort = $filter("timesheetHoursFormat")(vm.newResult.blb_effort, use_industrial_minutes);
 //                         }

 //                         var REST = resource.init(vm.entity);

 //                         vm.action = {
 //                             save: save,
 //                             cancel: cancel,
 //                         };

 //                         vm.default_timesheet_type = '';
 //                         $scope.$watch("lookup.project.all_reference_for_timesheet.default_timesheet_type", function (value) {
 //                             vm.default_timesheet_type = value;

 //                         });

 //                         function save() {
 //                             var timesheet_practice_validate =  {{ $timesheet_param }};
 //                             if (vm.selectedProjectIsCommodity && !vm.newResult.commodity_activity_id) {
 //                                 $('[ng-model="vm.newResult.commodity_activity_id"] .select2-selection').css("border", "1px solid red");
 //                                 return false;
 //                             }

 //                             if (!vm.newResult.input_effort) {
 //                                 $('[ng-model="vm.newResult.input_effort"]').css("border", "1px solid red");
 //                                 return false;
 //                             }

 //                             var s1 = vm.newResult.input_effort.split(":")[0];
 //                             if (s1 > 24 || vm.newResult.input_effort == "00:00") {
 //                                 $('[ng-model="vm.newResult.input_effort"]').css("border", "1px solid red");
 //                                 return false;
 //                             }

 //                             if (!vm.newResult.is_internal_hours) {
 //                                 if (!vm.newResult.customer_id) {
 //                                     $('[ng-model="vm.newResult.customer_id"] .select2-selection').css("border", "1px solid red");
 //                                     return false;
 //                                 }
 //                                 if (!vm.newResult.project_id) {
 //                                     $('[ng-model="vm.newResult.project_id"] .select2-selection').css("border", "1px solid red");
 //                                     return false;
 //                                 }
 //                                 if (!vm.newResult.reference_id) {
 //                                     $('[ng-model="vm.newResult.reference_id"] .select2-selection').css("border", "1px solid red");
 //                                     return false;
 //                                 }

 //                                 if (!vm.newResult.practice_id && timesheet_practice_validate == 1) {
 //                                     $('[ng-model="vm.newResult.practice_id"] .select2-selection').css("border", "1px solid red");
 //                                     return false;
 //                                 }
 //                             }


 //                             if (validator.isValid(vm.entity) && !vm.dateToOld) {
 //                                 vm.postData = angular.copy(vm.newResult);
 //                                 if (vm.newResult.is_internal_hours) {
 //                                     vm.postData.input_effort = humanTotalEffortToMinutes(vm.postData.input_effort);
 //                                     vm.postData.dnb_effort = vm.postData.input_effort;
 //                                     vm.postData.nb_effort = 0;
 //                                 } else {
 //                                     var temp_input_effort = angular.copy(vm.postData.input_effort);
 //                                     vm.postData.input_effort = humanTotalEffortToMinutes(vm.postData.input_effort);
 //                                     if ((vm.default_timesheet_type == "BLB") || (!vm.default_timesheet_type)) {
 //                                         vm.postData.blb_effort = humanTotalEffortToMinutes(vm.postData.blb_effort, true);
 //                                         vm.postData.nb_effort = humanTotalEffortToMinutes(temp_input_effort, true) - vm.postData.blb_effort;
 //                                         if (vm.postData.nb_effort < 0) {
 //                                             vm.postData.nb_effort = 0;
 //                                         }
 //                                         vm.postData.dnb_effort = 0;
 //                                         vm.postData.nc_effort = 0;
 //                                     } else if (vm.default_timesheet_type == "NB") {
 //                                         vm.postData.nb_effort = humanTotalEffortToMinutes(vm.newResult.blb_effort, true);
 //                                         vm.postData.blb_effort = 0;
 //                                     } else if (vm.default_timesheet_type == "NC") {
 //                                         vm.postData.nc_effort = humanTotalEffortToMinutes(vm.newResult.blb_effort, true);
 //                                         vm.postData.blb_effort = 0;
 //                                     } else if (vm.default_timesheet_type == "DNB") {
 //                                         vm.postData.dnb_effort = humanTotalEffortToMinutes(vm.newResult.blb_effort, true);
 //                                         vm.postData.blb_effort = 0;
 //                                     }
 //                                 }

 //                                 vm.postData.total_effort = vm.postData.dnb_effort + vm.postData.nb_effort + vm.postData.nc_effort + vm.postData.blb_effort;

 //                                 vm.postData.status_id = 1; // INIT WITH DRAFT
 //                                 vm.postData.date = cmdate.toServer(vm.postData.date);
 //                                 // if is internal hours
 //                                 if (vm.postData.is_internal_hours) {
 //                                     vm.postData.customer_id = null;
 //                                     vm.postData.project_id = null;
 //                                     vm.postData.reference_id = null;
 //                                 } else {
 //                                     vm.postData.internal_project_id = null;
 //                                 }
 //                                 if (!vm.postData.timesheet_ext) {
 //                                     vm.postData.timesheet_ext = {
 //                                         timesheet_id: null
 //                                     }
 //                                 }

 //                                 if (data.index != null) {
 //                                     REST.update({
 //                                         id: data.result[vm.primaryKey],
 //                                         data: vm.postData
 //                                     }).then(function (response) {
 //                                         $uibModalInstance.close({response: response, index: data.index});
 //                                     });
 //                                 } else {
 //                                     // only if new timesheet
 //                                     vm.postData.invoice_user_id = vm.postData.user_id;
 //                                     // save
 //                                     REST.create({
 //                                         data: vm.postData
 //                                     }).then(function (response) {
 //                                         $uibModalInstance.close({response: response, index: -1});
 //                                     });
 //                                 }
 //                             }
 //                         }

 //                         function humanTotalEffortToMinutes(hrs, round) {
 //                             var out = 0;
 //                             if (hrs.charAt(2) != ':') {
 //                                 out = hrs * 60;
 //                             } else {
 //                                 var hours = parseInt(hrs.substring(0, 2), 10);
 //                                 var minutes = parseInt(hrs.substring(3, 5), 10);
 //                                 out = hours * 60 + minutes;
 //                             }
 //                             if (round) {
 //                                 out = (Math.round((out / 60) * 10) / 10) * 60;
 //                             }
 //                             return out;
 //                         }

 //                         function cancel() {
 //                             $uibModalInstance.dismiss('cancel');
 //                         };


 //                         $scope.$watch("lookup.project.all_project_with_customer", function (value) {
 //                             if (value) {
 //                                 vm.newResult.customer_id = value.customer_id;
 //                                 vm.newResult.customer = {
 //                                     customer_name: value.customer_name
 //                                 }
 //                             }
 //                         });

 //                         $scope.$watch("lookup.user.all_user", function (value) {
 //                             if (value) {
 //                                 if (vm.newResult.user && (typeof vm.newResult.user != undefined)) {
 //                                     vm.newResult.user.add_timesheet_no_constraints = value.add_timesheet_no_constraints;
 //                                 }
 //                             }
 //                         });

 //                         $scope.$watch('vm.newResult.date', function (newValues) {
 //                             vm.message = "";

 //                             vm.today = new Date().getTime();
 //                             vm.date = cmdate.fromServer(cmdate.toServer(newValues)).valueOf();
 //                             if (vm.today < vm.date) {
 //                                 vm.message = '{{ trans('LANG.TIMESHEET_WARNING_MESSAGE') }}';
 //                             }

 //                               @if(!$guard->checkGroup("ADMIN") && !$guard->checkGroup("BILLING") && !$guard->checkGroup("CFO"))
 //                             var d = new Date();
 //                             vm.minDate = d.setDate(d.getDate() -{{ Auth::user()->tenant->tenantExt->timesheet_allowed_past_days }});
 //                             if (vm.minDate > vm.date && !vm.newResult.user.add_timesheet_no_constraints) {
 //                                 vm.dateToOld = true;
 //                                 vm.message = '{{ trans('LANG.TIMESHEET_DATE_CONSTRAINT') }} ' + moment(new Date(vm.minDate)).format('D MMM Y');
 //                             } else {
 //                                 vm.dateToOld = false;
 //                             }
 //                       @endif
 //                         });
 //                     },
 //                     controllerAs: 'vm',
 //                     size: 'md',
 //                     resolve: {
 //                         data: function () {
 //                             return {
 //                                 index: $index,
 //                                 result: ($index != null) ? vm.result[$index] : null,
 //                                 message: vm.message
 //                             };
 //                         }
 //                     },
 //                     backdrop: 'static',
 //                     keyboard: false
 //                 });

 //                 modalInstance.result.then(function (data) {
 //                     vm.inEdit = false;
 //                     window.location.reload();
 //                 });
 //                };

	return vm;
});

