(function () {
	'use strict';
	angular.module('kv').directive('infiniteScroll', infiniteScroll);

	function infiniteScroll() {
		return function (scope, elm, attr) {
			const scrollHandler = function () {
					var diff = ($(document).height() - $(window).height()) - $(window).scrollTop();
					if (diff < 100) {
						scope.$apply(attr.infiniteScroll);
					}
				};
			const infiniteScrollContainer = $('.infinite-scroll-container');
			if (infiniteScrollContainer.length)infiniteScrollContainer.scroll(function () {
					var diff = (infiniteScrollContainer.find("table").height() - infiniteScrollContainer.height()) - infiniteScrollContainer.scrollTop();
					if (diff < 100) {
						scope.$apply(attr.infiniteScroll);
					}
				});
			$(window).scroll(scrollHandler);
			const modal = $('.modal');
			if (modal.length) modal.scroll(scrollHandler);

		};
	}
})();
