kv.controller('expenseCategoryListCtrl', function ($element, $attrs, $scope, $injector) {
	return new dictionaryListCtrl($element, $attrs, $scope, $injector, {
		primaryKey: "expense_category_id",
		entity: "expense_category",
		modal: {
			templateUrl: "expense_category-edit",
			controller: "expenseCategoryEditCtrl"
		}
	});
});
