kv.controller('quickInvoiceAddCtrl', function ($uibModalInstance, params, bladeParams, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'invoice', 'invoice_id', $scope, params);

    vm.isNewRecord = true;
    // init data
    vm.data = {
        customer: {
            // valori default pentru campurile de pe client
            create_customer_name: null,
            vat_percent: bladeParams.vatType
        },
        project: {
            // valori default pentru campurile de pe proiect
            currency_id: bladeParams.legalCurrency,
            currency_name: bladeParams.legalCurrency,
            invoice_currency_id: bladeParams.legalCurrency,
            invoice_currency_name: bladeParams.legalCurrency,
        },
    };

    // aduce datele clientului selectat
    $scope.$watch('vm.data.customer.customer_name', function (value) {
        // daca exista clientul, aduce datele din repository prin apel get
        if (value) {
            vm.rest.get({
                url: "customer/" + vm.data.customer.customer_id,
                params: {
                    quickInvoiceCustomer: true
                }
            }).then(function (data) {
                // daca avem client, completeaza celelalte campuri cu datele sale si le face readonly
                if (vm.data.customer.customer_id) {
                    vm.data.customer.create_customer_name = data.customer_name;
                    $("input[name*='customer_name']").attr('disabled', true);

                    vm.data.customer.unique_id = data.unique_id;
                    $("input[name*='customer_unique_id']").attr('disabled', true);

                    vm.data.customer.primary_address = data.primary_address;
                    $("input[name*='primary_address']").attr('disabled', true);

                    vm.data.customer.vat_percent = data.vat_percent;
                    $("input[name*='vat_percent']").attr('disabled', true);

                    vm.data.customer.primary_country_id = data.primary_country_id;
                    vm.data.customer.primary_country_name = data.country_name;
                    $("select[name*='country']").attr('disabled', true);
                }
            });
        } else {
            // altfel, revine la valorile initiale
            vm.data.customer.vat_percent = bladeParams.vatType;
            vm.data.customer.create_customer_name = null;
            $("input[name*='customer_name']").attr('disabled', false);
            $("input[name*='customer_name']").val('');
            $("input[name*='unique_id']").attr('disabled', false);
            $("input[name*='unique_id']").val('');
            $("input[name*='vat_percent']").attr('disabled', false);
            $("input[name*='vat_percent']").val('');
            $("input[name*='primary_address']").attr('disabled', false);
            $("input[name*='primary_address']").val('');
            $("input[name*='country']").attr('disabled', false);
            $("input[name*='country']").val('');
        }
    });

    // aduce datele proiectului selectat
    $scope.$watch('vm.data.project.project_name', function (value) {
        // daca exista proiectul, aduce datele din repository prin get
        if (value) {
            vm.rest.get({
                url: "project/" + vm.data.project.project_id,
                params: {
                    quickInvoiceProject: true
                }
            }).then(function (data) {
                // daca avem proiect, completeaza celelalte campuri cu datele sale si le face readonly
                if (vm.data.project.project_id) {
                    vm.data.project.create_project_name = data.project_name;
                    $("input[name*='project_name']").attr('disabled', true);

                    vm.data.project.currency_id = data.currency;
                    vm.data.project.currency_name = data.currency;
                    $("select[name*='currency']").attr('disabled', true);

                    vm.data.project.invoice_currency_id = data.project_ext.use_billing_config_from_customer_fiscal_entity === 0  ? data.project_ext.force_invoice_currency : data.customer_fiscal_entity.customer_fiscal_entity_ext.invoice_currency;
                    vm.data.project.invoice_currency_name = data.project_ext.use_billing_config_from_customer_fiscal_entity === 0 ? data.project_ext.force_invoice_currency : data.customer_fiscal_entity.customer_fiscal_entity_ext.invoice_currency;
                    $("select[name*='invoice_currency']").attr('disabled', true);
                }
            });
        } else {
            // altfel, revine la valorile initiale
            vm.data.project.currency_id = bladeParams.legalCurrency;
            vm.data.project.currency_name = bladeParams.legalCurrency;
            vm.data.project.invoice_currency_id = bladeParams.legalCurrency;
            vm.data.project.invoice_currency_name = bladeParams.legalCurrency;
            vm.data.project.create_project_name = null;
            $("input[name*='project_name']").attr('disabled', false);
            $("input[name*='project_name']").val('');
            $("select[name*='currency']").attr('disabled', false);
            $("select[name*='invoice_currency']").attr('disabled', false);
        }
    });

    // check if customer already exists
    vm.checkCustomer = function (customer_name) {
        var REST = resource.init('customer');
        let error = false;

        // if is set customer_id (existent customer), set error to true,
        if (!vm.data.customer.customer_id) {
            // check if field was filled with data
            if (vm.data.customer.create_customer_name && vm.data.customer.create_customer_name.length) {
                error = false;
            } else {
                error = true;
                vm.customer_exists = false;
                vm.customer_is_valid = false;
            }
        } else {
            vm.existent_customer = true;
        }

        // if field is empty or an existent customer was selected, skip, else make request
        if (!error || !vm.existent_customer) {
            // variable for display success alert
            vm.number_is_valid = false;
            REST.list({
                params: {
                    customer_name: customer_name,
                    exact_match: true
                }
            }).then(function (res) {
                if (res.data.length) {
                    vm.customer_exists = true;
                    vm.customer_is_valid = false;
                } else {
                    vm.customer_exists = false;
                    vm.customer_is_valid = true;
                }
            });
        }
    };

    vm.prepareDataToSave = function() {
        let postData = angular.copy(vm.data);
        vm.currencyError = false;
        vm.currencyName = null;
        let errorCustomer = false;
        let errorProject = false;
        let errorCurrency = false;
        let errorInvoiceCurrency = false;

        // validari pentru campurile mandatory
        if (postData.customer.create_customer_name && postData.customer.create_customer_name.length) {
            vm.checkCustomer(postData.customer.create_customer_name);
            errorCustomer = false;
            $('[ng-model="vm.data.customer.create_customer_name"] input, [ng-model="vm.data.customer.create_customer_name"]').css("border", "1px solid #c2cad8");
        } else {
            errorCustomer = true;
            $('[ng-model="vm.data.customer.create_customer_name"] input, [ng-model="vm.data.customer.create_customer_name"]').css("border", "1px solid red");
        }

        if (postData.project.create_project_name && postData.project.create_project_name.length) {
            errorProject = false;
            $('[ng-model="vm.data.project.create_project_name"] input, [ng-model="vm.data.project.create_project_name"]').css("border", "1px solid #c2cad8");
        } else {
            errorProject = true;
            $('[ng-model="vm.data.project.create_project_name"] input, [ng-model="vm.data.project.create_project_name"]').css("border", "1px solid red");
        }

        if (postData.project.currency_id && postData.project.currency_name) {
            errorCurrency = false;
            $('[ng-model="vm.data.project.currency_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
        } else {
            errorCurrency = true;
            $('[ng-model="vm.data.project.currency_id"] .select2-selection--single').css("border", "1px solid red");
        }

        if (postData.project.invoice_currency_id && postData.project.invoice_currency_name) {
            errorInvoiceCurrency = false;
            $('[ng-model="vm.data.project.invoice_currency_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
        } else {
            errorInvoiceCurrency = true;
            $('[ng-model="vm.data.project.invoice_currency_id"] .select2-selection--single').css("border", "1px solid red");
        }
        // end validari

        // daca toate campurile sunt completate, cauta curs valutar in ziua curenta pentru valuta de facturare selectata
        if (!errorCustomer && !errorProject && !errorCurrency && !errorInvoiceCurrency && ((!vm.customer_exists && vm.customer_is_valid) || vm.existent_customer)) {
            var exchangeRateRest = resource.init("exchange_rate");
            exchangeRateRest.getArray({
                url: 'filter/exchange_rate/get_daily_currency_rate',
                params: {
                    date: bladeParams.currentDate,
                    currency: postData.project.invoice_currency_id
                },
            }).then(function (data) {
                // daca avem curs sau valuta este RON, creeaza factura si redirectioneaza utilizatorul catre pagina de editare a noii facturi
                if (data[0] || postData.project.invoice_currency_id == "RON") {
                    vm.rest.create({
                        url: "invoice/quick_invoice",
                        data: vm.data
                    }).then(function (data) {
                        window.location = "/invoice/" + data.invoice.invoice_id + "/edit";
                    });
                } else {
                    // altfel, seteaza eroare pentru valuta respectiva
                    vm.currencyError = true;
                    vm.currencyName = postData.project.invoice_currency_id;
                }
            });
        }
    };

	return vm;
});
