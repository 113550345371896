kv.controller('sysSecurityGroupEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $timeout, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'security_group_id';
    vm.entity = 'security_group';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');

    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];


    if (page_identifier == 'edit') {
        $http.get("/security_group/" + page_entity_id).then(function (response) {
            vm.data = response.data;
            console.log("data", vm.data);
        });
    } else {
        vm.data = {};
        vm.isNewRecord = true;
    }

    vm.saveChange = function (item, event) {
        if (event.target.tagName !== "INPUT") return;
        var REST = resource.init('sys_route_category/updateUserRouteCategory');
        // e necesar un ciclu digest pt. ca valoarea din checkbox sa se transmita in item;
        $timeout(function () {
            REST.get({
                params: {
                    tenant_id: API.user.tenant_id,
                    route_category_id: item.route_category_id,
                    group_id: page_entity_id,
                    read_access: item.read_access ? 1 : 0,
                    write_access: item.write_access ? 1 : 0

                }

            }).then(function (response) {
                if (!response.status) console.error(response.message);
                angular.extend(item, response.data);
            }).catch(console.error);
        });
        console.log("params", item);
    };

    //variable pt. butoane checkAll read/write access initial deselectat
    vm.checkAll = {
        values: {
            read_access: 0,
            write_access: 0
        },
        clickHandle: function (field, event) {
            if (event.target.tagName !== "INPUT") return;
            KApp.block($(event.target).closest('th'));
            var REST = resource.init('sys_route_category/updateUserRouteCategory');
            // e necesar un ciclu digest pt. ca valoarea din checkbox sa se transmita in item;

            $timeout(function () {
                REST.get({
                    params: {
                        tenant_id: API.user.tenant_id,
                        group_id: page_entity_id,
                        setAllTrue: field,
                        value: vm.checkAll.values[field] ? 1 : 0
                    }
                }).then(function (response) {
                    KApp.unblock($(event.target).closest('th'));
                    if (!response.status) console.error(response.message);
                    else vm.data.route_category = response.data;
                }).catch(console.error);
            });
        }
    };

    return vm;
});
