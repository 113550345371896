kv.controller('projectListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'project_id';
	vm.entity = 'project';

	vm.count = {
		total: 0,
		showing: 0
	};

	vm.getInfinitScroll({
		search: {
			enabled: "1",
		},
		params: {
			action: 'project'
		},
		url: "project",
		callback: function (response) {
			vm.countLines = 0;
			if (!response.data) return;
			vm.countLines = (response.data ? response.total : 0);
		}
	});


	vm.duplicate = function (entity) {
		vm.openModal({
			templateUrl: "duplicate-project",
			controller: 'duplicateProjectCtrl',
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				params: function () {
					if(!entity) return {"entity": {"project_id": null}};

					if (entity.project_manager) {
						entity.project_manager.forEach( function( index ) {
							if( !entity.project_manager[index].id ) {
								entity.project_manager[index].id = entity.project_manager[index].user_id;
							}
						})
					}
					if (entity.project_team) {
						entity.project_team.forEach( function( index ) {
							if( !entity.project_team[index].id ) {
								entity.project_team[index].id = entity.project_team[index].user_id;
							}
						})
					}
					if (entity.project_external_user) {
						entity.project_external_user.forEach( function( index ) {
							if( !entity.project_external_user[index].id ) {
								entity.project_external_user[index].id = entity.project_external_user[index].user_id;
							}
						})
					}
					return {"entity": entity};
				},
				saveCallback: function () {
					return function(response){
						vm.infinitScroll.search.refresh++;
						if(response){
							toastr.success(vm.trans('PROJECT_DUPLICATED_WITH_SUCCESS'));
						}else{
							toastr.error(vm.trans('PROJECT_WAS_NOT_DUPLICATED'));
						}
						response.modal.close();
					};
				}
			}
		});
	};

	vm.moveEntities = function (type_id) {
		vm.openModal({
			templateUrl: 'project-list-move',
			controller: ProjectListMoveCtrl,
			size: 'md',
			resolve: {
				data: function () {
					return {
						searchList: vm.infinitScroll.search,
						countLines: vm.countLines,
						type: type_id
					};
				},
			},
			backdrop: 'static',
			keyboard: false
		}).then(response => {
			if (response == "ok") vm.infinitScroll.search.refresh++;
			if(response){
				toastr.success(vm.trans('SUCCESS'));
			}else{
				toastr.error(vm.trans('ERROR_WHILE_SAVING'));
			}

		});
	};

	vm.disableProjects = function () {
		vm.confirm( "Attention! This action will close "  + ' ' + vm.countLines + " projects!", vm.trans("LANG.CONFIRM_THE_CHANGES")).then(response => {
			if (response !== "ok") return;
			let postData = angular.copy(vm.infinitScroll.search);
			KApp.block('.modal-content');
			vm.rest.getArray({
				url: vm.entity + '/bulk/disableProjects?' + $filter("objToParams")(vm.infinitScroll.search),
				data: postData
			}).then(function (data) {
				KApp.unblock('.modal-content');
				if(data.error && data.error.error_code === 1){
					toastr.error(data.error.message);
				}else{
					vm.infinitScroll.search.refresh++;
					//window.location.reload();
				}
				});
			});
	};

	return vm;
});

