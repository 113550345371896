(function () {
	'use strict';

	angular
		.module('kv')
		.directive('loading', loading)
		.directive('loader', loader)
		.directive('loadingWidget', loadingWidget);

	function loading($rootScope, $compile) {
		return {
			restrict: "A",
			binding: {
				isLoading: "&"
			},
			link: function (scope, element, attributes, controller) {
				element.after(
					$compile(
						'<div ng-show="loading" class="loading-spinner" style="text-align: center">' +
						'<div class="k-spinner k-spinner--md k-spinner--info"></div>' +
						'</div>'
					)(scope));
				scope.$watch(
					function () {
						return $rootScope.xhr; // index.run.js
					},
					function () {
						scope.isLoading = $rootScope.xhr; // xhr is boolean
						if ($rootScope.xhr) {
							element.addClass('loading-element');
						} else {
							element.removeClass('loading-element');
						}
					}
				);

				scope.displayNoResultMessage = true;
				scope.$watch('isLoading', function (value) {
					if (typeof value !== "undefined") {
						if (!value) {
							scope.displayNoResultMessage = true;
						} else {
							scope.displayNoResultMessage = false;
						}
					}
				});
			}
		};
	}

	function loadingWidget($rootScope, $compile) {
		return {
			restrict: "A",
			scope: {
				loadingWidget: "="
			},
			link: function (scope, element, attributes, controller) {
				element.prepend(
					$compile(
						'<div ng-show="isLoading" class="text-center" style="padding: 50px 15px">' +
						'<i class="fa fa-spinner fa-spin fa-2x fa-fw"></i><span class="sr-only">Loading...</span>' +
						'</div>'
					)(scope));
				scope.$watch('loadingWidget', function () {
					scope.isLoading = scope.loadingWidget;
					if (scope.loadingWidget) {
						$(element).find('.chart').hide();
					} else {
						$(element).find('.chart').show();
					}
				});
			}
		};
	}

	function loader($rootScope, $compile) {
		return {
			restrict: "A",
			scope: {
				loader: "&"
			},
			link: function (scope, element, attributes, controller) {
				element.after(
					$compile(
						'<div ng-show="loader()" class="loader-spinner" style="text-align: center">' +
						'<div class="k-spinner k-spinner--md k-spinner--info"></div>' +
						'</div>'
					)(scope));

			}
		};
	}

})();
