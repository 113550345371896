class DashboardWidgetListCtrl extends DashboardWidgetCtrl {
	constructor($element, $attrs, $scope, $injector, callBack) {
		super($element, $attrs, $scope, $injector, function (response) {
			// console.log('response', response);
			this.data = {initial: [], filtered: []};
			this.error[1] = undefined;

			if (response) {
				if (response.data) {
					this.data.initial = response.data;
					this.changeFilter();
				} else {
					this.data.initial = response;
					this.changeFilter();
				}

				if (callBack) callBack();
			} else {
				this.error[1] = "Error retrieving data!";
			}
			this.loading[1] = false;
		});

	}

	changeFilter(filter) {}
}
