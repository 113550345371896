kv.controller('legalCaseTeamEditCtrl', function ($uibModalInstance, data, $injector, $scope) {
	return new dictionaryEditCtrl($uibModalInstance, data, $injector, $scope, {
		entity: "legal_case_team",
		primaryKey: "legal_case_team_id",
		newEntity:{
		},
		prepareDataToSave: function () {
			let cmdate = this.getInjection('cmdate');
			let dataToSend = angular.copy(this.data);
			dataToSend.date = cmdate.toServer(this.data.date);
			return dataToSend;
		}
	});
});