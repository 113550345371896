kv.controller('BudgetPerformanceAnalysisReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
   // var data = JSON.parse(vm.bladeParams.data);
    var params = kvUtils.getAllQueryParams();

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
            "columnDefs": [ {
                "targets": 1,
                "orderable": false
            } ]
        });
        $('.dataTables_filter input[type="search"]').
        attr('placeholder','Search in table...').
        removeClass('input-small').
        css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').
        addClass('pull-right').addClass('no-print');
    });

    vm.isLoading = true;

    return vm;
});
