kv.controller('UserLegalCasesReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    let initParams = kvUtils.getAllQueryParams();
    
    if (initParams) {
        if (initParams.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }
       
    vm.drillDown = function(group_by, agg_id, agg_name = null) {
        let params = angular.copy(initParams);
        params[params.group_by + "_id"] = agg_id;
        params[params.group_by] = agg_name;
        params.group_by = group_by;

        let url = window.location.origin + window.location.pathname + "?" + $filter("objToParams")(params);
        window.open(url);
    }

    function setOrderBy() {
        var orderBy = $('[aria-sort="ascending"], [aria-sort="descending"]').attr("data-order-by");
        var direction = $('[aria-sort="ascending"], [aria-sort="descending"]').attr('aria-sort');
        $("#print").attr("href", $("#print").attr("href") + "&orderBy=" + orderBy + "&direction=" + direction);
    }

    return vm;
});
