kv.factory("$calendarSchedulerEditEvent", function ($uibModal) {
	return function (type, calendarEvent, start, end, e, room, deleteEvent, saveCallback) {
		let startDate = new dateClass(start);
		let endDate = new dateClass(end);
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			windowClass: 'show',
			backdropClass: 'show',
			templateUrl: 'calendar-scheduler-edit-event',
			controller: 'calendarSchedulerEditEventCtrl',
			controllerAs: 'vm',
			size: 'lg',
			resolve: {
				type: function () {
					return type;
				},
				calendarEvent: function () {
					return calendarEvent;
				},
				start: function () {
					return startDate;
				},
				end: function () {
					return endDate;
				},
				activity_id: function () {
					return null;
				},
				date: function () {
					return new Date();
				},
				room: function () {
					return room;
				},
				deleteEvent: function () {
					return deleteEvent;
				},
				saveCallback: function () {
					return saveCallback;
				}
			}
		});
		// modalInstance.result.then(saveCallback);
		return modalInstance;

	};
});
