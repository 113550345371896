kv.controller('TimesheetEditCtrl', function ($uibModalInstance, data, duplicate, activity, params, read_only, timesheetListCtrl, resource, $filter, cmdate, $uibModal, $rootScope, $scope, $injector) {
	if ($rootScope.lookup && $rootScope.lookup.commodity_activity) delete $rootScope.lookup.commodity_activity;

	let vm = new modalCtrl($uibModalInstance, $injector, 'timesheet', 'timesheet_id', $scope, params, 'timesheet-edit-params');
	vm.timesheetListCtrl = timesheetListCtrl;
	vm.isNewRecord = data.result == null || duplicate;
	vm.hasLedes = false;
	vm.hasCustomerReference = false;
	vm.customerLanguage = vm.API.user.language.toUpperCase();
	vm.primaryKey = 'timesheet_id';
	vm.entity = 'timesheet';
	vm.dataHasLoaded = false;
	vm.suggestions = [];
	vm.timesheetTemplates = [];
	vm.showSuggestions = false;
	vm.defaultTimesheet = data.defaultObject;

	// ceva temporar
	vm.API.USE_INDUSTRIAL_MINUTES = 0;

	vm.modal.rendered.then(() => {
		vm.modalData = {
			form_action: params.form_action,
			form_location: params.form_location
		};
		if (activity) {
			vm.data = activity;
			vm.customerLanguage = activity.customerLanguage ? activity.customerLanguage : vm.API.user.language.toUpperCase();
			if (activity.meeting_room_id) {
				let diff = new Date(activity.end_date - activity.start_date);
				vm.data.input_effort = moment.utc(diff).format('HH:mm');
				vm.data.blb_effort = moment.utc(diff).format('HH:mm');
			}
		}else if (!data.result) {
			let emptyTimesheet = vm.defaultTimesheet;
			emptyTimesheet.is_internal_hours = false;
			vm.data = emptyTimesheet;
			vm.data.input_effort = vm.API.USE_INDUSTRIAL_MINUTES ? 0 : "00:00";
			vm.data.blb_effort = vm.API.USE_INDUSTRIAL_MINUTES ? 0 : "00:00";
			if(!params.calendar_date){
				if(!vm.data.date){
					let currentDate = new Date();
					let day = currentDate.getDate();
					let month = currentDate.getMonth() + 1;
					let year = currentDate.getFullYear();
					vm.data.date = year + '-' + (month < 10 ?  '0' + month : month) + '-' + (day < 10 ? '0' + day : day)+ ' ' + vm.API.START_DAY_AT;
				}
			}else{
				vm.data.date = params.calendar_date;
			}
		} else {
			if (!vm.dataHasLoaded) KApp.block('.modal-content');
			vm.data = {
				date: data.result.date
			};
			vm.rest.get({
				id: data.result.timesheet_id
			}).then(function (res) {
				let customCanUpdate = customUserCanDeleteOrUpdate(res);
				vm.readonly = ( res.invoice_id !== null || !customCanUpdate);
				vm.customerLanguage = (res.project_id ? res.project.customer_fiscal_entity.customer_fiscal_entity_ext.communication_language : null);

				if(res.project && res.project.customer_fiscal_entity && res.project.customer_fiscal_entity.customer_fiscal_entity_ext.use_electronic_billing === true){
					vm.hasLedes = true;
				}
				if(res.project && res.project.customer_fiscal_entity && res.project.customer_fiscal_entity.customer_fiscal_entity_ext.request_customer_reference_on_timesheet === true){
					vm.hasCustomerReference = true;
				}
				//daca timpul este duplicat read_only = false
				if(read_only == false) {
					vm.readonly = false;
				}

				if (duplicate) {
					res.blb_effort = 0;
					res.dnb_effort = 0;
					res.nb_effort = 0;
					res.nc_effort = 0;
					delete res.timesheet_id;
					delete res.invoice_id;
					delete res.timesheet_ext.timesheet_ext_id;
				}

				if (duplicate && res.commodity_activity_id == null) {
					res.input_effort = 0;
				}

				// divide input minutes by 60
				vm.data = kvUtils.transformEffortsInHours(res);
				if (duplicate) {
					vm.data.input_effort = vm.API.USE_INDUSTRIAL_MINUTES ? 0 : "00:00";
					vm.data.blb_effort = vm.API.USE_INDUSTRIAL_MINUTES ? 0 : "00:00";
				}
				if (res.is_internal_hours == undefined && res.internal_project) vm.data.is_internal_hours = true;
				if (res.is_internal_hours == undefined && !res.internal_project) vm.data.is_internal_hours = false;
				if (duplicate) {
					vm.params.index = -1;
					vm.data.source_timesheet_id = vm.data.timesheet_id;
					vm.data.status_id = 1;
				}
				vm.data.original_project_id = vm.data.project_id;
				vm.data.original_reference_id = vm.data.reference_id;

				//make fields read-only if timesheet status is not draft
				if (parseInt(vm.data.status_id) !== 1 && parseInt(vm.bladeParams.canEdit) !== 1) {
					$('.timesheet-edit select').prop('disabled', true);
					$('.timesheet-edit input').prop('disabled', true);
					$('.timesheet-edit textarea').prop('disabled', true);
					$('.timesheet-edit .btn-primary').css('display', 'none');
					$('.timesheet-edit').parent().find('.save-timesheet').css('display', 'none');
					vm.readonly = true;
				}
				formatResultData(duplicate);

				vm.dataHasLoaded = true;
				KApp.unblock('.modal-content');
			});
		}
		vm.updateBlbEffort = function () {
			if (vm.isNewRecord) vm.data.blb_effort = vm.data.input_effort;
		};
		// in momentul in care se schimba valoarea din project_id sau reference_id se seteaza vm.selectedProjectHaveCourtHearing
		// daca proiectul este legat de un dosar sau referinta
		// se sterge din variabila globala $rootScope.lookup.all_project_with_customer (daca nu, apare un bug la adaugare, in momentul in care project_id este null dar lookup-ul are informatii din vechea adaugare)
		$scope.$watch("vm.data.reference_id", function (value) {
			if (value) {
				vm.rest.getArray({
					url: vm.bladeParams.filterGet,
					params: {
						project_id: vm.data.project_id,
						reference_id: vm.data.reference_id,
					}
				}).then(function (data) {
					vm.selectedProjectHaveCourtHearing = data.length;
				});
			}
		});
		$scope.$watch('vm.data.date', function (newValues) {
			if (!newValues) return;
			vm.message = "";

			vm.today = new Date().getTime();
			vm.date = cmdate.fromServer(cmdate.toServer(newValues)).valueOf();
			if (vm.today < vm.date) {
				vm.message = $filter('trans')('LANG.TIMESHEET_WARNING_MESSAGE');
			}

			var d = new Date();
			// if set, use timesheet_allowed_past_days to limit timesheet entries
		
			// with customization
			if (vm.data && vm.data.timesheetAllowedPastDays) {
				timesheet_allowed_past_days = vm.data.timesheetAllowedPastDays;	
			} else {
				timesheet_allowed_past_days = vm.bladeParams.timesheetAllowedPastDays;
			}
			
			if (timesheet_allowed_past_days > 0) {
				vm.minDate = d.setDate(d.getDate() - timesheet_allowed_past_days);
			} else {
				// if not set, max 10 years behind
				vm.minDate = d.setDate(d.getDate() - 365 * 10);
			}

			if (vm.data && vm.data.user) {
				if (vm.minDate > vm.date && !vm.data.user.add_timesheet_no_constraints) {
					if (vm.bladeParams.hasRights) {
						vm.dateTooOld = false;
					} else {
						vm.dateTooOld = true;
						vm.message = vm.trans('LANG.TIMESHEET_DATE_CONSTRAINT') + ' ' + timesheet_allowed_past_days + ' ' + vm.trans('LANG.DAYS_IN_PAST_SMALL');
					}
				} else {
					vm.dateTooOld = false;
				}
			}
		});

		//	$('#comments').prop('disabled', true);
		/*$('#comments').maxlength({
			alwaysShow: true,
			warningClass: "label label-success",
			limitReachedClass: "label label-danger",
			separator: ' out of ',
			preText: 'You write ',
			postText: ' chars.',
			validate: true
		});*/

	});
// se foloseste in html pentru a afisa campul de activitati
	vm.selectedProjectIsCommodity = false;
	vm.loadTimesheetTemplates = function() {
		vm.rest.get({
			url: "timesheet/suggestions",
		}).then(function (response) {
				vm.timesheetTemplates = response.data;
			});
	};
	vm.loadTimesheetTemplates();

// in momentul in care se schimba valoarea din project_id se seteaza vm.selectedProjectIsCommodity
// in functie de valoarea campului is_commodity din project
// se sterge din variabila globala $rootScope.lookup.all_project_with_customer (daca nu, apare un bug la adaugare, in momentul in care project_id este null dar lookup-ul are informatii din vechea adaugare)
	$scope.$watch("vm.data.project_id", function (value) {
		if (value && $rootScope.lookup && $rootScope.lookup.project && $rootScope.lookup.project.all_project_with_customer) {
			vm.selectedProjectIsCommodity = $rootScope.lookup.project.all_project_with_customer.is_commodity;
			if($rootScope.lookup.project.all_project_with_customer.communication_language !== null) {
				vm.customerLanguage = $rootScope.lookup.project.all_project_with_customer.communication_language;
			}
			if($rootScope.lookup.project.all_project_with_customer.has_ledes === 1) {
				vm.hasLedes = true;
			}else{
				vm.hasLedes = false;
			}
			if($rootScope.lookup.project.all_project_with_customer.has_customer_reference === 1) {
				vm.hasCustomerReference = true;
			}else{
				vm.hasCustomerReference = false;
			}
			delete $rootScope.lookup.project.all_project_with_customer;
		}

// if project input changes get data to check if new selected project has ledes activity code
		vm.selectedProjectHaveCourtHearing = false;
	});

// se foloseste in html pentru a afisa campul de court hearing
	vm.selectedProjectHaveCourtHearing = false;

// se face watch pe commodity_activity_id pentru a calcula inputurile de ore
	$scope.$watch("vm.data.commodity_activity_id", function (value) {
		if (value && $rootScope.lookup && $rootScope.lookup.commodity_activity) {
			var commodity_activity = $rootScope.lookup.commodity_activity.all_commodity_activity;
			vm.data.activity_is_editable = commodity_activity.is_editable;
			vm.data.input_effort = commodity_activity.effort / 60;
			vm.data.blb_effort = commodity_activity.effort / 60;
			vm.data.comments = commodity_activity.description;
			timesheetListCtrl.checkHoursFormat(vm.data);
		}
	});

	$scope.$watch("vm.data.blb_effort", function (value) {
		if (value) {
			if(value > vm.data.input_effort){
				vm.blb_effort_higher_than_input_effort = true;
			}else{
				vm.blb_effort_higher_than_input_effort = false;
			}
		}
	});

	function formatResultData(duplicate) {
		if (vm.data !== null) {
			if (vm.data.customer_id == null) {
				vm.data.is_internal_hours = true;
			}
			// vm.data.date = cmdate.fromServer(vm.data.date);
		}

		if (!duplicate || vm.data.commodity_activity_id !== null) {
			timesheetListCtrl.checkHoursFormat(vm.data);
		}
	}


	vm.default_timesheet_type = '';
	$scope.$watch("lookup.project.all_reference_for_timesheet.default_timesheet_type", function (value) {
		vm.default_timesheet_type = value;
	});
	vm.prepareDataToSave = function (saveType) {
		postData = angular.copy(vm.data);
		postData.form_action = vm.modalData.form_action;
		postData.form_location = vm.modalData.form_location;
		postData.timesheetPracticeValidate = vm.bladeParams.timesheetPracticeValidate == "1";
		postData.selectedProjectIsCommodity = vm.selectedProjectIsCommodity;

		// transforma orele introduse in minute (ex. 02:00 -> 120)
		// if use_industrial = true -> round up to industrial minutes (12, 18, samd)
		postData.input_effort = kvUtils.humanTotalEffortToMinutes(postData.input_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.blb_effort = kvUtils.humanTotalEffortToMinutes(postData.blb_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.dnb_effort = kvUtils.humanTotalEffortToMinutes(postData.dnb_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.nb_effort = kvUtils.humanTotalEffortToMinutes(postData.nb_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.nc_effort = kvUtils.humanTotalEffortToMinutes(postData.nc_effort, vm.API.USE_INDUSTRIAL_MINUTES);
		postData.total_effort = kvUtils.humanTotalEffortToMinutes(postData.total_effort, vm.API.USE_INDUSTRIAL_MINUTES);

		// daca orele sunt interne, atunci pune DNB = input, iar celelalte pe 0
		if (vm.data.is_internal_hours) {
			postData.total_effort = postData.input_effort;
			postData.dnb_effort = postData.input_effort;
			postData.nb_effort = postData.blb_effort = postData.nc_effort = 0;
			// altfel, in functie de tipul default de timesheet de pe proiect
		} else {
			// var temp_input_effort = angular.copy(postData.input_effort);
		
			// daca tipul de timesheet este BLB, pune BLB = input, iar celelalte pe 0
			if ((vm.default_timesheet_type == "BLB")) {
				postData.blb_effort = postData.input_effort;
				postData.nb_effort = postData.dnb_effort = postData.nc_effort = 0;
				postData.total_effort = postData.dnb_effort + postData.nb_effort + postData.nc_effort + postData.blb_effort;
				// daca tipul de timesheet este NB, pune NB = input, iar celelalte pe 0
			} else if (vm.default_timesheet_type == "NB") {
				postData.nb_effort = postData.input_effort;
				postData.blb_effort = postData.dnb_effort = postData.nc_effort = 0;
				postData.total_effort = postData.dnb_effort + postData.nb_effort + postData.nc_effort + postData.blb_effort;
				// daca tipul de timesheet este NC, pune NC = input, iar celelalte pe 0
			} else if (vm.default_timesheet_type == "NC") {
				postData.nc_effort = postData.input_effort;
				postData.blb_effort = postData.dnb_effort = postData.nb_effort = 0;
				postData.total_effort = postData.dnb_effort + postData.nb_effort + postData.nc_effort + postData.blb_effort;
				// daca tipul de timesheet este DNB, pune DNB = input, iar celelalte pe 0
			} else if (vm.default_timesheet_type == "DNB") {
				postData.dnb_effort = postData.input_effort;
				postData.blb_effort = postData.nc_effort = postData.nb_effort = 0;
				postData.total_effort = postData.dnb_effort + postData.nb_effort + postData.nc_effort + postData.blb_effort;
			} else {
				// default;
				// postData.blb_effort = postData.input_effort;
				postData.nb_effort = postData.input_effort - postData.blb_effort;
				postData.dnb_effort = postData.nc_effort = 0;
				postData.total_effort = postData.dnb_effort + postData.nb_effort + postData.nc_effort + postData.blb_effort;
			}
		}
		postData.status_id = 1; // INIT WITH DRAFT
		postData.date = cmdate.toServer(postData.date);

		// if is internal hours
		if (postData.is_internal_hours) {
			postData.customer_id = null;
			postData.project_id = null;
			postData.reference_id = null;
		} else {
			postData.internal_project_id = null;
		}
		if (!postData.timesheet_ext) {
			postData.timesheet_ext = {
				timesheet_id: null
			};
		}
		return postData;
	};


	$scope.$watch("lookup.project.all_project_with_customer", function (value) {
		if (value) {
			vm.data.customer_id = value.customer_id;
			vm.data.reference_has_project_access = value.reference_has_project_access;
			vm.data.customer = {
				customer_name: value.customer_name
			};
		}
	});

	$scope.$watch("lookup.project.all_project_with_reference", function (value) {
		if (value) {
			if(value.has_ledes === 1) {
				vm.hasLedes = true;
			}else{
				vm.hasLedes = false;
			}
			vm.data.project_id = value.project_id;
			vm.data.reference_id = value.id;
			vm.data.reference_has_project_access = value.reference_has_project_access;
			vm.data.project = {
				project_name: value.project_name
			};
			vm.data.reference = {
				project_name: value.reference_name
			};
		}
	});


	function removeDiacritics(str) {
		let result = str;

		// Replace specific diacritics
		result = result.replace(/ş/g, 's');
		result = result.replace(/ș/g, 's');
		result = result.replace(/ț/g, 't');
		result = result.replace(/ă/g, 'a');
		result = result.replace(/î/g, 'i');
		result = result.replace(/â/g, 'a');

		return result;
	}

	vm.suggest = function() {
		if (vm.data.comments && vm.data.comments.length > 2) {
			let data = [];
			vm.timesheetTemplates.forEach(function(element) {
				let newElement = removeDiacritics(element.template);
				if(newElement.toLowerCase().includes(vm.data.comments)){
					element.preview = element.template.substr(0, 170) + (element.template.length > 170 ? "..." : "");
					data.push(element);
				}
			});
			vm.suggestions = data;

			setTimeout(function(){
				vm.showSuggestions = true;
			}, 100);
		} else {
			vm.suggestions = [];
			vm.showSuggestions = true;
		}
	};

	$scope.$watch("lookup.user.all_user", function (value) {
		if (value) {
			if (vm.data.user && (typeof vm.data.user != undefined)) {
				vm.data.user.add_timesheet_no_constraints = value.add_timesheet_no_constraints;
			}
		}
	});

	function isNotRoundedUpTo(number, round_up_to) {
		let output = true;
		let minutes = kvUtils.humanTotalEffortToMinutes(number, vm.API.USE_INDUSTRIAL_MINUTES);;
		if (minutes % round_up_to === 0) {
			output = false;
		}
		return output;
	}

	vm.saveData = function (saveType) {
		vm.custom_errors = null;
		vm.has_error = false;
		if(vm.bladeParams.timesheetRoundUp !== null && vm.bladeParams.timesheetRoundUp !== ""){
			if(isNotRoundedUpTo(vm.data.input_effort, vm.bladeParams.timesheetRoundUp) || (isNotRoundedUpTo(vm.data.blb_effort, vm.bladeParams.timesheetRoundUp))){
				$('[ng-model="vm.data.blb_effort"] .select2-selection--single').css("border", "1px solid red");
				if (!vm.custom_errors) vm.custom_errors = [];
				vm.custom_errors.push(vm.trans('LANG.ROUND_UP_TO_IS_REQUIRED'));
				vm.has_error = true;
			}
		}else {
			$('[ng-model="vm.data.blb_effort"] .select2-selection--single').css("border", "1px solid #c2cad8");
		}

		if(vm.bladeParams.practiceIsRequired == 1 && vm.data.is_internal_hours == false) {
			if (vm.data.practice_id == null && vm.data.customer_id != null) {
				$('[ng-model="vm.data.practice_id"] .select2-selection--single').css("border", "1px solid red");
				if (!vm.custom_errors) vm.custom_errors = [];
				vm.custom_errors.push(vm.trans('LANG.PRACTICE') + ' ' + vm.trans('IS_REQUIRED'));
				vm.has_error = true;
			} else {
				$('[ng-model="vm.data.practice_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
			}
		}

		if(vm.bladeParams.hasRights == '' && vm.dateTooOld == true) {
			$('[ng-model="vm.data.date"] .select2-selection--single').css("border", "1px solid red");
			if (!vm.custom_errors) vm.custom_errors = [];
			vm.custom_errors.push(vm.trans('LANG.TIMESHEET_DATE_CONSTRAINT') + ' ' + timesheet_allowed_past_days + ' ' + vm.trans('LANG.DAYS_IN_PAST_SMALL'));
			vm.has_error = true;
		} else {
			$('[ng-model="vm.data.date"] .select2-selection--single').css("border", "1px solid #c2cad8");
		}

		if(vm.hasLedes && vm.data.ledes_activity_code_id == null) {
			if (vm.data.customer_id != null) {
				$('[ng-model="vm.data.ledes_activity_code_id"] .select2-selection--single').css("border", "1px solid red");
				if (!vm.custom_errors) vm.custom_errors = [];
				vm.custom_errors.push(vm.trans('LANG.LEDES_CODE') + ' ' + vm.trans('IS_REQUIRED'));
				vm.has_error = true;
			} else {
				$('[ng-model="vm.data.ledes_activity_code_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
			}
		}

		if(vm.bladeParams.displayLedesTaskCode == 1 && vm.data.ledes_task_code_id == null && vm.hasLedes) {
			$('[ng-model="vm.data.ledes_task_code_id"] .select2-selection--single').css("border", "1px solid red");
			if (!vm.custom_errors) vm.custom_errors = [];
			vm.custom_errors.push(vm.trans('LANG.TASK_CODE') + ' ' + vm.trans('IS_REQUIRED'));
			vm.has_error = true;
		}else {
			$('[ng-model="vm.data.ledes_task_code_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
		}


		if(vm.has_error){
				$('.custom_error_messages').css('display', 'block');
			} else {
				$('.custom_error_messages').css('display', 'none');
				vm.saveCallback = function (response) {
					this.close({entity: response.entity, index: response.index, saveType: saveType});
				};
				return vm.save();
			}
		};/*else{
			vm.saveCallback = function (response) {
				this.close({entity: response.entity, index: response.index, saveType: saveType});
			};
			return vm.save();
		}*/

	//};


	/**
	 * custom GNP code
	 * 
	 * @param  {[type]} entity [description]
	 * @return {[type]}        [description]
	 */
	function customUserCanDeleteOrUpdate (entity) {
		if (API.tenant.short_name === 'gnp' && vm.defaultTimesheet.timesheetAllowedPastDays > 0 && vm.bladeParams.canDelete !== '1')
		{
			if (managedTimesheet(entity)) return true;

			var d = new Date();
			var entityDate = cmdate.fromServer(cmdate.toServer(entity.date)).valueOf();
			let minDate = d.setDate(d.getDate() - vm.defaultTimesheet.timesheetAllowedPastDays);
			if (entityDate < minDate) return false;
		}
		return true;
	}

	/**
	 * check if user is project manager or controller
	 * 
	 * @param  {[type]} entity [description]
	 * @return {[type]}        [description]
	 */
	function managedTimesheet(entity) {
		if(entity){
			if (entity.project_managers.some(user => user.user_id === API.user.user_id)) return true;
			if (entity.project_controller && entity.project_controller.user_id === API.user.user_id) return true;

			return false;
		}
	}

	return vm;
});
