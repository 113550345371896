kv.controller('tenantInvoiceSeriesViewCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, $timeout, search) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.primaryKey = 'invoice_series_id';
    vm.entity = 'invoice_series';
    vm.entityType = 'invoice_series';
    var REST = resource.init(vm.entity);

    let url_segments = window.location.pathname.split('/');
    let page_entity_id = url_segments[2];

    vm.listEntity = function() {
        REST.list().then(function (data) {
            vm.data = data.data;
            // console.log("data", vm.data);
        });

    }

    vm.listEntity();

    vm.addEditInvoiceSeries = function (entity) {
        let isNewRecord = !entity;
        vm.openModal({
            templateUrl: "invoice_series-edit",
            controller: 'tenantInvoiceSeriesEditCtrl',
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                params: function () {
                    if (!entity) {
                        entity = {
                            invoice_series_id: page_entity_id
                        };
                    }
                    return entity;
                },
                saveCallback: function () {
                    return function(response){
                        vm.listEntity();
                        response.modal.close();
                    }
                },
            }
        });
    };

    vm.deleteInvoiceSeries = function ($index) {
        vm.confirm('Confirm', $filter('trans')('LANG.ARE_YOU_SURE_DELETE')).then(response => {
            if (response == 'cancel') return;

            let rest = this.getInjection('resource').init("invoice_series");
            let ctrl = this;
            rest.delete({
                params: {
                    id: vm.data[$index].invoice_series_id
                },
                success: function () {
                    vm.data.splice($index, 1);
                }
            });
        });
    }

    return vm;
});

