(function () {
	'use strict';


	/*
	date.fromServer()
	date.fromServer(null)
	date.fromServer('2016-06-24')
	date.fromServer('2016-06-24 14:30')
	date.fromServer('2016-06-24 14:30:10')

	date.toServer()
	date.toServer(null)
	date.toServer('24/06/2016')
	date.toServer('24/06/2016 13:30')
	date.toServer('24/06/2016 13:30:10')
	*/
	class cmdate {
		fromServer(date, format) {
			let parts = 0;
			if (typeof date !== "undefined" && date !== null) {
				parts = date.split(/[- :]/).length;
			}
			if (format) return moment(date, format);
			else if (parts === 3) {
				return moment(date, "YYYY-MM-DD");
			} else if (parts == 5) {
				return moment(date, "YYYY-MM-DD HH:mm");
			} else if (parts == 6) {
				return moment(date, "YYYY-MM-DD HH:mm:ss");
			} else {
				// console.info(date, '- date service return null becouse your date is not well formated');
				return "-";
			}
		}

		toServer(date, format) {
			let parts = 0;
			if (typeof date === "object") {
				if (date) {
					return moment(date).format(format || 'YYYY-MM-DD HH:mm:ss');
				} else {
					return date;
				}
			}

			if (typeof date !== "undefined" && date !== null) {
				parts = date.split(/[\- :]/).length;
			}
			if (parts === 3) {
				return moment(date, "YYYY-MM-DD").format(format || 'YYYY-MM-DD');
			} else if (parts === 5) {
				return moment(date, "YYYY-MM-DD HH:mm").format(format || 'YYYY-MM-DD HH:mm');
			} else if (parts === 6) {
				return moment(date, "YYYY-MM-DD HH:mm:ss").format(format || 'YYYY-MM-DD HH:mm:ss');
			} else {
				if (date === '') {
					return null;
				} else {
					// console.info(date, '- date service return current date becouse your date is not well formated');
					return moment().format('YYYY-MM-DD HH:mm:ss');
				}
			}
		}

	}

	angular.module('kv').factory('cmdate', cmdate);

}());
