kv.controller('SunSyncDataCustomerReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    
    var params = kvUtils.getAllQueryParams();
    vm.entity = 'sun-export-detail';
    var REST = resource.init(vm.entity);
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function () {

        function tableInit() {
            $('#table1').DataTable({
                destroy: true, //for reinitialization purposes
                paging: false,
                searching: true,
                scrollX: "100%",
                scrollY: '50vh',
                scrollCollapse: true,
            });
            $('#table2').DataTable({
                destroy: true, //for reinitialization purposes
                paging: false,
                searching: true,
                scrollX: "100%",
                scrollY: '50vh',
                scrollCollapse: true,
            });
            $('#table3').DataTable({
                destroy: true, //for reinitialization purposes
                paging: false,
                searching: true,
                scrollX: "100%",
                scrollY: '50vh',
                scrollCollapse: true,
            });
            $('#table4').DataTable({
                destroy: true, //for reinitialization purposes
                paging: false,
                searching: true,
                scrollX: "100vh",
                scrollY: '50vh',
                scrollCollapse: true,
            });
            $('#table5').DataTable({
                destroy: true, //for reinitialization purposes
                paging: false,
                searching: true,
                scrollX: "100vh",
                scrollY: '50vh',
                scrollCollapse: true,
            });
            $('#table6').DataTable({
                destroy: true, //for reinitialization purposes
                paging: false,
                searching: true,
                scrollX: "100vh",
                scrollY: '50vh',
                scrollCollapse: true,
            });
            $('#table7').DataTable({
                destroy: true, //for reinitialization purposes
                paging: false,
                searching: true,
                scrollX: "100vh",
                scrollY: '50vh',
                scrollCollapse: true,
            });
            $('#table8').DataTable({
                destroy: true, //for reinitialization purposes
                paging: false,
                searching: true,
                scrollX: "100vh",
                scrollY: '50vh',
                scrollCollapse: true,
            });

            $('.dataTables_filter input[type="search"]').
                attr('placeholder','Search in table...').
                removeClass('input-small').
                css({'width':'350px','display':'inline-block'});

            $('.dataTables_filter').addClass('pull-right').addClass('no-print');
        }

        tableInit();
    });

	return vm;
});
