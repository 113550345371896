kv.controller('NewBusinessReportShowCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    var params = kvUtils.getAllQueryParams();
    var data = JSON.parse(vm.bladeParams.data);

    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    $(document).ready(function() {
        $('#table').DataTable({
            "paging":   false,
            "ordering": true,
            "info":     false,
            "bLengthChange": false,
            "columnDefs": [ {
                "targets": 1,
                "orderable": false
            } ]
        });
        $('.dataTables_filter input[type="search"]').
        attr('placeholder','Search in table...').
        removeClass('input-small').
        css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right').addClass('no-print');

        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            $(e.target.hash).find('.chart').each(function() {
                if($(this).highcharts()) {
                    $(this).highcharts().reflow();
                }
            });
        });
    });

    vm.isLoading = true;

    var chart_categories = data[2];
    var series = data[1];
    var chart1Data = data[3];
   
    function chart1() {
        $('#gauge-1-1').hide();
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    theme: {
                        zIndex: 9999
                    }
                }
            },
            tooltip: {
                enabled: false,
            },
            title: {
                text: ''
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    innerSize: '60%',
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.2f}',
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    },
                    showInLegend: true
                }
            },
            series: [{
                colorByPoint: true,
                data: chart1Data
            }]
        }

        $('#gauge-1-1').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#gauge-1-1').show();
            $('#gauge-1-1').highcharts().reflow();
        }, 1);
    }
    function chart() {
        $('#secondary-chart').hide();
        var config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'area',
                events: {
                    load: function () {
                        this.reflow();
                    }
                },
                height: 550
            },
            title: {
                text: $filter('trans')('LANG.NEW_CUSTOMERS')
            },
            subtitle: {
                text: null
            },
            xAxis: {
                categories: chart_categories
            },
            yAxis: {
                title: {
                    text: $filter('trans')('LANG.CUSTOMERS')
                },
                 min: 0
            },
            tooltip: {
                enabled: true,
            },
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                    marker: {
                        radius: 4
                    },
                    lineWidth: 5,
                    states: {
                        hover: {
                            lineWidth: 5
                        }
                    },
                    threshold: null,
                    dataLabels: {
                        enabled: true,
                    },
                    enableMouseTracking: true
                },
            },
            credits: {
                enabled: false
            },
            series: [{
                name: $filter('trans')('LANG.NEW_CUSTOMERS'),
                data: series
            }]
        }

        $('#secondary-chart').highcharts(config);
        vm.isLoading = false;

        setTimeout(function() {
            $('#secondary-chart').show();
            $('#secondary-chart').highcharts().reflow();
        }, 1);
    }

    if (chart1Data) {
        chart1();
    }

    if (series) {
        chart();
    }

	return vm;
});
