kv.controller('projectEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'project_id';
    vm.entity = 'project';
    vm.rest = vm.getInjection('resource').init(vm.entity);
    vm.validator = vm.getInjection('validator');

    // let cmdate = vm.getInjection('cmdate');

    var REST = resource.init(vm.entity);
    let url_segments = window.location.pathname.split( '/' );
    let page_entity_id = url_segments[2];
    let page_identifier = url_segments[3];    
    
    if(page_identifier == 'edit') {
        vm.isNewRecord = false;
        // $http.get("/project/" + page_entity_id).then(function(response) {
        // gbarcun - 16 feb 2023 - replaced http.get with already loaded entity
        vm.data = JSON.parse(vm.bladeParams.entity);
        // vm.data.customer_fiscal_entity_id = response.data.customer_fiscal_entity_id;
        // vm.data.project_ext.tenant_bank_account_id = response.data.project_ext.tenant_bank_account_id;

        if(vm.data.project_team) {
            angular.forEach(vm.data.project_team, function(v,k) {
                vm.data.project_team[k] = {id: v.user.user_id, name: v.user.full_name}
            });
        }

        if(vm.data.project_external_user) {
            angular.forEach(vm.data.project_external_user, function(v,k) {
                vm.data.project_external_user[k] = {id: v.user.user_id, name: v.user.full_name}
            });
        }

        if(vm.data.project_manager) {
            angular.forEach(vm.data.project_manager, function(v,k) {
                vm.data.project_manager[k] = {id: v.user.user_id, name: v.user.full_name}
            });
        }

        // convert from serverside billingContacts (Contact collection) into billing_contact_id (array of objects)
        if(vm.data.billingContacts) {
            vm.data.billing_contact_id = [];
            angular.forEach(vm.data.billingContacts, function(v,k) {
                vm.data.billing_contact_id[k] = {id: v.id, name: v.name}
            });
        }

        if(!vm.data.project_budget) {
            vm.data.project_budget = {};
            if(!vm.data.project_budget.enable_budget_alert) {
                vm.data.project_budget.enable_budget_alert = false;
                vm.data.project_budget.alert_treshold_percent = "90.00";
            }
        }

        if (!vm.data.project_ext.force_invoice_currency) {
            if(!vm.data.project_ext) {
                vm.data.project_ext = {};
            }
            vm.data.project_ext.force_invoice_currency = vm.trans('LANG.FISCAL_ENTITY_CURRENCY');
        }

    } else {
        vm.isNewRecord = true;
        let defaultObjects = JSON.parse($element.find("#defaultObjects").attr("defaultObjects"));
        vm.data = defaultObjects;

        vm.data.project_type = "project";
        vm.data.entity_type = "standard";
        vm.data.contract_option = 'without_contract';
        if(!vm.data.project_budget) {
            vm.data.project_budget = {};
            vm.data.project_budget.enable_budget_alert = false;
            vm.data.project_budget.alert_treshold_percent = "90.00";
        }

        if(vm.API.user.tenant.tenant_ext.contract_is_mandatory_on_project)
            vm.data.contract_option = 'new_contract';

        vm.data.customer_fiscal_entity = {
            customer_fiscal_entity_ext: {}
        };

        let currentDate = new Date();
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();

        vm.data.start_date = year + '-' + (month < 10 ?  '0' + month : month) + '-' + (day < 10 ? '0' + day : day)+ ' ' + vm.API.START_DAY_AT;
        vm.data.contract = {
            contract_type_id: "CONTRACT",
            source_user_id: API.user.user_id,
            source_user: {
                full_name : API.user.full_name
            },
            date : new dateClass(vm.data.start_date)

        };
        vm.data.customer_fiscal_entity.customer_fiscal_entity_ext.vat_type = {};


        if(vm.data.contract_option == 'without_contract'){
            vm.data.contract_id = null;
            vm.data.has_contract = 0;
        } else if(vm.data.contract_option == 'existent_contract' || vm.data.contract_option == 'new_contract'){
            vm.data.has_contract = 1;
        }

        $http.get("/document_series").then(function(response) {
            if(response !== undefined && response.data !== undefined && response.data.data !== undefined ) {
                var result = response.data.data;
                angular.forEach(result, function (v, i) {
                    if( result[i].series_type_id === 1 && result[i].is_active === 1){
                        result.prefix = v.prefix;
                        result.current_number = v.current_number;
                        result.suffix = v.suffix;
                    }
                });

                if (result && result.current_number) {
                    vm.data.contract.contract_series_type = result.prefix.concat(result.current_number, result.suffix);
                    vm.data.contract.number = result.prefix.concat(result.current_number, result.suffix);
                }

            }
        });

        $scope.$watch("vm.data.contract.contract_series_type", function (value) {
            if (value){
                $http.get("/document_series").then(function(response) {
                    if(response !== undefined && response.data !== undefined && response.data.data !== undefined ) {
                        var result = response.data.data;
                        angular.forEach(result, function (v, i) {
                            if(result[i].series_type_id === 1 && result[i].is_active === 1 && (result[i].document_series_id == value || result[i].prefix.concat(result[i].current_number) == value || result[i].prefix.concat(result[i].current_number, result[i].suffix) == value)){
                                result.prefix = v.prefix;
                                result.current_number = v.current_number;
                                result.suffix = v.suffix;
                            }
                        });

                        if(result.suffix){
                            vm.data.contract.contract_series_type = result.prefix.concat(result.current_number, result.suffix);
                            vm.data.contract.number = result.prefix.concat(result.current_number, result.suffix);
                        }else{
                            vm.data.contract.contract_series_type = result.prefix.concat(result.current_number);
                            vm.data.contract.number = result.prefix.concat(result.current_number);
                        }

                    }
                });
            }
        }, true);
    }

    $scope.$watch("vm.data.customer_fiscal_entity_id", function () {
        if(vm.data && vm.data.customer_fiscal_entity_id){
            $http.get("/customer_fiscal_entity/" + vm.data.customer_fiscal_entity_id).then(function(response) {
                if(response && response.data && response.data.customer_fiscal_entity_ext) {
                    vm.data.customer_fiscal_entity.customer_fiscal_entity_ext.payment_term = response.data.customer_fiscal_entity_ext.payment_term;
                    vm.data.customer_fiscal_entity.customer_fiscal_entity_ext.payment_term_calculation_method = response.data.customer_fiscal_entity_ext.payment_term_calculation_method;
                    vm.data.customer_fiscal_entity.customer_fiscal_entity_ext.invoice_currency = response.data.customer_fiscal_entity_ext.invoice_currency;
                    if(response.data.customer_fiscal_entity_ext.vat_type){
                        vm.data.customer_fiscal_entity.customer_fiscal_entity_ext.vat_type.vat_code = response.data.customer_fiscal_entity_ext.vat_type.vat_code;
                    }
                }
            });
        }
    }, true);

    $scope.$watch("vm.data.project_ext.tenant_bank_account_id", function () {
        if(vm.data && vm.data.project_ext && vm.data.project_ext.tenant_bank_account_id){
            $http.get("/bank_account/" + vm.data.project_ext.tenant_bank_account_id).then(function(response) {
                if(response.data) {
                    vm.data.project_ext.tenant_bank_account.currency = response.data.currency;
                }
            });
        }
    }, true);


    vm.quickAddContact = function () {
        var modalInstanceContact = $uibModal.open({
            animation: true,
            component: 'quickAddEntity',
            resolve: {
                entity: function() {
                    return $rootScope.quickAddEntityTemplate = 'contact';
                }
            }
        }).result.then(function(result) {
            vm.data.billing_contact_id = result.billing_contact_id;
            vm.data.billing_contact = {
                contact_name: result.contact_name
            }
        });
    }

    vm.quickAddCustomer = function () {
        var modalInstanceContact = $uibModal.open({
            animation: true,
            component: 'quickAddEntity',
            resolve: {
                entity: function() {
                    return $rootScope.quickAddEntityTemplate = 'customer';
                }
            }
        }).result.then(function(result) {
            vm.data.customer_id = result.customer_id;
            vm.data.customer = {
                customer_name: result.customer_name
            }
        });
    }

    vm.quickAddCustomerFiscalEntity = function () {
        var modalInstanceContact = $uibModal.open({
            animation: true,
            component: 'quickAddEntity',
            resolve: {
                entity: function() {
                    return $rootScope.quickAddEntityTemplate = 'customer_fiscal_entity';
                }
            }
        }).result.then(function(result) {
            vm.data.customer_fiscal_entity_id = result.customer_fiscal_entity_id;
            vm.data.customer_fiscal_entity = {
                name: result.name
            }
        });
    }

    vm.newEntity = function newEntity(entity) {
        if(!vm.data) {
            vm.data = {};
        }

        if(!vm.data[entity]) {
            return vm.data[entity] = [{}];
        }

        return vm.data[entity].push({});
    };

    vm.deleteEntity = function deleteEntity($index, entity, id) {
        if(!id) {
            return vm.data[entity].splice($index,1);
        }
        
        if(page_entity_id) {
            REST.delete({
                url: vm.entity + '/:id/' + entity + '/:entityId',
                params: {
                    id: page_entity_id,
                    entityId: id
                }
            }).then(function(data) {
                vm.data[entity].splice($index,1);
            });
        }
    };

    vm.resetAlert = function (entity, id) {
        if(!id){
            vm.data.project_budget.alert_created_at = null;
        }
    };

    // used in case you want to cancel associating a contract to the project
    vm.cancelContract = function() {
          vm.data.contract_option = null;
          delete vm.data.contract.number;
          delete vm.data.contract.contract_number;
          delete vm.data.contract.contract_type_id;
          delete vm.data.contract.date;
          delete vm.data.contract.contract_status_id;
          delete vm.data.contract.source_user_id;
          delete vm.data.contract.has_hard_copy;
          delete vm.data.contract.description;
    };
    vm.diseableContract = function() {
        if(vm.data.has_contract == 1){
            vm.data.project_ext.contract_number = null;
        }
    };


    vm.validateSomeInputs = function () {
        vm.custom_errors = null;
        vm.has_error = false;
        let contract_is_mandatory = vm.API.user.tenant.tenant_ext.contract_is_mandatory_on_project;

        // daca in tenant este setat 'contract_is mandatory_on_project' => validare pe contract
        if(contract_is_mandatory){
            if(vm.data.contract_option == 'new_contract'){
                if(!vm.data.contract.number){
                    $('[ng-model="vm.data.contract.number"]').css("border", "1px solid red");
                    if (!vm.custom_errors) vm.custom_errors = [];
                    vm.custom_errors.push(vm.trans('LANG.CONTRACT_OR_CONTRACT_NUMBER_REQUIRED'));
                    vm.has_error = true;
                } else {
                    $('[ng-model="vm.data.contract_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
                    $('[ng-model="vm.data.project_ext.contract_number"]').css("border", "1px solid #c2cad8");
                    $('[ng-model="vm.data.contract.number"]').css("border", "1px solid #c2cad8");
                }
            }
            if(vm.data.contract_option == 'existent_contract'){
                if(!vm.data.contract_id && !vm.data.project_ext.contract_number){
                    $('[ng-model="vm.data.contract_id"] .select2-selection--single').css("border", "1px solid red");
                    $('[ng-model="vm.data.project_ext.contract_number"]').css("border", "1px solid red");
                    if (!vm.custom_errors) vm.custom_errors = [];
                    vm.custom_errors.push(vm.trans('LANG.CONTRACT_OR_CONTRACT_NUMBER_REQUIRED'));
                    vm.has_error = true;
                } else {
                    $('[ng-model="vm.data.contract_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
                    $('[ng-model="vm.data.project_ext.contract_number"]').css("border", "1px solid #c2cad8");
                    $('[ng-model="vm.data.contract.number"]').css("border", "1px solid #c2cad8");
                }
            }
        } else {
            $('[ng-model="vm.data.contract_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
            $('[ng-model="vm.data.project_ext.contract_number"]').css("border", "1px solid #c2cad8");
            $('[ng-model="vm.data.contract.number"]').css("border", "1px solid #c2cad8");
        }

        if(!vm.data.tenant_fiscal_entity_id){
            $('[ng-model="vm.data.tenant_fiscal_entity_id"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.PROJECT_TENANT_FISCAL_ENTITY_ERROR'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.data.tenant_fiscal_entity_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }

        if(vm.data.project_ext.use_billing_config_from_customer_fiscal_entity == 0 && !vm.data.project_ext.vat_type_id){
            $('[ng-model="vm.data.project_ext.vat_type_id"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.VAT_TYPE_ERROR'));
            vm.has_error = true;
        }else{
            $('[ng-model="vm.data.project_ext.vat_type_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }

        if(!vm.data.currency){
            $('[ng-model="vm.data.currency"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.CURRENCY_ERROR'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.data.currency"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }

        if(!vm.data.project_ext.tenant_bank_account_id){
            $('[ng-model="vm.data.project_ext.tenant_bank_account_id"] .select2-selection--single').css("border", "1px solid red");
            if (!vm.custom_errors) vm.custom_errors = [];
            vm.custom_errors.push(vm.trans('LANG.PROJECT_TENANT_BANK_ACCOUNT_ERROR'));
            vm.has_error = true;
        } else {
            $('[ng-model="vm.data.project_ext.tenant_bank_account_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
        }


        if(vm.has_error){
            $('.custom_error_messages').css('display', 'block');
        } else {
            $('.custom_error_messages').css('display', 'none');
            vm.save();
        }

    };


    vm.prepareDataToSave = function () {
        let postData = angular.copy(vm.data);

        if(postData.project_ext.force_invoice_currency === vm.trans('LANG.FISCAL_ENTITY_CURRENCY')){
            postData.project_ext.force_invoice_currency = 'fec';
        }

        if(!postData.project_id && postData.billing_rule && postData.billing_rule[0].rule_type == null){
            postData.billing_rule = null;
        }

        // sterge datele despre contract, in cazul in care nu se doreste asocierea cu un proiect sau cand se asociaza unul deja existent
        // se verifica bifa si un camp care este required la adaugare de contract
        if ((!postData.contract_option || postData.contract_option == 'existent_contract') && (postData.contract && !postData.contract.number)) {
            delete postData.contract;
        }
        postData.project_budget.alert_treshold_value = postData.project_budget.alert_treshold_percent/100 * postData.project_budget.budget_value;

        if(postData.contract_option == 'without_contract'){
            postData.contract_id = null;
            postData.has_contract = 0;
        }
        if(postData.contract_option == 'existent_contract' || postData.contract_option == 'new_contract'){
            postData.has_contract = 1;
        }
        postData.project_budget.alert_treshold_value = postData.project_budget.alert_treshold_percent/100 * postData.project_budget.budget_value;
        return postData;
    };
	
	return vm;
});