kv.controller('subscriptionEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	let vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'tenant_id';
	vm.entity = 'subscription';
	vm.rest = vm.getInjection('resource').init(vm.entity);
	vm.element = $element.find(".pageIsLoading");
	vm.saveUrl = 'subscription/update';
	vm.countryHasLitigation = false;
	$scope.$parent.mainCtrl.showGoToSubscription = false;
	vm.redirectUrl = '/subscription';
	let ltgEnabledValue = false;
	let insEnabledValue = false;

	let url_segments = window.location.pathname.split('/');
	let page_identifier = url_segments[2];

	if (page_identifier == 'edit' && vm.bladeParams.subscriptionId) {
		KApp.block(vm.element);
		vm.isNewRecord = false;
		vm.rest.get("/subscription/").then(response => {
			KApp.unblock(vm.element);
			vm.data = response;
			vm.data.addons = JSON.parse(vm.bladeParams.addons);
			$scope.$broadcast('dataLoaded');
		});
	} else {
		vm.isNewRecord = true;
		vm.data = {
			country_id: vm.API.user.user_ext.country_id,
			sys_country:{
				country_id: vm.API.user.user_ext.country_id,
				country_name: vm.API.tenantCountry.country_name
			},
			tenant_id: vm.bladeParams.tenantId,
			plan_type: 'timesheet',
			subscription_type: 'price_monthly',
			max_user_count: vm.bladeParams.activeUserCount,
			active_user_count: vm.bladeParams.activeUserCount,
			total: 0,
			addons: JSON.parse(vm.bladeParams.addons)

		};
	}	

	$scope.$watch('vm.data.sys_country', function(newValue, oldValue){
		if(newValue) {
			if(newValue.country_name == 'Romania' || newValue.country_name == 'Moldova' || newValue.country_name == 'Spain') {
				vm.countryHasLitigation = true;
			} else {
				vm.countryHasLitigation = false;
			}
		}

		if(oldValue) {
			if(oldValue.country_name != newValue.country_name) {
				vm.data.plan_type = 'timesheet';
				angular.forEach(newValue.addons[newValue.plan_type], function(value, key) {
					if(key !== 'std') {
						vm.data.addons[newValue.plan_type][key].enabled = false;
					}
				});
			}
		}
	}, true);

	vm.getTotal = function ($type) {
		if (!vm.data) return "";
		let total = 0;
		Object.keys(vm.data.addons[vm.data.plan_type] || {}).forEach(addon_code => {
			let addon = vm.data.addons[vm.data.plan_type][addon_code];
			let months_calculation = (vm.data.subscription_type == "price_monthly" ? 1 : 12)
			let subscription_type_suffix = '';
			if(vm.data.sys_country.country_name == 'Romania') subscription_type_suffix = '_ro';
			else subscription_type_suffix = '_intl';
			switch($type) {
				//total price
				case 'total':
					if(vm.countryHasLitigation == false)
					{
						if(addon.addon_name === 'ADDON_INSOLVENCY' || addon.addon_name === 'ADDON_LITIGATION'){
							addon.enabled = false;
						}
					}
					total += addon.enabled ? vm.data.max_user_count * parseFloat(addon[vm.data.subscription_type + subscription_type_suffix]) * months_calculation : 0;
					break;
				//total per user (montly/yearly)
				case 'userTotal':
					total += addon.enabled ? parseFloat(addon[vm.data.subscription_type + subscription_type_suffix]) * months_calculation : 0;
					break;
				//per user monthly
				case 'userMonthlyTotal':
					total += addon.enabled ? parseFloat(addon[vm.data.subscription_type + subscription_type_suffix]) * 1 : 0;
					break;
			}
		});
		return total;
	};

	return vm;
});
