kv.component('quickAddEntity', {
	templateUrl: function ($rootScope) {
		return 'quick-add-' + $rootScope.quickAddEntityTemplate;
	},
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	size: 'sm',
	controller: function ($scope, $element, $attrs, $injector, $filter, $http, resource, validator, $rootScope) {
		var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter, 'quick-add-entity-params');
		vm.isNewRecord = true;
		vm.$onInit = function () {
			vm.entity = vm.resolve.entity === 'supplier' ? "customer_fiscal_entity" : vm.resolve.entity;
			vm.rest = resource.init(vm.entity);
			vm.data = {};

			if ($rootScope.quickAddEntityTemplate === 'customer') {
				// if not ADMIN, MANAGEMENT, BILLING or SUPPORT, fill customer_type by default with LEAD
				if (vm.bladeParams.hasRights == 0 || !vm.bladeParams.hasRights) {
					vm.data.customer_type_id = 2;
					vm.data.customer_type = {
						customer_type_id: 2,
						customer_type_name: $filter('trans')('LANG.LEAD'),
					};
				}
				vm.data.sys_fiscal_entity_type = {
					fiscal_entity_type_id : 2,
					fiscal_entity_type_name : $filter('trans')('LANG.PJ')
				};
				vm.data.fiscal_entity_type_id =2;
				vm.data.customer_ext = {};
				vm.data.customer_ext.industry_id = null;
			}
		};
		vm.prepareDataToSave = function () {
			let data = angular.copy(vm.data);
			switch (vm.resolve.entity) {
				case "contact":
					data.contact_ext = {};
					data.primary_company = data.customer_contact ? data.customer_contact[0].customer.customer_name : null;
					if(data.phone){
						data.phone[0].is_primary = true;
						data.phone[0].phone_type_id = 2;
					}
					if(data.contact_user){
						data.contact_user[0].is_primary = true;
						data.contact_user[0].user.user_id = data.contact_user[0].user_id;
					}
					data.email[0].is_primary = true;
					data.email[0].email_type_id = 2;
					data.contact_ext.birthday = null;
					break;
				case "supplier":
					data.customer_type_id = 4; // Supplier
					data.supplier = true;
				case "customer_fiscal_entity":
					data.customer_fiscal_entity_ext = {};
					data.customer_fiscal_entity_ext.use_electronic_billing = 0;
					data.customer_fiscal_entity_ext.invoice_currency = API.LEGAL_CURRENCY ? API.LEGAL_CURRENCY : API.REPORTING_CURRENCY;
					data.primary_country_id = 85;
					data.sys_country = {
						country_id : 85,
						country_name : 'Romania'
					};
					break;
				default:
			}
			return data;
		};

		vm.saveCallback = function (response) {
			vm.dismiss({$value: response.data});
		};
		vm.cancel = function () {
			vm.dismiss({
				$value: 'cancel'
			});
		};
		return vm;
	},
	controllerAs: 'vm'
});
