kv.controller('EventLogReportShowCtrl', function($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
    var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
    vm.entity = 'event_log';
    var REST = resource.init(vm.entity);
    vm.expandDetails = expandDetails;
    var row = {};
    
    var params = kvUtils.getAllQueryParams();
    
    if (params) {
        if (params.generate) {
            $('[href="#results-tab"]').tab("show");
        }
    }

    function setOrderBy() {
        var orderBy = $('[aria-sort="ascending"], [aria-sort="descending"]').attr("data-order-by");
        var direction = $('[aria-sort="ascending"], [aria-sort="descending"]').attr('aria-sort');

        $("#print").attr("href", $("#print").attr("href") + "&orderBy=" + orderBy + "&direction=" + direction);
    }
        
    $(document).ready(function() {
        $('.dataTables_filter input[type="search"]').
            attr('placeholder','Search in table...').
            removeClass('input-small').
            css({'width':'350px','display':'inline-block'});

        $('.dataTables_filter').addClass('pull-right');
    });

    // functie necesara pentru butonul de expand modal extra detalii
    function expandDetails($row){
        var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'event-log-details',
            controller: function ($uibModalInstance) {
                var vm = this;
                vm.cancel = cancel;
                vm.data = $row;

                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                };
            },
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                data: function () {
                    return {
                        data: vm.data,
                    };
                }
            }
        });
    }

    return vm;
});
