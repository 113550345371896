kv.controller('userListCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'user_id';
	vm.entity = 'user';
	vm.max_user_count = parseInt(vm.bladeParams.maxUserCount);
	vm.active_user_count = parseInt(vm.bladeParams.activeUserCount);
	vm.showAlertMaxUserCountMsg = "LANG.NO_MORE_USER_ALLOWED";
	vm.showAlertUnusedUserCountMsg = "LANG.UNUSED_USERS";
	vm.count = {
		total: 0,
		showing: 0
	};

	if(vm.max_user_count <= vm.active_user_count) {
		$('.add-user').addClass('disabled');
	}

	vm.getInfinitScroll({
		params: {
			action: 'user'
		},
		url: "user"
	});

	vm.unlockUser = function ($index, email, first_name, last_name) {
		vm.rest.update({
			// id: vm.result[$index].user_id,
			id: vm.infinitScroll.items[$index].user_id,
			data: {
				account_is_locked: false,
				email: email,
				first_name: first_name,
				last_name: last_name,
				login_failed_attempts: 0
			},
			params: {
				force_update_entity: true
			}
		}).then(function (data) {
			// vm.search.fresh = new Date;
			window.location.reload();
		});
	};


	return vm;
});

