kv.controller('contactEditCtrl', function ($scope, $element, $attrs, $http, resource, $filter, $uibModal, $rootScope, $injector, API) {
	var vm = new baseCtrl($scope, $element, $attrs, $injector, $filter);
	vm.primaryKey = 'contact_id';
	vm.entity = 'contact';
	vm.rest = vm.getInjection('resource').init(vm.entity);
	vm.validator = vm.getInjection('validator');
	let url_segments = window.location.pathname.split('/');
	let page_entity_id = url_segments[2];
	let page_identifier = url_segments[3];

	if (page_identifier == 'edit') {
		vm.isNewRecord = false;
		$http.get("/contact/" + page_entity_id).then(function (response) {
			vm.data = response.data;

			if (!vm.data.email.length && API.user.tenant.short_name == 'nndkp') {
				vm.newRelatedModelEntity('email');
			}
			if(!vm.data.address.length && API.user.tenant.short_name == 'nndkp'){
				vm.newRelatedModelEntity('address');
			}
			if(!vm.data.address.length && API.user.tenant.short_name == 'nndkp'){
				vm.newRelatedModelEntity('customer_contact');
			}
			if (vm.data.contact_industry) {

				angular.forEach(vm.data.contact_industry, function (v, i) {
					vm.data.contact_industry[i].id = v.industry_id;
					vm.data.contact_industry[i].name = v.industry_name;
				});
			}

			if (vm.data.practices) {
				angular.forEach(vm.data.practices, function (v, i) {
					vm.data.practices[i].id = v.pivot.practice_id;
					vm.data.practices[i].name = v.practice_name;
				});
			}

			setTimeout(function () {
				$('select[ng-model="vm.data.contact_ext.sex"]').trigger("change");
			});
			$scope.$broadcast('dataLoaded');
		});
	} else {
		vm.isNewRecord = true;
		vm.data = {};

		if (API.user.tenant.short_name == 'nndkp') {
			vm.newRelatedModelEntity('email');
			vm.newRelatedModelEntity('customer_contact');
			vm.newRelatedModelEntity('address');
		}
		vm.newRelatedModelEntity('email');
		//vm.newRelatedModelEntity('contact_user');

	}

	vm.quickAddCustomer = function quickAddCustomer() {
		vm.openModal({
			animation: true,
			component: 'quickAddEntity',
			resolve: {
				entity: function () {
					return $rootScope.quickAddEntityTemplate = 'customer';
				}
			}
		});
	};

	vm.validateSomeInputs = function () {
		vm.custom_errors = null;
		vm.has_error = false;

		if (!vm.custom_errors) vm.custom_errors = [];
		if (vm.data.customer_contact) {
			vm.data.customer_contact.forEach(function (item) {
				if (!item.customer_id) {
					$('[ng-model="customer_contact.customer_id"] .select2-selection--single').css("border", "1px solid red");
					vm.has_error = true;
				} else {
					$('[ng-model="customer_contact.customer_id"] .select2-selection--single').css("border", "1px solid #c2cad8");
				}
			});
		}

		if(vm.has_error){
			vm.custom_errors.push(vm.trans('LANG.CUSTOMER') + ' ' + vm.trans('IS_REQUIRED'));
			$('.custom_error_messages').css('display', 'block');
		} else {
			$('.custom_error_messages').css('display', 'none');
			vm.save();
		}

	};
	return vm;
});
