kv.controller('addEditDirectoryCtrl', function ($uibModalInstance, params, saveCallback, $scope, $injector, resource) {
	let vm = new modalCtrl($uibModalInstance, $injector, 'file_storage', 'id', $scope, params);

	vm.directory_name = params.directory_name;
	vm.save = function () {
		KApp.block('#documents_storage_directory-name');
		vm.rest.update({
			url: params.entity + "/" + params.entity_id + "/update_folder/" + (params.directory_id !== -1 ? params.directory_id : ''),
			data: {
				parent_id: params.parent_id,
				entity_id : params.entity_id,
				folder_name: vm.directory_name,
				directory_id: params.directory_id
			}
		}).then(function(response){
			KApp.unblock('#documents_storage_directory-name');
			vm.saveCallback(response, vm.modal);
		});
	};

	vm.saveCallback = saveCallback;
	return vm;
});
