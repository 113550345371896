kv.factory("$calendarAddEvent", function calendarAddEvent($uibModal) {
	/**
	 * type = add
	 * saveCallback - functia ce se executa la save
	 */
	return function (saveCallback, start, end, type, e, room, calendarEvent, deleteEvent) {
		let startDate = new dateClass(start);
		let endDate = new dateClass(end);
		// console.debug("calendarAddEvent", saveCallback, start, startDate);
		var modalInstance = $uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			windowClass: 'show',
			backdropClass: 'show',
			templateUrl: 'calendar-add-event',
			controller: calendarAddEventCtrl,
			controllerAs: 'vm',
			size: 'md',
			resolve: {
				type: function () {
					return type;
				},
				calendarEvent: function () {
					if (calendarEvent) {
						calendarEvent.start_date = startDate;
						calendarEvent.end_date = endDate;
					}
					return calendarEvent;
				},
				start: function () {
					return startDate;
				},
				end: function () {
					return endDate;
				},
				activity_id: function () {
					return null;
				},
				date: function () {
					return moment().format("YYYY-MM-DD");
				},
				room: function () {
					return room;
				},
				deleteEvent: function () {
					return deleteEvent;
				},
				saveCallback: function () {
					return saveCallback;
				}
			}
		});
	};
});