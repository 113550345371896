function TimesheetApprovalListMoveCtrl($uibModalInstance, data, resource, cmdate, $injector, $scope) {
	var vm = new modalCtrl($uibModalInstance, $injector, 'timesheet_approval', 'timesheet_id', $scope, data);
	vm.count = data.countLines;
	vm.data = {};

	//TODO internal_project?????? like in expense_approval-list-move
	/**
	 * mass update timesheet
	 * vm.data = new project id, new reference id, new user_id
	 */
	vm.saveMove = function () {
		let errors = {};
		if (vm.data.project_id && !vm.data.reference_id) errors.reference_id = "required";
		if (!vm.clientSideValidate(errors, 'data', $('.modal-content'))) return;
		vm.confirm(vm.trans("LANG.CONFIRM_MOVE"), vm.trans("LANG.CONFIRM_MOVE_TIMESHEET")).then(response => {
			if (response !== "ok") return;
			let postData = angular.copy(data.searchList);
			postData.changeTo = angular.copy(vm.data);
			postData.date_from = cmdate.toServer(postData.date_from);
			postData.date_to = cmdate.toServer(postData.date_to);
			KApp.block('.modal-content');
			vm.rest.update({
				url: vm.entity + '/moveTimesheets',
				data: postData,
				success: function () {
					vm.close('ok');
				}
			});
		});
	};

	return vm;
}
